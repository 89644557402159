import { Tag, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled(Typography.Title).attrs({
    level: 5,
})`
    margin-bottom: 0.25rem !important;
`;

export const Paragraph = styled(Typography.Paragraph)`
    margin: 0 !important;
`;

export const TagItem = styled(Tag)`
    &.ant-tag {
        margin-left: 0.5rem;
        border-radius: 0.5rem !important;
    }
`;
