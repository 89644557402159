import { Button, Col, Input, Row, Select } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { FormItem, RowGroupInput } from "../../../common/CustomForm";
import React from "react";
import validator from "../../../../assets/formValidator";
import AddressForm from "../AddressForm";

type MemberFormProps = {
    memIndex?: number;
    numMembers?: number;
    form: FormInstance<any>;
    popMember?: () => void;
    initVaccines?: number;
    isLegalEntity?: boolean;
    isNotRequired?: boolean;
    isRequireSSN?: boolean;
};

const MemberForm: React.FC<MemberFormProps> = (props) => {
    const [numVaccines, setVaccines] = React.useState<number>(
        props.form.getFieldValue(`numVaccines${props.memIndex}`) === 0 ? 0 : 1
    );
    React.useEffect(() => {
        props.form &&
            props.form.setFields([
                {
                    name: `numVaccines${props.memIndex}`,
                    value: numVaccines,
                },
            ]);
    }, [numVaccines, props.form, props.memIndex]);

    React.useEffect(() => {
        props.form.resetFields([`ssn${props.memIndex}`]);
    }, [props.isLegalEntity, props.memIndex, props.form]);

    return (
        <>
            {props.isLegalEntity ? (
                <RowGroupInput
                    inputs={[
                        {
                            name: `firstName${props.memIndex}`,
                            label: "ชื่อนิติบุคคล",
                            rules: [
                                { required: !props.isNotRequired, message: "กรุณากรอกชื่อ" },
                                ...validator.firstName,
                            ],
                            placeholder: "กรุณากรอกชื่อ",
                            flex: 0.5,
                        },
                    ]}
                />
            ) : (
                <RowGroupInput
                    inputs={[
                        {
                            name: `prefix${props.memIndex}`,
                            label: "คำนำหน้าชื่อ",
                            customInput: (
                                <Select placeholder="คำนำหน้าชื่อ">
                                    <Select.Option children="นาย" key="นาย" value="นาย" />
                                    <Select.Option children="นาง" key="นาง" value="นาง" />
                                    <Select.Option children="นางสาว" key="นางสาว" value="นางสาว" />
                                </Select>
                            ),
                            flex: 0.25,
                            rules: [
                                {
                                    required: !props.isNotRequired,
                                    message: "กรุณาเลือกคำนำหน้าชื่อ",
                                },
                            ],
                        },
                        {
                            name: `firstName${props.memIndex}`,
                            label: "ชื่อ",
                            rules: [
                                { required: !props.isNotRequired, message: "กรุณากรอกชื่อ" },
                                ...validator.firstName,
                            ],
                            placeholder: "กรุณากรอกชื่อ",
                        },
                        {
                            name: `lastName${props.memIndex}`,
                            label: "นามสกุล",
                            rules: [
                                { required: !props.isNotRequired, message: "กรุณากรอกนามสกุล" },
                                ...validator.firstName,
                            ],
                            placeholder: "กรุณากรอกนามสกุล",
                        },
                    ]}
                />
            )}
            {props.isRequireSSN && (
                <RowGroupInput
                    inputs={[
                        {
                            name: `ssn${props.memIndex}`,
                            label: props.isLegalEntity
                                ? "เลขทะเบียนนิติบุคคล"
                                : "รหัสประจำตัวประชาชน",
                            rules: props.isLegalEntity
                                ? [
                                      {
                                          required: !props.isNotRequired,
                                          pattern: /^[0-9]{13}$/,
                                          message: "กรุณากรอกเลขทะเบียนนิติบุคคล",
                                      },
                                  ]
                                : [...validator.ssn],
                            placeholder: props.isLegalEntity
                                ? "กรุณากรอกเลขทะเบียนนิติบุคคล"
                                : "กรุณากรอกรหัสประจำตัวประชาชน",
                        },
                    ]}
                />
            )}
            <RowGroupInput
                inputs={[
                    {
                        name: `phoneNumber${props.memIndex}`,
                        label: "เบอร์โทรศัพท์",
                        rules: [
                            {
                                required: !props.isNotRequired,
                                message: "กรุณากรอกเบอร์โทรศัพท์",
                            },
                            ...validator.phoneNumber,
                        ],
                        placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                    },
                ]}
            />
            <b>ที่อยู่</b>
            <AddressForm
                isNotRequired={props.isNotRequired}
                addressKey={`address${props.memIndex}`}
                provinceKey={`province${props.memIndex}`}
                districtKey={`district${props.memIndex}`}
                subDistrictKey={`subDistrict${props.memIndex}`}
                zipCodeKey={`zipCode${props.memIndex}`}
                form={props.form}
            />
            {!props.isLegalEntity && (
                <Row gutter={24}>
                    <Col span={6} style={{ width: 160, minWidth: 190 }}>
                        <b>ประวัติการฉีดวัคซีน</b>
                        <FormItem name={"numVaccines" + props.memIndex} style={{ display: "none" }}>
                            <Input style={{ display: "none" }} />
                        </FormItem>
                    </Col>
                    <Button
                        style={{ marginLeft: 12 }}
                        icon={<PlusCircleOutlined />}
                        size={"small"}
                        onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                    >
                        เพิ่มวัคซีน
                    </Button>
                </Row>
            )}

            {!props.isLegalEntity &&
                Array.from(Array(numVaccines).keys()).map((index) => (
                    <RowGroupInput
                        key={index}
                        inputs={[
                            {
                                name: `vaccine${props.memIndex}${index}`,
                                label: "วัคซีน",
                                rules: [{ required: true, message: "กรุณากรอกวัคซีน" }],
                                placeholder: "กรุณากรอกวัคซีน",
                            },
                            {
                                name: `vaccineDate${props.memIndex}${index}`,
                                label: "วันที่ฉีด",
                                rules: [{ required: true, message: "กรุณากรอกวันที่ฉีด" }],
                                placeholder: "กรุณากรอกวันที่ฉีด",
                                type: "calendar",
                            },
                        ]}
                        rightExtra={
                            index === numVaccines - 1 && (
                                <MinusCircleOutlined
                                    style={{
                                        alignSelf: "center",
                                        marginTop: "10px",
                                        fontSize: "32",
                                        color: "red",
                                    }}
                                    onClick={() => {
                                        setVaccines((numVaccines) => numVaccines - 1);
                                    }}
                                />
                            )
                        }
                    />
                ))}
        </>
    );
};

export default MemberForm;
