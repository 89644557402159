import { Col, Row } from "antd";
import React from "react";

// import useDashboard from "./useDashboard";
import { Container, FinalColumn, PageHeaderItem, RowMiddle } from "./index.styles";
import StatisticBoothCard from "../../components/DashboardPage/StatisticBoothCard";
import StatisticMeterCard from "../../components/DashboardPage/StatisticMeterCard";
import {
    StatisticShirtCard,
    StatisticBoothShirt,
} from "../../components/DashboardPage/StatisticShirtCard";
import StatisticLomsakCard from "../../components/DashboardPage/StatisticLomsakCard";
import StatisticIncomeCard from "../../components/DashboardPage/StatisticIncomeCard";
import IncomeColumnGraph from "../../components/DashboardPage/IncomeColumnGraph";

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = () => {
    return (
        <Container>
            <PageHeaderItem title="ภาพรวมทางการเงิน" />
            <Row gutter={16}>
                <Col xs={24} sm={12} xl={8} span={8}>
                    <StatisticBoothCard type="booth" />
                </Col>
                <Col xs={24} sm={12} xl={8} span={8}>
                    <StatisticBoothCard type="veggie" />
                </Col>
                <FinalColumn>
                    <StatisticMeterCard />
                </FinalColumn>
            </Row>
            <RowMiddle>
                <Col xs={24} sm={15} lg={16} xl={10} span={8}>
                    <StatisticShirtCard />
                </Col>
                <Col xs={24} sm={9} lg={8} xl={6} span={8}>
                    <StatisticBoothShirt />
                </Col>
                <FinalColumn>
                    <StatisticLomsakCard />
                </FinalColumn>
            </RowMiddle>
            <Row gutter={16}>
                <Col xs={24} sm={24} lg={24} xl={16} span={8}>
                    <IncomeColumnGraph />
                </Col>
                <FinalColumn>
                    <StatisticIncomeCard />
                </FinalColumn>
            </Row>
        </Container>
    );
};

export default Dashboard;
