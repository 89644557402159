import axios from "axios";
import { User, UserCreate } from "../interfaces/User";

export const getUserDetail = async (): Promise<User> => {
    const userData = await axios.get<User>("/users/detail");
    return userData.data;
};

export const getDailyReport = async (): Promise<{ [key: string]: number }> => {
    const res = await axios.get("/users/daily");
    return res.data;
};

export const createUser = async (user: UserCreate) => await axios.post("/users", user);

export const deleteUser = async (uid: string) => await axios.delete(`/users/${uid}`);