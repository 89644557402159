import React from "react";
import { Collapse, Card, Row, Select, Button, Tag, Image, Form, Spin, Empty } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { Container, Title, ModalCustom, TransactionList, ContainerCollapse } from "./index.style";
import dayjs from "dayjs";
import { FormItem } from "../TableBillingComponent/AddItemModal";
import TextArea from "antd/lib/input/TextArea";
import { getPreviousMonth } from "../../../functions/getPreviousMonth";
import { formatNumber } from "../../../functions/formatNumber";
import { useAuthContext } from "../../../providers/AuthProvider";
import useTransaction from "./useTransaction";
import { IBillingResponse } from "../../../interfaces/Billing";
import Swal from "sweetalert2";
import { confirmation } from "../../../assets/sweetalert.config";
import { IRevertTransaction } from "../../../interfaces/Transaction";
import useDetailTenant from "../DetailTenant/useDetailTenant";
import PrintContentComponent from "../../PrintContent";

type TransactionProps = {
    boothCode: string;
    contractId: string;
    billing?: IBillingResponse;
    type: "booth" | "veggie" | "lomsak" | "rider" | "labor";
};

const TransactionCard: React.FC<TransactionProps> = (props: TransactionProps) => {
    const { boothCode, billing, type, contractId } = props;
    const {
        transaction,
        isLoading,
        statusTransaction,
        fetchTransaction,
        setMonthYear,
        staffRevertTransaction,
    } = useTransaction(boothCode, contractId, billing);

    const [isModalSlip, setIsModalSlip] = React.useState(false);
    const [isModalVoidBills, setIsModalVoidBills] = React.useState(false);
    const [form] = Form.useForm();

    const onOpenSlip = (image: string) => {
        setIsModalSlip(true);
    };

    const handleClose = () => {
        setIsModalSlip(false);
        setIsModalVoidBills(false);
    };

    const { tenant } = useDetailTenant(type, boothCode);

    const onFinish = async (values: any) => {
        const result = await onAddAlert();
        if (result) {
            try {
                const body: IRevertTransaction = {
                    transactionId: values.transaction,
                    description: values.description,
                };
                await staffRevertTransaction(body);
                setIsModalVoidBills(false);
                form.resetFields();
                fetchTransaction();
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    const { user } = useAuthContext();

    const transactionOnlyUser = React.useMemo(() => {
        if (!transaction) return [];
        return (
            transaction &&
            transaction?.filter((item) => {
                return item.collectorId === user?.uid && item.status === "standby";
            })
        );
    }, [transaction, user]);

    React.useEffect(() => {
        fetchTransaction();
    }, [fetchTransaction]);

    return (
        <Container>
            <Card bordered={false} title="การชำระเงิน">
                <Row className="mt-1" justify="space-between">
                    <Select
                        defaultValue={getPreviousMonth(6)[0].month}
                        onSelect={(value: string) => {
                            setMonthYear(+value);
                        }}
                    >
                        {getPreviousMonth(6).map((item, index) => (
                            <Select.Option value={item.unix} key={index}>
                                {item.month}
                            </Select.Option>
                        ))}
                    </Select>
                    <Button
                        type="primary"
                        icon={<FormOutlined />}
                        onClick={() => setIsModalVoidBills(true)}
                    >
                        ขอแก้บิล
                    </Button>
                </Row>
                <ContainerCollapse>
                    {isLoading && tenant ? (
                        transaction && transaction?.length > 0 ? (
                            <Collapse bordered={false} defaultActiveKey={["1"]}>
                                {transaction.map((item, index) => (
                                    <Collapse.Panel
                                        header={
                                            <Title>
                                                <div>
                                                    {statusTransaction(item.status)}
                                                    <p className="amount">
                                                        {formatNumber(item.amount)}
                                                    </p>
                                                </div>
                                                <p>
                                                    {dayjs
                                                        .unix(item.date / 1000)
                                                        .format("HH:mm, DD/MM/BBBB")}
                                                </p>
                                            </Title>
                                        }
                                        key={`${item.transactionId}${index}`}
                                    >
                                        {item.status !== "standby" ? (
                                            <p className="description">{item.description}</p>
                                        ) : null}
                                        {item.slipURL ? (
                                            <p>
                                                โอนจ่าย
                                                <Tag
                                                    color="success"
                                                    onClick={() => onOpenSlip(item.slipURL || "")}
                                                >
                                                    ดูสลิป
                                                </Tag>
                                                <ModalCustom
                                                    title="สลิปการจ่ายเงิน"
                                                    visible={isModalSlip}
                                                    onOk={handleClose}
                                                    onCancel={handleClose}
                                                    cancelButtonProps={{
                                                        style: { display: "none" },
                                                    }}
                                                >
                                                    <Image src={item.slipURL} preview={false} />
                                                </ModalCustom>
                                            </p>
                                        ) : (
                                            <p>เงินสด</p>
                                        )}

                                        <p>
                                            ​
                                            {item.status === "standby"
                                                ? "ผู้รับเงิน : "
                                                : "ผู้ขอยกเลิก : "}
                                            {item.collectorName}
                                        </p>

                                        {item.status === "approved" || item.status === "denied" ? (
                                            <Row>
                                                <p>ผู้รับคำร้อง : {item.adminName}</p>
                                                <Tag color="warning">
                                                    {item.status === "denied" ? "ไม่" : ""} อนุมัติ
                                                </Tag>
                                            </Row>
                                        ) : null}
                                        {item.status === "standby" ? (
                                            <PrintContentComponent
                                                transactionId={item.transactionId}
                                                type={type}
                                                slipURL={item.slipURL}
                                                tenant={tenant}
                                            />
                                        ) : null}
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        ) : (
                            <Empty />
                        )
                    ) : (
                        <Spin />
                    )}
                </ContainerCollapse>
            </Card>

            <ModalCustom
                title="ส่งคำร้องของแก้บิล"
                visible={isModalVoidBills}
                onOk={() => form.submit()}
                onCancel={handleClose}
            >
                <Form form={form} onFinish={onFinish}>
                    <FormItem label="รายการชำระเงิน" name="transaction" required>
                        <Select placeholder="เลือกรายการชำระเงิน">
                            {transactionOnlyUser &&
                                transactionOnlyUser.map((item, index) => (
                                    <Select.Option
                                        value={item.transactionId}
                                        key={`${item.transactionId}${index}`}
                                    >
                                        <TransactionList>
                                            <p>ชำระเงิน {formatNumber(item.amount)}</p>
                                            <p className="date">
                                                {dayjs
                                                    .unix(item.date / 1000)
                                                    .format("HH:mm, DD/MM/BBBB")}{" "}
                                                | {item.slipURL ? "โอนจ่าย" : "เงินสด"}
                                            </p>
                                        </TransactionList>
                                    </Select.Option>
                                ))}
                        </Select>
                    </FormItem>

                    <FormItem label="ระบุสาเหตุที่ต้องการยกเลิก" name="description" required>
                        <TextArea
                            placeholder="เช่น ใส่จำนวนเงินผิด "
                            autoSize={{ minRows: 4, maxRows: 10 }}
                            showCount
                            maxLength={150}
                        />
                    </FormItem>
                    <p className="warning-word">
                        เมื่อกดยกเลิกแล้วรายการชำระเงินนี้จะถูกส่งไปที่แอดมิน และรอการอนุมัติ
                    </p>
                </Form>
            </ModalCustom>
        </Container>
    );
};

export const onAddAlert = async () => {
    const result = await Swal.fire({
        ...confirmation({
            title: "ยืนยันการส่งคำร้องขอการยกเลิกบิล",
            text: `คุณต้องการยืนยันการส่งคำร้องขอการยกเลิกบิลนี้ใช่หรือไม่?`,
        }),
    });
    return result.value;
};
export default TransactionCard;
