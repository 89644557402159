import { ColumnsType } from "antd/lib/table";
import { formatNumber } from "../../../functions/formatNumber";
import StatusTypeComponents from "../../common/TableBillingComponent/StatusTypeComponents";

export const columns: ColumnsType<any> = [
    {
        title: "รายการ",
        dataIndex: "name",
        key: "name",
        render: (_: string, record: any, __: number) => <StatusTypeComponents record={record} />,
    },
    {
        title: "ยอดค้างชำระ",
        dataIndex: "amount",
        width: "30%",
        key: "amount",
        align: "center",
        render: (_: string, record: any, __: number) =>
            formatNumber(isNaN(record.amount) ? 0 : record.amount),
    },
];
