import React from "react";
import { User } from "../interfaces/User";
// import { timeout } from "../functions/delay";
import { showNotification } from "../functions/notification";
import axios, { AxiosError } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { getUserDetail } from "../apis/user";

const AuthContext = React.createContext<{
    user?: User;
    refreshUserData: () => void;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
}>({
    refreshUserData: () => {},
    login: () => {},
    logout: () => {},
    isLoading: false,
});

function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<User>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const navigate = useNavigate();
    const location = useLocation();

    const refreshUserData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const userData = await getUserDetail();
            if (!userData) throw new Error("User data is empty");
            userData.roles = [userData.role, ...userData.duty];
            if (userData.roles.length === 0) throw new Error("User roles is empty");
            setUser(userData);
            setUser((user) => (userData ? { ...user, ...userData } : undefined));
        } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status === 403) showNotification("กรุณาเข้าสู่ระบบอีกครั้ง", "info");
            else showNotification(err.response?.data.message, "error");
            localStorage.removeItem("token");
            axios.defaults.headers.common = { Authorization: "" };
        } finally {
            navigate("/");
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserData = React.useCallback(
        async (token) => {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${token}`,
            };
            await refreshUserData();
            navigate(location.pathname + location.search, {
                replace: true,
                state: { from: location },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refreshUserData]
    );

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) getUserData(token);
        else setIsLoading(false);
    }, [getUserData]);

    const logout = React.useCallback(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        axios.defaults.headers.common = { Authorization: "" };
        setUser(undefined);
        navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = React.useCallback(async () => {
        const authGoogle = getAuth();
        const googleProvider = new GoogleAuthProvider();
        try {
            const res = await signInWithPopup(authGoogle, googleProvider);
            const token = await res.user?.getIdToken();
            localStorage.setItem("token", token);
            axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
            await refreshUserData();
        } catch (err) {
            if (err instanceof FirebaseError) {
                if (err.code === "auth/popup-closed-by-user") return;
                else if (err.code === "auth/cancelled-popup-request") return;
                else if (err.code === "auth/id-token-expired") return;
                return showNotification(err.code, "error");
            }
            return showNotification("เกิดข้อผิดพลาดบางประการ", "error");
        }
    }, [refreshUserData]);

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const err = error as AxiosError;
            console.log({ err });

            if (err.response?.status === 403) logout();

            return Promise.reject(error);
        }
    );

    return (
        <AuthContext.Provider
            value={{
                user,
                refreshUserData,
                logout,
                isLoading,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthContext = () => React.useContext(AuthContext);
export default AuthProvider;
