import styled from "styled-components";
import breakpoint from "../../assets/breakpoint.json";
import colors from "../../assets/colors.json";
export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    flex-wrap: wrap;
    padding: 12px 0;
    @media (max-width: ${breakpoint.mobile}) {
        flex-direction: column;
    }
`;

export const HeaderSideContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (max-width: ${breakpoint.mobile}) {
        flex-direction: column;
        flex: 1;

        & > * {
            flex: 1 !important;
        }
    }
`;

export const SubRouter = styled.div`
    border-bottom: 1px solid ${colors.border};
    padding: 1rem 0;

    & span {
        padding: 1rem 0.5rem;
        cursor: pointer;
        color: ${colors.hintText};
    }
    & span.active {
        color: ${colors.primary};
        border-bottom: 2px solid ${colors.primary};
    }
`;
