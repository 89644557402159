import axios from "axios";
import { MemberType } from "../interfaces/MemberManagement";

export const getAllAdmin = async (): Promise<MemberType[]> => {
    const res = await axios.get<MemberType[]>("/users/admin");
    return res.data;
};

export const deleteAdmin = async (admin: MemberType) => {
    const res = await axios.delete(`/users/${admin.uid}`);
    return res.data;
};

export const createAdmin = async (admin: Partial<MemberType>) => {
    const res = await axios.post("/users/admin", admin);
    return res.data;
}


export const updateAdmin = async (admin: Partial<MemberType>) => {
    const res = await axios.patch(`/users/admin`, admin);
    return res.data;
}