import React from "react";
import Swal from "sweetalert2";
import { Avatar, Form, message, notification, Row, Spin } from "antd";
import { useAdminDetailContext } from "../../../pages/AdminManagement/useAdminDetail";
import { CustomModal, SubmitButton } from "./index.style";
import { UploadFile } from "antd/lib/upload/interface";
import * as swlConfig from "../../../assets/sweetalert.config";
import CustomUpload, { UploadButton } from "../../common/CustomUpload";
import { RowGroupInput } from "../../common/CustomForm";
import { UploadChangeParam } from "antd/lib/upload";
import { antdFileToBase64, base64ToBlob } from "../../../functions/files";
import { uploadImageReturnUrl } from "../../../functions/storage";
import validator from "../../../assets/formValidator";
import { updateAdmin } from "../../../apis/admin";
import { useAuthContext } from "../../../providers/AuthProvider";

type MemberFormProps = {
    name: string;
    email: string;
    mainDuty: string;
    ssn: string;
    tel: string;
    dutyPool: string[];
};

const ModalUpdateAdmin = () => {
    const {
        openModalUpdateAdmin,
        setOpenModalUpdateAdmin,
        isLoading,
        fetchDataPage,
        setIsLoading,
        adminUpdate,
    } = useAdminDetailContext();
    const [form] = Form.useForm<MemberFormProps>();
    const { user, refreshUserData } = useAuthContext();

    const [imageBase64, setImageBase64] = React.useState<string>();
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();

    React.useEffect(() => {
        if (!adminUpdate) return setOpenModalUpdateAdmin(false);
        form.setFieldsValue(adminUpdate);
        setImageBase64(adminUpdate?.photoURL);
    }, [adminUpdate, form, setOpenModalUpdateAdmin, openModalUpdateAdmin]);

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadImage = React.useCallback(async () => {
        if (!imageBase64) return;
        const blob = await base64ToBlob(imageBase64);
        const urls = await uploadImageReturnUrl("images/users", [blob]);
        return urls[0];
    }, [imageBase64]);

    const onCancel = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "ยกเลิกการเพิ่มผู้ดูแล!",
                text: `กรุณายืนยันเพื่อยกเลิกการเพิ่มผู้ดูแล`,
            })
        );
        if (isConfirmed) return setOpenModalUpdateAdmin(false);
    }, [setOpenModalUpdateAdmin]);

    const onFinish = React.useCallback(
        async (data: MemberFormProps) => {
            setIsLoading(true);
            const photoURL =
                imageBase64 === adminUpdate?.photoURL ? imageBase64 : await uploadImage();
            try {
                await updateAdmin({
                    name: data.name,
                    photoURL: photoURL || "",
                    uid: adminUpdate?.uid,
                    tel: data.tel,
                });
                setOpenModalUpdateAdmin(false);
                form.resetFields();
                setImageBase64(undefined);
                user?.uid === adminUpdate?.uid && refreshUserData();
                await fetchDataPage();
            } catch (error) {
                message.error("เกิดข้อผิดพลาดบางอย่าง");
            } finally {
                setIsLoading(false);
            }
        },
        [
            uploadImage,
            setOpenModalUpdateAdmin,
            fetchDataPage,
            setIsLoading,
            form,
            adminUpdate,
            imageBase64,
            user,
            refreshUserData,
        ]
    );

    return (
        <CustomModal
            visible={openModalUpdateAdmin}
            destroyOnClose
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <Form form={form} onFinish={onFinish}>
                    <Row justify="center">
                        <CustomUpload
                            error={imageError}
                            name="รูปผู้ดูแล"
                            listType="picture-card"
                            showUploadList={false}
                            onChange={onChangeImage}
                            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                        >
                            {imageBase64 ? (
                                <Avatar
                                    src={imageBase64}
                                    alt="avatar"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            ) : (
                                <UploadButton loadingImage={loadingImage} imageError={imageError} />
                            )}
                        </CustomUpload>
                    </Row>
                    <RowGroupInput
                        inputs={[
                            {
                                name: "name",
                                label: "ชื่อ - สกุล",
                                placeholder: "กรุณากรอกชื่อ - สกุล",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกชื่อ - สกุล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                disabled: true,
                                name: "email",
                                label: "อีเมล",
                                placeholder: "กรุณากรอกอีเมล",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกอีเมล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: `tel`,
                                label: "เบอร์โทรศัพท์",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกเบอร์โทรศัพท์",
                                    },
                                    ...validator.phoneNumber,
                                ],
                                placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <Row justify="center" style={{ marginTop: 12 }}>
                        <SubmitButton htmlType="submit">บันทึก</SubmitButton>
                    </Row>
                </Form>
            </Spin>
        </CustomModal>
    );
};

export default ModalUpdateAdmin;
