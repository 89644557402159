import { Drawer } from "antd";
import styled from "styled-components";

export const DrawerContainer = styled(Drawer).attrs({
    placement: "bottom",
    closeIcon: null,
})`
    & .ant-drawer-content-wrapper {
        height: 60vh !important;
        @media screen and (max-width: 540px) {
            height: 55vh !important;
        }
    }

    & .ant-drawer-content {
        border-radius: 1rem;
    }

    & .ant-drawer-body {
        padding: 0;
        position: relative;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
