import { Typography } from "antd";
import styled from "styled-components";
import breakpoint from "../../../assets/breakpoint.json";

type IStatus = {
    color: string;
};

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0.5rem;
    width: calc(1 / 6 * 100% - 1rem);
    padding-bottom: 2rem;
    height: fit-content;
    overflow: hidden;
    word-break: break-all;
    min-height: 90px;
    max-height: 90px;

    cursor: pointer;
    @media (max-width: 820px) {
        width: calc(1 / 5 * 100% - 1rem);
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    @media (max-width: ${breakpoint.tablet}) {
        width: calc(1 / 5 * 100% - 1rem);
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    @media (max-height: ${breakpoint.tablet}) and (orientation: landscape) {
        width: calc(1 / 5 * 100% - 1rem);
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    @media (max-width: ${breakpoint.mobile}) {
        width: calc(1 / 3 * 100% - 1rem);
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }

    ${(props: { status: string; canAccessInactive?: boolean }) =>
        props.status === "inactive" && `background-color: #f3f3f3; cursor: not-allowed;`}
    ${(props) => props.canAccessInactive && `cursor: pointer; background-color: #fff;`}
`;

export const TagStatus = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props: IStatus) => props.color};

    @media (max-width: 820px) {
        width: 12px;
        height: 12px;
        margin-top: 0.1rem;
    }
    @media (max-width: ${breakpoint.tablet}) {
        width: 12px;
        height: 12px;
        margin-top: 0.1rem;
    }
    @media (max-width: ${breakpoint.mobile}) {
        width: 12px;
        height: 12px;
        margin-top: 0rem;
    }
`;

export const Paragraph = styled(Typography.Paragraph)`
    margin: 0;
    font-size: 0.7rem;

    @media (max-width: ${breakpoint.mobile}) {
        font-size: 0.6rem;
    }
`;

export const Extra = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 0.75rem;
    font-weight: bold;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 820px) {
        margin-top: 0.25rem;
    }

    @media (max-width: ${breakpoint.tablet}) {
        margin-top: 0.25rem;
    }
`;
