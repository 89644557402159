import React from "react";
import Breadcrumb from "../../../components/TrackMeter/Breadcrumb";
import { Row, Col, Spin } from "antd";
import { CardBuildingSelectorArray } from "../../../components/common/CardBuildingSelector";
import LayoutContract from "../../../components/common/LayoutContract";
import MeterModal from "../../../components/common/MeterModal";
import { ZoneBuilding, ZoneBuildingType } from "../../../interfaces/Register";
import { catchingError } from "../../../functions/notification";
import { getBuildingType, getZoneByBuildingNumber } from "../../../apis/zone";

export const MeterBoothElectricity = () => {
    const [buildingStorage, setBuildingStorage] = React.useState<ZoneBuilding[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [buildingSelect, setBuildingSelect] = React.useState<string>("");
    const [booth, setBooth] = React.useState<{ boothCode: string; boothName: string }>();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const handleCancel = () => setIsModalVisible(false);
    const handleBooth = () => {
        setIsModalVisible(true);
    };
    const [zone, setZone] = React.useState<ZoneBuildingType[]>([]);

    const fetchZone = React.useCallback(async (buildingNumber: number) => {
        const zone = await getZoneByBuildingNumber("booth", buildingNumber);
        setZone(zone);
    }, []);

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const building = await getBuildingType("booth");
            setBuildingStorage(building);
            if (buildingSelect) {
                const indexing = building.findIndex((item) => item.buildingName === buildingSelect);
                if (indexing !== -1) {
                    setBuildingSelect(building[indexing].buildingName);
                    await fetchZone(building[indexing].buildingNumber);
                    return;
                }
            }
            setBuildingSelect(building[0].buildingName);
            await fetchZone(building[0].buildingNumber);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [buildingSelect, fetchZone]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSelectBuilding = React.useCallback(
        async (buildingName: string, buildingNumber: number) => {
            setIsLoading(true);
            setBuildingSelect(buildingName);
            try {
                await fetchZone(buildingNumber);
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchZone]
    );

    return (
        <div>
            <Breadcrumb labels={["แผงรายเดือน", "จดมิเตอร์ไฟฟ้า", buildingSelect]} />
            <Spin spinning={isLoading}>
                <Row justify="space-between">
                    <Col xs={24} sm={24} lg={9}>
                        <CardBuildingSelectorArray
                            type="booth"
                            data={buildingStorage}
                            onSelect={onSelectBuilding}
                            buildingSelect={buildingSelect}
                            pageType="booth"
                        />
                    </Col>
                    <Col xs={24} sm={24} lg={14}>
                        <LayoutContract
                            data={zone}
                            keyName="boothName"
                            pageType="booth"
                            trigger={handleBooth}
                            getData={setBooth}
                            disableStatus={true}
                            canAccessInactive={true}
                        />
                    </Col>
                    {booth && (
                        <MeterModal
                            title={`แผงรายเดือน  ${buildingSelect} แผง ${booth?.boothName}`}
                            visible={isModalVisible}
                            handleCancel={handleCancel}
                            booth={booth}
                            headMeter={"จดมิเตอร์ไฟฟ้า"}
                            typeBooth="booth"
                            typeMeter={"electricity"}
                        ></MeterModal>
                    )}
                </Row>
            </Spin>
        </div>
    );
};

export const MeterBoothWater = () => {
    const [buildingStorage, setBuildingStorage] = React.useState<ZoneBuilding[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [buildingSelect, setBuildingSelect] = React.useState<string>("");
    const [booth, setBooth] = React.useState<{ boothCode: string; boothName: string }>();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const handleCancel = () => setIsModalVisible(false);
    const handleBooth = () => {
        setIsModalVisible(true);
    };
    const [zone, setZone] = React.useState<ZoneBuildingType[]>([]);

    const fetchZone = React.useCallback(async (buildingNumber: number) => {
        const zone = await getZoneByBuildingNumber("booth", buildingNumber);
        setZone(zone);
    }, []);

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const building = await getBuildingType("booth");
            setBuildingStorage(building);
            if (buildingSelect) {
                const indexing = building.findIndex((item) => item.buildingName === buildingSelect);
                if (indexing !== -1) {
                    setBuildingSelect(building[indexing].buildingName);
                    await fetchZone(building[indexing].buildingNumber);
                    return;
                }
            }
            setBuildingSelect(building[0].buildingName);
            await fetchZone(building[0].buildingNumber);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [buildingSelect, fetchZone]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSelectBuilding = React.useCallback(
        async (buildingName: string, buildingNumber: number) => {
            setIsLoading(true);
            setBuildingSelect(buildingName);
            try {
                await fetchZone(buildingNumber);
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchZone]
    );

    return (
        <div>
            <Breadcrumb labels={["แผงรายเดือน", "จดมิเตอร์น้ำ", buildingSelect]} />
            <Spin spinning={isLoading}>
                <Row justify="space-between">
                    <Col xs={24} sm={24} lg={9}>
                        <CardBuildingSelectorArray
                            type="booth"
                            data={buildingStorage}
                            onSelect={onSelectBuilding}
                            buildingSelect={buildingSelect}
                            pageType="booth"
                        />
                    </Col>

                    <Col xs={24} sm={24} lg={14}>
                        <LayoutContract
                            data={zone}
                            keyName="boothName"
                            pageType="booth"
                            trigger={handleBooth}
                            getData={setBooth}
                            disableStatus={true}
                            canAccessInactive={true}
                        />
                    </Col>
                    {booth && (
                        <MeterModal
                            title={`แผงรายเดือน  ${buildingSelect} แผง ${booth?.boothName}`}
                            visible={isModalVisible}
                            handleCancel={handleCancel}
                            booth={booth}
                            headMeter={"จดมิเตอร์น้ำ"}
                            typeMeter={"water"}
                            typeBooth="booth"
                        ></MeterModal>
                    )}
                </Row>
            </Spin>
        </div>
    );
};
