import {
    CardContainer,
    CardEmpty,
    Container,
    FooterContainer,
    InputSearch,
    Paragraph,
    RowContainer,
    TagStatus,
} from "./index.styles";
import CardContract from "../CardContract";
import colors from "../../../assets/colors.json";
import React from "react";
import { Empty } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { RolePagesType } from "../../../assets/typeEnumTranslation";
import { ZoneBuildingType } from "../../../interfaces/Register";

dayjs.extend(isBetween);

type INote = {
    color: string;
    text: string;
};

const note: INote[] = [
    {
        color: colors.primary,
        text: "ชำระครบแล้ว",
    },
    {
        color: colors.remain,
        text: "มียอดค้างชำระภายในเดือนนี้",
    },
    {
        color: colors.error,
        text: "ผู้เช่ามียอดค้างมาจากเดือนก่อนที่ยังไม่ได้ชำระ",
    },
];

/*
    * Function for render card contract
    * use getData to get data from card contract (pass the setState to it)
    * use trigger to trigger function from card contract ex. open modal
    * use pageType to set header of card contract
    * use keyName to set key for search of data
    
    * @Note : any[] type in data is a mockup. it will change when know all of data usage
*/

// TODO: change data type if know all of data usage
type ComponentProps = {
    data?: ZoneBuildingType[];
    disableStatus: boolean;
    keyName: string;
    altKeyName?: string;
    pageType: RolePagesType;
    trigger?: () => void;
    getData?: React.Dispatch<React.SetStateAction<any>>;
    canAccessInactive?: boolean;
};

function LayoutContract(props: ComponentProps) {
    const { data, keyName, pageType, disableStatus, altKeyName, canAccessInactive } = props;
    const [searchKey, setSearchKey] = React.useState<string>("");

    const renderNote = note.map((item, index) => (
        <RowContainer key={index}>
            <TagStatus color={item.color} />
            <Paragraph strong>{item.text}</Paragraph>
        </RowContainer>
    ));

    const SearchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value);

    const handleSearch = React.useCallback(
        (search: string, list?: any[]) =>
            list && list.filter
                ? list.filter(
                      (item) =>
                          item[keyName]?.toLowerCase().includes(search.toLowerCase()) ||
                          item[altKeyName!]?.toLowerCase().includes(search.toLowerCase())
                  )
                : [],
        [keyName, altKeyName]
    );

    return (
        <Container>
            <InputSearch
                placeholder="ค้นหา"
                onChange={SearchKeys}
                value={searchKey}
                style={{ width: "100%" }}
            />
            {handleSearch(searchKey, data).length === 0 ? (
                <CardEmpty>
                    <Empty description="ไม่พบผลการค้นหาข้อมูล" />
                </CardEmpty>
            ) : (
                <CardContainer>
                    {handleSearch(searchKey, data).map((item, index) => (
                        <CardContract
                            key={index}
                            keyName={item[keyName] ? keyName : altKeyName!}
                            paymentStatus={item.paymentStatus}
                            status={item.status}
                            data={item}
                            pageType={pageType}
                            trigger={props.trigger ? props.trigger : () => {}}
                            getData={props.getData}
                            disableStatus={disableStatus}
                            canAccessInactive={canAccessInactive}
                        />
                    ))}
                </CardContainer>
            )}
            {!disableStatus && <FooterContainer>{renderNote}</FooterContainer>}
        </Container>
    );
}

export default LayoutContract;
