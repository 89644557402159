import React from "react";
import { Container, MenuWorkValueContainer, CustomInputNumber } from "./index.style";
import {
    WorkManagementCardListMenuType,
    WorkManagementCardListType,
} from "../../../../interfaces/MemberManagement";
import { Form } from "antd";
import { useWorkManagementContext } from "../../../../pages/MemberManagement/Staff/WorkManagement/useWorkManagment";

const WorkManagementCardListInput = (props: WorkManagementCardListMenuType) => {
    const [changed, setChanged] = React.useState(false);
    const { isLoading, fetching } = useWorkManagementContext();
    React.useEffect(() => setChanged(false), [fetching]);
    return (
        <MenuWorkValueContainer>
            <label>
                {props.label}
                <span> (สูงสุด {props.maxValue} คน)</span>
            </label>

            <Form.Item style={{ display: "flex", alignItems: "center" }} name={props?.name}>
                <CustomInputNumber
                    disabled={isLoading}
                    type="number"
                    style={{
                        backgroundColor: changed ? "#FAFFAF" : undefined,
                        textAlign: "center",
                    }}
                    onChange={(val) => {
                        setChanged(val !== props.value);
                    }}
                    min={0}
                    max={props.maxValue}
                />
            </Form.Item>
        </MenuWorkValueContainer>
    );
};

function WorkManagementCardList(props: WorkManagementCardListType) {
    return (
        <Container>
            <h3>{props.title}</h3>
            {props.menu.map((item) => (
                <WorkManagementCardListInput key={item.name} {...item} />
            ))}
        </Container>
    );
}

export default WorkManagementCardList;
