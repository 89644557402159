import React from "react";
import { RowGroupInput } from "../../../common/CustomForm";
import { FormInstance, Select } from "antd";
import {
    AddressText,
    FormItem,
    InputContainer,
    InputItem,
    TextAreaInput,
    TextPara,
} from "./index.styles";
import validator from "../../../../assets/formValidator";
import formValidator from "../../../../assets/formValidator";

export type FormInstanceType = "booth" | "veggie" | "rider" | "member" | "labor" | "lomsak";
type IFormContentProps = {
    form: FormInstance<any>;
    type: Omit<FormInstanceType, "inner">;
    editState: boolean;
    isCompany: boolean;
};

type FormContentItemProp = {
    type: Omit<FormInstanceType, "inner">[];
    title: string;
    name: string;
    editable?: boolean;
    rules?: any;
};

const FormContentItem: (isCompany: boolean) => FormContentItemProp[] = (isCompany: boolean) => [
    {
        type: isCompany ? [] : ["booth", "veggie", "rider", "labor", "lomsak"],
        title: "คำนำหน้าชื่อ",
        name: "prefix",
        editable: true,
        rules: [
            {
                required: true,
                message: "กรุณากรอกคำนำหน้าชื่อ",
            },
        ],
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak"],
        title: isCompany ? "ชื่อบริษัท" : "ชื่อจริง",
        name: isCompany ? "companyName" : "firstName",
        editable: true,
        rules: [
            {
                required: true,
                message: isCompany ? "กรุณากรอกชื่อบริษัท" : "กรุณากรอกชื่อจริง",
            },
            ...formValidator.firstName,
        ],
    },
    {
        type: isCompany ? [] : ["booth", "veggie", "rider", "labor", "lomsak"],
        title: "นามสกุล",
        name: "lastName",
        editable: true,
        rules: [
            {
                required: true,
                message: "กรุณากรอกนามสกุล",
            },
            ...formValidator.lastName,
        ],
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak"],
        title: "วันที่เริ่มต้นสัญญา",
        name: "startDate",
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak"],
        title: "วันที่สิ้นสุดสัญญา",
        name: "endDate",
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak", "inner"],
        title: isCompany ? "เลขทะเบียนนิติบุคคล" : "เลขบัตรประจำตัวประชาชน",
        name: isCompany ? "companyID" : "ssn",
        editable: true,
        rules: isCompany
            ? [
                  {
                      required: true,
                      message: "กรุณากรอกเลขทะเบียนนิติบุคคล",
                  },
              ]
            : [
                  {
                      required: true,
                      message: "กรุณากรอกเลขบัตรประจำตัวประชาชน",
                  },
                  ...formValidator.ssn,
              ],
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak", "inner"],
        title: "เบอร์โทรศัพท์ที่ติดต่อได้",
        name: "tel",
        editable: true,
        rules: [
            {
                required: true,
                message: "กรุณากรอกเบอร์โทรศัพท์",
            },
            ...formValidator.phoneNumber,
        ],
    },
    {
        type: ["labor", "rider", "booth", "veggie", "lomsak"],
        title: "อาคาร",
        name: "building",
    },
    {
        type: ["booth", "veggie", "lomsak"],
        title: "แผง",
        name: "boothName",
    },
    {
        type: ["booth", "veggie"],
        title: "ราคาแผง",
        name: "boothPrice-monthly",
    },
    {
        type: ["booth", "veggie"],
        title: "ค่ารายปี",
        name: "boothPrice-yearly",
    },
    {
        type: ["booth", "veggie"],
        title: "ค่าประกัน",
        name: "boothPrice-garantee",
    },
    {
        type: ["lomsak"],
        title: "ราคาที่จอด",
        name: "boothPrice-monthly",
    },
    {
        type: ["lomsak"],
        title: "ค่ารายปี",
        name: "boothPrice-yearly",
    },
    {
        type: ["labor", "rider"],
        title: "เลขเสื้อ",
        name: "shirtNumber",
    },
    {
        type: ["labor", "rider"],
        title: "ราคาค่าเสื้อรายเดือน",
        name: "boothPrice-monthly",
    },
    {
        type: ["labor", "rider"],
        title: "ราคาค่าเสื้อรายปี",
        name: "boothPrice-yearly",
    },
    {
        type: ["labor", "rider"],
        title: "ราคาค่ารักษาสิทธิ์ (รายเดือน)",
        name: "boothPrice-maintaining",
    },
    {
        type: ["lomsak"],
        title: "ประเภทรถ",
        name: "size",
    },
];

function FormContent(props: IFormContentProps) {
    const { form, type, editState, isCompany } = props;

    const convertArrayToString = (arr?: string[]) => {
        if (arr && arr.length > 0) {
            return arr.join(", ");
        }
        return "-";
    };

    return (
        <>
            {FormContentItem(isCompany).map((item, index) => {
                return item.type.includes(type) ? (
                    <InputContainer key={index}>
                        <TextPara strong>{item.title}</TextPara>
                        <FormItem name={item.name} hasFeedback={item.editable} rules={item.rules}>
                            {item.editable ? (
                                <InputItem readOnly={!editState} editable={`${editState}`} />
                            ) : (
                                <InputItem readOnly />
                            )}
                        </FormItem>
                        {/* {item.editable ? (
                                item.name.includes("Date") ? (
                                    <DatePicker
                                        format={"DD/MM/YYYY"}
                                        clearIcon={false}
                                        inputReadOnly
                                    />
                                ) : (
                                    <InputItem readOnly={!editState} editable={`${editState}`} />
                                )
                            ) : item.name.includes("Date") ? (
                                <DatePicker
                                    format={"DD/MM/YYYY"}
                                    clearIcon={false}
                                    inputReadOnly
                                    open={false}
                                />
                            ) : (
                                <InputItem readOnly />
                            )} */}
                    </InputContainer>
                ) : (
                    <React.Fragment key={index}></React.Fragment>
                );
            })}
            {!editState ? (
                <>
                    {["booth", "veggie"].includes(type as string) && (
                        <InputContainer>
                            <TextPara strong>ช่องจอดรถ</TextPara>
                            <FormItem hasFeedback>
                                <InputItem
                                    readOnly
                                    value={convertArrayToString(form.getFieldValue("parkingList"))}
                                />
                            </FormItem>
                        </InputContainer>
                    )}
                    {["booth"].includes(type as string) && (
                        <InputContainer>
                            <TextPara strong>เสื้อแผง</TextPara>
                            <FormItem hasFeedback>
                                <InputItem
                                    readOnly
                                    value={convertArrayToString(
                                        form.getFieldValue("laborShirtList")
                                    )}
                                />
                            </FormItem>
                        </InputContainer>
                    )}
                    <InputContainer>
                        <TextPara strong>ที่อยู่ปัจจุบัน</TextPara>
                        <FormItem name="full-address" hasFeedback>
                            <TextAreaInput readOnly autoSize={{ maxRows: 5 }} />
                        </FormItem>
                    </InputContainer>
                </>
            ) : (
                <>
                    {["booth", "veggie"].includes(type as string) && (
                        <InputContainer>
                            <TextPara strong>ช่องจอดรถ</TextPara>
                            <FormItem name="parkingList" hasFeedback>
                                <Select mode="tags" style={{ width: "100%" }} />
                            </FormItem>
                        </InputContainer>
                    )}
                    {["booth"].includes(type as string) && (
                        <InputContainer>
                            <TextPara strong>เสื้อแผง</TextPara>
                            <FormItem name="laborShirtList" hasFeedback>
                                <Select mode="tags" style={{ width: "100%" }} />
                            </FormItem>
                        </InputContainer>
                    )}
                    <AddressText strong>ที่อยู่ปัจจุบัน</AddressText>
                    <RowGroupInput
                        inputs={[
                            {
                                name: `address-address`,
                                label: "ที่อยู่",
                                rules: [{ required: true, message: "กรุณากรอกที่อยู่" }],
                                placeholder: "กรุณากรอกที่อยู่",
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: `address-province`,
                                label: "จังหวัด",
                                rules: [{ required: true, message: "กรุณากรอกจังหวัด" }],
                                placeholder: "กรุณากรอกจังหวัด",
                            },
                            {
                                name: `address-district`,
                                label: "อำเภอ",
                                rules: [{ required: true, message: "กรุณากรอกอำเภอ" }],
                                placeholder: "กรุณากรอกอำเภอ",
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: `address-subDistrict`,
                                label: "ตำบล",
                                rules: [{ required: true, message: "กรุณากรอกตำบล" }],
                                placeholder: "กรุณากรอกตำบล",
                            },
                            {
                                name: `address-postCode`,
                                label: "รหัสไปรษณีย์",
                                rules: [
                                    { required: true, message: "กรุณากรอกรหัสไปรษณีย์" },
                                    ...validator.zipCode,
                                ],
                                placeholder: "กรุณากรอกรหัสไปรษณีย์",
                            },
                        ]}
                    />
                </>
            )}
            <InputContainer>
                <TextPara strong>หมายเหตุ</TextPara>
                <FormItem name="reminder">
                    <TextAreaInput readOnly autoSize={{ maxRows: 5 }} />
                </FormItem>
            </InputContainer>
        </>
    );
}
export default FormContent;
