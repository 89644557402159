import { Button } from "antd";
import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const ButtonHeader = styled(Button)`
    width: 100%;
`;

export const HeaderMeter = styled.div`
    color: ${colors.primary};
    font-size: 1.5rem;
    text-align: center;
`;

export const DetailBox = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

export const DetailText = styled.div`
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
`;

export const LabelDetail = styled.p`
    padding-right: 1rem;
    margin: 0;
    text-align: center;
`;
