import dayjs from "dayjs";
import React from "react";
import { formatNumber } from "../../../../functions/formatNumber";
import { IBilling } from "../../../../interfaces/Billing";
import { mappingWord } from "../utility/mapping";
import { Container, Paragraph, Title, TagItem } from "./index.styles";

type StatusTypeComponentsProps = {
    record: IBilling;
    showSubType?: boolean;
};

const StatusTypeComponents: React.FC<StatusTypeComponentsProps> = (
    props: StatusTypeComponentsProps
) => {
    const { record, showSubType } = props;
    const { name, subType } = record;
    const renderParagraph = React.useMemo(() => {
        if (subType.includes("Other")) {
            const { date, description, billerName } = record;
            const dateFormat = dayjs(date).format("HH:mm น., DD/MM/BBBB");
            return (
                <>
                    <Paragraph>{description}</Paragraph>
                    <Paragraph>
                        ({billerName}, {dateFormat})
                    </Paragraph>
                </>
            );
        } else if (subType.includes("Parking")) {
            const { unit, costPerUnit } = record;
            return (
                <>
                    <Paragraph>
                        {unit} ช่อง | ราคา {formatNumber(costPerUnit!)} บาทต่อช่อง
                    </Paragraph>
                </>
            );
        } else if (subType.includes("Water") || subType.includes("Electricity")) {
            const { unit, costPerUnit, meterReadingStart, meterReadingEnd } = record;
            return (
                <>
                    <Paragraph>
                        {unit} หน่วย | ราคา {formatNumber(costPerUnit!)} บาทต่อหน่วย
                    </Paragraph>
                    <Paragraph>
                        มิเตอร์ครั้งก่อน-ครั้งนี้: {meterReadingStart}-{meterReadingEnd}
                    </Paragraph>
                </>
            );
        } else if (subType.includes("Shirt")) {
            const { date } = record;
            const dateFormat = dayjs(date).format("เดือนMMMM BBBB");
            return (
                <>
                    <Paragraph>ค่าเสื้อ {dateFormat}</Paragraph>
                </>
            );
        } else if (subType.includes("booth")) {
            const { date } = record;
            const dateFormat = dayjs(date).format("เดือนMMMM BBBB");
            return (
                <>
                    <Paragraph>ค่าเช่า {dateFormat}</Paragraph>
                </>
            );
        } else if (subType.includes("rider") || subType.includes("labor")) {
            const { date } = record;
            const dateFormat = dayjs(date).format("เดือนMMMM BBBB");
            return (
                <>
                    <Paragraph>ค่าเสื้อ {dateFormat}</Paragraph>
                </>
            );
        } else if (subType.includes("veggie")) {
            const { date } = record;
            const dateFormat = dayjs(date).format("DD MMMM BBBB");
            return (
                <>
                    <Paragraph>ค่าเช่า {dateFormat}</Paragraph>
                </>
            );
        }
        return <></>;
    }, [record, subType]);

    return (
        <Container>
            <Title>
                {name}{" "}
                {showSubType && (
                    <TagItem color={mappingWord[subType].color}>
                        {mappingWord[subType].word}
                    </TagItem>
                )}
            </Title>
            {renderParagraph}
        </Container>
    );
};

export default StatusTypeComponents;
