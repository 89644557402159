import { Container, Extra, Paragraph, TagStatus, Title } from "./index.styles";
import colors from "../../../assets/colors.json";
import React from "react";
import { RolePagesType } from "../../../assets/typeEnumTranslation";

// TODO: will change when know all of data usage

type ICardContract = {
    keyName: string;
    status: string;
    paymentStatus?: string;
    disableStatus: boolean;
    canAccessInactive?: boolean;
    pageType: RolePagesType;
    data: any;
    trigger: () => void;
    getData?: React.Dispatch<React.SetStateAction<any>>;
};

function CardContract(props: ICardContract) {
    const {
        disableStatus,
        pageType,
        data,
        trigger,
        getData,
        keyName,
        status,
        paymentStatus,
        canAccessInactive,
    } = props;
    const didMount = React.useRef(false);
    const [stateChange, setStateChange] = React.useState(false);
    const convertColor = (state: string) => {
        switch (state) {
            case "paid":
                return colors.primary;
            case "debted":
                return colors.error;
            case "unpaid":
                return colors.remain;
            default:
                return colors.error;
        }
    };

    const convertType = (type: RolePagesType) => {
        switch (type) {
            case "booth":
                return "แผงที่";
            case "veggie":
                return "แผงที่";
            case "rider":
                return "เบอร์เสื้อ";
            case "labor":
                return "เบอร์เสื้อ";
            case "lomsak":
                return "ช่องที่";
        }
    };

    React.useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
        } else {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateChange]);

    const onClick = () => {
        getData && getData(data);
        setStateChange(!stateChange);
    };

    const isShirt = pageType === "rider" || pageType === "labor";

    return (
        <Container
            status={status}
            canAccessInactive={canAccessInactive}
            onClick={canAccessInactive || status !== "inactive" ? onClick : () => {}}
        >
            <Extra>
                <Paragraph strong>
                    {convertType(pageType)} {!isShirt && (data?.zoneNumber ?? "")}
                    {isShirt && (data?.shirtNumber ?? "")}
                </Paragraph>
                {!disableStatus && paymentStatus && status !== "inactive" && (
                    <TagStatus color={convertColor(paymentStatus)} />
                )}
            </Extra>
            <Title>{data[keyName]}</Title>
        </Container>
    );
}

export default CardContract;
