import styled from "styled-components";
import { Table } from "antd";

export const HistoryTableBox = styled(Table)`
    margin-top: 1rem;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        text-align: center !important;
        font-weight: 1000;
    }
    white-space: nowrap;
    table-layout: fixed;
`;

export const TableBox = styled.div`
    margin-top: 1rem;
    align-items: center;
`;
