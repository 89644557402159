import styled from "styled-components";
import { Card, Row, Table, Typography } from "antd";
import colors from "../../../assets/colors.json";

export const AntCard = styled(Card).attrs({
    bordered: false,
})`
    &.ant-card {
        border-radius: 0.5rem;
    }
    & .ant-card-head {
        border-bottom: none;
    }
    & .ant-card-head-wrapper {
        @media screen and (max-width: 540px) {
            display: flex;
            flex-direction: column;
        }
        .ant-card-extra {
            display: flex;
            gap: 1rem;
            @media screen and (max-width: 540px) {
                float: none;
                margin-left: 0;
                align-items: space-around;
                padding: 0;
                margin-bottom: 1rem;
            }
        }
    }
    & .ant-card-body {
        padding: 0 1rem;
        margin-top: auto;
    }
    height: 100%;
`;

export const Title = styled(Typography.Title).attrs({
    level: 4,
})`
    margin-bottom: 0 !important;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    background-color: ${colors.memberDetailWorkColor.mainDuty};
    width: fit-content;
    border-radius: 50%;
`;

export const Paragraph = styled(Typography.Paragraph).attrs({
    strong: true,
})`
    font-size: 1rem !important;
    margin-bottom: 0 !important;
`;

export const RowTitleCard = styled(Row).attrs({
    justify: "start",
    align: "middle",
})`
    &.ant-row {
        gap: 0.5rem !important;
    }
`;

export const TableData = styled(Table)`
    & td.ant-table-cell {
        height: 150px;
    }
`;
