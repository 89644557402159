import React, { useRef } from "react";
import { Button, Row, Col, Divider } from "antd";
import useDetailTenant from "../../components/common/DetailTenant/useDetailTenant";
import { useReactToPrint } from "react-to-print";
import typeLabelMap from "../../functions/typeLabelMap";
import dayjs from "dayjs";
import { formatNumber } from "../../functions/formatNumber";
import { IBilling } from "../../interfaces/Billing";
import { PrintContentCon } from "../PrintContent";
import { PrinterOutlined } from "@ant-design/icons";

type PrintBillContentProps = {
    dataSource: IBilling[];
    type: string;
    remainingDebt: number;
    boothCode: string;
    date: string;
    icon?: boolean;
};

const PrintBillContent: React.FC<PrintBillContentProps> = (props: PrintBillContentProps) => {
    const { dataSource, type, remainingDebt, boothCode, date, icon } = props;
    const { tenant, wordingType } = useDetailTenant(type, boothCode);
    let componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <Row justify="end">
                <Button onClick={handlePrint} icon={icon && <PrinterOutlined />}>
                    {!icon ? "ปริ้นใบแจ้งยอด" : ""}
                </Button>
            </Row>
            <div style={{ display: "none" }}>
                <PrintContentCon justify="space-between" ref={componentRef}>
                    <Col span={24}>
                        <h3>ใบแจ้งยอด {typeLabelMap(type)}</h3>
                    </Col>
                    {tenant && (
                        <>
                            <Col span={12}>{`อาคาร: ${tenant.building} `}</Col>
                            <Col span={12}>
                                {tenant.type === "booth" || tenant.type === "veggie"
                                    ? tenant.boothName[0]
                                    : tenant.type === "lomsak"
                                    ? tenant.boothName
                                    : `${wordingType(tenant.type)}: ${tenant.shirtNumber}`}
                            </Col>
                            <Col span={12}>
                                ชื่อผู้เช่า{" "}
                                {tenant?.companyName
                                    ? tenant?.companyName
                                    : `${tenant.firstName} ${tenant.lastName} `}
                            </Col>
                        </>
                    )}
                    <Col span={12}>รอบบิล {date} </Col>
                    <Col span={12}>วันที่ออกบิล {dayjs().format("DD/MM/BBBB")} </Col>

                    <Divider />
                    <Col span={24}>
                        <h4>รายละเอียดการใบแจ้งยอด</h4>
                    </Col>
                    <Col span={8}>
                        <b>รายการ</b>
                    </Col>

                    <Col span={16}>
                        <b>ยอด</b>
                    </Col>
                    {dataSource.map((bill) => (
                        <>
                            <Col span={8}>
                                {bill.name} <br />
                                {bill.inspectDate &&
                                    `วันที่จด: ${dayjs(bill.inspectDate).format("DD/MM/BBBB")}`}
                                <br /> {bill.description && `(${bill.description})`}
                                {bill.type === "meter" &&
                                    `ครั้งก่อน ${bill.meterReadingStart}, ครั้งนี้ ${bill.meterReadingEnd}`}
                                <br />
                                {bill.type === "meter" && bill.unit && `จำนวนหน่วย: ${bill.unit} `}
                                <br />
                            </Col>
                            <Col span={16}>{formatNumber(bill.remainingDebt)}</Col>
                        </>
                    ))}
                    <Divider />
                    <Col span={8}>
                        <b>ยอดรวม</b>
                    </Col>

                    <Col span={16}>
                        <b>{remainingDebt && formatNumber(remainingDebt)}</b>
                    </Col>
                </PrintContentCon>
            </div>
        </>
    );
};

export default PrintBillContent;
