import { Progress, Spin } from "antd";
import React from "react";
import { AntCard, LogoContainer, Paragraph, RowTitleCard, TableData, Title } from "./index.styles";
import Chart from "react-apexcharts";
import { options } from "./config";
import dayjs, { Dayjs } from "dayjs";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import FilterWithDatePicker from "../FilterWithDatePicker";
import { colorsMap, meterOption, renderOther } from "../utility/mapping";
import { formatNumber } from "../../../functions/formatNumber";
import colors from "../../../assets/colors.json";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import { typeList } from "../../../assets/typeEnumTranslation";

type BarGraphProps = {
    type: "shirt" | "car" | "meter";
    optionGraph: string[];
};
const BarGraph: React.FC<BarGraphProps> = (props: BarGraphProps) => {
    const { type, optionGraph } = props;
    const optionFilter = ["month", "year"] as PickerProps<Dayjs>["picker"][];
    const [value, setValue] = React.useState<PickerProps<Dayjs>["picker"]>(
        optionFilter[0] as PickerProps<Dayjs>["picker"]
    );
    const [date, setDate] = React.useState<Dayjs | null>(
        type === "meter" ? dayjs().subtract(1, "month") : dayjs()
    );
    const { isLoadingComponent, fetchGraphComponent, graphData } = useDashboardProvider();

    React.useEffect(() => {
        let dateUnix = +date!;
        fetchGraphComponent(value!, type, dateUnix);
    }, [value, date, type, fetchGraphComponent]);

    const columns = [
        {
            dataIndex: ["name", "type"],
            fixed: true,
            width: 130,
            render: (_: string, record: any, __: number) =>
                type === "meter" ? (
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <LogoContainer>{meterOption[record.type].logo}</LogoContainer>
                        <Paragraph>{meterOption[record.type].name}</Paragraph>
                    </div>
                ) : (
                    <Paragraph>{typeList[record.type]}</Paragraph>
                ),
        },
        {
            dataIndex: ["paid", "total"],
            render: (_: string, record: any, __: number) => (
                <div>
                    <div>
                        ชำระแล้ว {formatNumber(record.paid ? record.paid : 0)} บาท (
                        {(record.paid / record.total) * 100 >= 100
                            ? 100
                            : !isNaN(record.paid / record.total)
                            ? ((record.paid / record.total) * 100).toPrecision(4)
                            : "0.00"}
                        %)
                    </div>
                    <Progress
                        percent={(record.paid / record.total) * 100}
                        showInfo={false}
                        strokeColor={colorsMap[record.type]}
                        strokeWidth={16}
                        strokeLinecap={"square"}
                    />
                    <div style={{ color: colors.hintText, float: "right" }}>
                        ยอดชำระทั้งหมด {formatNumber(record.total ? record.total : 0)} บาท
                    </div>
                </div>
            ),
        },
    ];

    return (
        <AntCard
            title={
                <RowTitleCard>
                    <LogoContainer>{renderOther[type].logo}</LogoContainer>
                    <Title>{renderOther[type].name} - ข้อมูลเชิงลึก</Title>
                </RowTitleCard>
            }
            extra={
                <FilterWithDatePicker
                    value={value}
                    date={date}
                    onChange={(v) => setDate(v)}
                    setFilter={(v) => setValue(v)}
                    useOption={optionFilter}
                    disableDate={dayjs().subtract(1, "month")}
                />
            }
        >
            {value === "year" ? (
                !isLoadingComponent && graphData[`${type}-${value!}`] ? (
                    <Chart
                        options={options(false, optionGraph)}
                        series={graphData[`${type}-${value!}`]
                            .map((item: any) => ({
                                ...item,
                                name: typeList[item.name],
                            }))
                            .sort((a: any, b: any) => {
                                if (type === "shirt") return a.name < b.name ? -1 : 1;
                                else if (type === "car") return a.name < b.name ? -1 : 1;
                                return a.name < b.name ? 1 : -1;
                            })}
                        type="bar"
                        height={350}
                    />
                ) : (
                    <Spin />
                )
            ) : !isLoadingComponent && graphData[`${type}-${value!}`] ? (
                <TableData
                    rowKey={(record: any) => record.type}
                    showHeader={false}
                    columns={columns}
                    dataSource={graphData[`${type}-${value!}`].sort((a: any, b: any) => {
                        if (type === "shirt") return a.type < b.type ? -1 : 1;
                        else if (type === "car") return a.type < b.type ? 1 : -1;
                        return a.type < b.type ? -1 : 1;
                    })}
                    pagination={false}
                />
            ) : (
                <Spin />
            )}
        </AntCard>
    );
};

export default BarGraph;
