import { Form } from "antd";
import React from "react";
import { editAlert, successAlert } from "../../../components/PriceManagementPage/alert";
import { catchingError } from "../../../functions/notification";
import { IPricing } from "../../../interfaces/PriceManagement";
import { getMeterPrices, patchMeterPrices } from "../../../apis/priceManagement";

function useMeterManagement() {
    const [data, setData] = React.useState<IPricing>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const onResetForm = () => form.resetFields();

    // TODO Try Catch Exception
    const onFinish = async (res: IPricing) => {
        const newData: IPricing = {};
        Object.keys(res).forEach((key) => {
            if (data && res[key] !== data[key]) {
                newData[key] = res[key];
            }
        });
        const result = await editAlert(newData);
        if (result) {
            setIsLoading(true);
            try {
                await patchMeterPrices(newData);
                fetchData();
                await successAlert("แก้ไขข้อมูล");
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await getMeterPrices();
            setData(res);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        data,
        form,
        isLoading,
        onResetForm,
        onFinish,
    };
}
export default useMeterManagement;
