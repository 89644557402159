import React from "react";
import {
    getBuildingType,
    getShirtZoneByBuildingNumber,
    getZoneByBuildingNumber,
} from "../../../apis/zone";
import { RolePagesType } from "../../../assets/typeEnumTranslation";
import { catchingError } from "../../../functions/notification";
import { IZoneBooth, IZoneOther } from "../../../interfaces/Zone";
import { ZoneBuilding, ZoneBuildingType } from "../../../interfaces/Register";

function usePayment(type: RolePagesType) {
    const [data, setData] = React.useState<ZoneBuilding[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [firstKey, setFirstKey] = React.useState<string>("");
    const [boothKey, setBoothKey] = React.useState<IZoneBooth | IZoneOther | null>(null);
    const [zone, setZone] = React.useState<ZoneBuildingType[]>();

    const fetchZone = React.useCallback(async (type: RolePagesType, buildingNumber: number) => {
        const shirtNumber = ["rider", "labor"];
        const zone = shirtNumber.includes(type)
            ? await getShirtZoneByBuildingNumber(type, buildingNumber)
            : await getZoneByBuildingNumber(type, buildingNumber);
        setZone(zone);
    }, []);

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const building = await getBuildingType(type);
            setData(building);
            if (firstKey) {
                const indexing = building.findIndex((item) => item.buildingName === firstKey);
                if (indexing !== -1) {
                    setFirstKey(building[indexing].buildingName);
                    await fetchZone(type, building[indexing].buildingNumber);
                    return;
                }
            }
            setFirstKey(building[0].buildingName);
            await fetchZone(type, building[0].buildingNumber);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [type, firstKey, fetchZone]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSelectBuilding = React.useCallback(
        async (buildingName: string, buildingNumber: number) => {
            setIsLoading(true);
            setFirstKey(buildingName);
            try {
                await fetchZone(type, buildingNumber);
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [type, fetchZone]
    );

    return {
        isLoading,
        firstKey,
        setFirstKey,
        data,
        zone,
        boothKey,
        setBoothKey,
        fetchData,
        onSelectBuilding,
    };
}
export default usePayment;
