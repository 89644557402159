import axios from "axios";
import {IAddUidLineNoti, IExecutiveLine} from "../interfaces/Line";

export const getQRRegisterLine = async (boothNumber: string) => {
    const response = await axios.get(`/lineNoti/qrcode/${boothNumber}`);
    return response.data;
};

export const postRegisterLine = async (body:IAddUidLineNoti) => {
    const response = await axios.post(`/lineNoti/register`, body);
    return response.data;
};

export const getExecutiveLine = async () => {
    const response = await axios.get(`/lineNoti/excutive`);
    return response.data as IExecutiveLine[];
}

export const deleteUserExecutiveLine = async (docId: string) => {
    const response = await axios.delete(`/lineNoti/excutive/${docId}`);
    return response.data;
}