import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    backdrop-filter: blur(2px);
    background-color: #fff9;
    z-index: 999;
`;

export const CardContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    max-width: 600px;
`;
