export const formatNumber = (number: number) => {
    return number.toLocaleString("th-TH", {});
};

export const formatNumberExport = (number: number) => {
    return number.toLocaleString("th-TH", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
