import { ApexOptions } from "apexcharts";
import th from "apexcharts/dist/locales/th.json";
import dayjs from "dayjs";
import colors from "../../../assets/colors.json";
import { formatNumber } from "../../../functions/formatNumber";

export const options = (horizontal: boolean, legendOptions: string[]) => {
    const xaxisOptions: ApexOptions["xaxis"] = xaxisAsYear();
    return {
        chart: {
            type: "bar",
            height: 200,
            fontFamily: "Kanit",
            toolbar: {
                show: false,
            },
            events: {
                mounted: (chart) => chart.windowResizeHandler(),
            },
            locales: [th],
            defaultLocale: "th",
        },
        colors: [colors.secondary, colors.info],
        plotOptions: {
            bar: {
                horizontal: horizontal,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            ...xaxisOptions,
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                },
                formatter: function (value) {
                    return formatNumber(value);
                }
            },
            title: {
                text: "จำนวนเงินที่ชำระแล้ว (บาท)",
                style: {
                    fontSize: "16px",
                    fontWeight: "bold",
                },
            },
        },
        legend: {
            customLegendItems: legendOptions,
            markers: {
                fillColors: [colors.secondary, colors.info],
            },
        },
    } as ApexOptions;
};

export const xaxisAsYear = () => {
    return {
        categories: Array(12)
            .fill(null)
            .map((_, i) => {
                let dateString = dayjs().startOf("years").add(i, "month");
                return dateString.format("MMMM");
            }),
    } as ApexXAxis;
};
