import React, { useRef } from "react";
import { Button, Row, Col, Divider } from "antd";
import useTransactionDetail from "./useTransactionDetail";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import typeLabelMap from "../../functions/typeLabelMap";
import dayjs from "dayjs";
import { formatNumber } from "../../functions/formatNumber";

type PrintContentProps = {
    transactionId: string;
    type: string;
    slipURL?: string;
    tenant: any;
};

const PrintContent: React.FC<PrintContentProps> = (props: PrintContentProps) => {
    const { transactionId, type, slipURL, tenant } = props;
    const { fetchTransactionDetail, transactionDetail, wordingType } =
        useTransactionDetail(transactionId);

    let componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => fetchTransactionDetail(),
        content: () => componentRef.current,
    });
    return (
        <>
            <Row justify="end">
                <Button type="primary" onClick={handlePrint}>
                    ปริ้นใบเสร็จ
                </Button>
            </Row>
            <div style={{ display: "none" }}>
                <PrintContentCon justify="space-around" ref={componentRef}>
                    <Col span={24}>
                        <h3>รายการชำระเงิน {typeLabelMap(type)}</h3>
                    </Col>
                    <Col span={12}>{`อาคาร ${tenant.building} `}</Col>
                    <Col span={12}>
                        {tenant.type === "booth" || tenant.type === "veggie"
                            ? tenant.boothName[0]
                            : tenant.type === "lomsak"
                            ? tenant.boothName
                            : `${wordingType(tenant.type)}: ${tenant.shirtNumber}`}
                    </Col>
                    <Col span={8}>ชื่อผู้เช่า</Col>
                    <Col span={16}>
                        {tenant?.companyName
                            ? tenant?.companyName
                            : `${tenant.firstName} ${tenant.lastName} `}
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <h4>รายละเอียดการชำระเงิน</h4>
                    </Col>
                    {transactionDetail?.billingDate && (
                        <Col span={24}>
                            รอบบิล{" "}
                            {tenant.type === "veggie"
                                ? dayjs(transactionDetail?.billingDate).format(
                                      "วันที่ DD เดือนMMMM BBBB"
                                  )
                                : dayjs(transactionDetail?.billingDate).format(
                                      "เดือนMMMM BBBB"
                                  )}{" "}
                        </Col>
                    )}

                    <Col span={8}>
                        <b>รายการ</b>
                    </Col>

                    <Col span={16}>
                        <b>ยอดชำระ</b>
                    </Col>
                    {transactionDetail?.billDetail.map((bill) => (
                        <>
                            <Col span={8}>
                                {bill.title} <br />
                                {bill.inspectDate &&
                                    `วันที่จด: ${dayjs(bill.inspectDate).format("DD/MM/BBBB")}`}
                                <br /> {bill.description && `(${bill.description})`}
                                {bill.type === "meter" &&
                                    `ครั้งก่อน ${bill.meterReadingStart}, ครั้งนี้ ${bill.meterReadingEnd}`}
                                <br />
                                {bill.type === "meter" && bill.unit && `จำนวนหน่วย: ${bill.unit} `}
                            </Col>
                            <Col span={16}>{formatNumber(bill.amount)}</Col>
                        </>
                    ))}
                    <Divider />
                    <Col span={8}>
                        <b>ยอดรวม</b>
                    </Col>

                    <Col span={16}>
                        <b>
                            {transactionDetail?.totalAmount &&
                                formatNumber(transactionDetail?.totalAmount)}
                        </b>
                    </Col>
                    <Divider />
                    <Col span={8}>วันที่ชำระ</Col>
                    <Col span={16}>
                        {transactionDetail?.date &&
                            dayjs.unix(transactionDetail?.date / 1000).format("HH:mm, DD/MM/BBBB")}
                    </Col>
                    <Col span={8}>ประเภท</Col>
                    <Col span={16}>{slipURL ? "โอนจ่าย" : "เงินสด"}</Col>
                    <Col span={8}>ผู้รับเงิน</Col>
                    <Col span={16}>{transactionDetail?.collectorName}</Col>
                </PrintContentCon>
            </div>
        </>
    );
};

export const PrintContentCon = styled(Row)`
    width: 100%;
    padding: 10px 20px;
    font-size: 20px;
    .ant-col-24 {
        text-align: center;
        h4 {
            margin-top: 18px;
        }
    }
    .ant-col-8 {
        padding: 8px 0;
    }
    .ant-col-16 {
        padding: 8px 0;
        text-align: end;
    }
`;

export default PrintContent;
