import React from "react";
import { Storefront, Leaf, Truck, Bicycle, TShirt, Coin } from "phosphor-react";
type DutyIconProps = {
    duty?: string | "booth" | "veggie" | "lomsak" | "rider" | "labor";
};
const DutyIcon: React.FC<DutyIconProps> = (props) => {
    switch (props.duty) {
        case "booth":
            return <Storefront size={18} />;
        case "veggie":
            return <Leaf size={18} />;
        case "lomsak":
            return <Truck size={18} />;
        case "rider":
            return <Bicycle size={18} />;
        case "labor":
            return <TShirt size={18} />;
        default:
            return <Coin size={18} />;
    }
};

export default DutyIcon;
