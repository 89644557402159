import React from "react";
import { Container } from "../index.style";
import { PlusOutlined } from "@ant-design/icons";

function CardMemberCreate(props: { onClick?: () => void }) {
    return (
        <Container style={{ cursor: "pointer" }} onClick={props.onClick}>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    fontSize: "2rem",
                    color: "#DDD",
                    minHeight: "170px",
                }}
            >
                <PlusOutlined size={120} />
                <span style={{ fontSize: "1.5rem" }}>เพิ่มพนักงาน</span>
            </div>
        </Container>
    );
}

export default CardMemberCreate;
