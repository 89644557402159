import React from "react";
import Swal from "sweetalert2";
import { Avatar, Form, message, notification, Row, Spin } from "antd";
import { useAdminDetailContext } from "../../../pages/AdminManagement/useAdminDetail";
import { CustomModal, SubmitButton } from "./index.style";
import { UploadFile } from "antd/lib/upload/interface";
import * as swlConfig from "../../../assets/sweetalert.config";
import CustomUpload, { UploadButton } from "../../common/CustomUpload";
import { RowGroupInput } from "../../common/CustomForm";
import { UploadChangeParam } from "antd/lib/upload";
import { antdFileToBase64, base64ToBlob } from "../../../functions/files";
import { uploadImageReturnUrl } from "../../../functions/storage";
import { AxiosError } from "axios";
import validator from "../../../assets/formValidator";
import { createAdmin } from "../../../apis/admin";

type MemberFormProps = {
    name: string;
    email: string;
    mainDuty: string;
    ssn: string;
    tel: string;
    dutyPool: string[];
};

const ModalCreateAdmin = () => {
    const {
        openModalCreateAdmin,
        setOpenModalCreateAdmin,
        isLoading,
        fetchDataPage,
        setIsLoading,
    } = useAdminDetailContext();
    const [form] = Form.useForm<MemberFormProps>();

    const [imageBase64, setImageBase64] = React.useState<string>();
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();

    React.useEffect(() => {
        form.resetFields();
        setImageBase64(undefined);
        setImageError(undefined);
        setLoadingImage(false);
    }, [openModalCreateAdmin, form, setOpenModalCreateAdmin, openModalCreateAdmin]);

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadImage = React.useCallback(async () => {
        if (!imageBase64) return;
        const blob = await base64ToBlob(imageBase64);
        const urls = await uploadImageReturnUrl("images/users", [blob]);
        return urls[0];
    }, [imageBase64]);

    const onCancel = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "ยกเลิกการเพิ่มผู้ดูแล!",
                text: `กรุณายืนยันเพื่อยกเลิกการเพิ่มผู้ดูแล`,
            })
        );
        if (isConfirmed) return setOpenModalCreateAdmin(false);
    }, [setOpenModalCreateAdmin]);

    const onFinish = React.useCallback(
        async (data: MemberFormProps) => {
            setIsLoading(true);
            const photoURL = await uploadImage();
            try {
                await createAdmin({
                    ...data,
                    photoURL,
                });
                setOpenModalCreateAdmin(false);
                form.resetFields();
                setImageBase64(undefined);
                await fetchDataPage();
            } catch (error) {
                const err = error as AxiosError<
                    {
                        code: string;
                    }[]
                >;
                const errs = err.response?.data || [];
                const code = errs.length >= 1 && errs[0].code;
                message.error(
                    code === "auth/email-already-exists"
                        ? "อีเมลนี้มีผู้ใช้แล้ว"
                        : "เกิดข้อผิดพลาดบางอย่าง"
                );
            } finally {
                setIsLoading(false);
            }
        },
        [uploadImage, setOpenModalCreateAdmin, fetchDataPage, setIsLoading, form]
    );

    return (
        <CustomModal
            visible={openModalCreateAdmin}
            destroyOnClose
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <Form form={form} onFinish={onFinish}>
                    <Row justify="center">
                        <CustomUpload
                            error={imageError}
                            name="รูปผู้ดูแล"
                            listType="picture-card"
                            showUploadList={false}
                            onChange={onChangeImage}
                            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                        >
                            {imageBase64 ? (
                                <Avatar
                                    src={imageBase64}
                                    alt="avatar"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            ) : (
                                <UploadButton loadingImage={loadingImage} imageError={imageError} />
                            )}
                        </CustomUpload>
                    </Row>
                    <RowGroupInput
                        inputs={[
                            {
                                name: "name",
                                label: "ชื่อ - สกุล",
                                placeholder: "กรุณากรอกชื่อ - สกุล",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกชื่อ - สกุล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "email",
                                label: "อีเมล",
                                placeholder: "กรุณากรอกอีเมล",
                                rules: [
                                    {
                                        required: true,
                                        type: "email",
                                        message: "กรุณากรอกอีเมล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: `tel`,
                                label: "เบอร์โทรศัพท์",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกเบอร์โทรศัพท์",
                                    },
                                    ...validator.phoneNumber,
                                ],
                                placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                                marginBottom: 0,
                            },
                        ]}
                    />

                    <Row justify="center" style={{ marginTop: 12 }}>
                        <SubmitButton htmlType="submit">บันทึก</SubmitButton>
                    </Row>
                </Form>
            </Spin>
        </CustomModal>
    );
};

export default ModalCreateAdmin;
