import { Button, Col, Collapse, Form, FormInstance, List, Row, Typography } from "antd";
import React from "react";
import BuildingSettingModal from "../../../components/PriceManagementPage/BuildingSettingModal";
import { IBuildingSetting } from "../../../interfaces/PriceManagement";
import { Divider, EditText, HeadPanel, PanalContainer } from "./index.styles";
import useBuildingManagement from "./useBuildingManagement";

type BuildingManagementProps = {};
const BuildingManagement: React.FC<BuildingManagementProps> = () => {
    const {
        form,
        onResetForm,
        isLoading,
        buildingSetting,
        visible,
        setVisible,
        typeModal,
        setTypeModal,
        onInitState,
        onFinish,
        setBuildingNumber,
    } = useBuildingManagement();
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                colon={false}
                ref={(formRef: FormInstance) => formRef}
                role="form"
            >
                <Collapse accordion onChange={onResetForm}>
                    <Collapse.Panel header="จัดการอาคารและแผง" key={1}>
                        <PanalContainer>
                            <HeadPanel>
                                <Typography.Title level={5}>ตั้งค่าอาคารและแผง</Typography.Title>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        setTypeModal("create");
                                        onInitState();
                                        setVisible((prev) => !prev);
                                    }}
                                >
                                    เพิ่มอาคาร
                                </Button>
                            </HeadPanel>
                            <List
                                itemLayout="horizontal"
                                dataSource={buildingSetting}
                                loading={isLoading}
                                renderItem={(item: IBuildingSetting) => (
                                    <React.Fragment key={item.buildingName}>
                                        <Row>
                                            <Col>
                                                <Typography.Paragraph style={{ marginBottom: 0 }}>
                                                    ชื่ออาคารที่ {item.buildingNumber}
                                                </Typography.Paragraph>
                                            </Col>
                                        </Row>
                                        <Row align="middle" justify="space-between">
                                            <Col offset={1} xs={12}>
                                                <Typography.Title
                                                    level={4}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {item.buildingName}
                                                </Typography.Title>
                                            </Col>
                                            <Col offset={1} xs={8}>
                                                <EditText
                                                    onClick={() => {
                                                        setTypeModal("edit");
                                                        Object.keys(item).forEach((key) => {
                                                            form.setFieldsValue({
                                                                [key]: item[key],
                                                            });
                                                        });
                                                        setBuildingNumber(item.buildingNumber!);
                                                        setVisible((prev) => !prev);
                                                    }}
                                                >
                                                    แก้ไขข้อมูล
                                                </EditText>
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </React.Fragment>
                                )}
                            />
                        </PanalContainer>
                    </Collapse.Panel>
                </Collapse>
                <BuildingSettingModal
                    withType={typeModal}
                    visible={visible}
                    handleOk={() => {
                        form.submit();
                    }}
                    onClose={() => setVisible((prev) => !prev)}
                />
            </Form>
        </>
    );
};

export default BuildingManagement;
