import { Button } from "antd";
import { Container } from "./index.styles";

type IFooter = {
    onOk: () => void;
    onCancel: () => void;
};

function FooterModal(props: IFooter) {
    const { onOk, onCancel } = props;
    return (
        <Container>
            <Button size="large" type="primary" danger onClick={onCancel}>
                ปฏิเสธ
            </Button>
            <Button size="large" type="primary" onClick={onOk}>
                อนุมัติ
            </Button>
        </Container>
    );
}

export default FooterModal;
