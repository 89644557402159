import { PageHeader, Tabs } from "antd";
import React from "react";
import { Container } from "./index.style";
import MemberDetail from "./MemberDetail";
import WorkManagement from "./WorkManagement";

type SubRouteProps = {
    title: string;
    value: string;
    component: React.ReactNode;
};

export const MemberManagementStaffSubRoute: SubRouteProps[] = [
    {
        title: "รายละเอียดสมาชิก",
        value: "memberDetail",
        component: <MemberDetail />,
    },
    {
        title: "จัดการงาน",
        value: "workManagement",
        component: <WorkManagement />,
    },
];

function MemberManagementStaff() {
    const [activeKey, setActiveKey] = React.useState("memberDetail");
    return (
        <Container>
            <PageHeader title="จัดการพนักงาน" />
            <Tabs activeKey={activeKey} style={{ padding: "0 24px" }} onChange={setActiveKey}>
                {MemberManagementStaffSubRoute.map((sRoute) => (
                    <Tabs.TabPane forceRender tab={sRoute.title} key={sRoute.value}>
                        {activeKey === sRoute.value && sRoute.component}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </Container>
    );
}

export default MemberManagementStaff;
