type IPreviousMonth = {
    month: string;
    unix: number;
};

export const getPreviousMonth = (amount: number) => {
    let previousMonth: IPreviousMonth[] = [];
    for (let i = 0; i < amount; i++) {
        const date = new Date() ;
        // dayjs('2019-01-25').format('DD/MM/YYYY') 
        // +dayjs(1548381600000)
        // parseInt(dayjs().add(-i, "month").format("MMMM YYYY"))
       
        
       
        date.setMonth(date.getMonth() - i);
        previousMonth.push({
            month: date.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
            }),
            unix:  Math.floor(new Date(date.getFullYear(), date.getMonth(), 1).getTime() )
        });
    }
    return previousMonth;
};
