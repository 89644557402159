import React from "react";
import { MemberType } from "../../interfaces/MemberManagement";
import { message } from "antd";
import * as swlConfig from "../../assets/sweetalert.config";
import Swal from "sweetalert2";
import { getAllAdmin, deleteAdmin } from "../../apis/admin";
import { AxiosError } from "axios";

export const AdminDetailContext = React.createContext({} as ReturnType<typeof useAdminDetail>);
export const useAdminDetailContext = () => React.useContext(AdminDetailContext);

function useAdminDetail() {
    const [adminList, setMemberList] = React.useState<MemberType[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [adminUpdate, setAdminUpdate] = React.useState<MemberType>();
    const [openModalUpdateAdmin, setOpenModalUpdateAdmin] = React.useState(false);
    const [openModalCreateAdmin, setOpenModalCreateAdmin] = React.useState(false);

    const getAdminList = React.useCallback(async () => {
        try {
            const members = await getAllAdmin();
            setMemberList(members);
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        }
    }, []);

    const fetchDataPage = React.useCallback(async () => {
        setIsLoading(true);
        await getAdminList();
        setIsLoading(false);
    }, [getAdminList]);
    React.useEffect(() => {
        fetchDataPage();
    }, [fetchDataPage]);

    const handleSearch = React.useCallback(
        (search: string, list: MemberType[]) =>
            list.filter(
                (item) =>
                    item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.email?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            ),
        []
    );

    const handleDelete = React.useCallback(
        async (admin: MemberType) => {
            if (!admin.uid) return;
            const { isConfirmed } = await Swal.fire(
                swlConfig.negativeConfirmation({
                    title: "ยืนยันการลบผู้ดูแลระบบ",
                    text: `กรุณากดยืนยันเพื่อลบ ${admin.name} ออกจากระบบ`,
                })
            );
            if (isConfirmed) {
                try {
                    setIsLoading(true);
                    await deleteAdmin(admin);
                    await fetchDataPage();
                } catch (error) {
                    const err = error as AxiosError<{ errors: { msg: string }[] }>;
                    message.error(err.response?.data.errors[0].msg);
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [fetchDataPage]
    );

    const handleEdit = React.useCallback(async (admin: MemberType) => {
        setAdminUpdate(admin);
        setOpenModalUpdateAdmin(true);
    }, []);

    return {
        handleSearch,
        adminList,
        setAdminUpdate,
        adminUpdate,
        setOpenModalUpdateAdmin,
        openModalUpdateAdmin,
        isLoading,
        setIsLoading,
        setOpenModalCreateAdmin,
        openModalCreateAdmin,
        fetchDataPage,
        handleDelete,
        handleEdit,
    };
}

export default useAdminDetail;
