import { Card, Typography, Button } from "antd";
import styled from "styled-components";

export const Title = styled(Typography.Title).attrs({
    level: 5,
})`
    margin: 0 !important;
`;

export const CardItem = styled(Card).attrs({
    bodyStyle: {
        borderStyle: "dashed",
        borderColor: "rgba(81, 167, 40, 0.15)",
        backgroundColor: "rgba(81, 167, 40, 0.15)",
        borderWidth: "2px",
        padding: "1rem 3rem",
    },
})``;

export const PaidButton = styled(Button).attrs({
    type: "primary",
    size: "large",
})`
    padding: 0 3rem !important;

    @media screen and (max-width: 540px) {
        margin-top: 1rem;
    }
`;

export const NoPaidButton = styled(Button).attrs({
    size: "large",
    disabled: true,
})`
    padding: 0 3rem !important;

    @media screen and (max-width: 540px) {
        margin-top: 1rem;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
