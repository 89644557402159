import { Col, Row } from "antd";
import { Rule } from "antd/lib/form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { ContainerRow, Item, RequiredMask, Title } from "./index.styles";

type RowInputProps = {
    label: string | React.ReactNode;
    useReactNode?: boolean;
    children: React.ReactNode;
    name?: string;
    rules?: Rule[];
    required?: boolean;
    other?: boolean;
    withPictureForm?: boolean;
};
const RowInput: React.FC<RowInputProps> = (props) => {
    const { label, children, name, rules, required, withPictureForm, other, useReactNode } = props;
    const { xs } = useBreakpoint();

    return (
        <>
            {other && (
                <Col offset={xs ? 0 : 6}>
                    <Title>ราคาอื่นๆ</Title>
                </Col>
            )}
            <ContainerRow>
                <Col offset={xs ? 0 : other ? 7 : 6} xs={24} sm={other ? 4 : 5}>
                    <Row>
                        {required && <RequiredMask>*</RequiredMask>}
                        {useReactNode ? <>{label}</> : <Title>{label}</Title>}
                    </Row>
                </Col>
                <Col xs={24} sm={8} style={{ marginTop: xs ? "0.5rem" : 0 }}>
                    {withPictureForm ? (
                        children
                    ) : (
                        <Item name={name} rules={rules} required={required}>
                            {children}
                        </Item>
                    )}
                </Col>
            </ContainerRow>
        </>
    );
};

export default RowInput;
