import React from "react";
import { Row, Col, Card, Avatar, Button, Modal, Image, Spin } from "antd";
import styled from "styled-components";
import { BellOutlined } from "@ant-design/icons";
import colors from "../../../assets/colors.json";
import useDetailTenant from "./useDetailTenant";

interface DetailTenantPropsType {
    type: "booth" | "veggie" | "lomsak" | "rider" | "labor";
    boothCode: string;
}

const DetailTenant = (props: DetailTenantPropsType) => {
    const { type, boothCode } = props;
    const {
        tenant,
        wordingType,
        isLoading,
        onGetQRRegisterLine,
        handleClose,
        isModalVisible,
        imageQR,
    } = useDetailTenant(type, boothCode);

    return (
        <Container>
            <Card bordered={false}>
                {!isLoading && tenant ? (
                    tenant.type === undefined ? (
                        <p>ไม่มีผู้ใช้งาน</p>
                    ) : (
                        <Row justify="space-around">
                            <Col>
                                <Avatar
                                    size={{ xs: 64, sm: 64, md: 64, lg: 64, xl: 80, xxl: 100 }}
                                    src={tenant.picturePath}
                                >
                                    {tenant.firstName} {tenant.lastName}
                                </Avatar>
                            </Col>
                            <Col>
                                <p>{wordingType(tenant.type)}</p>
                                <p>ชื่อผู้เช่า</p>
                                <p>เบอร์โทร</p>
                            </Col>
                            <Col>
                                <p>
                                    {`อาคาร ${tenant.building}  ${
                                        tenant.type === "booth" || tenant.type === "veggie"
                                            ? tenant.boothName[0]
                                            : tenant.type === "lomsak"
                                            ? tenant.boothName
                                            : `${wordingType(tenant.type)} ${tenant.shirtNumber}`
                                    } `}
                                </p>
                                <p>
                                    {tenant?.companyName
                                        ? tenant?.companyName
                                        : `${tenant.firstName} ${tenant.lastName} `}
                                </p>
                                <p>{tenant.tel}</p>
                            </Col>
                            <Col span={24} className="con-button">
                                <Row justify="end" gutter={[16, 24]}>
                                    <Button
                                        type="primary"
                                        icon={<BellOutlined />}
                                        onClick={onGetQRRegisterLine}
                                    >
                                        รับการแจ้งเตือน
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    )
                ) : (
                    <Spin />
                )}
            </Card>

            <ModalCustom
                title="QR code รับการแจ้งเตือนจาก LINE"
                visible={isModalVisible}
                onOk={handleClose}
                onCancel={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                {!isLoading && tenant ? (
                    tenant.type === undefined ? (
                        <p>ไม่มีผู้ใช้งาน</p>
                    ) : (
                        <>
                            <Image src={imageQR} preview={false} />
                            <p>
                                สแกนเพื่อรับข่าวสารและรายการชำระเงินของ <br />
                                {`อาคาร ${tenant.building}  ${
                                    tenant.type === "booth" || tenant.type === "veggie"
                                        ? tenant.boothName[0]
                                        : tenant.type === "lomsak"
                                        ? tenant.boothName
                                        : `${wordingType(tenant.type)} ${tenant.shirtNumber}`
                                } `}
                            </p>
                            <p style={{ color: colors.warning }}>
                                *QR code ใช้เฉพาะ{wordingType(tenant.type)}นี้เท่านั้น{" "}
                            </p>
                        </>
                    )
                ) : (
                    <Spin />
                )}
            </ModalCustom>
        </Container>
    );
};

export default DetailTenant;

export const Container = styled(Col)`
    margin-bottom: 1rem;
    p {
        font-size: 1rem;
        margin-bottom: 0.2rem !important;
    }
    .con-button {
        margin-top: 0.8rem;
    }

    Button {
        align-items: center !important;
        justify-content: center;
    }
`;

export const ModalCustom = styled(Modal)`
    .ant-modal-body {
        text-align: center !important;
    }
`;
