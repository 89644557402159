import { DrawerContainer } from "./index.styles";

type DrawerDisplayProps = {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
};
const DrawerDisplay: React.FC<DrawerDisplayProps> = (props: DrawerDisplayProps) => {
    const { children, isOpen, onClose } = props;
    return (
        <DrawerContainer visible={isOpen} onClose={onClose}>
            {children}
        </DrawerContainer>
    );
};

export default DrawerDisplay;
