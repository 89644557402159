import styled from "styled-components";
import { Modal as M, Typography } from "antd";
import breakpoint from "../../../assets/breakpoint.json";
export const Modal = styled(M)`
    &.ant-modal-content {
        display: flex;
        flex-direction: column;
    }
    width: 1000px !important;
    @media screen and (max-width: ${breakpoint.mobile}) {
        width: 400px;
    }
    @media screen and (min-width: ${breakpoint.mobile}) and (max-width: ${breakpoint.tablet}) {
        width: 600px;
    }
    @media screen and (min-width: ${breakpoint.tablet}) {
        width: 800px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 80vh;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-bottom: 0.5rem;
`;

export const TypoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Paragraph = styled(Typography.Paragraph).attrs({
    strong: true,
})`
    margin: 0 !important;
`;
