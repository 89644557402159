import { message, Table } from "antd";
import React from "react";
import { IncomeExpensesTable } from "../../interfaces/IncomesAndExpenses";
import {
    getIncomesAndExpensesData,
    postIncomesAndExpensesData,
} from "../../apis/incomesAndExpenses";
import { typeIncomeExpenses } from "../../assets/transaction";
import type { TableRowSelection } from "antd/lib/table/interface";
import dayjs from "dayjs";

export const MemberDetailContext = React.createContext(
    {} as ReturnType<typeof useIncomesAndExpenses>
);
export const useIncomesAndExpensesContext = () => React.useContext(MemberDetailContext);

function useIncomesAndExpenses() {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [data, setData] = React.useState<IncomeExpensesTable[]>([]);
    const [monthYear, setMonthYear] = React.useState<number[]>([+dayjs().startOf("month"), +dayjs().endOf("month")]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [onOpenModal, setOnOpenModal] = React.useState<boolean>(false);
    const [recordSelected, setRecordSelected] = React.useState<IncomeExpensesTable[]>([]);

    const fetchData = React.useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await getIncomesAndExpensesData(monthYear);
            setData(res);
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        } finally {
            setIsLoading(false);
        }
    }, [monthYear]);

    React.useEffect(() => {
        fetchData();
    }, [monthYear, fetchData]);

    const handleSearch = React.useCallback(
        (allData: IncomeExpensesTable[]) =>
            allData.filter(
                (data) =>
                    data?.IEName?.toString().replace(/ /g, '').toLocaleLowerCase().includes(searchKey.replace(/ /g, '').toLocaleLowerCase()) ||
                    data?.IEWriter?.toString().replace(/ /g, '').toLocaleLowerCase().includes(searchKey.replace(/ /g, '').toLocaleLowerCase()) ||
                    data.amount
                        ?.toString()
                        ?.toLocaleLowerCase()
                        .includes(searchKey.toLocaleLowerCase()) ||
                    (typeIncomeExpenses[data.billingType] || data.billingType)
                        ?.toString().toLocaleLowerCase()
                        .includes(searchKey.toLocaleLowerCase()) ||
                    (data.date &&
                        dayjs(data.date)
                            .format("DD/MM/YYYY")
                            ?.includes(searchKey.toLocaleLowerCase()))
            ),
        [searchKey]
    );

    const onSelectChange = (newSelectedRowKeys: React.Key[], record: IncomeExpensesTable[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setRecordSelected(record);
    };

    const rowSelection: TableRowSelection<IncomeExpensesTable> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            {
                key: "selectNone",
                text: "เอาข้อมูลที่เลือกออกทั้งหมด",
                onSelect: () => {
                    setSelectedRowKeys([]);
                },
            },
        ],
    };

    const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
        setSearchKey(event.target.value);

    const handleCloseModal = () => setOnOpenModal(false);

    const handleOpenModal = () => setOnOpenModal(true);

    const handleCreateIncomeExpense = React.useCallback(
        async (data: Omit<IncomeExpensesTable, "id" | "date">) => {
            try {
                setIsLoading(true);
                await postIncomesAndExpensesData(data);
                fetchData();
            } catch (error) {
                console.log({ error });
                message.error("มีบางอย่างผิดพลาด");
            } finally {
                setIsLoading(false);
            }
        },
        [fetchData]
    );

    return {
        data,
        fetchData,
        monthYear,
        setMonthYear,
        isLoading,
        setIsLoading,
        handleSearch,
        onSelectChange,
        handleChangeSearch,
        rowSelection,
        setOnOpenModal,
        onOpenModal,
        handleCloseModal,
        handleOpenModal,
        handleCreateIncomeExpense,
        recordSelected,
    };
}

export default useIncomesAndExpenses;
