import { Col, Form as F, Input, Row, Steps, Grid } from "antd";
import DatePicker from "../DatePicker";
import { Rule } from "antd/lib/form";
import styled from "styled-components";
export const FormItem = styled(F.Item)`
    display: flex !important;
    flex-direction: column !important;
    ${(props: { marginBottom?: number }) =>
        props.marginBottom || props.marginBottom === 0
            ? `margin-bottom: ${props.marginBottom}px !important;`
            : ""}
    & .ant-form-item-row {
        flex-direction: column !important;
    }
    & .ant-row {
        margin-bottom: 0 !important;
        flex-direction: column !important;
    }
    & .ant-form-item-label {
        width: fit-content !important;
        > label {
            &::after {
                content: "";
            }
        }
    }
    & .ant-form-item-required {
        &::before {
            content: "" !important;
        }
        &::after {
            content: "*" !important;
            color: red !important;
            margin-left: 0.25rem !important;
        }
    }
    & .ant-input {
        border-radius: 4px !important;
        overflow: hidden !important;
    }
`;

export const Form = styled(F)`
    margin: 0 auto !important;
    max-width: 600px !important;
    & > h3 {
        text-align: center !important;
    }
`;

export const Container = styled.div`
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
`;

export const ContentBackground = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    & > h1 {
        text-align: center;
        font-weight: bold;
    }
`;

export const CustomSteps = styled(Steps)`
    margin: 2rem 0;
`;

export type RowGroupInputProps = {
    inputs: {
        name: string;
        label: React.ReactNode;
        rules?: Rule[];
        placeholder?: string;
        type?: "text" | "calendar";
        customInput?: React.ReactNode;
        flex?: number;
        initialValue?: any;
        disabled?: boolean;
        defaultValue?: any;
        onChange?: (value: any) => void;
        typeInput?: "number" | "text";
        marginBottom?: number;
    }[];
    rightExtra?: React.ReactNode;
    leftExtra?: React.ReactNode;
    gutter?: number;
};

export const RowGroupInput: React.FC<RowGroupInputProps> = (props) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    return (
        <Row gutter={props.gutter || 12} style={{ flexDirection: xs ? "column" : "row" }}>
            {props.inputs.map(({ placeholder, type, customInput, flex, ...input }, index) => (
                <Col key={`input-${input.name}-${index}`} style={{ flex: flex || 1 }}>
                    <div style={{ display: "flex", flex: 1, gap: "0.5rem" }}>
                        {index === 0 && props.leftExtra}
                        <div style={{ flex: 1 }}>
                            <FormItem {...input} marginBottom={input.marginBottom}>
                                {customInput ? (
                                    customInput
                                ) : type === "calendar" ? (
                                    <DatePicker
                                        placeholder={placeholder}
                                        style={{ width: "100%" }}
                                    />
                                ) : (
                                    <Input
                                        placeholder={placeholder}
                                        disabled={props.inputs[index].disabled || false}
                                        defaultValue={props.inputs[index].defaultValue}
                                        onChange={props.inputs[index].onChange}
                                        type={props.inputs[index].typeInput || "text"}
                                    />
                                )}
                            </FormItem>
                        </div>

                        {index === props.inputs.length - 1 && props.rightExtra}
                    </div>
                </Col>
            ))}
        </Row>
    );
};
