export type typeTransaction = "booth" | "veggie" | "rider" | "labor" | "lomsak";

export const converterType = {
    booth: "แผงรายเดือน",
    veggie: "แผงผักบ้าน",
    rider: "เสื้อวิน",
    labor: "เสื้อขึ้นของ",
    lomsak: "รถหล่ม",
};

export const typeIncomeExpenses = {
    booth: "แผงรายเดือน",
    boothWater: "ค่าน้ำแผงรายเดือน",
    boothElectricity: "ค่าไฟแผงรายเดือน",
    veggie: "แผงผักบ้าน",
    veggieWater: "ค่าน้ำแผงผักบ้าน",
    veggieElectricity: "ค่าไฟแผงผักบ้าน",
    lomsak: "รถหล่ม",
    lomsakWater: "ค่าน้ำรถหล่ม",
    lomsakElectricity: "ค่าไฟรถหล่ม",
    rider: "เสื้อวิน",
    labor: "เสื้อขึ้นของ",
    nonRegisterCar: "รถรายวัน",
    lottery: "ล็อตเตอรี่",
    toilet: "ห้องน้ำ",
    trolley: "รถเข็น",
    salaryEmployee: "เงินเดือนพนักงาน",
    foodAllowanceEmployee: "ค่าอาหารพนักงาน",
    trafficPoliceAllowance: "ค่าตำรวจจราจร/สายตรวจ",
    fuelCost: "ค่าน้ำมัน",
    waterBill: "ค่าน้ำประปา",
    electricityBill: "ค่าไฟฟ้า",
    telephoneBill: "ค่าโทรศัพท์",
    internetCost: "ค่าอินเตอร์เน็ต",
    maintenanceCost: "ค่าซ่อมบำรุง",
    discountAmount: "ค่าส่วนลด",
    advancePayment: "จ่ายล่วงหน้า",
    other: "อื่นๆ",
};
