import React from "react";
import { Avatar, Tag } from "antd";
import { MemberType } from "../../../../interfaces/MemberManagement";
import { Container, EditButton, DeleteButton } from "./index.style";
import colors from "../../../../assets/colors.json";
import { rolesEnToTh } from "../../../../assets/roles";

function CardMember(
    props: MemberType & {
        onClickDelete?: () => void;
        onClickEdit?: () => void;
        hideDeleteButton?: boolean;
    }
) {
    return (
        <Container>
            <EditButton onClick={props.onClickEdit} />
            {!props.hideDeleteButton && <DeleteButton onClick={props.onClickDelete} />}
            <Avatar
                size={96}
                style={{ verticalAlign: "middle", fontSize: "2rem" }}
                src={props.photoURL}
            >
                {props.firstName?.slice(0, 1)}
            </Avatar>
            <h3>{props.name}</h3>
            <p style={{ margin: 0 }}>{props.email}</p>
            <p style={{ margin: 0 }}>{props.tel}</p>
            <div>
                {props.mainDuty && props.mainDuty !== "admin" ? (
                    <Tag
                        style={{ color: colors.textColor }}
                        color={colors.memberDetailWorkColor?.mainDuty}
                        children={rolesEnToTh[props.mainDuty] || props.mainDuty}
                    />
                ) : null}
                {props.subDuty &&
                    props.subDuty
                        .filter((duty) => duty !== props.mainDuty)
                        .map((item, k) => (
                            <Tag
                                style={{ color: colors.textColor }}
                                key={`subDuty-${k}-${props.name}`}
                                color={colors.memberDetailWorkColor.subDuty}
                                children={rolesEnToTh[item] || item}
                            />
                        ))}
                {props.dutyPool &&
                    props.dutyPool
                        .filter(
                            (duty) =>
                                (props?.subDuty || []).indexOf(duty) < 0 && duty !== props.mainDuty
                        )
                        .map((item, k) => (
                            <Tag
                                style={{ color: colors.textColor }}
                                key={`subDuty-${k}-${props.name}`}
                                color={colors.memberDetailWorkColor.dutyPool}
                                children={rolesEnToTh[item] || item}
                            />
                        ))}
            </div>
        </Container>
    );
}

export default CardMember;
