import { Divider as D } from "antd";
import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const PanalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
`;

export const HeadPanel = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
`;

export const EditText = styled.div`
    color: ${colors.primary};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

export const Divider = styled(D)`
    margin: 1rem 0 !important;
`;
