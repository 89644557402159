import { chainString } from "../../../../functions/chainString";
import { IContractResponse } from "../useContractTable";

export const getIncludes = (searchKey: string, item: IContractResponse, date: string) => {
    return (
        chainString([item.prefix, item.firstName, item.lastName])
            .toLocaleLowerCase().replace(/ /g, '')
            .includes(searchKey.toLocaleLowerCase().replace(/ /g, '')) ||
        item.tel?.toLocaleLowerCase().replace(/ /g, '').includes(searchKey.toLocaleLowerCase().replace(/ /g, '')) ||
        date?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
        item.size?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
        item.boothName.join(" ")?.toLocaleLowerCase().replace(/ /g, '').includes(searchKey.toLocaleLowerCase().replace(/ /g, '')) ||
        item.companyName?.toLocaleLowerCase().replace(/ /g, '').includes(searchKey.toLocaleLowerCase().replace(/ /g, '')) ||
        `${item.shirtNumber ? item.shirtNumber : ""}`
            .toLocaleLowerCase().replace(/ /g, '')
            .includes(searchKey.toLocaleLowerCase().replace(/ /g, '')) ||
        item.building?.toLocaleLowerCase().replace(/ /g, '').includes(searchKey.toLocaleLowerCase().replace(/ /g, ''))
    );
};
