import axios from "axios";
import { ZoneBuilding, ZoneBuildingType } from "../interfaces/Register";

export const getBuildingType = async (type: string) => {
    const res = await axios.get(`/building/${type}`);
    return res.data as ZoneBuilding[];
};

export const getZoneByBuildingNumber = async (type: string, buildingNumber: number) => {
    const res = await axios.get(`/zone/${type}/${buildingNumber}`);
    return res.data as ZoneBuildingType[];
};

export const getShirtZoneByBuildingNumber = async (type: string, buildingNumber: number) => {
    const res = await axios.get(`/contract/shirtNumber/${type}/${buildingNumber}`);
    return res.data as ZoneBuildingType[];
};
