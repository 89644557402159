import dayjs from "dayjs";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { negativeConfirmation, positiveConfirmation } from "../../assets/sweetalert.config";
import { compareVaccines } from "./PartnerContractTable/utility/filterValues";

const convertKeyToWord = (key: string) => {
    const item: { [key: string]: string } = {
        tel: "เบอร์โทรศัพท์",
        prefix: "คำนำหน้าชื่อ",
        firstName: "ชื่อจริง",
        lastName: "นามสกุล",
        ssn: "เลขบัตรประจำตัวประชาชน",
        address: "ที่อยู่",
        subDistrict: "ตำบล",
        district: "อำเภอ",
        province: "จังหวัด",
        postCode: "รหัสไปรษณีย์",
        status: "สถานะสัญญา",
        active: "สถานะปกติ",
        inactive: "สถานะยกเลิกสัญญา",
        onHold: "สถานะรักษาสิทธิ์",
        companyName: "ชื่อบริษัท",
        companyID: "เลขทะเบียนนิติบุคคล",
        parkingList: "รายการที่จอดรถ",
        laborShirtList: "รายการเสื้อแผง",
    };
    return item[key];
};

export const deleteAlert = async (record: any) => {
    const isCompany = record?.companyName || record?.companyID;

    const res = await Swal.fire({
        ...negativeConfirmation({
            html: isCompany
                ? `<h3>คุณต้องการลบสัญญาของบริษัท ${record.companyName} ใช่หรือไม่?</h3>`
                : `<h3>คุณต้องการลบสัญญาของคุณ ${record.firstName} ${record.lastName} ใช่หรือไม่?</h3>`,
        }),
    });
    return res.value;
};

export const timerAlert = async (icon: SweetAlertIcon, title: string) => {
    await Swal.fire({
        icon,
        title,
        timer: 1500,
        showConfirmButton: false,
    });
};

export const reportDeleteAlert = async (index: number) => {
    const result = await Swal.fire({
        ...negativeConfirmation({
            html: `<h3>ต้องการลบข้อมูลวัคซีนเข็มที่ ${index} ใช่หรือไม่</h3>`,
        }),
    });
    return result.value;
};

export const exitOnEditAlert = async () => {
    const result = await Swal.fire({
        ...negativeConfirmation({
            html: "<h3>หากออกตอนนี้ข้อมูลจะยังไม่ถูกแก้ไข <br /> ต้องการจะออกจากหน้านี้ใช่หรือไม่</h3>",
        }),
    });
    return result.value;
};

export const editAlert = async (data: any, old: any) => {
    let text = "";

    Object.keys(data).forEach((key) => {
        if (key === "vaccine") {
            const compare = compareVaccines(old.vaccine, data.vaccine);
            let vac = "";
            compare.forEach((item: any) => {
                if (item.state === "add") {
                    vac += `เพิ่มวัคซีนเข็มที่ ${item.index + 1} วันที่ฉีดวัคซีน ${dayjs(
                        item.date
                    ).format("DD/MM/YYYY")} ชนิด ${item.name} <br />`;
                } else if (item.state === "change date") {
                    vac += `วัคซีนเข็มที่ ${item.index + 1} แก้ไข วันที่ฉีดวัคซีน จาก ${dayjs(
                        item.oldDate
                    ).format("DD/MM/YYYY")} เป็น ${dayjs(item.date).format("DD/MM/YYYY")} <br />`;
                } else if (item.state === "change name") {
                    vac += `วัคซีนเข็มที่ ${item.index + 1} แก้ไขชนิดวัคซีน ${item.oldName
                        } จาก เป็น ${item.name} <br />`;
                } else if (item.state === "change date&name") {
                    vac += `แก้ไขวัคซีนเข็มที่ ${item.index + 1} จากวันที่ฉีดวัคซีน ${dayjs(
                        item.oldDate
                    ).format("DD/MM/YYYY")} ชนิด ${item.oldName} เป็น วันที่ฉีดวัคซีน ${dayjs(
                        item.date
                    ).format("DD/MM/YYYY")} ชนิด ${item.name} <br />`;
                } else if (item.state === "delete") {
                    vac += `ลบวัคซีนเข็มที่ ${item.index + 1} วันที่ฉีดวัคซีน ${dayjs(
                        item.date
                    ).format("DD/MM/YYYY")} ชนิด ${item.name} <br />`;
                }
                // vac += `เพิ่มวัคซีนเข็มที่ ${index + 1} วันที่ฉีดวัคซีน ${dayjs(item.date).format(
                //     "DD/MM/YYYY"
                // )} ชนิด ${item.name} <br />`;
            });
            text += vac;
        } else if (key === "involved") {
            let involved = "";
            if (data[key]["change"].length !== 0) {
                data[key]["change"].forEach((item: any) => {
                    if (data[key][item].match(/^\s+$|^$/gi)) {
                        involved += `ลบ ${convertKeyToWord(item)}ผู้เกี่ยวข้อง <br />`;
                    } else {
                        involved += `แก้ไข ${convertKeyToWord(item)}ผู้เกี่ยวข้อง เป็น ${data[key][item]
                            } <br />`;
                    }
                });
            }
            text += involved;
            delete data[key]["change"];
        } else if (key === "address") {
            let address = "";
            if (data[key]["change"].length !== 0) {
                data[key]["change"].forEach((item: any) => {
                    address += `แก้ไข ${convertKeyToWord(item)} เป็น ${data[key][item]} <br />`;
                });
            }
            text += address;
            delete data[key]["change"];
        } else if (key === "status") {
            text += `${convertKeyToWord(key)} เป็น ${convertKeyToWord(data[key])} <br />`;
        } else {
            text += `${convertKeyToWord(key)} เป็น ${data[key]} <br />`;
        }
    });

    if (text === "") return timerAlert("info", "ไม่มีข้อมูลที่ถูกแก้ไข");

    const result = await Swal.fire({
        ...positiveConfirmation({
            html: `<h3>คุณต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
        }),
    });
    return result.value;
};
