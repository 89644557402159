import React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors.json";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type BreadcrumbProps = {
    labels: string[];
    onClick?: (index: string) => void;
};

export const Container = styled.div`
    display: flex;

    p {
        font-size: 1rem;
    }
    p:not(:last-child):after {
        padding: 0 0.4rem;
        content: ">";
    }
    p:last-child {
        color: ${colors.primary};
    }
`;

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
    const { lg } = useBreakpoint();
    const { labels } = props;
    return (
        <Container>
            {labels.map((label, index) => {
                return (
                    <p key={index}>
                        {lg ? label !== "" ? label : <></> : label !== "" ? label : <></>}
                    </p>
                );
            })}
        </Container>
    );
};

export default Breadcrumb;
