import { Col, Row, Spin } from "antd";
import dayjs from "dayjs";
import {
    AntCard,
    CardTitle,
    Column,
    LogoContainer,
    Paragraph,
    RowTitleCard,
    StatisticItem,
    TypoContainer,
    TypoDesc,
} from "./index.styles";
import React from "react";
import BoothAreaGraph from "../BoothAreaGraph";
import DrawerDisplay from "../DrawerDisplay";
import { formatNumber } from "../../../functions/formatNumber";
import {
    formatDiffStatus,
    renderBoothCardOption,
    renderValue,
    statOption,
} from "../utility/mapping";
import Progress from "../Progress";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type StatisicBoothCardProps = {
    type: "booth" | "veggie";
};

const StatisicBoothCard: React.FC<StatisicBoothCardProps> = (props: StatisicBoothCardProps) => {
    const { type } = props;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { isLoading, summaryCard } = useDashboardProvider();
    const { xs } = useBreakpoint();

    const { paid, total, diff } = React.useMemo(() => {
        if (!summaryCard) return { paid: 0, total: 0, diff: 0 };
        return summaryCard[type];
    }, [summaryCard, type]);

    const { logo, symbol } = React.useMemo(() => {
        return statOption[formatDiffStatus(diff)];
    }, [diff]);

    return (
        <>
            <AntCard
                title={
                    <CardTitle>
                        {renderBoothCardOption[type].title}{" "}
                        {dayjs().format(renderBoothCardOption[type].defaultFormatTitle)}
                    </CardTitle>
                }
                extra={
                    <CardTitle color="subtitle" onClick={() => setIsOpen(!isOpen)}>
                        ดูข้อมูลเชิงลึก
                    </CardTitle>
                }
                style={{ marginTop: xs && type === "veggie" ? "0.75rem" : 0 }}
            >
                {!isLoading && summaryCard && summaryCard[type] ? (
                    <Row justify="space-between" align="middle">
                        <Column xs={17}>
                            <RowTitleCard>
                                <LogoContainer>{renderBoothCardOption[type].logo}</LogoContainer>
                                <Paragraph>{renderBoothCardOption[type].name}</Paragraph>
                                <StatisticItem
                                    value={Math.abs(diff)}
                                    prefix={
                                        <>
                                            {logo}
                                            <span>{symbol}</span>
                                        </>
                                    }
                                    color={formatDiffStatus(diff)}
                                />
                            </RowTitleCard>
                            <TypoContainer>
                                <span>{renderValue(summaryCard[type])}</span>
                                <span>/ {formatNumber(total!)} บาท</span>
                            </TypoContainer>
                            <TypoDesc>จำนวนเงินที่ชำระแล้ว</TypoDesc>
                        </Column>
                        <Col xs={6}>
                            <Progress recentValue={paid!} totalValue={total!} />
                        </Col>
                    </Row>
                ) : (
                    <Spin />
                )}
            </AntCard>
            <DrawerDisplay isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
                <BoothAreaGraph type={type} filter={renderBoothCardOption[type].filterOption} />
            </DrawerDisplay>
        </>
    );
};

export default StatisicBoothCard;
