import { ProgressCircle } from "./index.styles";
import colors from "../../../assets/colors.json";

type ProgressProps = {
    recentValue: number;
    totalValue: number;
    width?: number;
};
const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
    const { recentValue, totalValue, width } = props;
    return (
        <ProgressCircle
            width={width ? width : 86}
            percent={(recentValue / totalValue) * 100}
            strokeColor={(recentValue / totalValue) * 100 > 50 ? colors.primary : colors.warning}
            format={(percent) => (
                <div>
                    <span>{percent! >= 100 ? 100 : percent?.toPrecision(2)}%</span>
                    <span>จ่ายแล้ว</span>
                </div>
            )}
        />
    );
};

export default Progress;
