import { Grid } from "antd";
import React from "react";
import { useLayoutContext } from "../providers/LayoutProvider";
import AppSidebar from "./AppSideBar";
import { AppContent, AppLayout, ContentLayout } from "./index.style";
import AppHeader from "./AppHeader";
import PDPAComponent from "../components/PDPA";

function Layouts(props: { children: React.ReactElement }) {
    const { lg } = Grid.useBreakpoint();
    React.useEffect(() => {
        if (lg === undefined) return;
        setCollapsed(!lg);
    }, [lg]);

    const [collapsed, setCollapsed] = React.useState(false);
    const toggle = () => setCollapsed((collapse) => !collapse);
    const { isShowLayout } = useLayoutContext();

    return isShowLayout ? (
        <AppLayout>
            <AppHeader toggle={toggle} />
            <ContentLayout>
                <AppSidebar collapsed={collapsed} />
                <AppContent>{props.children}</AppContent>
            </ContentLayout>
            <PDPAComponent />
        </AppLayout>
    ) : (
        props.children
    );
}

export default Layouts;
