import { FormOneLabel, FormLaborAndRiderShirt } from "../FormShirtPrice";
import { Renderer } from "../../../../interfaces/PriceManagement";

export const FormShirtRenderer: Renderer[] = [
    {
        header: "ราคาเสื้อแผง",
        component: <FormOneLabel typelabel="boothShirt" />,
    },
    {
        header: "ราคาเสื้อวิน",
        component: <FormLaborAndRiderShirt type="riderShirt" />,
    },
    {
        header: "ราคาเสื้อขึ้นของ",
        component: <FormLaborAndRiderShirt type="laborShirt" />,
    },
    {
        header: "ราคาที่จอดรถ",
        component: <FormOneLabel typelabel="parking" />,
    },
];
