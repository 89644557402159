import Swal from "sweetalert2";
import { confirmation, positiveConfirmation } from "../../../assets/sweetalert.config";

export const onAddAlert = async () => {
    const result = await Swal.fire({
        ...confirmation({
            title: "ยืนยันการบันทึก",
            text: `คุณต้องการบันทึกรายการนี้ใช่หรือไม่?`,
        }),
    });
    return result.value;
};

export const successAlert = async (title: string, text: string) => {
    const result = await Swal.fire({
        ...confirmation({
            title,
            text,
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500,
        }),
    });
    return result.value;
};

export const onAddTransactionAlert = async () => {
    const result = await Swal.fire({
        ...positiveConfirmation({
            title: "ยืนยันการบันทึก",
            text: `คุณต้องการบันทึกรายการนี้ใช่หรือไม่?`,
        }),
    });
    return result.value;
};

export const onErrorAlert = async (title: string, text: string) => {
    const result = await Swal.fire({
        ...confirmation({
            title,
            text,
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500,
        }),
    });
    return result.value;
};
