import { Form, Input, Typography } from "antd";
import styled from "styled-components";

export const InputContainer = styled.div`
    display: flex;
    margin: 0.25rem 0;
    align-items: center;
    justify-content: space-between;
`;

export const TextPara = styled(Typography.Paragraph)`
    margin: 0 !important;
    font-size: 0.9rem;
`;

export const AddressText = styled(Typography.Paragraph)`
    margin-top: 0.75rem !important;
    font-size: 0.9rem;
`;

export const InputItem = styled(Input)`
    border: ${(props: { editable?: string }) =>
        props.editable === "true" ? "1px solid #d9d9d9" : "none"};
`;

export const TextAreaInput = styled(Input.TextArea)`
    margin-right: 0.5rem;
    border: ${(props: { editable?: string }) =>
        props.editable === "true" ? "1px solid #d9d9d9" : "none"};
`;

export const FormItem = styled(Form.Item)`
    margin: 0;
    margin-right: 0.5rem;
    width: 50%;
`;
