export const typeList: { [key: string]: any } = {
    labor: "เสื้อขึ้นของ",
    rider: "เสื้อวิน",
    lomsak: "รถหล่ม",
    booth: "แผงรายเดือน",
    veggie: "แผงผักบ้าน",
    parking: "ที่จอดรถ",
    electricity: "ค่าไฟฟ้า",
    water: "ค่าน้ำ",
};

export type RolePagesType = "labor" | "rider" | "lomsak" | "booth" | "veggie";

export const typeElectricityCostTs: { [key: string]: any } = {
    pricePerUnit: "ไฟปกติ",
    fifteenAmps: "ไฟ 15 แอมป์",
    threePhase: "ไฟ 3 เฟส",
};
