import React from "react";
import { Spin, PageHeader } from "antd";
import useDailyReport from "./useDailyReport";
import { CardMemberContainer } from "./index.style";
import CardDuty from "../../components/DailyPage/CardDuty";
import { useAuthContext } from "../../providers/AuthProvider";

type DailyProps = {};

const Daily: React.FC<DailyProps> = () => {
    const { isLoading, dailyReport } = useDailyReport();
    const { user } = useAuthContext();
    return (
        <>
            <PageHeader
                title="สรุปยอดวันนี้"
                subTitle={`สรุปยอดเก็บเงินวันนี้ของคุณ ${user?.name || ''}`}
            />
            <Spin tip="Loading..." spinning={isLoading}>
                <CardMemberContainer>
                    {dailyReport.map((item) => (
                        <CardDuty key={item.duty} {...item} />
                    ))}
                </CardMemberContainer>
            </Spin>
        </>
    );
};

export default Daily;
