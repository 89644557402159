import { Progress } from "antd";
import styled from "styled-components";

export const ProgressCircle = styled(Progress).attrs({
    type: "circle",
    strokeWidth: 12,
    strokeLinecap: "square",
})`
    & .ant-progress-text {
        div {
            display: flex;
            flex-direction: column;

            span:first-child {
                font-size: 0.9rem;
                font-weight: bold;
            }
            span:last-child {
                font-size: 0.7rem;
            }
        }
    }
`;
