import { Upload } from "antd";
import styled from "styled-components";
import colors from "../../../../assets/colors.json";

export const CustomUpload = styled(Upload)`
    width: fit-content !important;
    & .ant-upload.ant-upload-select-picture-card {
        border-radius: 999px !important;
        overflow: hidden !important;
        ${({ error }: { error?: string }) =>
            error && `border-color: red !important; color: red !important;`}
    }
`;

export const SubRouter = styled.div`
    border-bottom: 1px solid ${colors.border};
    padding: 1rem 0;

    & span {
        padding: 1rem 0.5rem;
        cursor: pointer;
        color: ${colors.hintText};
    }
    & span.active {
        color: ${colors.primary};
        border-bottom: 2px solid ${colors.primary};
    }
`;