import React from "react";
import { PageHeader, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import SearchInput from "../../components/common/SearchInput";
import CardMember from "../../components/MemberManagementPage/Staff/CardMember";
import CardMemberCreate from "../../components/MemberManagementPage/Staff/CardMember/Create";
import { CardMemberContainer } from "./index.style";
import useAdminDetail, { AdminDetailContext } from "./useAdminDetail";
import ModalCreateAdmin from "../../components/AdminManagementPage/ModalCreateAdmin";
import ModalUpdateAdmin from "../../components/AdminManagementPage/ModalUpdateAdmin";
import { useAuthContext } from "../../providers/AuthProvider";

const AdminManagement: React.FC = () => {
    const adminDetail = useAdminDetail();
    const [keySearch, setKeySearch] = React.useState("");
    const { user } = useAuthContext();
    const {
        adminList,
        handleSearch,
        isLoading,
        setOpenModalCreateAdmin,
        handleDelete,
        handleEdit,
    } = adminDetail;
    const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
        setKeySearch(event.target.value);
    return (
        <AdminDetailContext.Provider value={adminDetail}>
            <Content>
                <ModalCreateAdmin />
                <ModalUpdateAdmin />
                <PageHeader
                    style={{ padding: "0px" }}
                    title="จัดการข้อมูลผู้ดูแลระบบ"
                    extra={
                        <SearchInput
                            placeholder={"ค้นหาด้วย ชื่อ / อีเมล"}
                            onChange={handleChangeSearch}
                        />
                    }
                >
                    <Spin tip="Loading..." spinning={isLoading}>
                        <CardMemberContainer>
                            <CardMemberCreate onClick={() => setOpenModalCreateAdmin(true)} />
                            {handleSearch(keySearch, adminList).map((item, key) => (
                                <CardMember
                                    hideDeleteButton={user?.uid === item.uid}
                                    onClickDelete={() => handleDelete(item)}
                                    onClickEdit={() => handleEdit(item)}
                                    key={`${item.name}-${key}`}
                                    {...item}
                                />
                            ))}
                        </CardMemberContainer>
                        <Row justify="center" style={{ padding: 16 }}></Row>
                    </Spin>
                </PageHeader>
            </Content>
        </AdminDetailContext.Provider>
    );
};

export default AdminManagement;
