import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import breakpoint from "../../../assets/breakpoint.json";

export default styled(Input).attrs({
    prefix: <SearchOutlined />,
})`
    width: 240px;
    color: #d9d9d9;
    @media (max-width: ${breakpoint.mobile}) {
        flex: 1;
    }
`;
