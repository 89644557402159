import React from "react";
import { Modal, Row, Col } from "antd";
import { ButtonReset } from "./index.styles";

type ResetModalMeterProps = {
    isVisible: boolean;
    handleCancel: () => void;
    onResetNewContract: () => void;
    onClearMerter: () => void;
};

const ResetModalMeter: React.FC<ResetModalMeterProps> = (props) => {
    const { isVisible, handleCancel, onResetNewContract, onClearMerter } = props;

    return (
        <Modal title="รีเซ็ตมิเตอร์" visible={isVisible} footer={false} onCancel={handleCancel}>
            <Row justify="center" gutter={24}>
                <Col span={12}>
                    <ButtonReset onClick={onClearMerter}>รีเซ็ตมิเตอร์ใหม่</ButtonReset>
                </Col>
                <Col span={12}>
                    <ButtonReset onClick={onResetNewContract}>เปลี่ยนสัญญา</ButtonReset>
                </Col>
            </Row>
        </Modal>
    );
};

export default ResetModalMeter;
