import axios from "axios";
import { IDashboardCard } from "../interfaces/Dashboard";

export const getDashboardData = async (monthYear?: number) => {
    let url = `/dashboard`;
    if (monthYear) url = `${url}?monthYear=${monthYear}`;
    const res = await axios.get(url);
    // const res = await axios.get(`/dashboard/mockup`);
    return res.data as IDashboardCard;
};

export const getDashboardGraphWithType = async (
    filter: string,
    type: string,
    monthYear?: number
) => {
    // const res = await axios.get(`/dashboard/?monthYear=${monthYear}`);
    let res;
    if (monthYear) {
        res = await axios.get(`/dashboard/${filter}ly/${type}?monthYear=${monthYear}`);
    } else {
        res = await axios.get(`/dashboard/${filter}ly/${type}`);
    }
    return res.data as IDashboardCard;
};
