import { Form, Row, Typography } from "antd";
import styled from "styled-components";

export const ContainerRow = styled(Row).attrs({
    align: "middle",
})`
    margin-bottom: 1rem;
`;

export const Item = styled(Form.Item)`
    &.ant-form-item {
        margin-bottom: 0;
    }
`;

export const Title = styled(Typography.Title).attrs({
    level: 5,
})`
    &.ant-typography {
        margin-bottom: 0;
    }
`;

export const RequiredMask = styled.span`
    color: red;
    margin-right: 0.25rem;
`;
