import { Button } from "antd";
import React from "react";
import { ButtonContainer } from "./index.styles";

export type IPanelItem = {
    component: React.ReactNode;
    cancel: () => void;
};

function RenderPanalData(props: IPanelItem) {
    const { component, cancel } = props;
    return (
        <>
            {component}
            <ButtonContainer>
                <Button onClick={cancel}>ยกเลิก</Button>
                <Button type="primary" htmlType="submit">
                    ยืนยันการแก้ไข
                </Button>
            </ButtonContainer>
        </>
    );
}

export default RenderPanalData;
