import { Breadcrumb, PageHeader } from "antd";
import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BreadcrumbContainer = styled(Breadcrumb).attrs({
    separator: ">",
})`
    font-size: 1.25rem;
    font-weight: bold;
    color: ${colors.textColor};
    cursor: pointer;
    li:first-child {
        cursor: default;
    }

    li:last-child {
        color: ${colors.primary};
    }
`;

export const AntHeaderPage = styled(PageHeader)`
    &.ant-page-header {
        padding: 1rem 0;
    }
`;
