import { Row, Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import dayjs from "dayjs";
import { TShirt } from "phosphor-react";
import React from "react";
import { formatNumber } from "../../../functions/formatNumber";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import BarGraph from "../BarGraph";
import DrawerDisplay from "../DrawerDisplay";
import Progress from "../Progress";
import { formatDiffStatus, renderOther, renderValue, statOption } from "../utility/mapping";
import {
    AntCard,
    CardTitle,
    Column,
    LogoContainer,
    Paragraph,
    RowTitleCard,
    StatisticItem,
    TypoContainer,
} from "./index.styles";

type StatisticShirtCardProps = {};
export const StatisticShirtCard: React.FC<StatisticShirtCardProps> = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { isLoading, summaryCard } = useDashboardProvider();

    const { paidLabor, totalLabor, diffLabor } = React.useMemo(() => {
        if (!summaryCard) return { paidLabor: 0, totalLabor: 0, diffLabor: 0 };
        return {
            paidLabor: summaryCard["labor"].paid,
            totalLabor: summaryCard["labor"].total,
            diffLabor: summaryCard["labor"].diff,
        };
    }, [summaryCard]);

    const { paidRider, totalRider, diffRider } = React.useMemo(() => {
        if (!summaryCard) return { paidRider: 0, totalRider: 0, diffRider: 0 };
        return {
            paidRider: summaryCard["rider"].paid,
            totalRider: summaryCard["rider"].total,
            diffRider: summaryCard["rider"].diff,
        };
    }, [summaryCard]);

    const { symbolLabor, symbolRider } = React.useMemo(() => {
        return {
            symbolLabor: statOption[formatDiffStatus(diffLabor)].symbol,
            symbolRider: statOption[formatDiffStatus(diffRider)].symbol,
        };
    }, [diffLabor, diffRider]);

    return (
        <>
            <AntCard
                title={<CardTitle>ประจำเดือน {dayjs().format("MMMM YYYY")}</CardTitle>}
                extra={
                    <CardTitle color="subtitle" onClick={() => setIsOpen(!isOpen)}>
                        ดูข้อมูลเชิงลึก
                    </CardTitle>
                }
            >
                {!isLoading && summaryCard && summaryCard["rider"] && summaryCard["labor"] ? (
                    <>
                        <Row justify="space-between" align="middle">
                            <RowTitleCard>
                                <LogoContainer>
                                    <TShirt size={26} />
                                </LogoContainer>
                                <Paragraph>ค่าเสื้อ</Paragraph>
                            </RowTitleCard>
                            <CardTitle>จำนวนเงินที่ชำระแล้ว</CardTitle>
                        </Row>
                        <Row justify="space-between" style={{ marginTop: "0.25rem" }}>
                            <Column span={12}>
                                <Progress recentValue={paidLabor!} totalValue={totalLabor!} />
                                <Column>
                                    <TypoContainer>
                                        <span>{renderValue(summaryCard["labor"])}</span>
                                        <span>/ {formatNumber(totalLabor!)} บาท</span>
                                    </TypoContainer>
                                    <RowTitleCard>
                                        <Paragraph>เสื้อขึ้นของ</Paragraph>
                                        <StatisticItem
                                            value={Math.abs(diffLabor)}
                                            prefix={<span>{symbolLabor}</span>}
                                            color={formatDiffStatus(diffLabor)}
                                        />
                                    </RowTitleCard>
                                </Column>
                            </Column>
                            <Column span={12}>
                                <Progress recentValue={paidRider!} totalValue={totalRider!} />
                                <Column>
                                    <TypoContainer>
                                        <span>{renderValue(summaryCard["rider"])}</span>
                                        <span>/ {formatNumber(totalRider!)} บาท</span>
                                    </TypoContainer>
                                    <RowTitleCard>
                                        <Paragraph>เสื้อวิน</Paragraph>
                                        <StatisticItem
                                            value={Math.abs(diffRider)}
                                            prefix={<span>{symbolRider}</span>}
                                            color={formatDiffStatus(diffRider)}
                                        />
                                    </RowTitleCard>
                                </Column>
                            </Column>
                        </Row>
                    </>
                ) : (
                    <Spin />
                )}
            </AntCard>
            <DrawerDisplay isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
                <BarGraph type="shirt" optionGraph={renderOther["shirt"].legendGraph} />
            </DrawerDisplay>
        </>
    );
};

type StatisticBoothCardProps = {};
export const StatisticBoothShirt: React.FC<StatisticBoothCardProps> = () => {
    const { isLoading, summaryCard } = useDashboardProvider();
    const { xs } = useBreakpoint();

    const { paid, total, diff } = React.useMemo(() => {
        if (!summaryCard) return { paid: 0, total: 0, diff: 0 };
        return {
            paid: summaryCard["boothShirt"]?.paid ?? 0,
            total: summaryCard["boothShirt"]?.total ?? 0,
            diff: summaryCard["boothShirt"]?.diff ?? 0,
        };
    }, [summaryCard]);

    const { symbol } = React.useMemo(() => {
        return statOption[formatDiffStatus(diff)];
    }, [diff]);

    return (
        <>
            <AntCard
                title={<CardTitle>จำนวนเงินที่ชำระแล้ว</CardTitle>}
                extra={<CardTitle>ปี {dayjs().format("YYYY")}</CardTitle>}
                style={{ marginTop: xs ? "0.75rem" : 0 }}
            >
                {!isLoading && summaryCard && summaryCard["boothShirt"] ? (
                    <>
                        <div>
                            <Row justify="space-between" align="middle" style={{ opacity: 0 }}>
                                <RowTitleCard>
                                    <LogoContainer>
                                        <TShirt size={26} />
                                    </LogoContainer>
                                    <Paragraph>ค่าเสื้อ</Paragraph>
                                </RowTitleCard>
                                <CardTitle>จำนวนเงินที่ชำระแล้ว</CardTitle>
                            </Row>
                            <Column style={{ height: "100%" }}>
                                <Progress width={98} recentValue={paid!} totalValue={total!} />
                                <Column>
                                    <TypoContainer>
                                        <span>{renderValue(summaryCard["boothShirt"])}</span>
                                        <span>/ {formatNumber(total!)} บาท</span>
                                    </TypoContainer>
                                    <RowTitleCard>
                                        <Paragraph>เสื้อแผง</Paragraph>
                                        <StatisticItem
                                            value={Math.abs(diff)}
                                            prefix={<span>{symbol}</span>}
                                            color={formatDiffStatus(diff)}
                                        />
                                    </RowTitleCard>
                                </Column>
                            </Column>
                        </div>
                    </>
                ) : (
                    <Spin />
                )}
            </AntCard>
        </>
    );
};
