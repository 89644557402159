import { Rule } from "antd/lib/form";
import ssnValidation from "../functions/ssnValidation";

export default {
    phoneNumber: [
        {
            pattern: /^0[9, 8, 6][0-9]{8}$/,
            message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
        },
    ],
    ssn: [
        () => ({
            required: true,
            validator(_, value) {
                if (value && value.length === 13) {
                    const ssn = ssnValidation(value);
                    if (ssn === true) return Promise.resolve();
                    return Promise.reject(new Error("รหัสบัตรประชาชนรูปแบบไม่ถูกต้อง"));
                }
                if (value && value.length > 13) {
                    return Promise.reject(new Error("รหัสบัตรประชาชนรูปแบบต้องไม่เกิน 13 หลัก"));
                } else {
                    return Promise.reject(new Error("กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก"));
                }
            },
        }),
    ],
    zipCode: [
        {
            pattern: /^[0-9]{5}$/,
            message: "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง",
        },
    ],
    firstName: [
        {
            pattern: /^[a-zA-Z\s | ก-๏\s]+$/,
            message: "ชื่อไม่ควรมีอักขระพิเศษหรือตัวเลข",
        },
    ],
    lastName: [
        {
            pattern: /^[a-zA-Z\s | ก-๏\s]+$/,
            message: "นามสกุลไม่ควรมีอักขระพิเศษหรือตัวเลข",
        },
    ],
    shirtNumber: [
        {
            pattern: /^[a-zA-Z\s | ก-๏\s | 0-9]+$/,
            message: "หมายเลขเสื้อไม่ควรมีอักขระพิเศษ",
        },
    ],
    vaccine: [
        {
            pattern: /^[a-zA-Z\s | ก-๏\s | 0-9]+$/,
            message: "ชื่อวัคซีนไม่ควรมีอักขระพิเศษ",
        },
    ],
} as { [key: string]: Rule[] };
