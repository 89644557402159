import styled from "styled-components";
import { Modal, Button } from "antd";


export const CustomModal = styled(Modal)`
& .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
}
& .ant-modal-footer {
    border: none;
}
`;

export const SubmitButton = styled(Button).attrs({
    type: "primary",
})`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
`;
