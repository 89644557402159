import { Input, Dropdown, Menu, InputNumber, Select } from "antd";
import { FormItem } from "../../../ContractMemberManagementPage/ModalComponents/VaccineTimeline/index.styles";
import { EllipsisOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Container, EditContainer, RowContainer, DropdownButton } from "./index.styles";
import { IBoothPricingResponse } from "../../../../interfaces/PriceManagement";
import { formatNumber } from "../../../../functions/formatNumber";
import { typeElectricityCostTs } from "../../../../assets/typeEnumTranslation";

type IBoothCard = {
    boothList: string[];
    onDelete: (index: number) => void;
    onCancelEdit: () => void;
    onEdit: (index: number) => void;
    edit: boolean;
    data: IBoothPricingResponse;
    index: number;
    type: "booth" | "veggie" | "lomsak";
};

const { Option } = Select;

function BoothCardComponent(props: IBoothCard) {
    const { onDelete, onEdit, edit, boothList, data, index, type } = props;

    const isLomsak = type === "lomsak";

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => onEdit(index)}>
                <EditOutlined style={{ fontSize: "16px" }} /> แก้ไข
            </Menu.Item>
            {index !== 0 && (
                <Menu.Item key="2" onClick={() => onDelete(index)}>
                    <DeleteOutlined style={{ fontSize: "16px" }} /> ลบ
                </Menu.Item>
            )}
        </Menu>
    );
    return (
        <div>
            <Container>
                {!edit ? (
                    <>
                        <h2>
                            {data[boothList[index]].boothName
                                ? data[boothList[index]].boothName
                                : boothList[index]}
                        </h2>
                        <EditContainer>
                            <Dropdown
                                overlay={menu}
                                trigger={["click"]}
                                placement="bottomRight"
                                arrow
                            >
                                <DropdownButton>
                                    <EllipsisOutlined style={{ fontSize: "20px" }} />
                                </DropdownButton>
                            </Dropdown>
                        </EditContainer>
                    </>
                ) : (
                    <FormItem
                        style={{ width: "100%" }}
                        name={`${boothList[index]}-boothName-${index}`}
                        rules={[
                            {
                                required: true,
                                message: `กรุณาระบุ${isLomsak ? "ชื่อที่จอด" : "ชื่อแผง"}`,
                            },
                        ]}
                    >
                        <Input addonBefore={`${isLomsak ? "ชื่อที่จอด" : "ชื่อแผง"}`} />
                    </FormItem>
                )}
            </Container>
            <RowContainer>
                <h3>ราคา{isLomsak ? "ที่จอด" : "แผง"}</h3>
                {!edit ? (
                    <h3>{formatNumber(data[boothList[index]].monthly) + " บาท"}</h3>
                ) : (
                    <FormItem
                        name={`${boothList[index]}-monthly-${index}`}
                        style={{ width: 180 }}
                        rules={[
                            {
                                required: true,
                                message: "กรุณาระบุราคา",
                            },
                        ]}
                    >
                        <InputNumber
                            addonAfter="บาท"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                        />
                    </FormItem>
                )}
            </RowContainer>
            <RowContainer>
                <h3>ค่ารายปี</h3>
                {!edit ? (
                    <h3>{formatNumber(data[boothList[index]].yearly) + " บาท"}</h3>
                ) : (
                    <FormItem
                        name={`${boothList[index]}-yearly-${index}`}
                        style={{ width: 180 }}
                        rules={[
                            {
                                required: true,
                                message: "กรุณาระบุราคา",
                            },
                        ]}
                    >
                        <InputNumber
                            addonAfter="บาท"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                        />
                    </FormItem>
                )}
            </RowContainer>
            {!isLomsak && (
                <RowContainer>
                    <h3>ค่าประกัน</h3>
                    {!edit ? (
                        <h3>{formatNumber(data[boothList[index]]?.garantee || 0) + " บาท"}</h3>
                    ) : (
                        <FormItem
                            name={`${boothList[index]}-garantee-${index}`}
                            style={{ width: 180 }}
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                    message: "กรุณาระบุราคา",
                                },
                            ]}
                        >
                            <InputNumber
                                defaultValue={0}
                                addonAfter="บาท"
                                formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                            />
                        </FormItem>
                    )}
                </RowContainer>
            )}

            <RowContainer>
                <h3>ประเภทไฟ</h3>
                {!edit ? (
                    <h3>
                        {
                            typeElectricityCostTs[
                                data[boothList[index]]?.electricityCostType ?? "pricePerUnit"
                            ]
                        }
                    </h3>
                ) : (
                    <FormItem
                        name={`${boothList[index]}-electricityCostType-${index}`}
                        initialValue={data[boothList[index]]?.electricityCostType ?? "pricePerUnit"}
                        style={{ width: 180 }}
                        rules={[
                            {
                                required: true,
                                message: "กรุณาเลือกประเภทไฟ",
                            },
                        ]}
                    >
                        <Select
                            defaultValue={
                                data[boothList[index]]?.electricityCostType ?? "pricePerUnit"
                            }
                        >
                            <Option value="pricePerUnit">ไฟปกติ</Option>
                            <Option value="fifteenAmps">ไฟ 15 แอมป์</Option>
                            <Option value="threePhase">ไฟ 3 เฟส</Option>
                        </Select>
                    </FormItem>
                )}
            </RowContainer>
        </div>
    );
}
export default BoothCardComponent;
