import React from "react";
import { Avatar, Form, notification, Row, Select } from "antd";
import { useMemberDetailContext } from "../../../../pages/MemberManagement/Staff/MemberDetail/useMemberDetail";
import CustomUpload, { UploadButton } from "../../../common/CustomUpload";
import { rolesEnToTh, rolesIgnore } from "../../../../assets/roles";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { EditOutlined } from "@ant-design/icons";
import * as swlConfig from "../../../../assets/sweetalert.config";
import { CustomModal, MaxSelectorHint, SubmitButton, CustomSelect } from "./index.style";
import Swal from "sweetalert2";
import { uploadImageReturnUrl } from "../../../../functions/storage";
import { RowGroupInput } from "../../../common/CustomForm";
import validator from "../../../../assets/formValidator";
import { antdFileToBase64, base64ToBlob } from "../../../../functions/files";

export interface WorkOption {
    readonly value: string;
    readonly label: string;
}
const { Option } = Select;

const rolesList: string[] = Object.keys(rolesEnToTh).filter((item) => !rolesIgnore.includes(item));

function ModalUpdateWorkList() {
    const [dutyPool, setDutyPool] = React.useState<string[]>();
    const [mainDuty, setMainDuty] = React.useState<string>();
    const [imageBase64, setImageBase64] = React.useState<string>();
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [form] = Form.useForm();

    const {
        openModalUpdate,
        memberUpdate,
        setOpenModalUpdate,
        handleUpdateWorkList,
        updateDutyMember,
        setIsLoading,
        fetchDataPage,
    } = useMemberDetailContext();

    const [editMode, setEditMode] = React.useState<boolean>(false);
    React.useEffect(() => setEditMode(false), [openModalUpdate]);

    React.useEffect(() => {
        if (!memberUpdate) return;
        setDutyPool(memberUpdate?.dutyPool);
        setImageBase64(memberUpdate?.photoURL);
        setMainDuty(memberUpdate.mainDuty);
        form.setFieldsValue({
            name: memberUpdate?.name,
            email: memberUpdate?.email,
            tel: memberUpdate?.tel,
            mainDuty: memberUpdate?.mainDuty,
        });
    }, [memberUpdate, form, openModalUpdate]);

    React.useEffect(
        () => setDutyPool((old) => old?.filter((item) => item !== mainDuty)),
        [mainDuty]
    );

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadImage = React.useCallback(async () => {
        if (!imageBase64) return;
        const blob = await base64ToBlob(imageBase64);
        const urls = await uploadImageReturnUrl("images/users", [blob]);
        return urls[0];
    }, [imageBase64]);

    const handleSubmit = React.useCallback(
        async (data) => {
            if (!dutyPool) return setOpenModalUpdate(false);

            const { isConfirmed } = await Swal.fire(
                swlConfig.positiveConfirmation({
                    title: "คุณต้องการบันทึกข้อมูลหรือไม่ ?",
                    html: "หากลบหน้าที่ ที่ทำการสุ่มไปแล้วพนักงานจะยังต้องทำหน้าที่ต่อไป <br/> จนกว่าจะทำการสุ่มใหม่",
                })
            );

            if (isConfirmed) {
                setIsLoading(true);
                const photoURL =
                    imageBase64 === memberUpdate?.photoURL
                        ? imageBase64 || undefined
                        : await uploadImage();
                if (!memberUpdate) return;

                await updateDutyMember({
                    ...memberUpdate,
                    ...data,
                    photoURL,
                    mainDuty,
                    dutyPool,
                });
                handleUpdateWorkList(memberUpdate, mainDuty, dutyPool);
                setOpenModalUpdate(false);
                await fetchDataPage();
                setIsLoading(false);
            }
        },
        [
            memberUpdate,
            mainDuty,
            dutyPool,
            handleUpdateWorkList,
            updateDutyMember,
            setIsLoading,
            setOpenModalUpdate,
            uploadImage,
            imageBase64,
            fetchDataPage,
        ]
    );

    const onCancel = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "ยกเลิกการแก้ไขข้อมูลพนักงาน!",
                text: `กรุณายืนยันเพื่อยกเลิกการแก้ไขข้อมูลพนักงาน`,
            })
        );
        if (isConfirmed) return setOpenModalUpdate(false);
    }, [setOpenModalUpdate]);

    return (
        <CustomModal visible={openModalUpdate} footer={null} onCancel={onCancel} destroyOnClose>
            <Form form={form} onFinish={handleSubmit}>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="รูปพนักงาน"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={onChangeImage}
                        customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                    >
                        {imageBase64 ? (
                            <Avatar
                                src={imageBase64}
                                alt="avatar"
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                        ) : (
                            <UploadButton loadingImage={loadingImage} imageError={imageError} />
                        )}
                    </CustomUpload>
                </Row>
                {!editMode ? (
                    <>
                        <h3 style={{ textAlign: "center" }}>
                            {memberUpdate?.name}
                            <EditOutlined
                                style={{ marginLeft: 6, cursor: "pointer" }}
                                onClick={() => setEditMode((old) => !old)}
                            />
                        </h3>
                        <p style={{ textAlign: "center", margin: 0 }}>{memberUpdate?.email}</p>
                        <p style={{ textAlign: "center" }}>{memberUpdate?.tel}</p>
                    </>
                ) : (
                    <>
                        <RowGroupInput
                            inputs={[
                                {
                                    name: "name",
                                    label: "ชื่อ - สกุล",
                                    rules: [
                                        {
                                            required: true,
                                            message: "กรุณากรอกชื่อ - สกุล",
                                        },
                                    ],
                                    marginBottom: 0,
                                },
                            ]}
                        />
                        <RowGroupInput
                            inputs={[
                                {
                                    name: "email",
                                    label: "อีเมล",
                                    rules: [
                                        {
                                            required: true,
                                            message: "กรุณากรอกอีเมล",
                                        },
                                    ],
                                    disabled: true,
                                    marginBottom: 0,
                                },
                            ]}
                        />
                        <RowGroupInput
                            inputs={[
                                {
                                    name: "tel",
                                    label: "เบอร์โทรศัพท์",
                                    rules: [
                                        {
                                            required: true,
                                            message: "กรุณากรอกเบอร์โทรศัพท์",
                                        },
                                        ...validator.phoneNumber,
                                    ],
                                    placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                                    marginBottom: 0,
                                },
                            ]}
                        />
                    </>
                )}

                <RowGroupInput
                    inputs={[
                        {
                            name: "mainDuty",
                            label: "หน้าที่หลัก",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกหน้าที่หลัก",
                                },
                            ],
                            marginBottom: 0,
                            customInput: (
                                <CustomSelect
                                    value={mainDuty}
                                    placeholder="กรุณาเลือกหน้าที่หลัก"
                                    onChange={(value) => setMainDuty(value as string)}
                                >
                                    {rolesList
                                        .filter((item) => !dutyPool?.includes(item))
                                        .map((item) => (
                                            <Option key={item} value={item}>
                                                {rolesEnToTh[item]}
                                            </Option>
                                        ))}
                                </CustomSelect>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "dutyPool",
                            label: "หน้าที่สามารถทำได้",
                            marginBottom: 0,
                            customInput: (
                                <>
                                    <CustomSelect
                                        mode="multiple"
                                        value={dutyPool}
                                        placeholder="กรุณาเลือกหน้าที่สามารถทำได้"
                                        maxTagCount={5}
                                        onChange={(value) => {
                                            const val = value as string[];
                                            return setDutyPool((old) => {
                                                if (val.length > 5) {
                                                    form.setFieldsValue({ dutyPool: old });
                                                    return old;
                                                }
                                                return val;
                                            });
                                        }}
                                    >
                                        {rolesList
                                            .filter((item) => item !== mainDuty)
                                            .map((item) => (
                                                <Option key={item} value={item}>
                                                    {rolesEnToTh[item]}
                                                </Option>
                                            ))}
                                    </CustomSelect>
                                    <MaxSelectorHint>
                                        จำนวนหน้าที่ทั้งหมด <span>{dutyPool?.length || 0} / 5</span>
                                    </MaxSelectorHint>
                                </>
                            ),
                        },
                    ]}
                />
                <Row justify="center">
                    <SubmitButton htmlType="submit">บันทึก</SubmitButton>
                </Row>
            </Form>
        </CustomModal>
    );
}

export default ModalUpdateWorkList;
