import { useState, useCallback } from "react";
import { getTransactionDetailByBoothCode } from "../../apis/transaction";
import { ITransactionDetail } from "../../interfaces/Transaction";

const useTransactionDetail = (transactionId: string) => {
    const [transactionDetail, setTransactionDetail] = useState<ITransactionDetail>();

    const fetchTransactionDetail = useCallback(async () => {
        try {
            const response = await getTransactionDetailByBoothCode(transactionId);
            setTransactionDetail(response);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    const wordingType = useCallback((type: string) => {
        if (type === "booth" || type === "veggie") {
            return "แผง";
        } else if (type === "lomsak") {
            return "ช่อง";
        } else if (type === "rider" || type === "labor") {
            return "เบอร์เสื้อ";
        } else {
            return "";
        }
    }, []);

    return {
        fetchTransactionDetail,
        wordingType,
        transactionDetail,
    };
};

export default useTransactionDetail;
