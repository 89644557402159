import { FormOneLabel } from "../FormMeterPrice";
import { Renderer } from "../../../../interfaces/PriceManagement";

export const FormMeterRenderer: Renderer[] = [
    {
        header: "มิเตอร์ไฟฟ้า",
        component: (
            <div>
                <FormOneLabel
                    typelabel="electricity"
                    typeSubLabel="pricePerUnit"
                    label="ค่ามิเตอร์ไฟฟ้าปกติต่อหน่วย"
                />
                <FormOneLabel
                    typelabel="electricity"
                    typeSubLabel="fifteenAmps"
                    label="ค่ามิเตอร์ไฟฟ้า 15 แอมป์ต่อหน่วย"
                />
                <FormOneLabel
                    typelabel="electricity"
                    typeSubLabel="threePhase"
                    label="ค่ามิเตอร์ไฟฟ้า 3 เฟสต่อหน่วย"
                />
            </div>
        ),
    },
    {
        header: "มิเตอร์นํ้า",
        component: (
            <FormOneLabel
                typelabel="water"
                typeSubLabel="pricePerUnit"
                label="ค่ามิเตอร์นํ้าต่อหน่วย"
            />
        ),
    },
];
