import { Button, Row, Form, notification, Avatar } from "antd";
import React from "react";
import { Container } from "../../../common/CustomForm";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import MemberForm from "../../common/MemberForm";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { antdFileToBase64 } from "../../../../functions/files";
import { CustomUpload, SubRouter } from "./index.style";
import useRegister from "../../../../pages/Register/useRegister";
export const RegisterMonthlyTenantFormSubRoute: { title: string; value: string }[] = [
    {
        title: "บุคคลธรรมดา",
        value: "notLegalEntity",
    },
    {
        title: "นิติบุคคคล",
        value: "isLegalEntity",
    },
];

const RegisterMonthlyTenantForm: React.FC<ReturnType<typeof useRegister>> = ({
    nextStep,
    tenantForm,
    setTenantForm,
    isLegalEntity,
    setIsLegalEntity,
}) => {
    const [numMembers, setNumMembers] = React.useState<number>(tenantForm?.numMembers || 2);
    const [imageBase64, setImageBase64] = React.useState(tenantForm?.imageBase64);
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [form] = Form.useForm();
    const popMember = () => setNumMembers((val) => val - 1);

    const [subRoute, setSubRoute] = React.useState<{ title: string; value: string }>(
        RegisterMonthlyTenantFormSubRoute[0]
    );

    const classNameSubRoute = (sRoute: { title: string; value: string }) =>
        sRoute.value === subRoute.value ? "active" : "";

    const onChangeSubRoute = (sRoute: { title: string; value: string }) => () => {
        if (sRoute.value === "isLegalEntity") setIsLegalEntity(true);
        else setIsLegalEntity(false);
        setSubRoute(sRoute);
    };

    const onFinish = (values: any) => {
        // Validate Image
        setTenantForm({ ...values, numMembers, imageBase64 });
        nextStep();
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Container>
            <Form form={form} initialValues={tenantForm} onFinish={onFinish}>
                {/* Form ของผู้เช้าแผง  */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เช่า</h2>
                </Row>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="รูปผู้เช่า"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={onChangeImage}
                        customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                    >
                        {imageBase64 ? (
                            <Avatar
                                src={imageBase64}
                                alt="avatar"
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </CustomUpload>
                </Row>
                <SubRouter>
                    {RegisterMonthlyTenantFormSubRoute.map((sRoute) => (
                        <span
                            key={sRoute.value}
                            className={classNameSubRoute(sRoute)}
                            onClick={onChangeSubRoute(sRoute)}
                        >
                            {sRoute.title}
                        </span>
                    ))}
                </SubRouter>
                <MemberForm
                    isRequireSSN
                    isLegalEntity={isLegalEntity}
                    key={0}
                    memIndex={0}
                    numMembers={numMembers}
                    form={form}
                    popMember={popMember}
                    initVaccines={tenantForm?.numVaccines0 === 0 ? 0 : undefined}
                />
                <hr />
                {/* Form ของ สมาชิกแผง */}
                <Row justify="center">
                    <h2>ข้อมูลสมาชิก</h2>
                </Row>
                <Collapse
                    style={{ backgroundColor: "transparent", padding: "0", margin: "0" }}
                    defaultActiveKey={["1"]}
                    bordered={false}
                    onChange={() => {}}
                >
                    {Array.from(Array(numMembers).keys()).map(
                        (memIndex) =>
                            memIndex > 0 && (
                                <Collapse.Panel
                                    key={memIndex}
                                    header={"ข้อมูลสมาชิกคนที่ " + memIndex}
                                    extra={
                                        memIndex === numMembers - 1 && (
                                            <Button
                                                danger
                                                icon={<MinusCircleOutlined />}
                                                size={"small"}
                                                onClick={popMember}
                                            >
                                                ลบสมาชิก
                                            </Button>
                                        )
                                    }
                                >
                                    <MemberForm
                                        isNotRequired
                                        memIndex={memIndex}
                                        numMembers={numMembers}
                                        form={form}
                                        popMember={popMember}
                                    />
                                </Collapse.Panel>
                            )
                    )}
                </Collapse>
                {/* Add member button */}
                <Row justify="center" style={{ margin: "1rem" }}>
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => setNumMembers((num) => num + 1)}
                    >
                        เพิ่มสมาชิก
                    </Button>
                </Row>
                {/* Footer */}
                <Row justify="space-between">
                    <Button disabled>ย้อนกลับ</Button>
                    <Button type="primary" htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegisterMonthlyTenantForm;
