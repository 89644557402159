import { Avatar } from "antd";
import { Container, Typography } from "./index.styles";

type ICardManagement = {
    icon: string;
    title: string;
    active: string;
    onClick?: () => void;
};

function CardManagement(props: ICardManagement) {
    const { icon, title, active, onClick } = props;
    return (
        <Container onClick={onClick} state={active}>
            <Avatar size={90} src={icon} style={{ backgroundColor: "#FFF" }} />
            <Typography>{title}</Typography>
        </Container>
    );
}

export default CardManagement;
