import React from "react";
import { useIncomesAndExpensesContext } from "../../../pages/IncomesAndExpenses/useIncomesAndExpenses";
import { CustomModal, SubmitButton } from "./index.style";
import Swal from "sweetalert2";
import * as swlConfig from "../../../assets/sweetalert.config";
import { Typography, Form, Radio, Select, Row } from "antd";
import { RowGroupInput } from "../../common/CustomForm";
import { useAuthContext } from "../../../providers/AuthProvider";

const options = {
    nonRegisterCar: "รถรายวัน",
    lottery: "ล็อตเตอรี่",
    toilet: "ห้องน้ำ",
    trolley: "รถเข็น",
    salaryEmployee: "เงินเดือนพนักงาน",
    foodAllowanceEmployee: "ค่าอาหารพนักงาน",
    trafficPoliceAllowance: "ค่าตำรวจจราจร/สายตรวจ",
    fuelCost: "ค่าน้ำมัน",
    waterBill: "ค่าน้ำประปา",
    electricityBill: "ค่าไฟฟ้า",
    telephoneBill: "ค่าโทรศัพท์",
    internetCost: "ค่าอินเตอร์เน็ต",
    maintenanceCost: "ค่าซ่อมบำรุง",
    discountAmount: "ค่าส่วนลด",
    advancePayment: "จ่ายล่วงหน้า",
    other: "อื่นๆ",
};

const ModalCreateTransactionIE = () => {
    const { onOpenModal, setOnOpenModal, handleCreateIncomeExpense } =
        useIncomesAndExpensesContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const auth = useAuthContext();
    const [form] = Form.useForm<{
        IEType: string;
        IEName: string;
        amount: number;
    }>();

    React.useEffect(() => {
        form.resetFields();
    }, [onOpenModal, form]);

    const onCancel = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "ยกเลิกการเพิ่มรายการ",
                text: `กรุณายืนยันเพื่อยกเลิกการเพิ่มรายการ`,
            })
        );
        if (isConfirmed) return setOnOpenModal(false);
    }, [setOnOpenModal]);

    const onFinish = React.useCallback(
        async (values) => {
            const { isConfirmed } = await Swal.fire(
                swlConfig.positiveConfirmation({
                    title: "คุณต้องการบันทึกข้อมูลหรือไม่?",
                    html: "กรุณายืนยันเพื่อบันทึกข้อมูล",
                })
            );

            if (!isConfirmed) return;

            const data = {
                ...values,
                IEWriter:
                    auth.user?.name || `${auth.user?.firstName || ""} ${auth.user?.lastName || ""}`,
                IEWriterId: auth.user?.uid,
            };
            setIsLoading(true);
            await handleCreateIncomeExpense(data);
            setIsLoading(false);
            setOnOpenModal(false);
        },
        [auth, handleCreateIncomeExpense, setOnOpenModal, setIsLoading]
    );

    return (
        <CustomModal visible={onOpenModal} destroyOnClose onCancel={onCancel} footer={null}>
            <Typography.Title level={3}>เพื่อเพิ่มรายการรายรับ-รายจ่าย</Typography.Title>
            <Form form={form} onFinish={onFinish}>
                <RowGroupInput
                    inputs={[
                        {
                            name: "IEType",
                            label: "ประเภท",
                            disabled: isLoading,
                            rules: [{ required: true, message: "กรุณาเลือกประเภท" }],
                            customInput: (
                                <Radio.Group>
                                    <Radio value="income">รายรับ</Radio>
                                    <Radio value="expenses">รายจ่าย</Radio>
                                </Radio.Group>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "billingType",
                            label: "ชนิดของรายการ",
                            rules: [{ required: true, message: "กรุณาเลือกชนิดของรายการ" }],
                            customInput: (
                                <Select placeholder="ชนิดของรายการ" disabled={isLoading}>
                                    {Object.entries(options).map(([key, value]) => (
                                        <Select.Option key={key} value={key}>
                                            {value}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "IEName",
                            label: "ชื่อรายการ",
                            placeholder: "ชื่อรายการ",
                            disabled: isLoading,
                            rules: [{ required: true, message: "กรุณากรอกชื่อรายการ" }],
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "amount",
                            label: "จำนวนเงิน",
                            placeholder: "จำนวนเงิน",
                            typeInput: "number",
                            disabled: isLoading,
                            rules: [{ required: true, message: "กรุณากรอกจำนวนเงิน" }],
                        },
                    ]}
                />
                <Row justify="center">
                    <SubmitButton htmlType="submit">บันทึก</SubmitButton>
                </Row>
            </Form>
        </CustomModal>
    );
};

export default ModalCreateTransactionIE;
