import { Col, Divider, Row, Spin } from "antd";
import dayjs from "dayjs";
import { Drop, Lightning } from "phosphor-react";
import React from "react";
import { formatNumber } from "../../../functions/formatNumber";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import BarGraph from "../BarGraph";
import DrawerDisplay from "../DrawerDisplay";
import { formatDiffStatus, renderOther, renderValue, statOption } from "../utility/mapping";
import {
    AntCard,
    CardTitle,
    Column,
    LogoContainer,
    Paragraph,
    RowTitleCard,
    StatisticItem,
    TypoContainer,
} from "./index.styles";

type StatisticMeterCardProps = {};
const StatisticMeterCard: React.FC<StatisticMeterCardProps> = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { isLoading, summaryCard } = useDashboardProvider();

    const { totalElectricity, diffElectricity } = React.useMemo(() => {
        if (!summaryCard) return { totalElectricity: 0, diffElectricity: 0 };
        return {
            totalElectricity: summaryCard["electricity"].total,
            diffElectricity: summaryCard["electricity"].diff,
        };
    }, [summaryCard]);

    const { totalWater, diffWater } = React.useMemo(() => {
        if (!summaryCard) return { totalWater: 0, diffWater: 0 };
        return {
            totalWater: summaryCard["water"].total,
            diffWater: summaryCard["water"].diff,
        };
    }, [summaryCard]);

    const { symbolElectricity, symbolWater } = React.useMemo(() => {
        return {
            symbolElectricity: statOption[formatDiffStatus(diffElectricity)].symbol,
            symbolWater: statOption[formatDiffStatus(diffWater)].symbol,
        };
    }, [diffElectricity, diffWater]);
    return (
        <>
            <AntCard
                title={
                    <CardTitle>
                        จำนวนเงินที่ชำระแล้วประจำเดือน{" "}
                        {dayjs().subtract(1, "month").format("MMMM YYYY")}
                    </CardTitle>
                }
                extra={
                    <CardTitle color="subtitle" onClick={() => setIsOpen(!isOpen)}>
                        ดูข้อมูลเชิงลึก
                    </CardTitle>
                }
            >
                {!isLoading && summaryCard && summaryCard["electricity"] && summaryCard["water"] ? (
                    <>
                        <Row justify="space-between">
                            <Col span={10}>
                                <RowTitleCard>
                                    <LogoContainer>
                                        <Lightning size={12} />
                                    </LogoContainer>
                                    <Paragraph>ค่าไฟ</Paragraph>
                                    <StatisticItem
                                        value={Math.abs(diffElectricity)}
                                        prefix={<span>{symbolElectricity}</span>}
                                        color={formatDiffStatus(diffElectricity)}
                                    />
                                </RowTitleCard>
                                <TypoContainer>
                                    <div>{renderValue(summaryCard["electricity"])}</div>
                                    <div>/ {formatNumber(totalElectricity!)} บาท</div>
                                </TypoContainer>
                            </Col>
                            <Divider type="vertical" />
                            <Col span={10}>
                                <RowTitleCard>
                                    <LogoContainer>
                                        <Drop size={12} />
                                    </LogoContainer>
                                    <Paragraph>ค่านํ้า</Paragraph>
                                    <StatisticItem
                                        value={Math.abs(diffWater)}
                                        prefix={<span>{symbolWater}</span>}
                                        color={formatDiffStatus(diffWater)}
                                    />
                                </RowTitleCard>
                                <Column>
                                    <TypoContainer>
                                        <div>{renderValue(summaryCard["water"])}</div>
                                        <div>/ {formatNumber(totalWater!)} บาท</div>
                                    </TypoContainer>
                                </Column>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Spin />
                )}
            </AntCard>
            <DrawerDisplay isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
                <BarGraph type="meter" optionGraph={renderOther["meter"].legendGraph} />
            </DrawerDisplay>
        </>
    );
};

export default StatisticMeterCard;
