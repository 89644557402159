import { Breadcrumb, Col, Row, Spin } from "antd";
import LayoutContract from "../../../components/common/LayoutContract";
import { RolePagesType, typeList } from "../../../assets/typeEnumTranslation";
import { AntHeaderPage, BreadcrumbContainer, Container } from "./index.styles";
import PaymentBilling from "../PaymentBilling";
import React from "react";
import { CardBuildingSelectorArray } from "../../../components/common/CardBuildingSelector";

export type PaymentProps = {
    type: RolePagesType;
    usePayment: (type: RolePagesType) => any;
};

const Payment: React.FC<PaymentProps> = (props: PaymentProps) => {
    const { type, usePayment } = props;

    const { zone, firstKey, data, setBoothKey, boothKey, isLoading, fetchData, onSelectBuilding } =
        usePayment(type);

    const onBuildingBreadcrumbClick = () => {
        setBoothKey(null);
        fetchData();
    };

    return (
        <Container>
            <Spin spinning={isLoading}>
                <AntHeaderPage
                    className="site-page-header"
                    breadcrumb={
                        <BreadcrumbContainer>
                            <Breadcrumb.Item>{typeList[type]}</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={boothKey! && onBuildingBreadcrumbClick}>
                                {type === "lomsak" ? "ประเภทรถ" : ""} {firstKey}
                            </Breadcrumb.Item>
                            {boothKey && boothKey.boothName && (
                                <Breadcrumb.Item>{boothKey.boothName}</Breadcrumb.Item>
                            )}
                        </BreadcrumbContainer>
                    }
                />
                {!boothKey && (
                    <Row justify="space-between">
                        <Col xs={24} sm={24} lg={6} flex={1}>
                            <CardBuildingSelectorArray
                                type={type}
                                data={data}
                                onSelect={onSelectBuilding}
                                buildingSelect={firstKey}
                                pageType={type}
                            />
                        </Col>
                        <Col xs={24} sm={24} lg={17} flex={4}>
                            <LayoutContract
                                data={zone}
                                keyName="boothName"
                                altKeyName="boothCode"
                                pageType={type}
                                getData={setBoothKey}
                                disableStatus={false}
                            />
                        </Col>
                    </Row>
                )}
                {boothKey && (
                    <PaymentBilling
                        type={type}
                        code={boothKey.boothCode}
                        contractId={boothKey.contractId}
                    />
                )}
            </Spin>
        </Container>
    );
};

export default Payment;
