import styled from "styled-components";
import { convertToColor, IColor } from ".";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Badge = styled.div`
    width: ${(props: IColor) => props.size};
    height: ${(props: IColor) => props.size};
    border-radius: 50%;
    margin-right: ${(props: IColor) => (props.marginRight ? props.marginRight : "0")};
    background-color: ${(props: IColor) => convertToColor(props.status)};
`;
