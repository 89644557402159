import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";
import formValidator from "../../../../assets/formValidator";
import { IInvolved } from "../../../../interfaces/Contract";
import { RowGroupInput } from "../../../common/CustomForm";
import {
    Container,
    FormItem,
    FormItemReadOnly,
    InputContainer,
    InputItem,
    RowContainer,
    TextPara,
} from "./index.styles";

type IPerson = {
    edit: boolean;
    person?: IInvolved;
};

function PersonRelated(props: IPerson) {
    const { edit, person } = props;
    const [isHavePerson, setIsHavePerson] = React.useState<boolean>(false);

    React.useEffect(() => {
        let personText = "";
        if (!person) return setIsHavePerson(false);
        Object.keys(person).length > 0 &&
            Object.keys(person).forEach((key) => (personText += person[key as keyof IInvolved]));
        personText.match(/^\s+$|^$/gi) ? setIsHavePerson(false) : setIsHavePerson(true);
    }, [person]);

    return (
        <Container>
            <RowContainer>
                <TextPara strong>ผู้เกี่ยวข้องที่สามารถติดต่อได้</TextPara>
            </RowContainer>

            {!edit ? (
                <>
                    {isHavePerson ? (
                        <>
                            <InputContainer>
                                <TextPara strong>ชื่อ-นามสกุล</TextPara>
                                <FormItemReadOnly name={`name-involved`} hasFeedback>
                                    <InputItem readOnly />
                                </FormItemReadOnly>
                            </InputContainer>
                            <InputContainer>
                                <TextPara strong>เบอร์โทรศัพท์</TextPara>
                                <FormItemReadOnly name={`telInvolved`} hasFeedback>
                                    <InputItem readOnly />
                                </FormItemReadOnly>
                            </InputContainer>
                        </>
                    ) : (
                        <Paragraph style={{ margin: "1rem 0 0.5rem 1rem" }}>
                            ไม่มีผู้เกี่ยวข้องที่สามารถติดต่อได้
                        </Paragraph>
                    )}
                </>
            ) : (
                <>
                    <RowGroupInput
                        inputs={[
                            {
                                name: `firstNameInvolved`,
                                label: "ชื่อ",
                                rules: [...formValidator.firstName],
                                placeholder: "กรุณากรอกชื่อ",
                            },
                            {
                                name: `lastNameInvolved`,
                                label: "นามสกุล",
                                rules: [...formValidator.lastName],
                                placeholder: "กรุณากรอกนามสกุล",
                            },
                        ]}
                    />
                    <FormItem
                        name="telInvolved"
                        label={`เบอร์โทรศัพท์`}
                        rules={[...formValidator.phoneNumber]}
                        hasFeedback
                    >
                        <InputItem
                            readOnly={!edit}
                            editable={`${edit}`}
                            placeholder="เบอร์โทรศัพท์"
                        />
                    </FormItem>
                </>
            )}
        </Container>
    );
}
export default PersonRelated;
