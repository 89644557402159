import { Button, Image, Collapse, Form, FormInstance, Input, Upload, Spin, Col } from "antd";
import RowInput from "../../../components/PriceManagementPage/RowInput";
import { UploadOutlined } from "@ant-design/icons";
import useOtherManagement from "./useOtherManagement";
import React from "react";
import { FormItem } from "../../../components/common/TableBillingComponent/AddItemModal";
import { ButtonContainer, ZoomInOutlined } from "./index.styles";
import { Title } from "../../../components/PriceManagementPage/RowInput/index.styles";
import QrCodeLineRegister from "./qrCodeLineRegister";

type OtherManagementProps = {};
const OtherManagement: React.FC<OtherManagementProps> = () => {
    const {
        form,
        onResetForm,
        file,
        setFile,
        blobToBase64,
        preview,
        setPreview,
        isLoading,
        onFinish,
        data,
        previewOld,
        setPreviewOld,
    } = useOtherManagement();

    const formItem = React.useMemo(() => {
        const items = [
            {
                label: "ชื่อธนาคาร",
                name: "bankName",
                required: true,
                rules: [{ required: true, message: "กรุณากรอกชื่อธนาคาร" }],
                children: <Input placeholder="กรุณากรอกชื่อธนาคาร" />,
            },
            {
                label: "เลขที่บัญชี",
                name: "accountId",
                required: true,
                rules: [{ required: true, message: "กรุณากรอกเลขที่บัญชี" }],
                children: <Input placeholder="กรุณากรอกเลขที่บัญชี" />,
            },
            {
                label: "ชื่อบัญชี",
                name: "accountName",
                required: true,
                rules: [{ required: true, message: "กรุณากรอกชื่อบัญชี" }],
                children: <Input placeholder="กรุณากรอกชื่อบัญชี" />,
            },
            {
                label: (
                    <Col>
                        <Title>อัพโหลด QR </Title>
                        {data && data.qrCode && (
                            <Button
                                type="primary"
                                onClick={() => setPreviewOld(true)}
                                icon={<ZoomInOutlined />}
                                style={{ marginTop: "0.5rem" }}
                            >
                                QR Code เดิม
                            </Button>
                        )}
                    </Col>
                ),
                useReactNode: true,
                required: true,
                rules: [{ required: true, message: "กรุณาอัพโหลด QR Code" }],
                withCustomForm: true,
                children: (
                    <>
                        <FormItem
                            name="qrCode"
                            valuePropName="filelist"
                            required
                            style={{ marginBottom: 0 }}
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                customRequest={({ file }) => {}}
                                onChange={({ file }) => (file.status = "done")}
                                onPreview={async (file) => {
                                    let base64 = (await blobToBase64(
                                        file.originFileObj!
                                    )) as string;
                                    setFile(base64);
                                    setPreview(true);
                                }}
                                onRemove={() => setFile(undefined)}
                            >
                                <Button icon={<UploadOutlined />}>อัพโหลด QR Code</Button>
                            </Upload>
                        </FormItem>
                        {data && data.qrCode && (
                            <Image
                                style={{ display: "none" }}
                                preview={{
                                    visible: previewOld,
                                    src: data.qrCode,
                                    onVisibleChange: (value) => {
                                        setPreviewOld(value);
                                    },
                                }}
                            />
                        )}
                        {file && (
                            <Image
                                style={{ display: "none" }}
                                preview={{
                                    visible: preview,
                                    src: file ? file : "",
                                    onVisibleChange: (value) => {
                                        setPreview(value);
                                    },
                                }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return items.map((item, index) => (
            <RowInput
                key={`${item.label}-${index}`}
                label={item.label}
                required={item.required}
                rules={item.rules}
                withPictureForm={item.withCustomForm}
                name={item.name}
                useReactNode={item.useReactNode}
            >
                {item.children}
            </RowInput>
        ));
    }, [file, preview, blobToBase64, setFile, setPreview, data, previewOld, setPreviewOld]);

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                colon={false}
                ref={(formRef: FormInstance) => formRef}
                role="form"
            >
                {!isLoading ? (
                    <Collapse accordion onChange={onResetForm}>
                        <Collapse.Panel header="บัญชีธนาคาร" key={"1"}>
                            {!isLoading ? formItem : <Spin />}
                            <ButtonContainer>
                                <Button onClick={() => form.resetFields()}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit">
                                    ยืนยันการแก้ไข
                                </Button>
                            </ButtonContainer>
                        </Collapse.Panel>
                        <Collapse.Panel header="QR Code รับการแจ้งเตือน" key={"2"}>
                            <QrCodeLineRegister />
                            {/* <Container> */}

                            {/* <Typography.Title level={4}>
                                    สแกนเพื่อรับการแจ้งเตือนทางไลน์สำหรับผู้บริหาร
                                </Typography.Title>
                                {qrCodeLineRegister} */}
                            {/* </Container> */}
                        </Collapse.Panel>
                    </Collapse>
                ) : (
                    <Spin />
                )}
            </Form>
        </>
    );
};

export default OtherManagement;
