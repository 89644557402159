import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import {
    ArrowDownLeft,
    ArrowUpRight,
    Drop,
    Lightning,
    Storefront,
    Truck,
    TShirt,
} from "phosphor-react";
import colors from "../../../assets/colors.json";
import { formatNumber } from "../../../functions/formatNumber";
import { IValueCard } from "../../../interfaces/Dashboard";

export type StatColor = "increase" | "decrease";

export const colorsMap: { [key: string]: string } = {
    increase: colors.primary,
    decrease: colors.warning,
    subtitle: colors.primary,
    lomsak: colors.info,
    labor: colors.secondary,
    water: colors.info,
    electricity: colors.secondary,
    rider: colors.info,
    parking: colors.secondary,
};

export const statOption = {
    increase: {
        symbol: "+",
        color: colorsMap["increase"],
        logo: <ArrowUpRight size={16} />,
    },
    decrease: {
        symbol: "-",
        color: colorsMap["decrease"],
        logo: <ArrowDownLeft size={16} />,
    },
};

export const meterOption: { [key: string]: any } = {
    electricity: {
        logo: <Lightning size={12} />,
        name: "ค่าไฟฟ้า",
    },
    water: {
        logo: <Drop size={12} />,
        name: "ค่าน้ำ",
    },
};

export const renderBoothCardOption = {
    booth: {
        name: "แผงรายเดือน",
        title: "ประจำเดือน",
        defaultFormatTitle: "MMMM YYYY",
        logo: <Storefront size={26} />,
        filterOption: ["month", "year"] as PickerProps<Dayjs>["picker"][],
    },
    veggie: {
        name: "แผงผักบ้าน",
        title: "ประจำวันที่",
        defaultFormatTitle: "DD MMM YYYY",
        logo: <Storefront size={26} />,
        filterOption: ["week", "month", "year"] as PickerProps<Dayjs>["picker"][],
    },
};

export const renderOther: { [key: string]: any } = {
    shirt: {
        name: "เสื้อ",
        logo: <TShirt size={26} />,
        legendGraph: ["เสื้อขึ้นของ", "เสื้อวิน"],
    },
    car: {
        name: "รถ",
        logo: <Truck size={26} />,
        legendGraph: ["ที่จอดรถ", "รถหล่ม"],
    },
    meter: {
        name: "ค่านํ้า/ค่าไฟฟ้า",
        logo: <Storefront size={26} />,
        legendGraph: ["ค่าไฟฟ้า", "ค่านํ้า"],
    },
    income: {
        name: "รายได้เพิ่มเติม",
    },
    expenses: {
        name: "รายจ่าย",
    },
    รายรับ: {
        name: "รายได้เพิ่มเติม",
    },
    รายจ่าย: {
        name: "รายจ่าย",
    },
    nonRegisterCar: {
        name: "รถที่ไม่ลงทะเบียน",
    },
};

export const formatDiffStatus = (diff: number) => {
    if (diff >= 0) return "increase";
    else return "decrease";
};

export const renderValue = (data?: IValueCard) => {
    if (!data) return "";
    else if (data.paid === data.total) return "จ่ายครบ";
    else return formatNumber(data.paid as number);
};
