import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Container, HeaderContainer, Modal, Paragraph } from "./index.styles";
import { columns as columnsMemo } from "./index.memo";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import DatePicker from "../../common/DatePicker";
import ModalDetailPetition from "../ModalDetailPetition";
import dayjs from "dayjs";

type ModalPetitionHistoryProps = {
    visible: boolean;
    isLoading: boolean;
    onCancel: () => void;
    dataSource: any[];
    fetchData: (monthYear?: number) => void;
};

const ModalPetitionHistory: React.FC<ModalPetitionHistoryProps> = (
    props: ModalPetitionHistoryProps
) => {
    const { visible, onCancel, dataSource, isLoading, fetchData } = props;

    const columns: ColumnsType<any> = React.useMemo(() => columnsMemo(), []);
    const { xs } = useBreakpoint();
    const [recordData, setRecordData] = React.useState<any>();
    const [openHistory, setOpenHistory] = React.useState<boolean>(false);

    const dataSourceMemo = React.useMemo(() => {
        if (isLoading) return [];
        return dataSource;
    }, [dataSource, isLoading]);

    return (
        <>
            <Modal
                centered
                visible={visible}
                onCancel={onCancel}
                title={`ประวัติคำร้องขอชำระเงิน`}
                footer={null}
            >
                <Container>
                    <HeaderContainer>
                        <Paragraph>รายการทั้งหมด</Paragraph>
                        <DatePicker
                            picker="month"
                            clearIcon={false}
                            defaultPickerValue={dayjs()}
                            defaultValue={dayjs()}
                            onChange={(date: any) => fetchData(+dayjs(date))}
                            inputReadOnly
                        />
                    </HeaderContainer>
                    <Table
                        rowKey={(record) => record["transactionId"]}
                        columns={columns}
                        dataSource={dataSourceMemo}
                        scroll={{ x: xs ? 800 : undefined }}
                        loading={isLoading}
                        pagination={{
                            pageSize: 5,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                setRecordData(record);
                                setOpenHistory(true);
                            },
                        })}
                    />
                </Container>
            </Modal>
            <ModalDetailPetition
                visible={openHistory}
                data={recordData}
                editable={false}
                onClose={() => setOpenHistory(false)}
            />
        </>
    );
};

export default ModalPetitionHistory;
