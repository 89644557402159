import { Radio, Space } from "antd";
import { RolePagesType } from "../../../assets/typeEnumTranslation";

export const typeRadioRender = (type: RolePagesType) => {
    const radioItem = getSubTypeListWithType[type].map((item, index) => {
        return (
            <Radio value={item} key={index}>
                {typeDictionary[item]}
            </Radio>
        );
    });

    return <Space direction="vertical">{radioItem}</Space>;
};

export const typeDictionary: { [key: string]: any } = {
    booth: "ค่าเช่าแผงรายเดือน",
    boothWater: "ค่านํ้าแผงรายเดือน",
    boothElectricity: "ค่าไฟแผงรายเดือน",
    boothShirt: "ค่าเสื้อแผง",
    boothParking: "ค่าที่จอดรถแผงรายเดือน",
    boothOther: "ค่าอื่น ๆ",
    veggie: "ค่าเช่าแผงผักบ้าน",
    veggieWater: "ค่านํ้าแผงผักบ้าน",
    veggieElectricity: "ค่าไฟแผงผักบ้าน",
    veggieParking: "ค่าที่จอดรถแผงผักบ้าน",
    veggieOther: "ค่าอื่น ๆ",
    lomsak: "ค่ารถหล่ม",
    lomsakWater: "ค่านํ้ารถหล่ม",
    lomsakElectricity: "ค่าไฟรถหล่ม",
    lomsakOther: "ค่าอื่น ๆ",
    rider: "ค่าเสื้อวิน",
    riderOther: "ค่าอื่น ๆ",
    labor: "ค่าเสื้อขึ้นของ",
    laborOther: "ค่าอื่น ๆ",
};

export const getSubTypeListWithType = {
    booth: ["booth", "boothWater", "boothElectricity", "boothShirt", "boothParking", "boothOther"],
    veggie: ["veggie", "veggieWater", "veggieElectricity", "veggieParking", "veggieOther"],
    lomsak: ["lomsak", "lomsakWater", "lomsakElectricity", "lomsakOther"],
    rider: ["rider", "riderOther"],
    labor: ["labor", "laborOther"],
};
