import styled from "styled-components";

export const Content = styled.div`
    margin: 0 1rem;
`;

export const TabTitle = styled.div`
    width: 100px;
    text-align: center;
`;
