export const rolesThToEn: { [key: string]: string } = {
    เก็บค่าเสื้อขึ้นของ: "labor",
    เก็บค่าเสื้อวิน: "rider",
    เก็บค่ารถหล่ม: "lomsak",
    เก็บค่าแผงรายเดือน: "booth",
    เก็บค่าแผงผักบ้าน: "veggie",
    "จดมิเตอร์น้ำ/ไฟ แผงรายเดือน": "meterBooth",
    "จดมิเตอร์น้ำ/ไฟ แผงผักบ้าน": "meterVeggie",
    ผู้จัดการสัญญา: "adminContract",
    "จดมิเตอร์น้ำ/ไฟ รถหล่ม": "meterLomsak",
};
export const rolesEnToTh: { [key: string]: string } = {
    labor: "เก็บค่าเสื้อขึ้นของ",
    rider: "เก็บค่าเสื้อวิน",
    lomsak: "เก็บค่ารถหล่ม",
    booth: "เก็บค่าแผงรายเดือน",
    veggie: "เก็บค่าแผงผักบ้าน",
    meterBooth: "จดมิเตอร์น้ำ/ไฟ แผงรายเดือน",
    meterVeggie: "จดมิเตอร์น้ำ/ไฟ แผงผักบ้าน",
    adminContract: "ผู้จัดการสัญญา",
    meterLomsak: "จดมิเตอร์น้ำ/ไฟ รถหล่ม",
};

export const rolesIgnore: string[] = ["adminContract", "ผู้จัดการสัญญา"];

export const roleList = { ...rolesEnToTh, ...rolesThToEn };

export const roleArray = [
    "labor",
    "rider",
    "parking",
    "lomsak",
    "booth",
    "veggie",
    "meterBooth",
    "meterVeggie",
    "meterLomsak",
];

export const roleToPhat: { [key: string]: string } = {
    labor: "/laborPayment",
    rider: "/riderPayment",
    lomsak: "/lomsakPayment",
    booth: "/boothPayment",
    veggie: "/veggiePayment",
    meterBooth: "/meterBooth/electricity",
    meterVeggie: "/meterVeggie/electricity",
    admin: "/dashboard",
    adminContract: "/contractManagement",
    meterLomsak: "/meterLomsak/electricity",
};
