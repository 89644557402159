import { FormInstance } from "antd";
import dayjs from "dayjs";
import { IBoothMember, IVaccine } from "../../../../interfaces/Contract";
import { IContractResponse } from "../useContractTable";

export const initValuesForm = (form: FormInstance, record: IContractResponse | IBoothMember) => {
    Object.keys(record).forEach((key) => {
        form.setFieldsValue({ [key]: record[key] });
        if (key.includes("Date")) {
            const date = dayjs(record[key]).format("DD/MM/YYYY");
            form.setFieldsValue({ [key]: date });
        }
        if (key === "address") {
            let address = "";
            address += record[key]["address"] ? record[key]["address"] : "";
            address += record[key]["subDistrict"] ? ` ต.${record[key]["subDistrict"]}` : "";
            address += record[key]["district"] ? ` อ.${record[key]["district"]}` : "";
            address += record[key]["province"] ? ` จ.${record[key]["province"]}` : "";
            address += record[key]["postCode"] ? ` ${record[key]["postCode"]}` : "";
            Object.keys(record["address"]).forEach((innerkey) => {
                form.setFieldsValue({ [`address-${innerkey}`]: record["address"][innerkey] });
            });
            form.setFieldsValue({ [`full-${key}`]: address });
        }
        if (record.vaccine && record.vaccine.length > 0) {
            record.vaccine.forEach((vac: IVaccine, index: number) => {
                const dateVaccine = `dateVaccine${index}`;
                const nameVaccine = `nameVaccine${index}`;
                form.setFieldsValue({
                    [dateVaccine]: dayjs(vac.date),
                    [nameVaccine]: vac.name,
                });
            });
        }
        if (record.involved) {
            let nameinvolved = "";
            Object.keys(record.involved).forEach((key) => {
                form.setFieldsValue({ [`${key}Involved`]: record.involved[key] });
            });
            nameinvolved += record.involved.prefix ? `${record.involved.prefix} ` : "";
            nameinvolved += record.involved.firstName ? `${record.involved.firstName} ` : "";
            nameinvolved += record.involved.lastName ? `${record.involved.lastName} ` : "";

            form.setFieldsValue({ [`name-involved`]: nameinvolved });
        }
        if (key === "boothPrice") {
            const priceItem = {} as any;

            record[key].forEach((price: any) => {
                Object.keys(price).forEach((innerkey) => {
                    if (priceItem[`${innerkey}`] === undefined)
                        priceItem[`${innerkey}`] = price[innerkey];
                    else priceItem[`${innerkey}`] += price[innerkey];
                });
            });

            Object.keys(priceItem).forEach((innerkey) => {
                form.setFieldsValue({ [`boothPrice-${innerkey}`]: priceItem[innerkey] });
            });

            const garantee = Object.keys(priceItem).filter((key) => key === "garantee");
            if (garantee.length === 0) {
                form.setFieldsValue({ "boothPrice-garantee": 0 });
            }
        }
    });
    const reminder = Object.keys(record).filter((key) => key === "reminder");
    if (reminder.length === 0) {
        form.setFieldsValue({ reminder: "-" });
    }
};
