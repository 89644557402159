import { ApexOptions } from "apexcharts";
import th from "apexcharts/dist/locales/th.json";
import dayjs from "dayjs";
import colors from "../../../assets/colors.json";
import { formatNumber } from "../../../functions/formatNumber";

const colorMapping: { [key: string]: any } = {
    รายรับ: colors.primary,
    รายจ่าย: colors.warning,
};

export const options = (value: string, xs?: boolean) => {
    const xaxisOptions: ApexOptions["xaxis"] = xaxisAsYear(xs);
    return {
        chart: {
            type: "bar",
            fontFamily: "Kanit",
            stacked: true,
            toolbar: {
                show: false,
            },
            events: {
                mounted: (chart) => chart.windowResizeHandler(),
            },
            locales: [th],
            defaultLocale: "th",
        },
        colors: [colorMapping[value]],
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            ...xaxisOptions,
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
                formatter: function (value) {
                    return formatNumber(value);
                }
            },
        },
        legend: {
            show: false,
        },
    } as ApexOptions;
};

export const xaxisAsYear = (xs?: boolean) => {
    return {
        categories: Array(12)
            .fill(null)
            .map((_, i) => {
                let dateString = dayjs().startOf("years").add(i, "month");
                return xs ? "" : dateString.format("MMM");
            }),
    } as ApexXAxis;
};
