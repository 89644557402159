import React from "react";
import { getActiveContractSplitCode } from "../../apis/contract";
import { RolePagesType } from "../../assets/typeEnumTranslation";
import { IContractResponse } from "../../components/ContractMemberManagementPage/PartnerContractTable/useContractTable";
import { catchingError } from "../../functions/notification";
import { chainString } from "../../functions/chainString";

function useTransactionComponent(type: RolePagesType) {
    const [dataStorage, setDataStorage] = React.useState<IContractResponse[]>([]);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            let data = await getActiveContractSplitCode(type);
            setDataStorage(data as IContractResponse[]);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [type, setIsLoading]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = React.useCallback(
        (search: string, list: IContractResponse[]) =>
            list.filter
                ? list.filter(
                    (item) =>
                        chainString([item.prefix, item.firstName, item.lastName])
                            .toLocaleLowerCase().replace(/ /g, '')
                            .includes(search.toLocaleLowerCase().replace(/ /g, '')) ||
                        item.building?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                        item.booth
                            ?.join(" ")
                            .toLocaleLowerCase().replace(/ /g, '')
                            .includes(search.toLocaleLowerCase()) ||
                        item?.boothName
                            ?.join(" ")
                            .toLocaleLowerCase().replace(/ /g, '')
                            .includes(search.toLocaleLowerCase().replace(/ /g, '')) ||
                        `${item.shirtNumber ? item.shirtNumber : ""}`
                            .toLocaleLowerCase().replace(/ /g, '')
                            .includes(search.toLocaleLowerCase().replace(/ /g, '')) ||
                        item.companyName?.toLocaleLowerCase().replace(/ /g, '').includes(search.toLocaleLowerCase().replace(/ /g, ''))
                )
                : [],
        []
    );

    const searchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value);

    return {
        dataStorage,
        searchKey,
        searchKeys,
        isLoading,
        handleSearch,
        visible,
        setVisible,
    };
}
export default useTransactionComponent;
