import axios from "axios";
import {
    IBoothPricingResponse,
    IBuildingCreate,
    IBuildingSetting,
    IPricing,
} from "../interfaces/PriceManagement";

export const getShirtPrices = async () => {
    const res = await axios.get(`/pricingManagement/shirt`);
    return convertKeys(res.data) as IPricing;
};

export const getMeterPrices = async () => {
    const res = await axios.get(`/pricingManagement/meter`);
    return convertKeys(res.data) as IPricing;
};

export const getCarPrices = async () => {
    const res = await axios.get(`/pricingManagement/car`);
    return convertKeys(res.data) as IPricing;
};

export const patchShirtPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/shirt`, data);
    return res.data;
};

export const patchMeterPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/meter`, data);
    return res.data;
};

export const patchCarPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/car`, data);
    return res.data;
};

export const getZonePricing = async (boothCode: string) => {
    const res = await axios.get(`/pricingManagement/${boothCode}`);
    return res.data as IBoothPricingResponse;
};

export const editZonePricing = async (boothCode: string, data: any) => {
    const res = await axios.post(`/pricingManagement/${boothCode}`, data);
    return res.data;
};

export const getBuildingSetting = async () => {
    const res = await axios.get(`/building/all`);
    return res.data as IBuildingSetting[];
};

export const createBuilding = async (data: IBuildingCreate) => {
    const res = await axios.post(`/building/newBuilding`, data);
    return res.data;
};

export const editBuilding = async (data: IBuildingSetting, buildingNumber: number) => {
    const res = await axios.post(`/building/increaseZone/${buildingNumber}`, data);
    return res.data;
};

export const getBankInfo = async () => {
    const res = await axios.get(`/pricingManagement/bank`);
    return res.data;
};

export const editBankInfo = async (data: any) => {
    const res = await axios.post(`/pricingManagement/bank`, data);
    return res.data;
};

const convertKeys = (data: any) => {
    const newData: IPricing = {};
    Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((subKey) => {
            newData[`${key}-${subKey}`] = data[key][subKey];
        });
    });
    return newData as IPricing;
};
