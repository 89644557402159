import { Button, Form, Row, Select } from "antd";
import React from "react";
import { DatePicker } from "antd";
import { Container, RowGroupInput } from "../../../common/CustomForm";
import TextArea from "antd/lib/input/TextArea";
import useRegister from "../../../../pages/Register/useRegister";
import dayjs from "dayjs";
const { Option } = Select;

const BoothForm: React.FC<ReturnType<typeof useRegister>> = ({
    buildingStorage,
    prevStep,
    setBoothForm,
    boothForm,
    nextStep,
}) => {
    const [buildingSelect, setBuildingSelect] = React.useState<string | undefined>(
        boothForm?.building
    );
    const [form] = Form.useForm();
    const onSubmit = (values: any) => {
        setBoothForm(values);
        nextStep();
    };

    return (
        <Container>
            <Form form={form} onFinish={onSubmit} initialValues={boothForm}>
                <Row justify="center">
                    <h2>ข้อมูลแผงรายเดือน</h2>
                </Row>
                <b>ข้อมูลแผงที่เช่า</b>
                <RowGroupInput
                    inputs={[
                        {
                            name: "building",
                            label: "อาคาร",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกอาคาร",
                                },
                            ],
                            customInput: (
                                <Select onChange={setBuildingSelect} placeholder="กรุณาเลือกอาคาร">
                                    {Object.keys(buildingStorage).map((item) => (
                                        <Option children={item} key={item} />
                                    ))}
                                </Select>
                            ),
                        },
                        {
                            name: "booth",
                            label: "เลขแผง",
                            flex: 2,
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกเลขแผง",
                                },
                            ],
                            customInput: (
                                <Select
                                    mode="multiple"
                                    placeholder="กรุณาเลือกเลขแผง"
                                    notFoundContent={!buildingSelect && "กรุณาเลือกอาคาร"}
                                >
                                    {buildingSelect &&
                                        buildingStorage[buildingSelect].map((item) => (
                                            <Option
                                                children={item.boothName}
                                                key={item.boothCode}
                                                value={item.boothCode}
                                                disabled={item.status !== "inactive"}
                                            />
                                        ))}
                                </Select>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "startDate",
                            label: "วันเริ่มสัญญา",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกวันเริ่มสัญญา",
                                },
                            ],
                            customInput: (
                                <DatePicker
                                    // ! We will uncomment after imported all of promises offline
                                    // disabledDate={(current) =>
                                    //     dayjs(+current) < dayjs().subtract(1, "day") ||
                                    //     (form.getFieldValue("endDate") &&
                                    //         dayjs(form.getFieldValue("endDate")) <= dayjs(+current))
                                    // }
                                    style={{ width: "100%" }}
                                    placeholder={"วันที่เริ่ม"}
                                />
                            ),
                        },
                        {
                            name: "endDate",
                            label: "วันที่สิ้นสุดสัญญา",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกวันสิ้นสุดสัญญา",
                                },
                            ],
                            customInput: (
                                <DatePicker
                                    disabledDate={(current) =>
                                        dayjs(+current) < dayjs() ||
                                        (form.getFieldValue("startDate") &&
                                            dayjs(form.getFieldValue("startDate")) >=
                                                dayjs(+current))
                                    }
                                    style={{ width: "100%" }}
                                    placeholder={"วันที่สิ้นสุด"}
                                />
                            ),
                        },
                        {
                            name: "parkingList",
                            label: "เลขช่องจอด",
                            customInput: (
                                <Select mode="tags" open={false} placeholder="เลขช่องจอด"></Select>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "reminder",
                            label: "หมายเหตุ",
                            customInput: <TextArea rows={4} />,
                        },
                    ]}
                />
                {/* Footer */}
                <Row justify="space-between">
                    <Button onClick={prevStep}>ย้อนกลับ</Button>
                    <Button type="primary" htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default BoothForm;
