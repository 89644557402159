import { Button, Form, Input, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
`;
export const InputContainer = styled.div`
    display: flex;
    margin: 0.25rem 0;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
`;
export const RowContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-bottom: 0.25rem;
`;
export const TextPara = styled(Typography.Paragraph)`
    margin: 0 !important;
    font-size: 0.9rem;
`;

export const RowInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const ButtonAdd = styled(Button)`
    margin-top: -0.2rem;
`;

export const FormItemReadOnly = styled(Form.Item)`
    margin: 0;
    margin-right: 0.5rem;
    width: 50%;
`;

export const FormItem = styled(Form.Item)`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    & .ant-form-item-label {
        width: fit-content;
        > label {
            &::after {
                content: "";
            }
        }
    }
    & .ant-form-item-required {
        &::before {
            content: "" !important;
        }
        &::after {
            content: "*" !important;
            color: red;
            margin-left: 0.25rem;
        }
    }
    & .ant-input {
        border-radius: 4px;
        overflow: hidden;
    }
`;

export const InputItem = styled(Input)`
    border: ${(props: { editable?: string }) =>
        props.editable === "true" ? "1px solid #d9d9d9" : "none"};
`;
