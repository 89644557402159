import { Badge, Input, Button as B } from "antd";
import styled from "styled-components";
import {
    BellOutlined as Bell,
    FileTextOutlined as FileText,
    DownloadOutlined as Download,
} from "@ant-design/icons";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const InputSearch = styled(Input.Search).attrs({
    size: "large",
})`
    margin: 1rem 0;
`;

export const Content = styled.div`
    margin: 0 1rem;
`;

export const TableContainer = styled.div`
    padding: 1rem;
    background-color: white;
`;

export const BellOutlined = styled(Bell)`
    font-size: 1.25rem;
    margin-right: 1rem;
    color: white;
`;

export const FileTextOutlined = styled(FileText)`
    font-size: 1.25rem;
`;

export const DownloadOutlined = styled(Download)`
    font-size: 1.25rem;
`;

export const BadgeIcon = styled(Badge).attrs({
    size: "small",
    overflowCount: 999,
    offset: [-12, -5],
})``;

export const Button = styled(B).attrs({
    size: "large",
    type: "primary",
})`
    @media screen and (max-width: 540px) {
        flex: 1;
        margin: 0.25rem 0;
        width: 100%;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
`;

export const TabTitle = styled.div`
    width: 100px;
    text-align: center;
`;
