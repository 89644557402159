import { Layout } from "antd";
import styled from "styled-components";

import MoreMenuIcon from "../../assets/icons/MoreMenuIcon";

export const { Header, Sider, Content } = Layout;
export const Container = styled(Header)`
    display: flex;
    padding: 0 1.5rem;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    align-items: center;
`;

export const MenuIcon = styled(MoreMenuIcon)`
    transform: scale(0.75);
    cursor: pointer;
`;


export const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
`