import React from "react";
import { Button, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import SearchInput from "../../../../components/common/SearchInput";
import CardMember from "../../../../components/MemberManagementPage/Staff/CardMember";
import CardMemberCreate from "../../../../components/MemberManagementPage/Staff/CardMember/Create";
import {
    CardMemberContainer,
    DetailLabel,
    DetailLabelContainer,
    HeaderContainer,
    ContainerDetailAndButton,
} from "./index.style";
import useMemberDetail, { MemberDetailContext, listColorDetail } from "./useMemberDetail";
import ModalUpdateWorkList from "../../../../components/MemberManagementPage/Staff/ModalUpdateWorkList";
import ModalCreateMember from "../../../../components/MemberManagementPage/Staff/ModalCreateMember";

function MemberDetail() {
    const memberDetail = useMemberDetail();
    const [keySearch, setKeySearch] = React.useState("");
    const { memberList, handleSearch, isLoading, handleReRole, handleDelete, handleEdit } =
        memberDetail;
    const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
        setKeySearch(event.target.value);

    return (
        <MemberDetailContext.Provider value={memberDetail}>
            <Content>
                <ModalUpdateWorkList />
                <ModalCreateMember />
                <HeaderContainer>
                    <ContainerDetailAndButton>
                        <DetailLabelContainer>
                            {listColorDetail.map((item) => (
                                <DetailLabel key={`${item.key}-label`} color={item.color}>
                                    {item.title}
                                </DetailLabel>
                            ))}
                        </DetailLabelContainer>
                        <Button
                            type="primary"
                            style={{ width: "100px", flex: 1, maxWidth: "360px" }}
                            onClick={handleReRole}
                        >
                            สุ่มพนักงาน
                        </Button>
                    </ContainerDetailAndButton>
                    <SearchInput
                        style={{ width: "100%", maxWidth: "360px" }}
                        placeholder={"ค้นหาด้วย ชื่อ / อีเมล"}
                        onChange={handleChangeSearch}
                    />
                </HeaderContainer>
                <Spin tip="Loading..." spinning={isLoading}>
                    <CardMemberContainer>
                        <CardMemberCreate
                            onClick={() => memberDetail.setOpenModalCreateMember(true)}
                        />
                        {handleSearch(keySearch, memberList).map((item, key) => (
                            <CardMember
                                onClickDelete={() => handleDelete(item)}
                                onClickEdit={() => handleEdit(item)}
                                key={`${item.name}-${key}`}
                                {...item}
                            />
                        ))}
                    </CardMemberContainer>
                    <Row justify="center" style={{ padding: 16 }}></Row>
                </Spin>
            </Content>
        </MemberDetailContext.Provider>
    );
}

export default MemberDetail;
