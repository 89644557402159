import React from "react";
import { getTenantByBoothCode } from "../../../apis/contract";
import { convertHashTag } from "../../../functions/convertHashTag";
import { getQRRegisterLine } from "../../../apis/registerLine";

function useDetailTenant(type: string, boothCode: string) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [tenant, setTenant] = React.useState<any>();

    const [imageQR, setImageQR] = React.useState<string>("");
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const fetchTenant = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await getTenantByBoothCode(type, convertHashTag(boothCode));
            setTenant(res);
        } catch (error) {
            console.log("error", error);
        }
        setIsLoading(false);
    }, [boothCode, type]);

    const onGetQRRegisterLine = React.useCallback(async () => {
        try {
            setImageQR((await getQRRegisterLine(convertHashTag(boothCode))).qrCode);
        } catch (error) {
            console.log("error", error);
        }
        setIsModalVisible(true);
    }, [boothCode]);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const wordingType = React.useCallback((type: string) => {
        if (type === "booth" || type === "veggie") {
            return "แผง";
        } else if (type === "lomsak") {
            return "ช่อง";
        } else if (type === "rider" || type === "labor") {
            return "เบอร์เสื้อ";
        } else {
            return "";
        }
    }, []);

    React.useEffect(() => {
        fetchTenant();
    }, [fetchTenant]);

    return {
        fetchTenant,
        isLoading,
        tenant,
        wordingType,
        onGetQRRegisterLine,
        handleClose,
        isModalVisible,
        imageQR,
    };
}

export default useDetailTenant;
