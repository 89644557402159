import { Tag, Typography } from "antd";
import styled from "styled-components";
import colors from "../../../../assets/colors.json";

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    justify-content: center;
`;

export const ButtonPrint = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border-radius: 1rem;
    padding: 0.75rem 0.3rem;
    background-color: ${colors.warning}33;
    cursor: pointer;
`;

export const TextPrinter = styled(Typography.Paragraph)`
    margin-bottom: 0 !important;
    margin-top: 0.25rem;
    font-size: 10px;
    text-align: center;
`;

export const TagItem = styled(Tag)`
    display: flex;
    align-items: center;
    margin-top: -0.25rem;
`;
