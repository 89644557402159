import { Button, Row, Form, Col, Spin, message, Select, Avatar, Switch, Typography } from "antd";
import DatePicker from "../../../common/DatePicker";
import dayjs from "dayjs";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Container } from "../../../common/CustomForm";
import { CustomUpload } from "./index.style";
import { RowGroupInput } from "../../../common/CustomForm";
import { antdFileToBase64, base64ToBlob } from "../../../../functions/files";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getBuildingShirtAndLomsak, createContract } from "../../../../apis/register";
import validator from "../../../../assets/formValidator";
import { BuildingShirtAndCarType } from "../../../../interfaces/Register";
import { uploadImageReturnUrl } from "../../../../functions/storage";
import * as swlConfig from "../../../../assets/sweetalert.config";
import { ILomsak, IVaccine, IInvolved } from "../../../../interfaces/Contract";
import { usePDPA } from "../../../../providers/PDPAProviders";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Swal from "sweetalert2";
import AddressForm from "../../common/AddressForm";

type ShirtProps = {
    initVaccines?: number;
};
const { Option } = Select;
const RegisterLomSak: React.FC<ShirtProps> = (props) => {
    const { xs } = useBreakpoint();
    const [form] = Form.useForm();
    const [imageBase64, setImageBase64] = React.useState("");
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [numVaccines, setVaccines] = React.useState<number>(props.initVaccines || 1);
    const [isLoading, setIsLoading] = React.useState(false);
    const [buildingSelect, setBuildingSelect] = React.useState<string>();
    const [carTypeSelect, setCarTypeSelect] = React.useState<string>("");
    const [buildingStorage, setBuildingStorage] = React.useState<BuildingShirtAndCarType>();
    const [carSizeSelect, setCarSizeSelect] = React.useState<"4w" | "6w" | "10w">();
    const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
    const [involvedCheck, setInvolvedCheck] = React.useState<boolean>(true);

    const prefixName: { prefix: string }[] = [
        { prefix: "นาย" },
        { prefix: "นาง" },
        { prefix: "นางสาว" },
    ];
    const pdpa = usePDPA();

    const carType: { typeName: string; typeCode: string }[] = [
        { typeName: "รถหล่ม", typeCode: "Lomsak" },
    ];
    const carSize: { sizeName: string; sizeCode: string }[] = [
        { sizeName: "4 ล้อ", sizeCode: "4w" },
        { sizeName: "6 ล้อ", sizeCode: "6w" },
        { sizeName: "10 ล้อ", sizeCode: "10w" },
    ];

    const getUrlImagePicturePathContract = React.useCallback(async () => {
        const image = await base64ToBlob(imageBase64);
        try {
            const picturePaths: string[] | undefined = imageBase64
                ? await uploadImageReturnUrl("images/rider", [image])
                : undefined;

            return {
                picturePath: picturePaths ? picturePaths[0] : undefined,
            };
        } catch (error) {
            return message.error("กรุณาลองใหม่อีกครั้ง");
        }
    }, [imageBase64]);

    const getBuildingLomSakStorage = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const buildingStorage = await getBuildingShirtAndLomsak("lomsak");

            setBuildingStorage(buildingStorage);
        } catch (error) {
            console.log({ error });
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        const fetch = async () => await getBuildingLomSakStorage();
        fetch();
    }, [getBuildingLomSakStorage]);

    const onSubmit = async (values: any) => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.confirmation({
                title: "คุณต้องการลงทะเบียนหรือไม่ ?",
                text: "กรุณายืนยันการลงทะเบียน",
            })
        );
        if (isConfirmed) {
            setIsLoading(true);
            setLoadingButton(true);

            const picturePath = await getUrlImagePicturePathContract();
            const address = {
                address: values.address,
                subDistrict: values.subDistrict,
                district: values.district,
                province: values.province,
                postCode: values.zipCode.toString(),
            };
            const vaccine: Array<IVaccine> = [];
            Array.from(Array(numVaccines).keys()).map((index) =>
                vaccine.push({
                    name: values[`vaccine${index}`],
                    date: +dayjs(values[`vaccineDate${index}`]),
                })
            );
            const involved: IInvolved = involvedCheck
                ? {
                      prefix: values.relativePrefix,
                      firstName: values.relativeFirstName,
                      lastName: values.relativeLastName,
                      tel: values.relativePhoneNumber,
                  }
                : null;

            const contract: ILomsak = {
                ...picturePath,
                type: "lomsak",
                prefix: values.prefix,
                firstName: values.firstName,
                lastName: values.lastName,
                ssn: values.ssn,
                tel: values.phoneNumber,
                status: "active",
                address: address,
                vaccine: vaccine,
                involved: involved,
                startDate: +dayjs(values.startDate),
                endDate: +dayjs(values.endDate),
                size: carSizeSelect,
                booth: values.booth,
            };
            try {
                await createContract(contract);
                await Swal.fire(swlConfig.successConfirmation({}));
            } catch (error) {
                message.error("กรุณาลองใหม่อีกครั้ง");
                throw new Error("กรุณาลองใหม่อีกครั้ง");
            } finally {
                setIsLoading(false);
                setLoadingButton(false);

                form.resetFields();
                await getBuildingLomSakStorage();
                setImageBase64("");
            }
        }
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                message.error("กรุณาอัพโหลดไฟล์รูป");
            } else setImageBase64(src);
        }

        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Spin spinning={isLoading}>
            <Container>
                <Form form={form} onFinish={onSubmit}>
                    <Row justify="center">
                        <CustomUpload
                            error={imageError}
                            name="รูปผู้เช่า"
                            listType="picture-card"
                            showUploadList={false}
                            onChange={onChangeImage}
                            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                        >
                            {imageBase64 ? (
                                <Avatar
                                    src={imageBase64}
                                    alt="avatar"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </CustomUpload>
                    </Row>
                    <RowGroupInput
                        inputs={[
                            {
                                name: "prefix",
                                label: "คำนำหน้า",
                                flex: 1.5,
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกคำนำหน้า",
                                    },
                                ],

                                customInput: (
                                    <Select placeholder="คำนำหน้า">
                                        {prefixName &&
                                            prefixName.map((item) => (
                                                <Option children={item.prefix} key={item.prefix} />
                                            ))}
                                    </Select>
                                ),
                            },
                            {
                                name: "firstName",
                                label: "ชื่อ",
                                flex: 4,
                                rules: [
                                    { required: true, message: "กรุณากรอกชื่อ", whitespace: true },
                                    ...validator.firstName,
                                ],
                                placeholder: "กรุณากรอกชื่อ",
                            },
                            {
                                name: "lastName",
                                label: "นามสกุล",
                                flex: 4,
                                rules: [
                                    { required: true, message: "กรุณากรอกนามสกุล" },
                                    ...validator.lastName,
                                ],
                                placeholder: "กรุณากรอกนามสกุล",
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "ssn",
                                label: "รหัสประจำตัวประชาชน",

                                rules: [...validator.ssn],
                                placeholder: "กรุณากรอกรหัสประจำตัวประชาชน",
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "phoneNumber",
                                label: "เบอร์โทรศัพท์",
                                rules: [
                                    ...validator.phoneNumber,
                                    { required: true, message: "กรุณากรอกเบอร์โทรศัพท์" },
                                ],
                                placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "building",
                                label: "อาคาร",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกอาคาร",
                                    },
                                ],
                                customInput: (
                                    <Select
                                        onChange={setBuildingSelect}
                                        placeholder="กรุณาเลือกอาคาร"
                                    >
                                        {buildingStorage &&
                                            Object.keys(buildingStorage).map((item) => (
                                                <Option children={item} key={item} />
                                            ))}
                                    </Select>
                                ),
                            },
                            {
                                name: "booth",
                                label: "เลขแผง",
                                flex: 2,
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกเลขแผง",
                                    },
                                ],
                                customInput: (
                                    <Select
                                        notFoundContent={!buildingSelect && "กรุณาเลือกอาคาร"}
                                        placeholder="กรุณาเลือกแผง"
                                    >
                                        {buildingSelect &&
                                            buildingStorage![buildingSelect].map((item) => (
                                                <Option
                                                    children={item.boothName}
                                                    value={item.boothCode}
                                                    key={item.boothCode}
                                                    disabled={item.status !== "inactive"}
                                                />
                                            ))}
                                    </Select>
                                ),
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "carType",
                                label: "ประเภทรถ",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกประเภทรถ",
                                    },
                                ],
                                customInput: (
                                    <Select
                                        onChange={setCarTypeSelect}
                                        placeholder="กรุณาเลือกประเภทรถ"
                                    >
                                        {carType &&
                                            carType.map((item) => (
                                                <Option
                                                    children={item.typeName}
                                                    key={item.typeCode}
                                                />
                                            ))}
                                    </Select>
                                ),
                            },
                            {
                                name: "size",
                                label: "ขนาด",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกขนาดของรถ",
                                    },
                                ],
                                customInput: (
                                    <Select
                                        notFoundContent={!carTypeSelect && "กรุณาเลือกประเภทรถ"}
                                        onChange={setCarSizeSelect}
                                        placeholder="กรุณาเลือกขนาดรถ"
                                    >
                                        {carTypeSelect &&
                                            carSize.map((item) => (
                                                <Option
                                                    children={item.sizeName}
                                                    key={item.sizeCode}
                                                />
                                            ))}
                                    </Select>
                                ),
                            },
                        ]}
                    />
                    <b>ที่อยู่</b>
                    <AddressForm
                        isNotRequired={false}
                        addressKey={`address`}
                        provinceKey={`province`}
                        districtKey={`district`}
                        subDistrictKey={`subDistrict`}
                        zipCodeKey={`zipCode`}
                        form={form}
                    />
                    {xs ? (
                        <Row gutter={24}>
                            <Col span={20}>
                                <b>ประวัติการฉีดวัคซีน</b>
                            </Col>
                            <Button
                                icon={<PlusCircleOutlined />}
                                size={"small"}
                                onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                            ></Button>
                        </Row>
                    ) : (
                        <Row gutter={24}>
                            <Col span={6}>
                                <b>ประวัติการฉีดวัคซีน</b>
                            </Col>
                            <Button
                                icon={<PlusCircleOutlined />}
                                size={"small"}
                                onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                            >
                                เพิ่มวัคซีน
                            </Button>
                        </Row>
                    )}
                    {Array.from(Array(numVaccines).keys()).map((index) => (
                        <RowGroupInput
                            key={index}
                            inputs={[
                                {
                                    name: `vaccine${index}`,
                                    label: "วัคซีน",
                                    ...validator.vaccine,
                                    rules: [{ required: true, message: "กรุณากรอกวัคซีน" }],
                                    placeholder: "กรุณากรอกวัคซีน",
                                },
                                {
                                    name: `vaccineDate${index}`,
                                    label: "วันที่ฉีด",
                                    type: "calendar",
                                    rules: [{ required: true, message: "กรุณากรอกวันที่ฉีด" }],
                                    placeholder: "กรุณากรอกวันที่ฉีด",
                                },
                            ]}
                            rightExtra={
                                index === numVaccines - 1 && (
                                    <MinusCircleOutlined
                                        style={{
                                            alignSelf: "center",
                                            marginTop: "10px",
                                            fontSize: "32",
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            setVaccines((numVaccines) => numVaccines - 1);
                                        }}
                                    />
                                )
                            }
                        />
                    ))}
                    <hr />

                    <Row justify="center">
                        <h2>ข้อมูลผู้เกี่ยวข้อง</h2>
                    </Row>
                    <Row justify="space-between">
                        <h3>มีรายชื่อผู้เกี่ยวข้อง</h3>
                        <Switch
                            defaultChecked
                            size="small"
                            onChange={(val) => {
                                setInvolvedCheck(val);
                                form.setFieldsValue({
                                    involvedPrefix: undefined,
                                    involvedFirstName: undefined,
                                    involvedLastName: undefined,
                                    involvedPhoneNumber: undefined,
                                });
                            }}
                        />
                    </Row>
                    {involvedCheck && (
                        <>
                            <RowGroupInput
                                inputs={[
                                    {
                                        name: "relativePrefix",
                                        label: "คำนำหน้า",
                                        flex: 1.5,
                                        rules: [
                                            {
                                                required: true,
                                                message: "กรุณาเลือกคำนำหน้า",
                                            },
                                        ],
                                        customInput: (
                                            <Select placeholder="คำนำหน้า">
                                                {prefixName &&
                                                    prefixName.map((item) => (
                                                        <Option
                                                            children={item.prefix}
                                                            key={item.prefix}
                                                        />
                                                    ))}
                                            </Select>
                                        ),
                                    },
                                    {
                                        name: "relativeFirstName",
                                        label: "ชื่อ",
                                        flex: 4,
                                        rules: [
                                            { message: "กรุณากรอกชื่อ", required: true },
                                            ...validator.firstName,
                                        ],
                                        placeholder: "กรุณากรอกชื่อ",
                                    },
                                    {
                                        name: "relativeLastName",
                                        label: "นามสกุล",
                                        flex: 4,

                                        rules: [
                                            { required: true, message: "กรุณากรอกนามสกุล" },
                                            ...validator.lastName,
                                        ],
                                        placeholder: "กรุณากรอกนามสกุล",
                                    },
                                ]}
                            />
                            <RowGroupInput
                                inputs={[
                                    {
                                        name: "relativePhoneNumber",
                                        label: "เบอร์โทรศัพท์",
                                        rules: [
                                            ...validator.phoneNumber,
                                            { message: "กรุณากรอกเบอร์โทรศัพท์" },
                                        ],
                                        placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                                    },
                                ]}
                            />
                        </>
                    )}
                    <RowGroupInput
                        inputs={[
                            {
                                name: "startDate",
                                label: "วันเริ่มสัญญา",
                                flex: 2,

                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกวันเริ่มสัญญา",
                                    },
                                ],
                                customInput: (
                                    <DatePicker
                                        // ! We will uncomment after imported all of promises offline
                                        // disabledDate={(current) => {
                                        //     return form.getFieldValue("endDate")
                                        //         ? dayjs(form.getFieldValue("endDate")) <=
                                        //               dayjs(+current)
                                        //         : false || current < dayjs().add(-1, "day");
                                        // }}
                                        style={{ width: "100%" }}
                                        placeholder={"วันที่เริ่ม"}
                                    />
                                ),
                            },
                            {
                                name: "endDate",
                                label: "วันที่สิ้นสุดสัญญา",
                                flex: 2,

                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกวันสิ้นสุดสัญญา",
                                    },
                                ],
                                customInput: (
                                    <DatePicker
                                        disabledDate={(current) =>
                                            form.getFieldValue("startDate")
                                                ? dayjs(form.getFieldValue("startDate")) >=
                                                  dayjs(+current)
                                                : false || current < dayjs().add(-1, "day")
                                        }
                                        style={{ width: "100%" }}
                                        placeholder={"วันที่สิ้นสุด"}
                                    />
                                ),
                            },
                        ]}
                    />
                    <Typography.Paragraph>
                        เรามีการเก็บข้อมูลส่วนบุคคลเพื่อเพิ่มประสิทธิภาพ
                        และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
                        คุณสามารถตรวจสอบการเก็บข้อมูลส่วนบุคคลของเว็บไซต์โดยคลิกที่
                        <a href="#PDPA" onClick={pdpa.showPDPA}>
                            นโยบายความเป็นส่วนตัว
                        </a>
                    </Typography.Paragraph>
                    <Row justify="center">
                        {loadingButton ? (
                            <>
                                <Button type="primary" htmlType="submit" size={"large"} disabled>
                                    บันทึกการลงทะเบียน
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button type="primary" htmlType="submit" size={"large"}>
                                    บันทึกการลงทะเบียน
                                </Button>
                            </>
                        )}
                    </Row>
                </Form>
            </Container>
        </Spin>
    );
};

export default RegisterLomSak;
