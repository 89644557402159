import { Col, Divider, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { IBilling } from "../../../interfaces/Billing";
import dayjs from "dayjs";
import { formatNumber } from "../../../functions/formatNumber";
import PaymentModal from "./PaymentModal";
import { columnsMemo, summaryMemo } from "./index.memo";
import { CardItem, FooterContainer, NoPaidButton, PaidButton, Title } from "./index.styles";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import PrintBillContent from "../../PrintBillContent";

export type TableBillingProps = {
    unix: number;
    dataSource: IBilling[];
    fetchData: () => void;
    loading: () => void;
    code: string;
    contractId?: string;
    type: "booth" | "veggie" | "lomsak" | "rider" | "labor";
};

const TableBillingComponent: React.FC<TableBillingProps> = (props) => {
    const { dataSource, unix, code, fetchData, loading, type, contractId } = props;
    const [visible, setVisible] = React.useState<boolean>(false);
    const { xs } = useBreakpoint();

    const columns: ColumnsType<IBilling> = React.useMemo(() => {
        if (!dataSource) return [];
        return columnsMemo;
    }, [dataSource]);

    const summary: { [key: string]: number } = React.useMemo(() => {
        if (!dataSource)
            return {
                totalDebt: 0,
                balanceDebt: 0,
                remainingDebt: 0,
            };
        let totalDebt = dataSource.reduce((acc: number, item: IBilling) => acc + item.totalDebt, 0);
        let remainingDebt = dataSource.reduce(
            (acc: number, item: IBilling) => acc + item.remainingDebt,
            0
        );
        let balanceDebt = totalDebt - remainingDebt;
        return { totalDebt, balanceDebt, remainingDebt };
    }, [dataSource]);

    const summaryRows: React.ReactNode = React.useMemo(() => summaryMemo(summary), [summary]);

    return summary.remainingDebt > 0 ? (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <Title>
                        {type === "veggie"
                            ? dayjs(unix).format("วันที่ DD เดือนMMMM BBBB")
                            : dayjs(unix).format("เดือนMMMM BBBB")}
                    </Title>
                </Col>
                <Col>
                    <PrintBillContent
                        type={type}
                        dataSource={dataSource}
                        remainingDebt={summary.remainingDebt}
                        boothCode={code}
                        date={
                            type === "veggie"
                                ? dayjs(unix).format("วันที่ DD เดือนMMMM BBBB")
                                : dayjs(unix).format("เดือนMMMM BBBB")
                        }
                    />
                </Col>
            </Row>
            <Divider style={{ margin: "1rem 0 0 0" }} />
            <Table
                rowKey={(record: IBilling) => record.billingId}
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                summary={() => summaryRows}
            />
            <Divider />
            <Row justify={xs ? "center" : "space-between"} align="bottom">
                {!xs ? (
                    <>
                        <Col>
                            <CardItem>
                                <Typography.Paragraph>ยอดค้างชำระ</Typography.Paragraph>
                                <Typography.Title level={3} style={{ margin: 0 }}>
                                    {formatNumber(summary.remainingDebt)} บาท
                                </Typography.Title>
                            </CardItem>
                        </Col>
                        <Col>
                            {summary.remainingDebt !== 0 ? (
                                <PaidButton onClick={() => setVisible(!false)}>ชำระเงิน</PaidButton>
                            ) : (
                                <NoPaidButton>ไม่มียอดค้างชำระ</NoPaidButton>
                            )}
                        </Col>
                    </>
                ) : (
                    <FooterContainer>
                        <CardItem>
                            <Typography.Paragraph>ยอดค้างชำระ</Typography.Paragraph>
                            <Typography.Title level={3} style={{ margin: 0 }}>
                                {formatNumber(summary.remainingDebt)} บาท
                            </Typography.Title>
                        </CardItem>
                        {summary.remainingDebt !== 0 ? (
                            <PaidButton onClick={() => setVisible(!false)}>ชำระเงิน</PaidButton>
                        ) : (
                            <NoPaidButton>ไม่มียอดค้างชำระ</NoPaidButton>
                        )}
                    </FooterContainer>
                )}
            </Row>
            <Divider />
            <PaymentModal
                visible={visible}
                code={code}
                contractId={contractId ?? ""}
                dataSource={dataSource}
                onCancel={() => setVisible(!true)}
                fetchData={fetchData}
                loading={loading}
                type={type}
            />
        </>
    ) : (
        <></>
    );
};

export default TableBillingComponent;
