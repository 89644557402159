import React from "react";
import {
    getTransactionByBoothCodeAndContractId,
    revertTransaction,
} from "../../../apis/transaction";
import { ITransaction, IRevertTransaction } from "../../../interfaces/Transaction";
import { convertHashTag } from "../../../functions/convertHashTag";
import { IBillingResponse } from "../../../interfaces/Billing";

const useTransaction = (boothCode: string, contractId: string, billing?: IBillingResponse) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [transaction, setTransaction] = React.useState<ITransaction>();
    const [monthYear, setMonthYear] = React.useState<number>(0);

    const fetchTransaction = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getTransactionByBoothCodeAndContractId(
                convertHashTag(boothCode),
                contractId,
                monthYear
            );
            setTransaction(response);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothCode, monthYear, billing]);

    const staffRevertTransaction = React.useCallback(async (body: IRevertTransaction) => {
        try {
            await revertTransaction(body);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const statusTransaction = React.useCallback((status: string) => {
        switch (status) {
            case "standby":
                return <p className="standby">ชำระเงิน</p>;
            case "pending":
                return <p className="pending">รอการตรวจสอบ</p>;
            case "approved":
                return <p className="request">อนุมัติ</p>;
            case "denied":
                return <p className="request">ไม่อนุมัติ</p>;
            default:
                return <p className="request">รอการตรวจสอบ</p>;
        }
    }, []);

    return {
        transaction,
        isLoading,
        fetchTransaction,
        statusTransaction,
        setMonthYear,
        staffRevertTransaction,
    };
};

export default useTransaction;
