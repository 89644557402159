import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { ApexOptions } from "apexcharts";
import th from "apexcharts/dist/locales/th.json";
import { Dayjs } from "dayjs";
import colors from "../../../assets/colors.json";
import { formatNumber } from "../../../functions/formatNumber";

export const defaultOptions = (
    value: PickerProps<Dayjs>["picker"],
    date: Dayjs,
    isMobile?: boolean
) => {
    let xaxisOptions: ApexOptions["xaxis"];
    switch (value) {
        case "month":
            xaxisOptions = xaxisAsMonth(date, isMobile);
            break;
        case "year":
            xaxisOptions = xaxisAsYear(date, isMobile);
            break;
        default:
            break;
    }
    return {
        chart: {
            height: 350,
            toolbar: {
                show: false,
            },
            events: {
                mounted: (chart) => chart.windowResizeHandler(),
            },
            locales: [th],
            defaultLocale: "th",
            fontFamily: "Kanit",
            zoom: {
                enabled: false,
            },
        },
        colors: [colors.primary, colors.warning, colors.info],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        legend: {
            showForSingleSeries: true,
            position: "top",
        },
        title: {
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: colors.hintText,
            },
        },
        xaxis: {
            ...xaxisOptions,
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                    colors: [colors.hintText],
                },
                formatter: function (value) {
                    return formatNumber(value);
                }
            },
        },
        tooltip: {
            x: {
                format: "dd/MM/yyyy",
            },
        },
        grid: {
            show: false,
        },
    } as ApexOptions;
};

export const xaxisAsMonth = (date: Dayjs, isMobile?: boolean) => {
    return {
        type: "category",
        categories: Array(date.endOf("month").date())
            .fill(null)
            .map((_, i) => {
                let dateString = date.startOf("month").date(i + 1);
                const isShowSomeLabel =
                    dateString.date() % (date.endOf("month").date() / 2) === 0 ||
                    dateString.date() === 1;
                return isMobile
                    ? !isShowSomeLabel
                        ? ""
                        : dateString.format("DD MMM")
                    : dateString.format("DD MMM");
            }),
        labels: {
            style: {
                fontSize: "12px",
                fontWeight: "bold",
                colors: Array(date.endOf("month").date())
                    .fill(null)
                    .map((_, __) => colors.hintText),
            },
        },
    } as ApexXAxis;
};

export const xaxisAsYear = (date: Dayjs, isMobile?: boolean) => {
    return {
        type: "category",
        categories: Array(12)
            .fill(null)
            .map((_, i) => {
                let dateString = date.startOf("years").add(i, "month");
                const isShowSomeLabel =
                    dateString.month() === 0 ||
                    dateString.month() === 5 ||
                    dateString.month() === 11;
                return isMobile
                    ? !isShowSomeLabel
                        ? ""
                        : dateString.format("MMM YY")
                    : dateString.format("MMM YY");
            }),
        labels: {
            style: {
                fontSize: "12px",
                fontWeight: "bold",
                colors: Array(12)
                    .fill(null)
                    .map((_, __) => colors.hintText),
            },
        },
    } as ApexXAxis;
};
