import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    InputNumber,
    Modal,
    Radio,
    Row,
    Table,
    Typography,
    Upload,
    Image,
    Spin,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { formatNumber } from "../../../../functions/formatNumber";
import { IBilling } from "../../../../interfaces/Billing";
import StatusTypeComponents from "../StatusTypeComponents";
import { Container } from "./index.styles";
import { Title as T } from "../index.styles";
import { FormItem } from "../AddItemModal";
import Column from "antd/lib/table/Column";
import { UploadOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { onAddTransactionAlert, successAlert, onErrorAlert } from "../alert";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { addTransaction } from "../../../../apis/transaction";
import { typeList } from "../../../../assets/typeEnumTranslation";
import { uploadImageReturnUrl } from "../../../../functions/storage";
import { base64ToBlob } from "../../../../functions/files";
import { getBankInfo } from "../../../../apis/priceManagement";
import { catchingError } from "../../../../functions/notification";
import { ZoomInOutlined } from "../../../../pages/PriceManagement/OtherManagement/index.styles";
import TextArea from "antd/lib/input/TextArea";

type PaymentModalProps = {
    visible: boolean;
    code: string;
    onCancel: () => void;
    fetchData: () => void;
    loading: () => void;
    dataSource: IBilling[];
    contractId: string;
    type: "booth" | "veggie" | "lomsak" | "rider" | "labor";
};

const PaymentModal: React.FC<PaymentModalProps> = (props: PaymentModalProps) => {
    const { visible, code, onCancel, dataSource, fetchData, loading, type, contractId } = props;
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [payDebt, setPayDebt] = React.useState<number>(0);
    const [radioValue, setRadioValue] = React.useState<string>("1");
    const [file, setFile] = React.useState<string>();
    const [preview, setPreview] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [bankInfo, setBankInfo] = React.useState<any>();
    const [previewOld, setPreviewOld] = React.useState<boolean>(false);

    const { xs } = useBreakpoint();
    const { user } = useAuthContext();

    const Title = Typography.Title;

    const blobToBase64 = (blob: Blob) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const fetchBankInfo = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const bankData = await getBankInfo();
            setBankInfo(bankData);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        fetchBankInfo();
    }, [fetchBankInfo]);

    const columns: ColumnsType<IBilling> = React.useMemo(() => {
        if (!dataSource) return [];
        return [
            {
                title: <T>รายการ</T>,
                dataIndex: "name",
                key: "name",
                render: (_: string, record: IBilling, __: number) => (
                    <StatusTypeComponents record={record} />
                ),
            },
            {
                title: <T>ยอดค้าง</T>,
                dataIndex: "remainingDebt",
                key: "totalDebt",
                width: "20%",
                align: "center",
                render: (_: string, record: IBilling, __: number) =>
                    formatNumber(record.remainingDebt),
            },
            {
                title: <T>ยอดที่ต้องการชำระ</T>,
                dataIndex: "payDebt",
                key: "payDebt",
                width: "35%",
                align: "center",
            },
        ];
    }, [dataSource]);

    const onFinish = async (values: any) => {
        if (payDebt === 0) {
            await onErrorAlert("พบข้อผิดพลาด", "กรุณากรอกยอดที่ต้องการชำระ");
        } else {
            const result = await onAddTransactionAlert();
            if (result) {
                try {
                    loading();
                    let payload = {
                        billingInvoice: {} as any,
                    } as any;
                    selectedRowKeys.forEach((key: React.Key) => {
                        payload["billingInvoice"][key] = form.getFieldValue(`${key}-remainingDebt`);
                    });
                    payload["collectorId"] = user?.uid;
                    payload["collectorName"] = user?.name;
                    payload["description"] = values["description"];
                    payload["contractId"] = contractId;
                    let slipUrl;
                    if (values.paymentMethod === "2") {
                        if (values["upload"]) {
                            const base64 = await blobToBase64(values["upload"].file.originFileObj);
                            const blob = await base64ToBlob(base64 as string);
                            const url = await uploadImageReturnUrl("slip", [blob]);
                            slipUrl = url[0];
                        }
                        payload["slipURL"] = slipUrl;
                    } else {
                        payload["slipURL"] = "";
                    }
                    onCancel();
                    await addTransaction(payload);
                    await successAlert("สำเร็จ", "ทำรายการเสร็จสิ้น");
                    fetchData();
                } catch (error) {
                    await onErrorAlert("พบข้อผิดพลาด", "เกิดข้อผิดพลาดบางอย่าง");
                } finally {
                    loading();
                }
            }
        }
    };

    return (
        <Modal
            centered
            visible={visible}
            onOk={() => form.submit()}
            onCancel={onCancel}
            title={`ชำระเงิน${typeList[type]} ${code}`}
        >
            <Container>
                <Title level={5}>รายการชำระเงิน</Title>
                <Form form={form} onFinish={onFinish}>
                    <Table
                        rowKey={(record: IBilling) => record.billingId}
                        rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys: React.Key[], _: IBilling[]) => {
                                setSelectedRowKeys((prev) => {
                                    prev.forEach((key) => {
                                        if (!selectedRowKeys.includes(key)) {
                                            form.setFieldsValue({
                                                [`${key}-remainingDebt`]: 0,
                                            });
                                        }
                                    });
                                    return selectedRowKeys;
                                });
                                let sum = 0;
                                selectedRowKeys.forEach((key) => {
                                    sum += form.getFieldValue(`${key}-remainingDebt`);
                                });
                                setPayDebt(sum);
                            },
                        }}
                        pagination={false}
                        dataSource={dataSource}
                    >
                        {columns.map((col: any) => {
                            if (col.dataIndex === "payDebt") {
                                return (
                                    <Column
                                        {...col}
                                        render={(_: string, record: IBilling, __: number) => {
                                            let isDisabled = !selectedRowKeys.includes(
                                                record.billingId
                                            );
                                            return (
                                                <FormItem
                                                    name={`${record.billingId}-remainingDebt`}
                                                    rules={[
                                                        {
                                                            type: "number",
                                                            min: 0,
                                                            max: record.remainingDebt,
                                                            message: `ยอดที่ต้องชำระต้องมีค่าในระหว่าง 0 - ${record.remainingDebt}`,
                                                        },
                                                    ]}
                                                    initialValue={0}
                                                >
                                                    <InputNumber
                                                        disabled={isDisabled}
                                                        formatter={(value) =>
                                                            `${value}`.replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )
                                                        }
                                                        parser={(value) =>
                                                            `${value}`.replace(/\$\s?|(,*)/g, "")
                                                        }
                                                        onChange={(value) => {
                                                            let sum = 0;
                                                            selectedRowKeys.forEach((key) => {
                                                                if (key !== record.billingId) {
                                                                    sum += form.getFieldValue(
                                                                        `${key}-remainingDebt`
                                                                    );
                                                                } else {
                                                                    sum += value ? +value : 0;
                                                                }
                                                            });
                                                            setPayDebt(sum);
                                                        }}
                                                    />
                                                </FormItem>
                                            );
                                        }}
                                    />
                                );
                            }
                            return <Column {...col} />;
                        })}
                    </Table>
                    <Divider />
                    <Row align="top" justify={xs ? "space-around" : "space-between"}>
                        <Col
                            xs={15}
                            sm={10}
                            lg={10}
                            flex={1}
                            style={{ marginBottom: xs ? "1rem" : 0, marginRight: "1rem" }}
                        >
                            <Typography.Paragraph>
                                รายการที่เลือก {selectedRowKeys.length} รายการ
                            </Typography.Paragraph>
                            <Card
                                bodyStyle={{
                                    borderStyle: "dashed",
                                    borderColor: "rgba(81, 167, 40, 0.15)",
                                    backgroundColor: "rgba(81, 167, 40, 0.15)",
                                    borderWidth: "2px",
                                    padding: "1rem 2.5rem",
                                }}
                            >
                                <Typography.Paragraph>ยอดที่ต้องการชำระ</Typography.Paragraph>
                                <Typography.Title level={3} style={{ margin: 0 }}>
                                    {formatNumber(payDebt)} บาท
                                </Typography.Title>
                            </Card>
                        </Col>
                        <Col xs={20} sm={10} lg={12} flex={1} style={{ margin: "0 auto" }}>
                            <Title level={5}>วิธีการชำระเงิน</Title>
                            <FormItem
                                label="วิธีการชำระเงิน"
                                name="paymentMethod"
                                initialValue="1"
                                style={{ marginBottom: 0 }}
                            >
                                <Radio.Group onChange={(e) => setRadioValue(e.target.value)}>
                                    <Radio value="1" onChange={() => setFile("")}>
                                        เงินสด
                                    </Radio>
                                    <Radio value="2">โอน</Radio>
                                </Radio.Group>
                            </FormItem>
                            {radioValue === "2" && (
                                <>
                                    {!isLoading && bankInfo ? (
                                        <Card
                                            bodyStyle={{
                                                borderStyle: "dashed",
                                                borderWidth: "1px",
                                                padding: "1rem",
                                            }}
                                        >
                                            <Row justify="space-between">
                                                <Typography.Paragraph strong>
                                                    ธนาคาร:
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                    {bankInfo.bankName}
                                                </Typography.Paragraph>
                                            </Row>
                                            <Row justify="space-between">
                                                <Typography.Paragraph strong>
                                                    ชื่อบัญชี:
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                    {bankInfo.accountName}
                                                </Typography.Paragraph>
                                            </Row>
                                            <Row justify="space-between">
                                                <Typography.Paragraph strong>
                                                    เลขที่บัญชี:
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                    {bankInfo.accountId}
                                                </Typography.Paragraph>
                                            </Row>
                                            <Button
                                                type="primary"
                                                icon={<ZoomInOutlined />}
                                                onClick={() => setPreviewOld(true)}
                                            >
                                                QR Code
                                            </Button>
                                            {bankInfo.qrCode && (
                                                <Image
                                                    style={{ display: "none" }}
                                                    preview={{
                                                        visible: previewOld,
                                                        src: bankInfo.qrCode,
                                                        onVisibleChange: (value) => {
                                                            setPreviewOld(value);
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Card>
                                    ) : (
                                        <Spin />
                                    )}
                                    <FormItem
                                        name="upload"
                                        label="อัพโหลดรูปภาพ"
                                        valuePropName="filelist"
                                        required
                                        style={{ marginBottom: 0 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "กรุณาอัพโหลดรูปภาพ",
                                            },
                                        ]}
                                    >
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            customRequest={({ file }) => {}}
                                            onChange={({ file }) => (file.status = "done")}
                                            onPreview={async (file) => {
                                                let base64 = (await blobToBase64(
                                                    file.originFileObj!
                                                )) as string;
                                                setFile(base64);
                                                setPreview(true);
                                            }}
                                            onRemove={() => setFile(undefined)}
                                        >
                                            <Button icon={<UploadOutlined />}>อัพโหลดสลิป</Button>
                                        </Upload>
                                    </FormItem>
                                </>
                            )}
                            {file && (
                                <Image
                                    style={{ display: "none" }}
                                    preview={{
                                        visible: preview,
                                        src: file ? (file as string) : "",
                                        onVisibleChange: (value) => {
                                            setPreview(value);
                                        },
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                    <FormItem
                        label="หมายเหตุ"
                        name="description"
                        initialValue={""}
                        style={{ marginTop: "1rem" }}
                    >
                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
                    </FormItem>
                </Form>
            </Container>
        </Modal>
    );
};

export default PaymentModal;
