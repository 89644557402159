import { Form } from "antd";
import React from "react";
import { createBuilding, editBuilding, getBuildingSetting } from "../../../apis/priceManagement";
import { editBuildingAlert, successAlert } from "../../../components/PriceManagementPage/alert";
import { catchingError } from "../../../functions/notification";
import { IBuildingCreate, IBuildingSetting } from "../../../interfaces/PriceManagement";

function useBuildingManagement() {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [buildingSetting, setBuildingSetting] = React.useState<IBuildingSetting[]>([]);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [buildingNumber, setBuildingNumber] = React.useState<number>(0);
    const [typeModal, setTypeModal] = React.useState<"create" | "edit">("create");

    const onInitState = () => {
        form.setFieldsValue({ [`buildingName`]: "" });
        form.setFieldsValue({ [`veggie`]: 0 });
        form.setFieldsValue({ [`lomsak`]: 0 });
        form.setFieldsValue({ [`rider`]: 0 });
        form.setFieldsValue({ [`booth`]: 0 });
        form.setFieldsValue({ [`labor`]: 0 });
    };

    const fetchBuildingSetting = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getBuildingSetting();
            setBuildingSetting(data);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const onResetForm = React.useCallback(() => form.resetFields(), [form]);

    React.useEffect(() => {
        fetchBuildingSetting();
    }, [fetchBuildingSetting]);

    const onFinish = React.useCallback(
        async (values: any) => {
            const result = await editBuildingAlert();
            if (!result) return;
            setIsLoading(true);
            try {
                if (typeModal === "create") {
                    const payload = {
                        property: {},
                    } as IBuildingCreate;
                    Object.keys(values).forEach((key) => {
                        if (key !== "buildingName") {
                            payload["property"][key] = values[key];
                        } else {
                            payload["name"] = values[key];
                        }
                    });

                    await createBuilding(payload);
                    await successAlert("เพิ่มข้อมูล");
                } else {
                    const payload = {} as IBuildingSetting;
                    Object.keys(values).forEach((key) => {
                        if (key !== "buildingNumber" && key.includes("-value") && values[key]) {
                            payload[key.split("-")[0]] = values[key];
                        } else if (key === "buildingName") {
                            payload["buildingName"] = values[key];
                        }
                    });
                    await editBuilding(payload, buildingNumber);
                    await successAlert("แก้ไขข้อมูล");
                }
                onResetForm();
                setVisible(false);
                fetchBuildingSetting();
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [typeModal, buildingNumber, fetchBuildingSetting, onResetForm]
    );

    return {
        form,
        onResetForm,
        fetchBuildingSetting,
        isLoading,
        buildingSetting,
        visible,
        setVisible,
        typeModal,
        setTypeModal,
        onInitState,
        onFinish,
        buildingNumber,
        setBuildingNumber,
    };
}

export default useBuildingManagement;
