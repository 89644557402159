import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { TypoContainer } from "./index.styles";
// import colors from "../../../assets/colors.json";

const colorMapping: { [key: string]: any } = {
    approved: "green",
    denied: "red",
    pending: "yellow",
};

const wording: { [key: string]: any } = {
    approved: "อนุมัติ",
    denied: "ไม่อนุมัติ",
    pending: "รอการตอบรับ",
};

export const columns = () => {
    const isShirt = ["rider", "labor"];

    return [
        {
            title: "วันที่",
            dataIndex: "date",
            key: "date",
            align: "center",
            render: (_: string, record: any, __: number) => (
                <TypoContainer>
                    <div>{dayjs(record.date).format("DD/MM/BBBB")}</div>
                    <div>{dayjs(record.date).format("HH:mm น.")}</div>
                </TypoContainer>
            ),
            sorter: (a: any, b: any) => b.startDate - a.startDate,
        },
        {
            title: "รายการ",
            dataIndex: ["building", "booth", "shirtNumber"],
            key: "listed",
            align: "center",
            render: (_: string, record: any, __: number) =>
                !isShirt.includes(record.type) ? (
                    <TypoContainer>
                        <div>อาคาร {record.building}</div>
                        <div>แผง {record.boothCode}</div>
                    </TypoContainer>
                ) : (
                    <TypoContainer>
                        <div>อาคาร {record.building}</div>
                        <div>เลขเสื้อ {record.shirtNumber}</div>
                    </TypoContainer>
                ),
        },
        {
            title: "ผู้ยื่นคำร้อง",
            dataIndex: "collectorName",
            key: "collectorName",
            align: "center",
            // render: (_: string, record: any, __: number) => (
            //     <Typography.Paragraph>{record.name}</Typography.Paragraph>
            // ),
        },
        {
            title: "ผู้ตอบคำร้อง",
            dataIndex: "adminName",
            key: "adminName",
            align: "center",
        },
        {
            title: "สถานะ",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (_: string, record: any, __: number) => (
                <Tag color={colorMapping[record.status]} style={{ width: "5rem" }}>
                    {wording[record.status]}
                </Tag>
            ),
        },
    ] as ColumnsType<any>;
};
