import { InputNumber } from "antd";
import RowInput from "../../RowInput";

type MeterLabelProps = {
    typelabel: "water" | "electricity";
    typeSubLabel: "pricePerUnit" | "fifteenAmps" | "threePhase";
    label: string;
};

export function FormOneLabel(props: MeterLabelProps) {
    const { typelabel, typeSubLabel, label } = props;
    return (
        <RowInput
            name={`${typelabel}-${typeSubLabel}`}
            label={label}
            required={true}
            rules={[{ required: true, message: "กรุณากรอกค่ามิเตอร์ต่อหน่วย" }]}
        >
            <InputNumber
                addonAfter="บาท"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
        </RowInput>
    );
}
