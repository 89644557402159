import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layouts from "./layouts";
import { ROUTES, converter } from "./layouts/routes";
import { useLayoutContext } from "./providers/LayoutProvider";
import { Result } from "antd";
import { useAuthContext } from "./providers/AuthProvider";

const App = () => {
    const routes = converter(ROUTES());
    const { hideLayout, showLayout } = useLayoutContext();
    const { pathname } = useLocation();
    const auth = useAuthContext();
    React.useEffect(() => {
        const route = routes.find(
            (route) => pathname.includes(route.path) || pathname === route.path
        );
        if (!route) return hideLayout();
        if (route.isHideLayout) return hideLayout();
        else return showLayout();
    }, [pathname, routes, hideLayout, showLayout]);

    return (
        <div className="App">
            <Layouts>
                <Routes>
                    {routes.map((route) => {
                        if (route.isAcceptRoles === false) return null;
                        return (
                            <Route key={route.path} path={route.path} element={route.component} />
                        );
                    })}
                    <Route
                        path="*"
                        element={
                            auth.user ? (
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="ไม่พบหน้าที่คุณต้องการ"
                                />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                </Routes>
            </Layouts>
        </div>
    );
};

export default App;
