import { Col, Input, InputNumber, Modal, Row, Typography } from "antd";
import React from "react";
import { FormItem } from "../../common/CustomForm";
import { CancelButton, ConfirmButton, RowFooter } from "./index.styles";

const property = [
    {
        name: "booth",
        label: "แผงรายเดือน",
        addOnAfter: "แผง",
    },
    {
        name: "veggie",
        label: "แผงผักบ้าน",
        addOnAfter: "แผง",
    },
    {
        name: "lomsak",
        label: "รถหล่ม",
        addOnAfter: "คัน",
    },
    {
        name: "rider",
        label: "เสื้อวิน",
        addOnAfter: "ตัว",
    },
    {
        name: "labor",
        label: "เสื้อขึ้นของ",
        addOnAfter: "ตัว",
    },
];

type BuildingSettingModalProps = {
    visible: boolean;
    handleOk: () => void;
    onClose: () => void;
    withType: "create" | "edit";
};

const BuildingSettingModal: React.FC<BuildingSettingModalProps> = (props) => {
    const { visible, onClose, withType, handleOk } = props;

    const formItems = React.useMemo(() => {
        return property.map((item) => (
            <Col offset={1} key={item.name}>
                <Row justify="space-between" align="middle">
                    <Col xs={withType === "edit" ? 14 : 24}>
                        <Typography.Paragraph strong style={{ marginBottom: "0.5rem" }}>
                            {item.label}
                        </Typography.Paragraph>
                        <FormItem
                            name={item.name}
                            rules={[
                                {
                                    type: "number",
                                    message: `กรุณากรอกจำนวนเป็นตัวเลข`,
                                },
                            ]}
                            initialValue={0}
                        >
                            <InputNumber
                                disabled={withType === "edit"}
                                addonBefore="จำนวน"
                                addonAfter={item.addOnAfter}
                                formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                            />
                        </FormItem>
                    </Col>
                    {withType === "edit" && (
                        <Col xs={8}>
                            <Typography.Paragraph style={{ marginBottom: "0.5rem" }}>
                                เพิ่มจำนวน
                            </Typography.Paragraph>
                            <FormItem
                                name={`${item.name}-value`}
                                rules={[
                                    {
                                        type: "number",
                                        message: `กรุณากรอกจำนวนเป็นตัวเลข`,
                                    },
                                ]}
                                initialValue={0}
                            >
                                <InputNumber
                                    addonAfter={item.addOnAfter}
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                                />
                            </FormItem>
                        </Col>
                    )}
                </Row>
            </Col>
        ));
    }, [withType]);

    return (
        <Modal
            title={withType === "create" ? "ตั้งค่าอาคาร" : "แก้ไขอาคาร"}
            visible={visible}
            onCancel={onClose}
            footer={
                <RowFooter>
                    <CancelButton onClick={onClose}>
                        <span> ยกเลิก</span>
                    </CancelButton>
                    <ConfirmButton onClick={handleOk}>
                        <span> {withType === "create" ? "ตกลง" : "ยืนยันการแก้ไข"}</span>
                    </ConfirmButton>
                </RowFooter>
            }
        >
            <FormItem
                name="buildingName"
                rules={[
                    {
                        required: true,
                        message: "กรุณากรอกชื่ออาคาร",
                    },
                ]}
            >
                <Input placeholder="ชื่ออาคาร" />
            </FormItem>
            <Typography.Title level={5}>ตั้งค่าแผง</Typography.Title>
            {formItems}
        </Modal>
    );
};

export default BuildingSettingModal;
