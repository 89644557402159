import dayjs from "dayjs";
import HeadColumn from "./HeadColumn";
import BadgeStatus from "./BadgeStatus";
import { FormInstanceType } from "./ModalComponents/FormContent";

export type ColumnsProps = {
    title: React.ReactNode;
    dataIndex: string | string[];
    render?: (text: string, record: any, index: number) => React.ReactNode;
    renderType: Omit<FormInstanceType, "inner">[];
    filters?: FilterType[];
    onFilter?: (value: string, record: any) => boolean;
    sorter?: (a: any, b: any) => number;
};

type FilterType = {
    text: string;
    value: string;
};

const allTabs: Omit<FormInstanceType, "inner">[] = [
    "booth",
    "veggie",
    "rider",
    "member",
    "labor",
    "lomsak",
    "inner",
];

export const columns: ColumnsProps[] = [
    {
        title: HeadColumn("สถานะ"),
        dataIndex: "status",
        render: (record: any) => <BadgeStatus status={record} size="1rem" />,
        renderType: ["booth", "veggie", "lomsak"],
        filters: [
            {
                text: "ปกติ",
                value: "active",
            },
            {
                text: "หมดสัญญา/ยกเลิกสัญญา",
                value: "inactive",
            },
        ],
        onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
        title: HeadColumn("สถานะ"),
        dataIndex: "status",
        render: (record: any) => <BadgeStatus status={record} size="1rem" />,
        renderType: ["rider", "labor"],
        filters: [
            {
                text: "ปกติ",
                value: "active",
            },
            {
                text: "หมดสัญญา/ยกเลิกสัญญา",
                value: "inactive",
            },
            {
                text: "รักษาสิทธิ์",
                value: "onHold",
            },
        ],
        onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
        title: HeadColumn("วันที่เริ่มทำสัญญา"),
        dataIndex: "startDate",
        render: (record: any) => dayjs(record).format("DD/MM/YYYY"),
        renderType: ["booth", "veggie", "rider", "labor", "lomsak"],
        sorter: (a: any, b: any) => b.startDate - a.startDate,
    },
    {
        title: HeadColumn("ชื่อ - นามสกุล"),
        dataIndex: ["prefix", "firstName", "lastName", "companyName"],
        render: (_: string, record: any, __: number) =>
            record.firstName && record.lastName
                ? `${record.prefix !== undefined ? record.prefix + " " : ""} ${record.firstName} ${
                      record.lastName
                  }`
                : record.companyName,
        renderType: allTabs,
    },
    {
        title: HeadColumn("เบอร์โทรศัพท์"),
        dataIndex: "tel",
        renderType: allTabs,
    },
    { title: HeadColumn("ประเภทรถ"), dataIndex: "size", renderType: ["lomsak"] },
    {
        title: HeadColumn("อาคาร"),
        dataIndex: "building",
        renderType: ["booth", "veggie", "lomsak"],
    },
    {
        title: HeadColumn("แผง"),
        dataIndex: "boothName",
        render: (_: string, record: any, __: number) => {
            let text = "";
            if (record.boothName instanceof Array) {
                text = record.boothName.join(" ");
            } else {
                text = record.boothName;
            }
            return text;
        },
        renderType: ["booth", "veggie", "lomsak"],
    },
    {
        title: HeadColumn("จำนวนเสื้อแผง"),
        dataIndex: "laborShirt",
        renderType: ["booth"],
    },
    {
        title: HeadColumn("จำนวนช่องจอดรถ"),
        dataIndex: "parking",
        renderType: ["booth", "veggie"],
    },
    {
        title: HeadColumn("อาคาร"),
        dataIndex: "building",
        renderType: ["labor", "rider"],
    },
    {
        title: HeadColumn("เลขเสื้อ"),
        dataIndex: "shirtNumber",
        renderType: ["labor", "rider"],
    },
    {
        title: HeadColumn("เพิ่มเติม"),
        dataIndex: ["contractCode", "memberCode", "firstName", "lastName"],
        renderType: ["booth", "veggie", "lomsak"],
    },
];
