import React from "react";
import { MemberType } from "../../../../interfaces/MemberManagement";
import colors from "../../../../assets/colors.json";
import { getAllMember, patchUpdateDutyMember, reRandomAssignValue } from "../../../../apis/member";
import { deleteUser } from "../../../../apis/user";
import { message } from "antd";
import { rolesEnToTh } from "../../../../assets/roles";
import * as swlConfig from "../../../../assets/sweetalert.config";
import Swal from "sweetalert2";
import { AxiosError } from "axios";

export const listColorDetail = [
    {
        title: "หน้าที่หลัก",
        key: "mainDuty",
        color: colors.memberDetailWorkColor.mainDuty,
    },
    {
        title: "หน้าที่ถูกสุ่ม",
        key: "subDuty",
        color: colors.memberDetailWorkColor.subDuty,
    },
    {
        title: "หน้าที่สามารถทำได้",
        key: "dutyPool",
        color: colors.memberDetailWorkColor.dutyPool,
    },
];

export const MemberDetailContext = React.createContext({} as ReturnType<typeof useMemberDetail>);
export const useMemberDetailContext = () => React.useContext(MemberDetailContext);

function useMemberDetail() {
    const [memberList, setMemberList] = React.useState<MemberType[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [memberUpdate, setMemberUpdate] = React.useState<MemberType>();
    const [openModalUpdate, setOpenModalUpdate] = React.useState(false);
    const [openModalCreateMember, setOpenModalCreateMember] = React.useState(false);

    const getMemberList = React.useCallback(async () => {
        try {
            const members = await getAllMember();
            setMemberList(members);
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        }
    }, []);
    const updateDutyMember = React.useCallback(async (member: MemberType) => {
        try {
            await patchUpdateDutyMember(
                member.uid,
                member.name,
                member.tel,
                member.photoURL,
                member.mainDuty,
                member.dutyPool
            );
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        }
    }, []);

    const fetchDataPage = React.useCallback(async () => {
        setIsLoading(true);
        await getMemberList();
        setIsLoading(false);
    }, [getMemberList]);

    React.useEffect(() => {
        fetchDataPage();
    }, [fetchDataPage]);

    const handleUpdateWorkList = (member: MemberType, mainDuty?: string, dutyPoll?: string[]) => {
        setMemberList((memberAllList) =>
            memberAllList.map((m) => {
                if (m.email === member.email) {
                    m.mainDuty = mainDuty;
                    m.dutyPool = dutyPoll;
                }
                return m;
            })
        );
    };

    const handleDelete = async (member: MemberType) => {
        if (!member.uid) return;
        const { isConfirmed } = await Swal.fire(
            swlConfig.negativeConfirmation({
                title: "ยืนยันการลบพนักงาน",
                text: `กรุณากดยืนยันเพื่อลบ ${member.name} ออกจากระบบ`,
            })
        );
        if (isConfirmed) {
            try {
                setIsLoading(true);
                await deleteUser(member.uid);
                await fetchDataPage();
            } catch (error) {
                const err = error as AxiosError<{ errors: { msg: string }[] }>;
                message.error(err.response?.data.errors[0].msg);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleEdit = (member: MemberType) => {
        setMemberUpdate(member);
        setOpenModalUpdate(true);
    };

    const handleSearch = React.useCallback(
        (search: string, list: MemberType[]) =>
            list.filter(
                (item) =>
                    item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    item.email?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                    (item.duty &&
                        item.duty
                            .map((d) => rolesEnToTh[d] || d)
                            .join(" ")
                            .includes(search))
            ),
        []
    );

    const handleReRole = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire({
            ...swlConfig.positiveConfirmation({
                title: "คุณยืนยันที่จะสุ่มหน้าที่พนักงานหรือไม่?",
                html: "กรุณากดปุ่มยืนยันเพื่อทำการสุ่มหน้าที่พนักงาน",
            }),
        });
        if (!isConfirmed) return;
        try {
            setIsLoading(true);
            await reRandomAssignValue();
            await getMemberList();
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        } finally {
            setIsLoading(false);
        }
    }, [getMemberList]);

    return {
        handleSearch,
        memberList,
        handleUpdateWorkList,
        setMemberUpdate,
        memberUpdate,
        setOpenModalUpdate,
        openModalUpdate,
        isLoading,
        setIsLoading,
        updateDutyMember,
        handleReRole,
        setOpenModalCreateMember,
        openModalCreateMember,
        fetchDataPage,
        handleDelete,
        handleEdit,
    };
}

export default useMemberDetail;
