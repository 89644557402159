import React from "react";
import { getZonePricing } from "../../../apis/priceManagement";
import { getBuildingType, getZoneByBuildingNumber } from "../../../apis/zone";
import { catchingError } from "../../../functions/notification";
import { IBoothPricingResponse } from "../../../interfaces/PriceManagement";
import { ZoneBuilding, ZoneBuildingType } from "../../../interfaces/Register";

function useVeggieManagement() {
    const [data, setData] = React.useState<ZoneBuilding[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [firstKey, setFirstKey] = React.useState<string>("");
    const [visible, setVisible] = React.useState<boolean>(false);
    const [boothKey, setBoothKey] = React.useState<{ boothCode: string; boothName: string }>({
        boothCode: "",
        boothName: "",
    });
    const [modalData, setModalData] = React.useState<IBoothPricingResponse>(
        {} as IBoothPricingResponse
    );

    const [zone, setZone] = React.useState<ZoneBuildingType[]>([]);

    const fetchZone = React.useCallback(async (buildingNumber: number) => {
        const zone = await getZoneByBuildingNumber("lomsak", buildingNumber);
        setZone(zone);
    }, []);

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const building = await getBuildingType("lomsak");
            setData(building);
            if (firstKey) {
                const indexing = building.findIndex((item) => item.buildingName === firstKey);
                if (indexing !== -1) {
                    setFirstKey(building[indexing].buildingName);
                    await fetchZone(building[indexing].buildingNumber);
                    return;
                }
            }
            setFirstKey(building[0].buildingName);
            await fetchZone(building[0].buildingNumber);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchZone, firstKey]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSelectBuilding = React.useCallback(
        async (buildingName: string, buildingNumber: number) => {
            setIsLoading(true);
            setFirstKey(buildingName);
            try {
                await fetchZone(buildingNumber);
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchZone]
    );

    const triggerModal = async () => {
        try {
            const res = await getZonePricing(boothKey.boothCode);
            setModalData(res);
        } catch (error) {
            catchingError(error);
        } finally {
            setVisible(!visible);
        }
    };

    const triggerCloseModal = async () => {
        setVisible(!visible);
    };

    return {
        isLoading,
        firstKey,
        visible,
        data,
        modalData,
        triggerModal,
        triggerCloseModal,
        boothKey,
        setBoothKey,
        fetchData,
        onSelectBuilding,
        zone,
    };
}
export default useVeggieManagement;
