import { Col, PageHeader, Row, Table, Tabs } from "antd";
import {
    BadgeIcon,
    BellOutlined,
    Button,
    ButtonContainer,
    Container,
    Content,
    FileTextOutlined,
    InputSearch,
    TableContainer,
    TabTitle,
    DownloadOutlined,
} from "./index.styles";
import useTransactionComponent from "./useTransactionComponent";
import React from "react";
import { columns as columnsTransaction } from "./utility/columns";
import Column from "antd/lib/table/Column";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ModalPetitionList from "../../components/TransactionPage/ModalPetitionList";
import ModalPetitionHistory from "../../components/TransactionPage/ModalPetitionHistory";
import { useNavigate } from "react-router-dom";
import { convertHashTag } from "../../functions/convertHashTag";
import useTransaction from "./useTransaction";
import { RolePagesType } from "../../assets/typeEnumTranslation";
import { useAuthContext } from "../../providers/AuthProvider";
import { RequestTx } from "../../interfaces/Transaction";
import ModalExportWithType from "../../components/TransactionPage/ModalExportWithType";

export type TransactionComponentProps = {
    type: RolePagesType;
};

export const TransactionComponent: React.FC<TransactionComponentProps> = (
    props: TransactionComponentProps
) => {
    const { type } = props;
    const navigate = useNavigate();
    const { searchKeys, searchKey, dataStorage, isLoading, handleSearch, visible, setVisible } =
        useTransactionComponent(type);

    const columns = React.useMemo(() => {
        return columnsTransaction.map((column, index) => {
            if (column.renderType!.includes(type)) {
                const propsCol = { ...column };
                delete propsCol.renderType;
                return <Column {...propsCol} key={index} align="center" />;
            }
            return null;
        });
    }, [type]);

    return (
        <>
            <Row justify="space-between" align="middle">
                <Col xs={24} sm={16} lg={18}>
                    <InputSearch
                        placeholder={`ค้นหาด้วย ชื่อผู้เช่า อาคาร ${
                            ["rider", "labor"].includes(type) ? "เลขเสื้อ" : "สังกัดแผง"
                        }`}
                        onChange={searchKeys}
                        value={searchKey}
                    />
                </Col>
                <Col>
                    <Button icon={<DownloadOutlined />} onClick={() => setVisible(true)}>
                        ดาวน์โหลดเอกสาร
                    </Button>
                </Col>
            </Row>
            <TableContainer>
                <Table
                    rowKey={(record) => record["transactionCode"]}
                    dataSource={handleSearch(searchKey, dataStorage)}
                    scroll={{ x: "calc(500px + 50%)" }}
                    onRow={(record) => {
                        return {
                            onClick: () =>
                                navigate(
                                    `/transaction/${type}/${convertHashTag(
                                        record["transactionCode"]
                                    )}`,
                                    { state: record }
                                ),
                        };
                    }}
                    footer={() =>
                        `จำนวนข้อมูลทั้งหมด ${handleSearch(searchKey, dataStorage).length} รายการ`
                    }
                    loading={isLoading}
                >
                    {columns}
                </Table>
            </TableContainer>
            <ModalExportWithType type={type} visible={visible} onClose={() => setVisible(false)} />
        </>
    );
};

type SubRouteProps = {
    title: string | React.ReactNode;
    component: React.ReactNode;
};
const Transaction: React.FC = () => {
    const { xs } = useBreakpoint();
    const { user } = useAuthContext();

    const {
        pendingVisible,
        historyVisible,
        togglePendingVisible,
        toggleHistoryVisible,
        historyData,
        pendingData,
        isLoading,
        acceptTransaction,
        denyTransaction,
        fetchHistoryData,
    } = useTransaction();

    const transactionSubRoute: SubRouteProps[] = [
        {
            title: <TabTitle>แผงรายเดือน</TabTitle>,
            component: <TransactionComponent type="booth" />,
        },
        {
            title: <TabTitle>แผงผักบ้าน</TabTitle>,
            component: <TransactionComponent type="veggie" />,
        },
        {
            title: <TabTitle>เสื้อวิน</TabTitle>,
            component: <TransactionComponent type="rider" />,
        },
        {
            title: <TabTitle>เสื้อขึ้นของ</TabTitle>,
            component: <TransactionComponent type="labor" />,
        },
        {
            title: <TabTitle>รถหล่ม</TabTitle>,
            component: <TransactionComponent type="lomsak" />,
        },
    ];

    const accepted = async (transactionId: string) => {
        const payload: RequestTx = {
            transactionId,
            adminId: user?.uid!,
            adminName: user?.name!,
        };
        await acceptTransaction(payload);
    };

    const denied = async (transactionId: string) => {
        const payload: RequestTx = {
            transactionId,
            adminId: user?.uid!,
            adminName: user?.name!,
        };
        await denyTransaction(payload);
    };

    return (
        <Container>
            <PageHeader
                title="รายการชำระเงิน"
                extra={
                    xs ? (
                        <Row justify="space-between">
                            <Col xs={24} sm={12}>
                                <Button icon={<FileTextOutlined />} onClick={toggleHistoryVisible}>
                                    ประวัติคำร้อง
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} style={{ marginTop: xs ? "0.5rem" : 0 }}>
                                <Button
                                    icon={
                                        <BadgeIcon count={pendingData.length}>
                                            <BellOutlined />
                                        </BadgeIcon>
                                    }
                                    onClick={togglePendingVisible}
                                >
                                    รายการคำร้อง
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <ButtonContainer>
                            <Button icon={<FileTextOutlined />} onClick={toggleHistoryVisible}>
                                ประวัติคำร้อง
                            </Button>
                            <Button
                                icon={
                                    <BadgeIcon count={pendingData.length}>
                                        <BellOutlined />
                                    </BadgeIcon>
                                }
                                onClick={togglePendingVisible}
                            >
                                รายการคำร้อง
                            </Button>
                        </ButtonContainer>
                    )
                }
            />

            <Content>
                <Tabs defaultActiveKey="1">
                    {transactionSubRoute.map((sRoute, index) => (
                        <Tabs.TabPane tab={sRoute.title} key={`${index + 1}`}>
                            {sRoute.component}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Content>
            <ModalPetitionList
                visible={pendingVisible}
                dataSource={pendingData}
                isLoading={isLoading}
                onAccept={accepted}
                onDenied={denied}
                onCancel={togglePendingVisible}
            />
            <ModalPetitionHistory
                visible={historyVisible}
                isLoading={isLoading}
                onCancel={toggleHistoryVisible}
                dataSource={historyData}
                fetchData={fetchHistoryData}
            />
        </Container>
    );
};

export default Transaction;
