import dayjs from "dayjs";
import * as Excel from "exceljs";
import FileSaver from "file-saver";
import {
    alignment,
    borderStyles,
    fillGreenDebt,
    fillRedDebt,
    headers,
    initRows,
    widthArr,
} from "../assets/excelTemplateTransaction";
import { typeDictionary } from "../components/TransactionPage/ModalExportWithType/index.memo";
import { IExportTx } from "../interfaces/Transaction";

export const exportExcel = async (payload: IExportTx, data: any) => {
    const { startDate, endDate, subType } = payload;
    let rowStart = 6;
    let mergeRowsInit = 5;
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet(`SMT-${subType}`);
    const startDateFormat = dayjs(startDate).format("DD/MM/YYYY");
    const endDateFormat = dayjs(endDate).format("DD/MM/YYYY");

    ws.mergeCells(`A1:M1`);
    ws.getCell("A1").value = `รายการชำระค่าเช่า ของ${typeDictionary[subType]}`;
    ws.getCell("A1").font = { bold: true };
    ws.getCell("A1").alignment = alignment;

    ws.mergeCells(`A2:M2`);
    ws.getCell("A2").value = `ประจำวันที่ ${startDateFormat} ถึง ${endDateFormat}`;
    ws.getCell("A2").font = { bold: true };
    ws.getCell("A2").alignment = alignment;

    ws.getCell(`M4`).fill = fillRedDebt;
    headers.forEach((item) => {
        if (item.isMerge) {
            ws.mergeCells(item.merge as string);
        }
        ws.getCell(item.cell).value = item.value;
        ws.getCell(item.cell).alignment = alignment;
        ws.getCell(item.cell).border = borderStyles;
    });

    let debtAll = 0,
        paidAll = 0;

    Object.keys(data).forEach((key, index) => {
        let billing = data[key].billing.length - 1;
        let debt = data[key].transaction.length - 1;
        let rowRef = Math.max(...[mergeRowsInit, billing, debt]);

        initRows(data[key]).forEach((item) => {
            ws.mergeCells(`${item.colName}${rowStart}:${item.colName}${rowStart + rowRef}`);
            ws.getCell(`${item.colName}${rowStart}`).value =
                item.colName === "A" ? index + 1 : item.keyName;
            ws.getCell(`${item.colName}${rowStart}`).alignment = alignment;
            ws.getCell(`${item.colName}${rowStart}`).border = borderStyles;
            if (item.colName === "M") {
                ws.getCell(`${item.colName}${rowStart}`).value = item.keyName === 0 ? "-" : item.keyName;
                ws.getCell(`${item.colName}${rowStart}`).fill = item.keyName === 0 ? fillGreenDebt : fillRedDebt;
            }
            if (item.numFmt) {
                ws.getCell(`${item.colName}${rowStart}`).numFmt = item.numFmt;
            }
        });

        data[key].billing.length > 0 &&
            data[key].billing.forEach((keyItem: any, i: number) => {
                ws.getCell(`F${rowStart + i}`).value = dayjs(keyItem.date).format("DD/MM/YYYY");
                ws.getCell(`F${rowStart + i}`).alignment = alignment;
                ws.getCell(`F${rowStart + i}`).border = borderStyles;
                ws.getCell(`G${rowStart + i}`).value = keyItem.amount
                ws.getCell(`G${rowStart + i}`).alignment = alignment;
                ws.getCell(`G${rowStart + i}`).border = borderStyles;
                ws.getCell(`G${rowStart + i}`).numFmt = '#,##0.00'

            });

        data[key].transaction.length > 0 &&
            data[key].transaction.forEach((keyItem: any, i: number) => {
                ws.getCell(`I${rowStart + i}`).value = dayjs(keyItem.billingDate).format("DD/MM/YYYY");
                ws.getCell(`I${rowStart + i}`).alignment = alignment;
                ws.getCell(`I${rowStart + i}`).border = borderStyles;
                ws.getCell(`J${rowStart + i}`).value = keyItem.amount;
                ws.getCell(`J${rowStart + i}`).alignment = alignment;
                ws.getCell(`J${rowStart + i}`).border = borderStyles;
                ws.getCell(`J${rowStart + i}`).numFmt = '#,##0.00'
                ws.getCell(`K${rowStart + i}`).value = dayjs(keyItem.date).format("DD/MM/YYYY");
                ws.getCell(`K${rowStart + i}`).alignment = alignment;
                ws.getCell(`K${rowStart + i}`).border = borderStyles;
            });

        rowStart += rowRef + 1;
        debtAll += data[key].totalDebt;
        paidAll += data[key].totalPaid;
    });

    ws.mergeCells(`A${rowStart}:E${rowStart}`);
    ws.getCell(`A${rowStart}`).value = "รวม";
    ws.getCell(`A${rowStart}`).font = { bold: true };
    ws.getCell(`A${rowStart}`).border = borderStyles;
    ws.getCell(`A${rowStart}`).alignment = alignment;
    ws.getCell(`H${rowStart}`).value = debtAll
    ws.getCell(`H${rowStart}`).numFmt = '#,##0.00'
    ws.getCell(`H${rowStart}`).border = borderStyles;
    ws.getCell(`H${rowStart}`).alignment = alignment;
    ws.mergeCells(`F${rowStart}:G${rowStart}`);
    ws.getCell(`F${rowStart}`).border = borderStyles;
    ws.getCell(`F${rowStart}`).alignment = alignment;
    ws.mergeCells(`I${rowStart}:K${rowStart}`);
    ws.getCell(`I${rowStart}`).border = borderStyles;
    ws.getCell(`J${rowStart}`).border = borderStyles;
    ws.getCell(`L${rowStart}`).value = paidAll;
    ws.getCell(`L${rowStart}`).numFmt = '#,##0.00'
    ws.getCell(`L${rowStart}`).alignment = alignment;
    ws.getCell(`L${rowStart}`).border = borderStyles;
    ws.getCell(`M${rowStart}`).value = debtAll - paidAll ?? "-";
    ws.getCell(`M${rowStart}`).numFmt = '#,##0.00'
    ws.getCell(`M${rowStart}`).alignment = alignment;
    ws.getCell(`M${rowStart}`).border = borderStyles;
    ws.getCell(`M${rowStart}`).fill = debtAll - paidAll ? fillRedDebt : fillGreenDebt;

    widthArr.forEach((item, index) => {
        ws.getColumn(index + 1).width = item;
    });

    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(blob, `SMT-${subType}-${dayjs().format("DD/MM/YYYY")}.xlsx`);
};
