import dayjs from "dayjs";
import React from "react";
import { getTransactionByStatus, requestTransaction } from "../../apis/transaction";
import { timerAlert } from "../../components/ContractMemberManagementPage/alert";
import { catchingError } from "../../functions/notification";
import { ITransactionResponse, RequestTx } from "../../interfaces/Transaction";

function useTransaction() {
    const [pendingVisible, setPendingVisible] = React.useState<boolean>(false);
    const [historyVisible, setHistoryVisible] = React.useState<boolean>(false);
    const [historyData, setHistoryData] = React.useState<ITransactionResponse>([]);
    const [pendingData, setPendingData] = React.useState<ITransactionResponse>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const fetchPendingData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getTransactionByStatus(`pending`);
            setPendingData(data);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [setPendingData, setIsLoading]);

    const fetchHistoryData = React.useCallback(
        async (monthYear?: number) => {
            setIsLoading(true);
            try {
                const pathReq = `approved,denied&monthYear=${monthYear ? monthYear : +dayjs()}`;
                const data = await getTransactionByStatus(pathReq);
                setHistoryData(data);
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [setHistoryData, setIsLoading]
    );

    React.useEffect(() => {
        fetchPendingData();
    }, [fetchPendingData]);

    const togglePendingVisible = async () => {
        setPendingVisible(!pendingVisible);
        if (!pendingVisible) await fetchPendingData();
    };
    const toggleHistoryVisible = async () => {
        setHistoryVisible(!historyVisible);
        if (!historyVisible) await fetchHistoryData();
    };

    const acceptTransaction = async (payload: RequestTx) => {
        setIsLoading(true);
        try {
            await requestTransaction("approved", payload);
            await timerAlert("success", "ดำเนินการเสร็จสิ้น รายการนี้ได้รับการอนุมัติแล้ว");
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
            await fetchPendingData();
        }
    };

    const denyTransaction = async (payload: RequestTx) => {
        setIsLoading(true);
        try {
            await requestTransaction("denied", payload);
            await timerAlert("success", "ดำเนินการเสร็จสิ้น รายการนี้ถูกปฏิเสธคำขอแล้ว");
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
            await fetchPendingData();
        }
    };

    return {
        pendingVisible,
        historyVisible,
        historyData,
        pendingData,
        togglePendingVisible,
        toggleHistoryVisible,
        acceptTransaction,
        denyTransaction,
        isLoading,
        fetchHistoryData,
    };
}

export default useTransaction;
