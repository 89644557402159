import { Divider, Empty, Spin } from "antd";
import React from "react";
import { uniqueId } from "../../../functions/uniqueKeys";
import ModalDetailPetition from "../ModalDetailPetition";
import PetitionComponent from "../PetitionComponent";
import { Container, Modal } from "./index.styles";

type ModalPetitionListProps = {
    visible: boolean;
    onAccept: (id: string) => void;
    onDenied: (id: string) => void;
    onCancel: () => void;
    dataSource: any[];
    isLoading: boolean;
};

const ModalPetitionList: React.FC<ModalPetitionListProps> = (props: ModalPetitionListProps) => {
    const { visible, onCancel, onAccept, onDenied, dataSource, isLoading } = props;

    const [recordData, setRecordData] = React.useState<any>();
    const [openListed, setOpenListed] = React.useState<boolean>(false);

    const onClick = (record: any) => () => {
        setRecordData(record);
        setOpenListed(true);
    };

    const listData = React.useMemo(() => {
        if (!dataSource || dataSource.length === 0) return <Empty />;
        return dataSource.map((item, index: number) => {
            if (index !== dataSource.length - 1)
                return (
                    <React.Fragment key={uniqueId()}>
                        <PetitionComponent data={item} type={item.type} onClick={onClick(item)} />
                        <Divider style={{ margin: "0.5rem 0" }} />
                    </React.Fragment>
                );
            return (
                <PetitionComponent
                    key={uniqueId()}
                    data={item}
                    type={item.type}
                    onClick={onClick(item)}
                />
            );
        });
    }, [dataSource]);

    return (
        <>
            <Modal
                centered
                visible={visible}
                onCancel={onCancel}
                title={`รายการคำร้องขอชำระเงิน`}
                footer={null}
            >
                <Container>{!isLoading ? listData : <Spin />}</Container>
            </Modal>
            <ModalDetailPetition
                visible={openListed}
                data={recordData}
                editable={true}
                onOk={() => onAccept(recordData.transactionId)}
                onDenied={() => onDenied(recordData.transactionId)}
                onClose={() => setOpenListed((prev) => !prev)}
            />
        </>
    );
};

export default ModalPetitionList;
