import axios from "axios";
import { IPostUtilityRequest } from "../interfaces/Meter";

export const getMeterByBooth = async (contractCode: string, type: string) => {
    const contractCodeEncode = encodeURIComponent(contractCode);
    const response = await axios.get(`/utility/${contractCodeEncode}/${type}/latest`);
    return response.data;
};

export const getDetailByBooth = async (contractCode: string, type: string) => {
    const contractCodeEncode = encodeURIComponent(contractCode);
    const response = await axios.get(`/contract/boothCode/${type}/${contractCodeEncode}`);

    return response.data;
};

export const postMeter = async (data: IPostUtilityRequest) => {
    const response = await axios.post("/utility/", data);

    return response.data;
};

export const getAllMeterRecord = async (
    boothCode: string,
    type: string,
    date?: number | string | Date
) => {
    const boothCodeEncode = encodeURIComponent(boothCode);
    const response = await axios.get(`/utility/${boothCodeEncode}/${type}?date=${date}`);
    return response.data;
};

export const revertMeterLastRecord = async (boothCode: string, type: string) => {
    const boothCodeEncode = encodeURIComponent(boothCode);
    const response = await axios.delete(`/utility/${boothCodeEncode}/${type}`);
    return response.data;
};
