import styled from "styled-components";
import { Modal as M, Typography } from "antd";
export const Modal = styled(M)`
    &.ant-modal-content {
        display: flex;
        flex-direction: column;
    }
    & .ant-modal-body {
        padding-bottom: 0.5rem !important;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 80vh;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Title = styled(Typography.Title).attrs({
    level: 5,
})`
    margin: 0 !important;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.5rem;
    flex: 1;
    margin-bottom: 0.5rem;
`;

export const TypoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Paragraph = styled(Typography.Paragraph)`
    margin: 0 !important;
`;

export const TitleModalContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

export const FooterTable = styled.div`
    display: flex;
    flex: 1;
    margin-top: 0.5rem;
    justify-content: flex-end;
`;
