import axios from "axios";
import dayjs from "dayjs";
import { convertHashTag } from "../functions/convertHashTag";
import { IAddBilling, IBillingResponse } from "../interfaces/Billing";
import {
    ITransaction,
    ITransactionDetail,
    ITransactionResponse,
    RequestTx,
    IRevertTransaction,
    IExportTx,
} from "../interfaces/Transaction";

export const getBilling = async (boothCode: string, contractId: string) => {
    const res = await axios.get(`/transaction/${boothCode}/billing?contractId=${contractId}`);
    return res.data as IBillingResponse;
};

export const postBilling = async (billing: IAddBilling) => {
    const res = await axios.post("/transaction/billing", billing);
    return res.data;
};

export const addTransaction = async (billing: IAddBilling) => {
    const res = await axios.post(`/transaction`, billing);
    return res.data;
};

export const getTransactionByBoothCodeAndContractId = async (
    boothCode: string,
    contractId: string,
    monthYear?: number
): Promise<ITransaction> => {
    let monthUnix = monthYear === 0 ? dayjs().startOf('month').valueOf() : monthYear;

    const transactionData = await axios.get(
        `/transaction/${boothCode}?monthYear=${monthUnix}&contractId=${contractId ?? ""}`
    );
    return transactionData.data as ITransaction;
};

export const getTransactionDetailByBoothCode = async (
    transactionId: string,
): Promise<ITransactionDetail> => {
    const transactionData = await axios.get(
        `/transaction/detail/${transactionId}`
    );
    return transactionData.data as ITransactionDetail;
};

export const getAllTransactionByIdAndType = async (
    code: string,
    type: string,
    monthYear: number
) => {
    const transactionData = await axios.get(
        `/transaction/${convertHashTag(code)}/${type}?monthYear=${monthYear}`
    );
    return transactionData.data as { transaction: ITransaction; remainingDebtTotal: number };
};

export const getTransactionByStatus = async (status: string) => {
    const transactionData = await axios.get(`/transaction?status=${status}`);
    return transactionData.data as ITransactionResponse;
};

export const requestTransaction = async (type: "approved" | "denied", data: RequestTx) => {
    const res = await axios.post(`/transaction/request/${type}`, data);
    return res.data;
};

export const revertTransaction = async (transaction: IRevertTransaction) => {
    const res = await axios.delete("/transaction", { data: transaction });
    return res.data;
};

export const exportTransaction = async (transaction: IExportTx) => {
    const res = await axios.post("/transaction/export", transaction);
    return res.data;
};
