import styled from "styled-components";
import breakpoint from "../../../assets/breakpoint.json";
import colors from "../../../assets/colors.json";
export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0.5rem;
    width: calc(1 / 3 * 100% - 1rem);
    overflow: hidden;
    @media (max-width: ${breakpoint.tablet}) {
        width: calc(50% - 1rem);
    }
    @media (max-width: ${breakpoint.mobile}) {
        width: 100%;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    background-color: ${colors.memberDetailWorkColor.mainDuty};
    border-radius: 50%;
    aspect-ratio: 1 / 1;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.3rem;
    font-size: 16px;
    gap: 0.5rem;
    font-weight: bold;
`;

export const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.3rem;
    font-size: 16px;
    gap: 0.5rem;
    & > span {
        font-size: 16px;
        color: ${colors.primary};
    }
`;
