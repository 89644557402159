import { Avatar, Image, Select, Typography } from "antd";
import { ButtonPrint, Container, NameContainer, TagItem, TextPrinter } from "./index.styles";
import { PrinterOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BadgeStatus, { convertToColor } from "../../BadgeStatus";
import { FormInstanceType } from "../FormContent";
import React from "react";
import { FormItem } from "../../../common/CustomForm";
const { Option } = Select;

type ITitleModal = {
    record: any;
    onPrintData: () => void;
    type: FormInstanceType | "inner";
    isEdit: boolean;
};

export type IColor = {
    status: "active" | "inactive" | "onHold";
};

const convertToStatus = (status: IColor["status"]) => {
    switch (status) {
        case "active":
            return "ปกติ";
        case "inactive":
            return "หมดสัญญา/ยกเลิกสัญญา";
        case "onHold":
            return "รักษาสิทธิ์";
    }
};

const convertToBadge = (status: IColor["status"]) => {
    switch (status) {
        case "active":
            return "success";
        case "inactive":
            return "default";
        case "onHold":
            return "warning";
    }
};
function TitleModal(props: ITitleModal) {
    const { record, onPrintData, type, isEdit } = props;
    const { prefix, firstName, lastName, status, picturePath, companyName } = record;
    const includeType = ["rider", "labor", "lomsak"];
    const includeEditTagType = ["rider", "labor", "booth", "veggie", "lomsak"];

    const includeTypeonHold = ["rider", "labor"];

    const { Title } = Typography;
    const { xs } = useBreakpoint();
    const [preview, setPreview] = React.useState<boolean>(false);
    const previewChange = () => setPreview((prev) => !prev);

    return (
        <Container>
            <div onClick={picturePath && previewChange}>
                <Avatar
                    size={xs ? 48 : 64}
                    src={picturePath}
                    style={{ boxShadow: `0 0 0 2px ${convertToColor(status)}` }}
                    alt={
                        firstName && lastName
                            ? `avatar-image-${firstName}-${lastName}`
                            : `avatar-image-${companyName}`
                    }
                >
                    {firstName && lastName ? firstName[0] + lastName[0] : companyName[0]}
                </Avatar>
            </div>

            {picturePath && (
                <Image
                    style={{ display: "none" }}
                    preview={{
                        visible: preview,
                        src: picturePath,
                        onVisibleChange: (visible) => setPreview(visible),
                    }}
                />
            )}
            <NameContainer>
                <Title level={4}>
                    {firstName && lastName
                        ? `${prefix !== undefined ? prefix + " " : ""}` + firstName + " " + lastName
                        : companyName}
                </Title>
                {isEdit && includeEditTagType.includes(type) ? (
                    <FormItem name="status">
                        <Select style={{ width: "100%" }} size="middle">
                            <Option value="active">ปกติ</Option>
                            <Option value="inactive">หมดสัญญา/ยกเลิกสัญญา</Option>
                            {includeTypeonHold.includes(type) && (
                                <Option value="onHold">รักษาสิทธิ์</Option>
                            )}
                        </Select>
                    </FormItem>
                ) : (
                    <div style={{ width: "max-content" }}>
                        <TagItem
                            icon={
                                <BadgeStatus status={status} size="0.6rem" marginRight="0.3rem" />
                            }
                            color={convertToBadge(status)}
                        >
                            {convertToStatus(status)}
                        </TagItem>
                    </div>
                )}
            </NameContainer>
            {!includeType.includes(type) && (
                <ButtonPrint onClick={onPrintData}>
                    <PrinterOutlined style={{ fontSize: 30 }} />
                    <TextPrinter strong>รายละเอียดสัญญา</TextPrinter>
                </ButtonPrint>
            )}
        </Container>
    );
}
export default TitleModal;
