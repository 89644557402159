import { Layout } from "antd";
import styled from "styled-components";
import colors from "../assets/colors.json";

export const { Sider, Content } = Layout;

export const AppLayout = styled(Layout)`
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

export const ContentLayout = styled(Layout)`
    background: #fff;
`;
export const AppContent = styled(Content)`
    padding: 1rem;
    background: ${colors.background};
    overflow: auto;
`;
