import { Breadcrumb, Table, Skeleton, Tabs } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { typeTransaction, converterType } from "../../assets/transaction";
import { Container, HeaderSideContainer, HeaderContainer } from "./index.style";
import colors from "../../assets/colors.json";
import { PaymentReportTransaction } from "../../interfaces/Transaction";
import { getAllTransactionByIdAndType } from "../../apis/transaction";
import dayjs from "dayjs";
// import { revertHashTag } from "../../functions/convertHashTag";
import SearchInput from "../../components/common/SearchInput";
import DatePicker from "../../components/common/DatePicker";
import { ColumnsType } from "antd/lib/table";
import { IContractResponse } from "../../components/ContractMemberManagementPage/PartnerContractTable/useContractTable";

export type PaymentReportProps = {
    type: typeTransaction;
};

const columns: ColumnsType<PaymentReportTransaction> = [
    {
        title: "วันเวลา",
        dataIndex: "date",
        render: (date: number) => dayjs(date).format("DD/MM/YYYY HH:mm"),
    },
    {
        title: "ชื่อผู้รับชำระ",
        dataIndex: "collectorName",
    },
    {
        title: "ยอดชำระ",
        dataIndex: "amount",
    },
];

const PaymentReport: React.FC<PaymentReportProps> = ({ type }) => {
    const local = useLocation();
    const id = local.pathname.split("/").slice(-1)[0];
    const { state: contact } = useLocation();
    const [transaction, setTransaction] = React.useState<PaymentReportTransaction[]>([]);
    const [remainingDebtTotal, setRemainingDebtTotal] = React.useState<number>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [monthYear, setMonthYear] = React.useState<number>(+dayjs());
    const [subRoute, setSubRoute] = React.useState<string>(type);
    const [searchKey, setSearchKey] = React.useState<string>("");

    const getTitlePage = React.useCallback(() => {
        const cont = (contact as IContractResponse)
        const index = cont?.booth.findIndex(
            (item: string) => item === cont?.transactionCode
        );
        if (type === "booth" || type === "veggie" || type === "lomsak")
            return `${cont?.building || "-"} ${
                cont?.boothName[index] || "-"
            }`;
        else if (type === "labor" || type === "rider") return `หมายเลขเสื้อ ${id}`;
    }, [id, contact, type]);

    const filterTransaction = React.useCallback(
        (t: PaymentReportTransaction[]) =>
            t.filter(
                (item) =>
                    item.collectorName.includes(searchKey) ||
                    item.boothCode.includes(searchKey) ||
                    dayjs(item.date).format("DD/MM/YYYY").includes(searchKey)
            ),
        [searchKey]
    );

    const listTypeTransaction = React.useMemo(
        () =>
            type === "booth"
                ? [
                      {
                          title: `ค่า${converterType[type]}`,
                          value: type,
                      },
                      {
                          title: "ค่าน้ำ",
                          value: `${type}Water`,
                      },
                      {
                          title: "ค่าไฟ",
                          value: `${type}Electricity`,
                      },
                      {
                          title: `ค่าเสื้อแผง`,
                          value: `${type}Shirt`,
                      },
                      {
                          title: `ค่าที่จอดรถ`,
                          value: `${type}Parking`,
                      },
                      {
                          title: `อื่นๆ`,
                          value: `${type}Other`,
                      },
                  ]
                : type === "veggie"
                ? [
                      {
                          title: `ค่า${converterType[type]}`,
                          value: type,
                      },
                      {
                          title: "ค่าน้ำ",
                          value: `${type}Water`,
                      },
                      {
                          title: "ค่าไฟ",
                          value: `${type}Electricity`,
                      },
                      {
                          title: `ค่าที่จอดรถ`,
                          value: `${type}Parking`,
                      },
                      {
                          title: `อื่นๆ`,
                          value: `${type}Other`,
                      },
                  ]
                : [
                      {
                          title: `ค่า${converterType[type]}`,
                          value: type,
                      },
                      {
                          title: "อื่นๆ",
                          value: `${type}Other`,
                      },
                  ],
        [type]
    );

    const fetchData = React.useCallback(
        async (t?: string) => {
            try {
                setIsLoading(true);
                const response = await getAllTransactionByIdAndType(id, t || subRoute, monthYear);
                setTransaction(response.transaction);
                setRemainingDebtTotal(response.remainingDebtTotal);
            } catch (error) {
                console.log({ error });
            } finally {
                setIsLoading(false);
            }
        },
        [monthYear, id, subRoute]
    );

    React.useEffect(() => {
        fetchData();
    }, [fetchData, listTypeTransaction]);

    return (
        <Container>
            <Breadcrumb separator=">" style={{ fontSize: "1.5rem" }}>
                <Breadcrumb.Item>
                    <Link to={`/transaction`}>รายการชำระเงิน</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{converterType[type]}</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ color: colors.primary }}>{getTitlePage()}</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Tabs onChange={(sRoute) => setSubRoute(sRoute)} style={{ marginTop: 12 }}>
                {listTypeTransaction.map((sRoute) => (
                    <Tabs.TabPane key={sRoute.value} tab={sRoute.title} />
                ))}
            </Tabs>
            <HeaderContainer>
                {isLoading ? (
                    <Skeleton.Input active={true} size="small" />
                ) : (
                    <h3>
                        {"ยอดเงินคงเหลือ: " +
                            (remainingDebtTotal || remainingDebtTotal === 0
                                ? remainingDebtTotal + " บาท"
                                : "-")}
                    </h3>
                )}
                <HeaderSideContainer>
                    <DatePicker
                        style={{ minWidth: "120px" }}
                        defaultValue={dayjs()}
                        picker={type === "veggie" ? "month" : "year"}
                        onChange={(date) => setMonthYear(date ? +date : +dayjs())}
                    />
                    <SearchInput
                        style={{ width: "100%" }}
                        onChange={(e) => setSearchKey(e.target.value)}
                        placeholder="ค้นหารหัส / ชื่อ / วันที่"
                    />
                </HeaderSideContainer>
            </HeaderContainer>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterTransaction(transaction)}
            />
        </Container>
    );
};

export default PaymentReport;
