import styled from "styled-components";
import { Modal as M } from "antd";

export const Modal = styled(M)`
    &.ant-modal-content {
        display: flex;
        flex-direction: column;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 400px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
