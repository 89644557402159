import Swal from "sweetalert2";
import { formatNumber } from "../../../functions/formatNumber";
import colors from "../../../assets/colors.json";

const convertKeyToWord = (key: string) => {
    const item: { [key: string]: string } = {
        boothName: "ชื่อ",
        monthly: "ราคาแผงรายเดือน",
        yearly: "ราคาแผงรายปี",
        garantee: "ค่าประกัน",
        electricityCostType: "ประเภทไฟ",
        pricePerUnit: "ไฟปกติ",
        fifteenAmps: "ไฟ 15 แอมป์",
        threePhase: "ไฟ 3 เฟส",
    };
    return item[key];
};

export const onAddAlert = async (type: "booth" | "veggie" | "lomsak") => {
    const result = await Swal.fire({
        icon: "info",
        title: `ต้องการเพิ่มข้อมูล${type === "lomsak" ? "ที่" : "แผง"}ใช่หรือไม่ ?`,
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.hintText,
        reverseButtons: true,
    });
    return result.value;
};

export const onDeleteAlert = async (type: "booth" | "veggie" | "lomsak") => {
    const result = await Swal.fire({
        icon: "warning",
        title: `ต้องการลบข้อมูล${type === "lomsak" ? "ที่" : "แผง"}ใช่หรือไม่ ?`,
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: colors.warning,
        cancelButtonColor: colors.hintText,
        reverseButtons: true,
    });
    return result.value;
};

export const onEditAlert = async (data: any, newData: any) => {
    let text = "";
    Object.keys(newData).forEach((key) => {
        const [booth, keyName, index] = key.split("-");
        if (newData[key] !== data[booth][keyName]) {
            if (keyName === "monthly" || keyName === "yearly" || keyName === "garantee") {
                text += `แก้ไข ${convertKeyToWord(keyName)} ของส่วนย่อยลำดับที่ ${
                    +index + 1
                } จาก ${formatNumber(data[booth][keyName] || 0)} บาท เป็น ${formatNumber(
                    newData[key]
                )} บาท <br />`;
            } else if (keyName === "electricityCostType") {
                text += `แก้ไข ${convertKeyToWord(keyName)} ของส่วนย่อยลำดับที่ ${+index + 1} จาก ${
                    convertKeyToWord(data[booth][keyName]) ?? "ไฟปกติ"
                } เป็นประเภท ${convertKeyToWord(newData[key])}<br />`;
            } else {
                text += `แก้ไข ${convertKeyToWord(keyName)} ของส่วนย่อยลำดับที่ ${+index + 1} จาก ${
                    data[booth][keyName]
                } เป็น ${newData[key]} <br />`;
            }
        }
    });

    const result = await Swal.fire({
        icon: "warning",
        html: `<h3>ต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.hintText,
        reverseButtons: true,
    });
    return result.value;
};

export const onSuccessAlert = async () => {
    const result = await Swal.fire({
        icon: "success",
        title: `ทำรายการสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};

export const onErrorAlert = async () => {
    const result = await Swal.fire({
        icon: "error",
        title: `เกิดข้อผิดพลาดบางประการ`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};

export const onErrorEditAlert = async () => {
    const result = await Swal.fire({
        icon: "error",
        title: `สามารถแก้ไขได้ครั้งละ 1 อย่างเท่านั้น`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};
