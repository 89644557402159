import React from "react";
import { Typography, Card } from "antd";

const { Title, Paragraph } = Typography;
const PDPA = () => {
    return (
        <Card>
            <Title level={2}>คำประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคล</Title>
            <Paragraph>
                นโยบายนี้อธิบายถึงวิธีการที่บริษัท ศรีเมืองทอง (2008) จำกัด
                ปฏิบัติต่อข้อมูลส่วนบุคคล ประกอบด้วยการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
                ภายใต้หลักการคุ้มครองข้อมูลส่วนบุคคลไม่ให้มีการละเมิดสิทธิความเป็นส่วนตัวของบุคคล
                ซึ่งอาจก่อให้เกิดความเดือดร้อนรำคาญหรือความเสียหายให้แก่เจ้าของข้อมูลส่วนบุคคล
                แต่ยังคงไว้ซึ่งความจำเป็นในการใช้ประโยชน์จากข้อมูลส่วนบุคคลตามวัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคล
                จึงขอแจ้งข้อมูลดังต่อไปนี้ให้ท่านทราบเพื่อเป็นการปฏิบัติตาม
                พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 โดยมีสาระสำคัญดังต่อไปนี้
            </Paragraph>
            <Title level={4}>1. การเคารพสิทธิในความเป็นส่วนบุคคล</Title>
            <Paragraph>
                บริษัทฯ เคารพสิทธิในข้อมูลส่วนบุคคลของเจ้าของข้อมูล และตระหนักดีว่าเจ้าของข้อมูล
                ย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยเกี่ยวกับข้อมูลของตนข้อมูลส่วนบุคคลที่บริษัทฯได้รับมา
                เช่น ชื่อ อายุ ที่อยู่ หมายเลขโทรศัพท์ หมายเลขบัตรประชาชน ข้อมูลทางการเงิน เป็นต้น
                ซึ่งสามารถบ่งบอกตัวบุคคลของเจ้าของข้อมูลส่วนบุคคลนั้นได้
                จะถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์การดำเนินงานของบริษัทฯเท่านั้น
                และบริษัทฯจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัย
                ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิชอบ
            </Paragraph>
            <Title level={4}>2. การเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจำกัด</Title>
            <Paragraph>
                ในการเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลโดยตรง และการนำข้อมูลส่วนบุคคลไปใช้
                รวมถึงการเปิดเผยข้อมูลส่วนบุคคล บริษัทฯ
                จะขอความยินยอมจากเจ้าของข้อมูลก่อนหรือขณะทำการเก็บรวบรวม
                หากกฎหมายกำหนดให้ต้องขอความยินยอม
                และจะดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลเท่าที่จำเป็นตามวัตถุประสงค์ที่บริษัทฯ ระบุไว้
                บริษัทฯจะไม่จัดเก็บข้อมูลส่วนบุคคลซึ่งเกี่ยวกับเชื้อชาติ เผ่าพันธุ์
                ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ
                ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
                หรือข้อมูลอื่นใดที่อาจเป็นผลร้าย ทำให้เสียชื่อเสียง
                หรืออาจก่อให้เกิดความรู้สึกเกี่ยวกับการเลือกปฏิบัติโดยไม่เป็นธรรมหรือความไม่เท่าเทียมกันแก่บุคคลใด
                เว้นแต่
                <ol>
                    <li>ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล</li>
                    <li>เป็นกรณีที่กฎหมายกำหนด</li>
                    <li>
                        เป็นไปเพื่อประโยชน์ของเจ้าของข้อมูลส่วนบุคคล
                        และการขอความยินยอมไม่อาจกระทำได้ในเวลานั้น
                    </li>
                    <li>เป็นไปเพื่อประโยชน์เกี่ยวกับชีวิต สุขภาพ หรือความปลอดภัย</li>
                </ol>
            </Paragraph>
            <Paragraph>
                ทั้งนี้ บริษัทฯ
                อาจรวบรวมข้อมูลส่วนบุคคลที่ได้รับมาจากแหล่งอื่นที่ไม่ใช่จากเจ้าของข้อมูลส่วนบุคคลโดยตรง
                เช่น จากสื่อสาธารณะต่าง ๆ เฉพาะในกรณีที่มีความจำเป็นด้วยวิธีการตามที่กฎหมายกำหนด
            </Paragraph>
            <Title level={4}>3. วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</Title>
            <Paragraph>
                วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผย
                ข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ดังต่อไปนี้
                <ol>
                    <li>
                        เพื่อการทำธุรกรรมทางการเงิน และภาษี
                        ที่เกี่ยวข้องกับการปฏิบัติตามสัญญาของบริษัทฯ
                    </li>
                    <li>
                        เพื่อประโยชน์ในการปรับปรุงคุณภาพในการดำเนินงาน การให้บริการ
                        และการดำเนินการที่เกี่ยวข้องกับธุรกิจของบริษัทฯ
                    </li>
                    <li>
                        เพื่อการวิเคราะห์และติดตามการใช้บริการทางเว็บไซต์
                        และวัตถุประสงค์ในการตรวจสอบย้อนหลังในกรณีที่เกิดปัญหาการใช้งาน
                    </li>
                    <li>เพื่อการเข้าร่วมกิจกรรมต่างๆ ของบริษัทฯ</li>
                    <li>
                        เพื่อวัตถุประสงค์ในการควบคุมการเข้าถึง การป้องกันและระงับอันตรายต่อชีวิต
                        ร่างกาย หรือสุขภาพของท่านหรือบุคคลอื่น การควบคุมและป้องกันโรคติดต่อ
                        และเพื่อประโยชน์ในการดูแลรักษาความปลอดภัย ของบริเวณอาคาร ภายในอาคาร
                        และพื้นที่ของบริษัทฯ
                    </li>
                    <li>
                        เพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่ใช้บังคับกับบริษัทฯ ทั้งในปัจจุบันและ
                        ในอนาคต
                    </li>
                </ol>
                ข้อมูลส่วนบุคคลที่บริษัทฯ
                ดำเนินการเก็บรวบรวมเพื่อวัตถุประสงค์ข้างต้นเป็นข้อมูลที่จำเป็นในการปฏิบัติตามสัญญาหรือการปฏิบัติตามกฎหมายต่าง
                ๆ ที่ใช้บังคับ หากท่านไม่ให้ข้อมูลส่วนบุคคลที่จำเป็นดังกล่าว อาจเป็นการฝ่าฝืนกฎหมาย
                หรือบริษัทฯ อาจไม่สามารถบริหารหรือจัดการสัญญา หรืออำนวยความสะดวกในการดำเนินการต่างๆ
                ให้กับท่านได้
                ทั้งนี้หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล บริษัทฯ
                จะแจ้งให้ท่านทราบ และดำเนินการอื่นใดตามที่กฎหมายกำหนด
                รวมถึงจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
            </Paragraph>
            <Title level={4}>4. การนำข้อมูลส่วนบุคคลไปใช้อย่างจำกัด</Title>
            <Paragraph>
                บริษัทฯจะใช้
                เปิดเผยข้อมูลส่วนบุคคลได้ต่อเมื่อเป็นการใช้ตามวัตถุประสงค์ของบริษัทฯเท่านั้น
                บริษัทฯจะดูแลให้ผู้ปฏิบัติงานของบริษัทฯมิให้เปิดเผย แสดง
                หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลนอกเหนือไปจากวัตถุประสงค์หรือต่อบุคคลภายนอก
                เว้นแต่
                <ol>
                    <li>เป็นกรณีที่กฎหมายกำหนด</li>
                    <li>ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล</li>
                    <li>
                        เป็นไปเพื่อประโยชน์เกี่ยวกับชีวิต สุขภาพ
                        หรือความปลอดภัยของเจ้าของข้อมูลส่วนบุคคลและผู้ใช้บริการอื่น
                    </li>
                    <li>
                        เพื่อประโยชน์แก่การสอบสวนของพนักงานสอบสวน หรือการพิจารณาพิพากษาคดีของศาล
                    </li>
                </ol>
                ในบางกรณีบริษัทฯอาจให้บุคคลหรือหน่วยงานอื่นเข้าถึงหรือใช้ข้อมูลส่วนบุคคลเท่าที่จำเป็น
                และเพื่อให้เป็นไปตามวัตถุประสงค์และอำนาจหน้าที่ของบริษัทฯ
            </Paragraph>
            <Title level={4}>5. ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล</Title>
            <Paragraph>
                บริษัทฯ
                จะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าที่จำเป็นเพื่อวัตถุประสงค์ในการเก็บรวบรวม
                ใช้ และเปิดเผยข้อมูลส่วนบุคคลซึ่งได้ระบุไว้ในคำประกาศฉบับนี้
                ตามหลักเกณฑ์ที่ใช้กำหนดระยะเวลาเก็บ ได้แก่ ระยะเวลาที่บริษัทฯ
                ยังมีความสัมพันธ์กับท่านในฐานะลูกค้าของบริษัทฯ
                และอาจเก็บต่อไปตามระยะเวลาที่จำเป็นเพื่อการปฏิบัติตามกฎหมายหรือตามอายุความทางกฎหมาย
                เพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                การปฏิบัติตามกฎหมายหรือการใช้สิทธิเรียกร้องตามกฎหมาย
                หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
                หรือเพื่อเหตุอื่นตามนโยบายและข้อกำหนดภายในของบริษัทฯ
                ในกรณีที่ไม่สามารถระบุระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลได้ชัดเจน บริษัทฯ
                จะเก็บรักษาข้อมูลไว้ตามระยะเวลาที่อาจคาดหมายได้ตามมาตรฐานของการเก็บรวบรวม (เช่น
                อายุความตามกฎหมายทั่วไปสูงสุด 10 ปี)
            </Paragraph>

            <Title level={4}>6. การรักษาความมั่นคงปลอดภัย</Title>
            <Paragraph>
                บริษัทฯตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
                บริษัทฯจึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
                การเข้าถึง ทำลาย ใช้ แปลง
                แก้ไขหรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
                โดยให้เป็นไปตามที่กำหนดในนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัทฯ
            </Paragraph>
            <Title level={4}>7. การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</Title>
            <Paragraph>
                ในฐานะที่เป็นเจ้าของข้อมูลส่วนบุคคลท่านมีสิทธิตามที่กำหนดไว้โดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. 2562 รวมถึงสิทธิต่าง ๆ ดังนี้
            </Paragraph>
            <Paragraph>
                <ol>
                    <li>
                        สิทธิในการเพิกถอนความยินยอม
                        ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ
                        ได้
                        เว้นแต่การเพิกถอนความยินยอมจะมีข้อจำกัดโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่ท่าน
                        ในฐานะที่ท่านเป็นเจ้าของข้อมูล ทั้งนี้
                        การเพิกถอนความยินยอมจะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ท่าน
                        ได้ให้ความยินยอมไปแล้วโดยชอบด้วยกฎหมาย
                    </li>
                    <li>
                        สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล
                        ท่านมีสิทธิขอเข้าถึงและขอรับสำเนาข้อมูลของท่านซึ่งอยู่ในความรับผิดชอบของบริษัทฯ
                        รวมถึงขอให้บริษัทฯ
                        เปิดเผยการได้มาซึ่งข้อมูลดังกล่าวที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทฯ ได้
                    </li>
                    <li>
                        สิทธิในการขอให้ส่งหรือโอนข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้บริษัทฯ
                        โอนข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัทฯ ได้ตามที่กฎหมายกำหนด
                    </li>
                    <li>
                        สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
                        ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลที่เกี่ยวกับท่านสำหรับกรณีการเก็บรวบรวม
                        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตนได้ตามที่กฎหมายกำหนด
                    </li>
                    <li>
                        สิทธิในการขอลบข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้บริษัทฯ
                        ลบหรือทำลายหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ตามที่กฎหมายกำหนด
                    </li>
                    <li>
                        สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้บริษัทฯ
                        ระงับการใช้ข้อมูลของท่านได้ตามที่กฎหมายกำหนด
                    </li>
                    <li>
                        สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
                        กรณีที่ท่านเห็นว่าข้อมูลที่บริษัทฯ
                        มีอยู่นั้นไม่ถูกต้องหรือท่านมีการเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านเอง
                        ท่านมีสิทธิขอให้บริษัทฯ
                        แก้ไขข้อมูลส่วนบุคคลของท่านเพื่อให้ข้อมูลส่วนบุคคลดังกล่าวถูกต้อง
                        เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                    </li>
                    <li>
                        สิทธิในการรับทราบกรณีมีการแก้ไขเปลี่ยนแปลงแบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                        บริษัทฯ อาจมีการพิจารณาทบทวนและแก้ไขเปลี่ยนแปลงแบบแจ้งนี้ตามความเหมาะสม
                        ในบางครั้งเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับความคุ้มครองอย่างเหมาะสม
                    </li>
                </ol>
            </Paragraph>
            <Title level={4}>8. การเชื่อมโยงข้อมูลส่วนบุคคลกับบุคคลหรือหน่วยงานอื่น</Title>
            <Paragraph>
                บริษัทฯ อาจมีความจำเป็นในการเปิดเผยข้อมูลส่วนบุคคลให้แก่บริษัทในกลุ่ม
                หรือบุคคลหรือหน่วยงานอื่นที่เป็นพันธมิตรทางกลยุทธ์ทั้งในประเทศและต่างประเทศ
                ซึ่งทำงานร่วมกับบริษัทฯ
                ตามความจำเป็นตามสมควรในการบังคับใช้ข้อกำหนดและเงื่อนไขของบริษัทฯ
                หรือกรณีที่มีการปรับโครงสร้างองค์กร การควบรวมบริษัท หรือการขายกิจการ
                และอาจมีการเปิดเผยข้อมูลส่วนบุคคลให้กับหน่วยงานราชการหรือหน่วยงานภาครัฐตามข้อบังคับของกฎหมายหรือตามคำสั่งศาลหรือตามคำสั่งเจ้าหน้าที่ผู้มีอำนาจ
                โดยข้อมูลส่วนบุคคลจะได้รับการเก็บรักษาเป็นความลับ
                ทั้งในรูปเอกสารและข้อมูลอิเล็กทรอนิกส์ รวมทั้งในระหว่างการส่งผ่านข้อมูลทุกขั้นตอน
                ทั้งนี้ ในกรณีที่ต้องมีการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ บริษัทฯ
                จะดำเนินการตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                กำหนดไว้อย่างเคร่งครัด
            </Paragraph>
            <Title level={4}>9. การเปลี่ยนแปลงนโยบายข้อมูลส่วนบุคคล</Title>
            <Paragraph>
                บริษัทฯ จะทำการพิจารณาทบทวนเงื่อนไขนโยบายบริษัทฯ ฉบับนี้เป็นครั้งคราว
                เพื่อให้สอดคล้องกับแนวปฏิบัติ และกฎหมายที่เกี่ยวข้อง หากมีการแก้ไขเปลี่ยนแปลงบริษัทฯ
                จะแจ้งให้ทราบด้วยการเผยแพร่ผ่านการประกาศที่เหมาะสมของบริษัทฯ
                สำหรับข้อมูลเพิ่มเติมเกี่ยวกับนโยบายและแนวปฏิบัติคุ้มครองข้อมูลส่วนบุคคล
                โปรดศึกษาจากนโยบายและแนวปฏิบัติคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ หากมีข้อสงสัยเพิ่มเติม
                โปรดติดต่อที่ บริษัท ศรีเมืองทอง (2008) จำกัด 181 หมู่ที่ 17 ถนนมิตรภาพ ตำบลในเมือง
                อำเภอเมืองขอนแก่น จ.ขอนแก่น 40000 หมายเลขโทรศัพท์ 081-964-7777
            </Paragraph>
        </Card>
    );
};

export default PDPA;
