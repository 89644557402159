import { Avatar, Popover, Skeleton, Tag, Typography } from "antd";
import React from "react";
import { Container, MenuIcon, PopoverContent } from "./index.style";
import logo from "../../assets/images/smt-logo.png";
import { useAuthContext } from "../../providers/AuthProvider";
import colors from "../../assets/colors.json";
import { rolesEnToTh } from "../../assets/roles";

export type AppHeaderProps = {
    toggle: () => void;
};

const AppHeader: React.FC<AppHeaderProps> = ({ toggle }) => {
    const { user, isLoading } = useAuthContext();

    return (
        <Container>
            <MenuIcon onClick={toggle} color={colors.primary} />
            <img src={logo} alt="logo" height={42} />
            <Popover
                trigger="click"
                style={{ maxWidth: 100 }}
                placement="bottomRight"
                content={
                    <PopoverContent>
                        <Avatar size={100} style={{ marginBottom: 16 }} src={user?.photoURL}>
                            {user?.name.slice(0, 1)}
                        </Avatar>
                        {isLoading ? (
                            <Skeleton.Input
                                active={true}
                                size={"small"}
                                style={{ marginBottom: 8, width: 160 }}
                            />
                        ) : (
                            <Typography.Title style={{ margin: 0 }} level={3}>
                                {user?.name}
                            </Typography.Title>
                        )}
                        {isLoading ? (
                            <Skeleton.Input active={true} size={"small"} />
                        ) : (
                            <Typography.Text style={{ width: 130 }} type="secondary" ellipsis>
                                {user?.email}
                            </Typography.Text>
                        )}
                        <div
                            style={{
                                marginTop: 16,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                gap: "8px 0"
                            }}
                        >
                            {user?.mainDuty ? (
                                <Tag
                                    style={{ color: colors.textColor }}
                                    color={colors.memberDetailWorkColor?.mainDuty}
                                    children={rolesEnToTh[user?.mainDuty] || user?.mainDuty}
                                />
                            ) : null}
                            {user?.subDuty &&
                                user?.subDuty
                                    .filter((duty) => duty !== user?.mainDuty)
                                    .map((item, k) => (
                                        <Tag
                                            style={{ color: colors.textColor }}
                                            key={`subDuty-${k}-${user?.name}`}
                                            color={colors.memberDetailWorkColor.subDuty}
                                            children={rolesEnToTh[item] || item}
                                        />
                                    ))}
                        </div>
                    </PopoverContent>
                }
            >
                <Avatar style={{ cursor: "pointer" }} src={user?.photoURL}>
                    {user?.name.slice(0, 1)}
                </Avatar>
            </Popover>
        </Container>
    );
};

export default AppHeader;
