import { Select } from "antd";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import DatePicker from "../../common/DatePicker";

const { Option } = Select;

const wordMapping: { [key: string]: any } = {
    date: "รายวัน",
    week: "รายสัปดาห์",
    month: "รายเดือน",
    year: "รายปี",
};

const formatMapping: { [key: string]: any } = {
    date: "DD/MM/YYYY",
    week: "สัปดาห์ที่ WW-YYYY",
    month: "MMMM YYYY",
    year: "YYYY",
};

type FilterWithDatePickerProps = {
    date: Dayjs | null;
    value: PickerProps<Dayjs>["picker"];
    onChange: (date: Dayjs | null) => void;
    setFilter: (v: PickerProps<Dayjs>["picker"]) => void;
    useOption: PickerProps<Dayjs>["picker"][];
    disableDate?: Dayjs;
};

const FilterWithDatePicker: React.FC<FilterWithDatePickerProps> = (
    props: FilterWithDatePickerProps
) => {
    const { value, date, onChange, useOption, setFilter, disableDate = dayjs() } = props;

    return (
        <>
            <Select
                defaultValue={useOption[0]}
                style={{ width: 120 }}
                onChange={(v) => setFilter(v as PickerProps<Dayjs>["picker"])}
            >
                {useOption.map((v) => (
                    <Option value={v} key={v}>
                        {wordMapping[v as string]}
                    </Option>
                ))}
            </Select>
            <DatePicker
                value={date}
                onChange={onChange}
                allowClear={false}
                format={(v) => v.format(formatMapping[value!])}
                inputReadOnly
                picker={value as PickerProps<Dayjs>["picker"]}
                disabledDate={(v) =>
                    +v > +disableDate || +v < +dayjs("2022-01-01").subtract(1, "year")
                }
            />
        </>
    );
};

export default FilterWithDatePicker;
