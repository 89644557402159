import styled from "styled-components";
import colors from "../../../assets/colors.json";
import { Image } from "antd";

export const ContainerTable = styled.div`
    Table {
        box-shadow: 0px 1px 8px rgba(219, 217, 217, 0.45);
        .ant-table-thead > tr > th {
            text-align: center !important;
        }

        .ant-table-cell:first-child:not(.ant-table-thead > tr > th),
        .ant-table-cell:last-of-type:not(.ant-table-thead > tr > th) {
            div {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;
                > :nth-child(1) {
                    margin-right: 1rem;
                }
                p {
                    margin-bottom: 0 !important;
                }
            }
        }
        .selected {
            background-color: ${colors.primary}33 !important;
        }

        .ant-table-tbody > tr.ant-table-row:hover > td {
            background: none !important;
        }
    }
`;

export const TextBuilding = styled.p`
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const IconBuilding = styled(Image)`
    margin-left: 1.4rem;
`;
