import { message } from "antd";
import dayjs from "dayjs";
import React from "react";
import { createContract, getBuildingMonthly, getBuildingVeggie } from "../../apis/register";
import { base64ToBlob } from "../../functions/files";
import { uploadImageReturnUrl } from "../../functions/storage";
import { MemberType, BoothType, BuildingType, VeggieType } from "../../interfaces/Register";

export const RegisterContext = React.createContext({} as ReturnType<typeof useRegister>);
export const useRegisterContext = () => React.useContext(RegisterContext);

function useRegister() {
    const [index, setIndex] = React.useState(0);
    const [tenantForm, setTenantForm] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [buildingStorage, setBuildingStorage] = React.useState<BuildingType>({});
    const [isLegalEntity, setIsLegalEntity] = React.useState(false);
    const [boothForm, setBoothForm] = React.useState<any>();
    const [typeForm, setTypeForm] = React.useState<string>("booth");
    const [resultId, setResultId] = React.useState<string>("");
    const nextStep = () => setIndex((index) => index + 1);
    const prevStep = () => setIndex((index) => index - 1);

    const getBuildingStorage = React.useCallback(async (type: "booth" | "veggie") => {
        setIsLoading(true);
        try {
            const buildingStorage =
                type === "booth" ? await getBuildingMonthly() : await getBuildingVeggie();
            setBuildingStorage(buildingStorage);
        } catch (error) {
            console.log({ error });
            message.error("การเชื่อมต่อล้มเหลว");
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getBoothName = React.useCallback(
        (boothCode: string) => {
            const keys = Object.keys(buildingStorage);
            const booth: { [keys: string]: string } = {};
            keys.forEach((item) => {
                buildingStorage[item].map((i) => (booth[i.boothCode] = i.boothName));
            });
            return booth[boothCode];
        },
        [buildingStorage]
    );

    const getUrlImagePicturePathAndSignContract = React.useCallback(
        async (firstSignature: Blob | undefined) => {
            const image = await base64ToBlob(tenantForm.imageBase64);
            try {
                const picturePaths: string[] | undefined = tenantForm.imageBase64
                    ? await uploadImageReturnUrl("images/tenant", [image])
                    : undefined;
                const signaturePath: string[] | undefined = firstSignature
                    ? await uploadImageReturnUrl("images/tenant", [firstSignature])
                    : undefined;
                return {
                    picturePath: picturePaths ? picturePaths[0] : undefined,
                    signaturePath: signaturePath ? signaturePath[0] : undefined,
                };
            } catch (error) {
                console.log({ error });
                setIsLoading(false);
                return message.error("กรุณาลองใหม่อีกครั้ง");
            }
        },
        [tenantForm]
    );

    const getMembers = React.useCallback(
        (tenantForm: any) =>
            Array.from(Array(tenantForm.numMembers).keys()).map(
                (num) =>
                    ({
                        prefix: tenantForm[`prefix${num}`],
                        firstName: tenantForm[`firstName${num}`],
                        lastName: tenantForm[`lastName${num}`],
                        ssn: tenantForm[`ssn${num}`],
                        tel: tenantForm[`phoneNumber${num}`],
                        isLegalEntity,
                        status: "active",
                        address: {
                            address: tenantForm[`address${num}`],
                            province: tenantForm[`province${num}`],
                            district: tenantForm[`district${num}`],
                            subDistrict: tenantForm[`subDistrict${num}`],
                            postCode: tenantForm[`zipCode${num}`]?.toString(),
                        },
                        vaccine: tenantForm[`numVaccines${num}`]
                            ? Array.from(Array(tenantForm[`numVaccines${num}`]).keys()).map(
                                  (numVaccine) => {
                                      return {
                                          name: tenantForm[`vaccine${num}${numVaccine}`],
                                          date: +dayjs(
                                              tenantForm[`vaccineDate${num}${numVaccine}`]
                                          ),
                                      };
                                  }
                              )
                            : [],
                    } as MemberType)
            ),
        [isLegalEntity]
    );

    const onSubmitApplication = React.useCallback(
        async (firstSignature: Blob | undefined) => {
            if (isLoading) return message.loading("กำลังทำการส่งข้อมูล");
            setIsLoading(true);
            const [tenant, ...member] = getMembers(tenantForm);
            const { picturePath, signaturePath } = await getUrlImagePicturePathAndSignContract(
                firstSignature
            );
            if (!boothForm?.startDate && !boothForm?.endDate) {
                message.error("กรุณาเลือกวันที่เริ่มต้นและสิ้นสุด");
                throw new Error("กรุณาเลือกวันที่เริ่มต้นและสิ้นสุด");
            }

            const config = {
                picturePath,
                signaturePath,
                booth: boothForm.booth,
                parkingList: boothForm?.parkingList || [],
                parking: !boothForm.parkingList === undefined && +boothForm.parkingList?.length,
                startDate: +dayjs(+boothForm.startDate),
                endDate: +dayjs(+boothForm.endDate),
                reminder: boothForm.reminder,
            };

            let contract: BoothType | VeggieType;

            const involved =
                tenantForm.involvedFirstName &&
                tenantForm.involvedLastName &&
                tenantForm.involvedPhoneNumber &&
                tenantForm.involvedPrefix
                    ? {
                          firstName: tenantForm.involvedFirstName,
                          lastName: tenantForm.involvedLastName,
                          tel: tenantForm.involvedPhoneNumber,
                          prefix: tenantForm.involvedPrefix,
                      }
                    : null;

            if (typeForm === "booth") {
                if (!tenantForm.numMembers) {
                    message.error("มีบางอย่างผิดพลาด");
                    throw new Error("มีบางอย่างผิดพลาด");
                }
                contract = {
                    ...tenant,
                    ...config,
                    type: "booth",
                    laborShirt:
                        (boothForm.laborShirtList && boothForm.laborShirtList?.length) || [],
                    laborShirtList: boothForm.laborShirtList || [],
                    member,
                } as BoothType;
            } else if (typeForm === "veggie") {
                contract = {
                    ...tenant,
                    ...config,
                    involved,
                    type: "veggie",
                } as VeggieType;
            } else {
                message.error("มีบางอย่างผิดพลาด");
                throw new Error("มีบางอย่างผิดพลาด");
            }

            try {
                const { documentID } = await createContract(contract);
                setResultId(documentID);
            } catch (error) {
                setIsLoading(false);
                message.error("กรุณาลองใหม่อีกครั้ง");
                throw new Error("กรุณาลองใหม่อีกครั้ง");
            }
            setIsLoading(false);
        },
        [
            tenantForm,
            boothForm,
            typeForm,
            getUrlImagePicturePathAndSignContract,
            getMembers,
            isLoading,
        ]
    );

    return {
        index,
        setIndex,
        nextStep,
        prevStep,
        tenantForm,
        setTenantForm,
        buildingStorage,
        setBoothForm,
        boothForm,
        isLoading,
        getBuildingStorage,
        onSubmitApplication,
        setTypeForm,
        typeForm,
        resultId,
        getBoothName,
        setIsLegalEntity,
        isLegalEntity,
    };
}

export default useRegister;
