import axios from "axios";
import {
    MemberType,
    RandomAssignValueType,
    WorkManagementCardType,
} from "../interfaces/MemberManagement";
import meterIcon from "../assets/icons/meter.png";
import shop from "../assets/icons/shop.png";
import shirt from "../assets/icons/shirt.png";

export const getAllMember = async () => {
    const result = await axios.get<MemberType[]>("/users/all/active");
    return result.data;
};

export const patchUpdateDutyMember = async (
    uid: string,
    name: string,
    tel: string,
    photoURL?: string,
    mainDuty?: string,
    dutyPool?: string[]
) =>
    await axios.patch(`/users/assignDuty`, {
        uid,
        tel,
        name,
        photoURL,
        duty: { mainDuty, dutyPool },
    });
export const getRandomAssignValue = async () => {
    const result = await axios.get<RandomAssignValueType>("/random/assignValue");
    const template: (_: RandomAssignValueType) => WorkManagementCardType[] = (props) => [
        {
            title: "จดมิเตอร์ค่าน้ำ/ไฟฟ้า",
            icon: meterIcon,
            list: [
                {
                    title: "จดมิเตอร์น้ำ/ไฟฟ้า",
                    menu: [
                        {
                            label: "แผงรายเดือน",
                            value: props?.meterBooth?.currValue || 0,
                            maxValue: props?.meterBooth?.maxValue || 0,
                            name: "meterBooth",
                        },
                        {
                            label: "แผงผักบ้าน",
                            value: props?.meterVeggie?.currValue || 0,
                            maxValue: props?.meterVeggie?.maxValue || 0,
                            name: "meterVeggie",
                        },
                        {
                            label: "รถหล่ม",
                            value: props?.meterLomsak?.currValue || 0,
                            maxValue: props?.meterLomsak?.maxValue || 0,
                            name: "meterLomsak",
                        },
                    ],
                },
            ],
        },
        {
            title: "เก็บค่าเช่า",
            icon: shop,
            list: [
                {
                    title: "เก็บค่าเช่า",
                    menu: [
                        {
                            label: "แผงรายเดือน",
                            value: props?.booth?.currValue || 0,
                            maxValue: props?.booth?.maxValue || 0,
                            name: "booth",
                        },
                        {
                            label: "แผงผักบ้าน",
                            value: props?.veggie?.currValue || 0,
                            maxValue: props?.veggie?.maxValue || 0,
                            name: "veggie",
                        },
                        {
                            label: "รถหล่ม",
                            value: props?.lomsak?.currValue || 0,
                            maxValue: props?.lomsak?.maxValue || 0,
                            name: "lomsak",
                        },
                    ],
                },
            ],
        },
        {
            title: "เก็บค่าเสื้อ",
            icon: shirt,
            list: [
                {
                    title: "เก็บค่าเสื้อ",
                    menu: [
                        {
                            label: "เสื้อวิน",
                            value: props?.rider?.currValue || 0,
                            maxValue: props?.rider?.maxValue || 0,
                            name: "rider",
                        },
                        {
                            label: "เสื้อขึ้นของ",
                            value: props?.labor?.currValue || 0,
                            maxValue: props?.labor?.maxValue || 0,
                            name: "labor",
                        },
                    ],
                },
            ],
        },
    ];
    return { temp: template(result.data), data: result.data as RandomAssignValueType };
};

export const updateValueRandom = async (update: { [key: string]: number }) =>
    await axios.patch("/random/assignValue", update);

export const reRandomAssignValue = async () => await axios.patch("/random/reRole");
