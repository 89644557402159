import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 400px;

    overflow-y: scroll;
`;
