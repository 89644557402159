import { Alignment, Borders, Fill } from "exceljs";
import { HeaderExcelTemp } from "../interfaces/Transaction";

export const borderStyles: Partial<Borders> = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
};

export const alignment: Partial<Alignment> = { vertical: "middle", horizontal: "center" };

export const fillRedDebt: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
        argb: "FFA07A",
    },
};

export const fillGreenDebt: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
        argb: "CBE5BF",
    },
};

export const widthArr = [5, 15, 15, 30, 15, 15, 15, 15, 15, 15, 15, 15, 15];

export const headers: HeaderExcelTemp[] = [
    {
        value: "ลำดับ",
        isMerge: true,
        merge: "A4:A5",
        cell: "A4",
    },
    {
        value: "อาคาร",
        isMerge: true,
        merge: "B4:B5",
        cell: "B4",
    },
    {
        value: "แผง",
        isMerge: true,
        merge: "C4:C5",
        cell: "C4",
    },
    {
        value: "ชื่อผู้เช่า",
        isMerge: true,
        merge: "D4:D5",
        cell: "D4",
    },
    {
        value: "เบอร์โทร",
        isMerge: true,
        merge: "E4:E5",
        cell: "E4",
    },
    {
        value: "รายการค่าเช่า",
        isMerge: true,
        merge: "F4:G4",
        cell: "F4",
    },
    {
        value: "วันที่",
        isMerge: false,
        cell: "F5",
    },
    {
        value: "จำนวนเงิน",
        isMerge: false,
        cell: "G5",
    },
    {
        value: "รวมค่าเช่า",
        isMerge: true,
        merge: "H4:H5",
        cell: "H4",
    },
    {
        value: "รายการชำระเงิน",
        isMerge: true,
        merge: "I4:K4",
        cell: "I4",
    },
    {
        value: "วันที่ออกบิล",
        isMerge: false,
        cell: "I5",
    },
    {
        value: "จำนวนเงิน",
        isMerge: false,
        cell: "J5",
    },
    {
        value: "วันเก็บเงิน",
        isMerge: false,
        cell: "K5",
    },
    {
        value: "รวมการชำระ",
        isMerge: true,
        merge: "L4:L5",
        cell: "L4",
    },
    {
        value: "ยอดค้างชำระ",
        isMerge: true,
        merge: "M4:M5",
        cell: "M4",
    },
];

export const initRows = (data: any) => {
    return [
        {
            colName: "A",
            keyName: data["index"] + 1,
        },
        {
            colName: "B",
            keyName: data["buildingName"],
        },
        {
            colName: "C",
            keyName: data["boothName"],
        },
        {
            colName: "D",
            keyName: data["contractOwner"],
        },
        {
            colName: "E",
            keyName: data["tel"],
        },
        {
            colName: "H",
            keyName: data["totalDebt"] ?? 0,
            numFmt: "#,##0.00",
        },
        {
            colName: "L",
            keyName: data["totalPaid"] ?? 0,
            numFmt: "#,##0.00",
        },
        {
            colName: "M",
            keyName: data["totalDebt"] - data["totalPaid"] ?? 0,
            numFmt: "#,##0.00",
        },
    ]
};
