import { Col, Divider, Row, Spin } from "antd";
import { Car, CurrencyCircleDollar } from "phosphor-react";
import React from "react";
import { formatNumber } from "../../../functions/formatNumber";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import AreaGraph from "../AreaGraph";
import DrawerDisplay from "../DrawerDisplay";
import { formatDiffStatus, statOption } from "../utility/mapping";
import {
    AntCard,
    CardTitle,
    Column,
    LogoContainer,
    Paragraph,
    RowTitleCard,
    StatisticItem,
    TypoContainer,
} from "./index.styles";

type StatisticIncomeCardProps = {};

const StatisticIncomeCard: React.FC<StatisticIncomeCardProps> = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const { isLoading, summaryCard } = useDashboardProvider();

    const { income, expenses } = React.useMemo(() => {
        if (!summaryCard) return { income: 0, expenses: 0, diffIEAccount: 0 };
        return { ...summaryCard["IEAccount"], diffIEAccount: summaryCard["IEAccount"]?.diff ?? 0 };
    }, [summaryCard]);

    const { paid, diff } = React.useMemo(() => {
        if (!summaryCard) return { paid: 0, diff: 0 };
        return {
            paid: summaryCard["nonRegisterCar"]?.paid ?? 0,
            diff: summaryCard["nonRegisterCar"]?.diff ?? 0,
        };
    }, [summaryCard]);

    const symbolCar = React.useMemo(() => {
        return statOption[formatDiffStatus(diff)].symbol;
    }, [diff]);

    return (
        <>
            <AntCard
                title={<CardTitle>จำนวนเงินที่ชำระแล้ว</CardTitle>}
                extra={
                    <CardTitle color="subtitle" onClick={() => setIsOpen(!isOpen)}>
                        ดูข้อมูลเชิงลึก
                    </CardTitle>
                }
            >
                {!isLoading &&
                summaryCard &&
                summaryCard["IEAccount"] &&
                summaryCard["nonRegisterCar"] ? (
                    <>
                        <Row justify="space-between" align="middle" style={{ marginTop: "0.8rem" }}>
                            <RowTitleCard>
                                <LogoContainer>
                                    <Car size={26} />
                                </LogoContainer>
                                <Paragraph>รถรายวัน</Paragraph>
                            </RowTitleCard>
                            <StatisticItem
                                value={Math.abs(diff)}
                                prefix={<span>{symbolCar}</span>}
                                color={formatDiffStatus(diff)}
                            />
                        </Row>
                        <TypoContainer>
                            <span></span>
                            <span>{formatNumber(paid!)} บาท</span>
                        </TypoContainer>
                        <Row
                            justify="space-between"
                            align="middle"
                            style={{ marginTop: "1.25rem" }}
                        >
                            <RowTitleCard>
                                <LogoContainer>
                                    <CurrencyCircleDollar size={26} />
                                </LogoContainer>
                                <Paragraph>รายรับ/รายจ่าย</Paragraph>
                            </RowTitleCard>
                        </Row>
                        <Row justify="space-between" align="middle" style={{ marginTop: "0.5rem" }}>
                            <Col span={10}>
                                <TypoContainer>
                                    <span>รายรับ</span>
                                    <span>{formatNumber(income!)} บาท</span>
                                </TypoContainer>
                            </Col>
                            <Divider type="vertical" />
                            <Col span={10}>
                                <Column>
                                    <TypoContainer>
                                        <span>รายจ่าย</span>
                                        <span>{formatNumber(expenses!)} บาท</span>
                                    </TypoContainer>
                                </Column>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Spin />
                )}
            </AntCard>
            <DrawerDisplay isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
                <AreaGraph />
            </DrawerDisplay>
        </>
    );
};

export default StatisticIncomeCard;
