import axios from "axios";
import { IncomeExpensesTable } from "../interfaces/IncomesAndExpenses";

export const getIncomesAndExpensesData = async (dateRange: number[]) => {
    const res = await axios.get(`/ieAccount?startDate=${dateRange[0]}&endDate=${dateRange[1]}`);
    return res.data as IncomeExpensesTable[];
};

export const postIncomesAndExpensesData = async (
    data: Omit<IncomeExpensesTable, "id" | "date">
) => {
    const res = await axios.post("/ieAccount", data);
    return res.data as IncomeExpensesTable;
};
