import { useState, useEffect } from "react";
import liff from "@line/liff";
import { postRegisterLine } from "../../apis/registerLine";
import { Spin } from "antd";
import styled from "styled-components";

const ContainerSpin = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RegisterLine = () => {
    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(true);

    const LineLoginGetToken = async () => {
        const params = new URLSearchParams(window.location.search);

        await liff.init({ liffId: "1657032114-4MEKoWzp" });

        if (liff.isLoggedIn()) {
            const res = await postRegisterLine({
                boothCode: params.get("boothNumber") || "",
                accessToken: liff.getAccessToken() || "",
            });
            setDisplay(true);
            if (res.error) {
                alert(res.error);
            }
            if (res.success) {
                window.location.href =
                    "https://liff.line.me/1645278921-kWRPP32q/?accountId=983zulrk";
            }
        } else {
            try {
                liff.login({
                    redirectUri: "https://liff.line.me/1645278921-kWRPP32q/?accountId=983zulrk",
                });
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    useEffect(() => {
        LineLoginGetToken().finally(() => setLoading(false));
    }, []);

    return (
        <ContainerSpin>
            {display && (
                <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=983zulrk">link</a>
            )}
            {loading && <Spin />}
        </ContainerSpin>
    );
};

export default RegisterLine;
