import React from "react";
import { Table, Col, Row, Button, Modal, Image } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { BellOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
    getQRRegisterLine,
    getExecutiveLine,
    deleteUserExecutiveLine,
} from "../../../apis/registerLine";
import { IExecutiveLine } from "../../../interfaces/Line";
import Swal from "sweetalert2";
import * as swlConfig from "../../../assets/sweetalert.config";
import { confirmation } from "../../../assets/sweetalert.config";
import Logo from "../../../assets/images/smt-logo.png";

const QrCodeLineRegister = () => {
    const columns: ColumnsType<IExecutiveLine> = [
        {
            title: "รูปโปรไฟล์",
            dataIndex: "pictureUrl",
            key: "pictureUrl",
            render: (image: string) => (
                <Image
                    src={image}
                    preview={false}
                    width={60}
                    height={60}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = Logo;
                    }}
                />
            ),
        },
        {
            title: "ชื่อ",
            dataIndex: "displayName",
            key: "displayName",
            render: (_: string, record: any) => (
                <>
                    <p>{record.displayName}</p>
                    <p>{record.statusMessage}</p>
                </>
            ),
        },
        {
            title: "เพิ่มเติม",
            key: "more",
            dataIndex: "more",
            render: (_: string, record: IExecutiveLine) => (
                <>
                    <DeleteOutlined onClick={(e) => deleteUserLine(_, record)} />
                </>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [imageQR, setImageQR] = React.useState<string>("");
    const [data, setData] = React.useState<IExecutiveLine[]>([]);

    const onGetQRRegisterLine = React.useCallback(async () => {
        try {
            setImageQR((await getQRRegisterLine("executive")).qrCode);
        } catch (error) {
            console.log("error", error);
        }
        setIsModalVisible(true);
    }, []);

    const handleClose = () => {
        fetchData();
        setIsModalVisible(false);
    };
    const removeDuplicateObjects = (arr: IExecutiveLine[]) => {
        const uniqueObjects = new Set();
        return arr.filter((obj) => {
            if (uniqueObjects.has(obj.pictureUrl)) {
                return false;
            } else {
                uniqueObjects.add(obj.pictureUrl);
                return true;
            }
        });
    };

    const fetchData = React.useCallback(async () => {
        try {
            const data: IExecutiveLine[] = await getExecutiveLine();
            setData(removeDuplicateObjects(data));
        } catch (error) {
            console.log("error", error);
        }
    }, []);

    const deleteUserLine = React.useCallback(
        async (_, record: IExecutiveLine) => {
            const { isConfirmed } = await Swal.fire(
                swlConfig.positiveConfirmation({
                    title: `ยืนยันการลบ ${record.displayName}`,
                    text: `กรุณายืนยันเพื่อลบไลน์ของ ${record.displayName}`,
                })
            );
            if (isConfirmed) {
                try {
                    const res = await deleteUserExecutiveLine(record.docId);
                    if (res.success) {
                        await Swal.fire({
                            ...confirmation({
                                title: `ลบไลน์ ${record.displayName} สำเร็จ`,
                                text: `${record.displayName} ไม่ได้รับการแจ้งเตือนอีก`,
                                icon: "success",
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1500,
                            }),
                        });
                        fetchData();
                    }
                } catch (error) {
                    console.log("error", error);
                }
            }
        },
        [fetchData]
    );

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Container>
            <Row justify="space-between">
                <h2>การรับการแจ้งเตือนผ่านไลน์ ของผู้บริหาร</h2>
                <Button type="primary" icon={<BellOutlined />} onClick={onGetQRRegisterLine}>
                    รับการแจ้งเตือน
                </Button>
            </Row>
            <Table columns={columns} dataSource={data} rowKey={(record) => record.docId} />
            <ModalCustom
                title="QR code รับการแจ้งเตือนจาก LINE"
                visible={isModalVisible}
                onOk={handleClose}
                onCancel={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <>
                    <Image src={imageQR} preview={false} />
                    <p>สแกนเพื่อรับข่าวสารและรายการประจำวันของผู้บริหาร</p>
                </>
            </ModalCustom>
        </Container>
    );
};

export const ModalCustom = styled(Modal)`
    .ant-modal-body {
        text-align: center !important;
    }
`;
export const Container = styled(Col)`
    p {
        margin: 0;
    }
`;
export default QrCodeLineRegister;
