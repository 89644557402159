import React from "react";
import { ContainerTable, TextBuilding, IconBuilding } from "./index.style";
import { Table, Tabs } from "antd";
import Building from "../../../../src/assets/icons/building.png";
import Market from "../../../../src/assets/icons/market.png";
import Lomsak from '../../../../src/assets/icons/lomsak.png'
import Shirt from '../../../../src/assets/icons/shirtIcon.png'

import { RightOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ZoneBuilding } from "../../../interfaces/Register";
import { typeTransaction } from "../../../assets/transaction";

interface DataType {
    key: React.Key;
    building: string;
    rent: number;
    available: number;
}

type CardBuildingSelectorProps = {
    type?: string;
    data: {
        key: string;
        building: string;
        rent: number | any;
        available: number | any;
        boothLayout?: string;
    }[];
    onSelect: (building: string) => void;
    buildingSelect: string;
};

const typeHandlerIcon = (type?: string) => {
    if (type === "booth") return Building;
    if (type === "lomsak") return Lomsak;
    if (type === "rider" || type === "labor") return Shirt;
    return Market;
};

const CardBuildingSelector: React.FC<CardBuildingSelectorProps> = (props) => {
    const { type, data, onSelect, buildingSelect } = props;
    const { lg } = useBreakpoint();
    const { TabPane } = Tabs;

    return (
        <>
            {lg ? (
                <ContainerTable>
                    <Table
                        scroll={{ y: 300 }}
                        onRow={(record: DataType) => {
                            return {
                                onClick: () => {
                                    onSelect(record.building);
                                },
                            };
                        }}
                        rowClassName={(record) =>
                            record.building === buildingSelect ? "selected" : ""
                        }
                        dataSource={data}
                        pagination={false}
                        size="middle"
                    >
                        <Table.Column
                            title="อาคาร"
                            dataIndex="building"
                            key="building"
                            render={(building) => (
                                <div>
                                    {type ? (
                                        <IconBuilding
                                            src={typeHandlerIcon(type)}
                                            width={30}
                                            preview={false}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <TextBuilding>{building}</TextBuilding>
                                </div>
                            )}
                        />

                        <Table.Column
                            title="เช่า"
                            dataIndex="rent"
                            key="rent"
                            render={(rent) => rent}
                        />

                        <Table.Column
                            title="ว่าง"
                            dataIndex="available"
                            key="available"
                            render={(available) => (
                                <div>
                                    <p> {available}</p>

                                    <RightOutlined />
                                </div>
                            )}
                        />
                    </Table>
                </ContainerTable>
            ) : (
                <Tabs
                    defaultActiveKey={buildingSelect}
                    onChange={(key) => {
                        onSelect(key);
                    }}
                >
                    {data.map(({ building, rent, available }, index) => (
                        <TabPane tab={building} key={building}>
                            <p>จำนวนแผงที่เช่า &emsp; {rent}</p>
                            <p>จำนวนแผงที่ว่าง &emsp; {available}</p>
                        </TabPane>
                    ))}
                </Tabs>
            )}
        </>
    );
};

export default CardBuildingSelector;

type CardBuildingSelectorArrayProps = {
    type?: string;
    data: ZoneBuilding[];
    onSelect: (buildingName: string, buildingNumber: number) => void;
    buildingSelect: string;
    pageType: typeTransaction;
};

export const CardBuildingSelectorArray: React.FC<CardBuildingSelectorArrayProps> = (props) => {
    const { type, data, onSelect, buildingSelect, pageType } = props;
    const { lg } = useBreakpoint();
    const { TabPane } = Tabs;

    const columns = [
        {
            title: "อาคาร",
            dataIndex: "buildingName",
            render: (record: string) => (
                <div>
                    {type ? (
                        <IconBuilding
                            src={typeHandlerIcon(type)}
                            width={30}
                            preview={false}
                        />
                    ) : (
                        <></>
                    )}
                    <TextBuilding> {record}</TextBuilding>
                </div>
            ),
        },
        {
            title: "จำนวนแผง",
            dataIndex: ["booth", "veggie", "lomsak", "rider", "labor"],
            render: (_: string, record: any, __: number) => (
                <div>
                    <p> {record[pageType]}</p>
                    <RightOutlined />
                </div>
            ),
        },
    ];

    return (
        <>
            {lg ? (
                <ContainerTable>
                    <Table
                        scroll={{ y: 300 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    onSelect(record.buildingName, record.buildingNumber);
                                },
                            };
                        }}
                        rowClassName={(record) =>
                            record.buildingName === buildingSelect ? "selected" : ""
                        }
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        size="middle"
                    />
                </ContainerTable>
            ) : (
                <Tabs
                    defaultActiveKey={buildingSelect}
                    onChange={(key) => onSelect(key.split(":")[0], Number(key.split(":")[1]))}
                >
                    {data?.map(({ buildingName, buildingNumber }) => (
                        <TabPane tab={buildingName} key={`${buildingName}:${buildingNumber}`}>
                            <p>จำนวนทั้งหมด &emsp; {data[pageType as any]}</p>
                        </TabPane>
                    ))}
                </Tabs>
            )}
        </>
    );
};
