import { Form } from "antd";
import React from "react";
import { editBankInfo, getBankInfo } from "../../../apis/priceManagement";
import {
    editOtherAlert,
    errorAlert,
    successAlert,
} from "../../../components/PriceManagementPage/alert";
import { catchingError } from "../../../functions/notification";
import { uploadImageReturnUrl } from "../../../functions/storage";

function useOtherManagement() {
    const [form] = Form.useForm();
    const [file, setFile] = React.useState<string>();
    const [preview, setPreview] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({});
    const [previewOld, setPreviewOld] = React.useState<boolean>(false);

    const blobToBase64 = (blob: Blob) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await getBankInfo();
            setData(res);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const onResetForm = (key: string | string[]) => {
        form.resetFields();
        if (key === "1") {
            Object.keys(data).forEach((key) => {
                if (key !== "qrCode") {
                    form.setFieldsValue({ [key]: data[key] });
                }
            });
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filterValues = (values: any, data: any) => {
        const payload = {} as any;
        Object.keys(values).forEach((key) => {
            if (key === "qrCode") {
                if (values[key]) payload[key] = true;
            } else if (data[key] !== values[key]) {
                payload[key] = values[key];
            }
        });
        return payload;
    };

    const onFinish = React.useCallback(
        async (values: any) => {
            const filterV = filterValues(values, data);
            if (Object.keys(filterV).length === 0) return errorAlert();
            const result = await editOtherAlert(filterV);
            if (result) {
                setIsLoading(true);
                try {
                    let qrCodeurl;
                    if (values["qrCode"]) {
                        const fileData = values["qrCode"].file.originFileObj;
                        const url = await uploadImageReturnUrl("banking", [fileData]);
                        qrCodeurl = url[0];
                    } else {
                        qrCodeurl = data.qrCode;
                    }

                    const payload = {
                        ...values,
                        qrCode: qrCodeurl,
                    };
                    await editBankInfo(payload);
                    await successAlert("แก้ไขข้อมูล");
                } catch (error) {
                    catchingError(error);
                } finally {
                    setIsLoading(false);
                    await fetchData();
                }
            }
        },
        [data, fetchData]
    );

    return {
        form,
        onResetForm,
        file,
        setFile,
        blobToBase64,
        preview,
        setPreview,
        isLoading,
        data,
        onFinish,
        previewOld,
        setPreviewOld,
    };
}

export default useOtherManagement;
