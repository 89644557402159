import { Button } from "antd";
import styled from "styled-components";

export const RowFooter = styled.div`
    display: flex;
    justify-content: center;
`;

export const CancelButton = styled(Button)`
    border-style: hidden;
    background-color: #ececec;
    border-radius: 2px;
`;

export const ConfirmButton = styled(Button)`
    border-style: hidden;
    background-color: #51a728;
    color: #fff;
    border-radius: 2px;
`;
