import { Badge, Container } from "./index.styles";
import colors from "../../../assets/colors.json";

export type IColor = {
    status: "active" | "inactive" | "onHold";
    size: string;
    marginRight?: string;
};

export const convertToColor = (status: IColor["status"]) => {
    switch (status) {
        case "active":
            return colors.primary;
        case "onHold":
            return colors.warning;
        case "inactive":
            return colors.hintText;
    }
};

function BadgeStatus(props: IColor) {
    const { status, size, marginRight } = props;
    return (
        <Container>
            <Badge status={status} size={size} marginRight={marginRight} />
        </Container>
    );
}

export default BadgeStatus;
