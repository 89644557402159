import styled from "styled-components";
import { Col, Modal, Row } from "antd";
import colors from "../../../assets/colors.json";

export const Container = styled(Col)`
    .mt-1 {
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 0 !important;
    }
    .ant-collapse-header {
        margin-bottom: -0.8rem;
    }
    .ant-tag.ant-tag-success,
    .ant-tag.ant-tag-warning {
        margin-left: 0.8rem;
    }

    p.description {
        color: #727272;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .amount {
            font-size: 1rem;
        }
    }
    p {
        &.standby {
            color: ${colors.primary};
        }
        &.pending {
            color: ${colors.warning};
        }
        &.request {
            color: ${colors.error};
        }
    }
`;

export const ModalCustom = styled(Modal)`
    .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0;
        &.warning-word {
            ::before {
                content: "*";
                color: ${colors.warning};
            }
        }
    }
`;

export const TransactionList = styled(Col)`
    p {
        margin: 0;
        :last-child {
            color: #727272;
        }
    }
`;

export const ContainerCollapse = styled.div`
    padding: 0 0.2rem;

    @media (min-width: 425px) {
        max-height: 33.6vh;
        overflow-y: auto;
    }
`;

export const PrintContent = styled(Row)`
    width: 100%;
    padding: 10px 20px;
    .ant-col-24 {
        text-align: center;
        h4 {
            margin-top: 18px;
        }
    }
    .ant-col-8 {
        padding: 8px 0;
    }
    .ant-col-16 {
        padding: 8px 0;
        text-align: end;
    }
`;
