import { Row, Spin } from "antd";
import { Jeep, Truck } from "phosphor-react";
import React from "react";
import { formatNumber } from "../../../functions/formatNumber";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import BarGraph from "../BarGraph";
import DrawerDisplay from "../DrawerDisplay";
import { formatDiffStatus, renderOther, renderValue, statOption } from "../utility/mapping";
import {
    AntCard,
    CardTitle,
    LogoContainer,
    Paragraph,
    RowTitleCard,
    StatisticItem,
    TypoContainer,
} from "./index.styles";

type StatisticLomsakCardProps = {};
const StatisticLomsakCard: React.FC<StatisticLomsakCardProps> = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { isLoading, summaryCard } = useDashboardProvider();

    const { totalLomsak, diffLomsak } = React.useMemo(() => {
        if (!summaryCard) return { totalLomsak: 0, diffLomsak: 0 };
        return {
            totalLomsak: summaryCard["lomsak"].total,
            diffLomsak: summaryCard["lomsak"].diff,
        };
    }, [summaryCard]);

    const { totalParking, diffParking } = React.useMemo(() => {
        if (!summaryCard) return { totalParking: 0, diffParking: 0 };
        return {
            totalParking: summaryCard["parking"].total,
            diffParking: summaryCard["parking"].diff,
        };
    }, [summaryCard]);

    const { symbolLomsak, symbolParking } = React.useMemo(() => {
        return {
            symbolLomsak: statOption[formatDiffStatus(diffLomsak)].symbol,
            symbolParking: statOption[formatDiffStatus(diffParking)].symbol,
        };
    }, [diffLomsak, diffParking]);

    return (
        <>
            <AntCard
                title={<CardTitle>จำนวนเงินที่ชำระแล้ว</CardTitle>}
                extra={
                    <CardTitle color="subtitle" onClick={() => setIsOpen(!isOpen)}>
                        ดูข้อมูลเชิงลึก
                    </CardTitle>
                }
            >
                {!isLoading && summaryCard && summaryCard["lomsak"] && summaryCard["parking"] ? (
                    <>
                        <Row justify="space-between" align="middle">
                            <RowTitleCard>
                                <LogoContainer>
                                    <Truck size={26} />
                                </LogoContainer>
                                <Paragraph>รถหล่ม</Paragraph>
                            </RowTitleCard>
                            <StatisticItem
                                value={Math.abs(diffLomsak)}
                                prefix={<span>{symbolLomsak}</span>}
                                color={formatDiffStatus(diffLomsak)}
                            />
                        </Row>
                        <TypoContainer>
                            <span>{renderValue(summaryCard["lomsak"])}</span>
                            <span>/ {formatNumber(totalLomsak!)} บาท</span>
                        </TypoContainer>
                        <Row justify="space-between" align="middle" style={{ marginTop: "0.8rem" }}>
                            <RowTitleCard>
                                <LogoContainer>
                                    <Jeep size={26} />
                                </LogoContainer>
                                <Paragraph>ที่จอดรถ</Paragraph>
                            </RowTitleCard>
                            <StatisticItem
                                value={Math.abs(diffParking)}
                                prefix={<span>{symbolParking}</span>}
                                color={formatDiffStatus(diffParking)}
                            />
                        </Row>
                        <TypoContainer>
                            <span>{renderValue(summaryCard["parking"])}</span>
                            <span>/ {formatNumber(totalParking!)} บาท</span>
                        </TypoContainer>
                    </>
                ) : (
                    <Spin />
                )}
            </AntCard>
            <DrawerDisplay isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
                <BarGraph type="car" optionGraph={renderOther["car"].legendGraph} />
            </DrawerDisplay>
        </>
    );
};

export default StatisticLomsakCard;
