import { Button, Row, Form, notification, Avatar, Select, Switch } from "antd";
import React from "react";
import { Container, RowGroupInput } from "../../../common/CustomForm";
import MemberForm from "../../common/MemberForm";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { antdFileToBase64 } from "../../../../functions/files";
import { CustomUpload } from "./index.style";
import validator from "../../../../assets/formValidator";
import useRegister from "../../../../pages/Register/useRegister";

const RegisterMonthlyTenantForm: React.FC<ReturnType<typeof useRegister>> = ({
    nextStep,
    tenantForm,
    setTenantForm,
}) => {
    const [imageBase64, setImageBase64] = React.useState(tenantForm?.imageBase64);
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [involvedCheck, setInvolvedCheck] = React.useState<boolean>(true);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        setTenantForm({ ...values, imageBase64 });
        nextStep();
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        }
        if (info.file.status === "error" || info.file.status === "done") {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Container>
            <Form form={form} initialValues={tenantForm} onFinish={onFinish}>
                {/* Form ของผู้เช้าแผง  */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เช่า</h2>
                </Row>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="รูปผู้เช่า"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={onChangeImage}
                        customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                    >
                        {imageBase64 ? (
                            <Avatar
                                src={imageBase64}
                                alt="avatar"
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </CustomUpload>
                </Row>
                <MemberForm numMembers={1} memIndex={0} key={0} form={form} isRequireSSN />
                <hr />
                {/* Form ของ ผู้เกี่ยวข้อง */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เกี่ยวข้อง</h2>
                </Row>
                <Row justify="space-between">
                    <h3>มีรายชื่อผู้เกี่ยวข้อง</h3>
                    <Switch
                        defaultChecked
                        size="small"
                        onChange={(val) => {
                            setInvolvedCheck(val);
                            form.setFieldsValue({
                                involvedPrefix: undefined,
                                involvedFirstName: undefined,
                                involvedLastName: undefined,
                                involvedPhoneNumber: undefined,
                            });
                        }}
                    />
                </Row>
                {involvedCheck && (
                    <RowGroupInput
                        inputs={[
                            {
                                name: `involvedPrefix`,
                                label: "คำนำหน้าชื่อ",
                                customInput: (
                                    <Select placeholder="คำนำหน้าชื่อ">
                                        <Select.Option children="นาย" key="นาย" value="นาย" />
                                        <Select.Option children="นาง" key="นาง" value="นาง" />
                                        <Select.Option
                                            children="นางสาว"
                                            key="นางสาว"
                                            value="นางสาว"
                                        />
                                    </Select>
                                ),
                                flex: 0.25,
                                rules: [{ required: true, message: "กรุณาเลือกคำนำหน้าชื่อ" }],
                            },
                            {
                                label: "ชื่อ",
                                name: "involvedFirstName",
                                placeholder: "กรุณากรอกชื่อ",
                                rules: [
                                    { required: true, message: "กรุณากรอกชื่อ" },
                                    ...validator.firstName,
                                ],
                            },
                            {
                                label: "นามสกุล",
                                name: "involvedLastName",
                                placeholder: "กรุณากรอกนามสกุล",
                                rules: [
                                    { required: true, message: "กรุณากรอกนามสกุล" },
                                    ...validator.lastName,
                                ],
                            },
                        ]}
                    />
                )}
                {involvedCheck && (
                    <RowGroupInput
                        inputs={[
                            {
                                label: "เบอร์โทรศัพท์",
                                name: "involvedPhoneNumber",
                                rules: [
                                    {
                                        message: "กรุณากรอกเบอร์โทรศัพท์",
                                    },
                                    ...validator.phoneNumber,
                                ],
                                placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                            },
                        ]}
                    />
                )}
                {/* Footer */}
                <Row justify="space-between">
                    <Button disabled>ย้อนกลับ</Button>
                    <Button type="primary" htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegisterMonthlyTenantForm;
