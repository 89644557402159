import { Container, Modal } from "./index.styles";
import VaccineTimeline from "../ModalComponents/VaccineTimeline";
import FooterModal from "../ModalComponents/FooterModal";
import TitleModal from "../ModalComponents/TitleModal";
import FormContent, { FormInstanceType } from "../ModalComponents/FormContent";
import PersonRelated from "../ModalComponents/PersonInvolved";
import { IContractResponse } from "../PartnerContractTable/useContractTable";
import { IBoothMember } from "../../../interfaces/Contract";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { FormInstance } from "antd";

type IModal = {
    form: FormInstance<any>;
    visible: boolean;
    visibleInner: boolean;
    onCancel: () => void;
    onOk: () => void;
    onEdit: () => void;
    onCancelEdit: () => void;
    editable: boolean;
    onPrintData: () => void;
    record: IContractResponse | IBoothMember;
    type: FormInstanceType;
};

function ContractInformationModal(props: IModal) {
    const {
        form,
        visible,
        visibleInner,
        onCancel,
        onOk,
        record,
        editable,
        type,
        onEdit,
        onCancelEdit,
        onPrintData,
    } = props;

    const includeItem: FormInstanceType[] = ["booth"];
    const { xs } = useBreakpoint();

    return (
        <Modal
            centered
            closable={false}
            visible={visible || visibleInner}
            onCancel={onCancel}
            onOk={onOk}
            width={xs ? 450 : 550}
            title={
                <TitleModal
                    type={visibleInner ? "inner" : type}
                    record={record}
                    isEdit={editable}
                    onPrintData={onPrintData}
                />
            }
            footer={
                <FooterModal
                    onOk={onOk}
                    onClose={onCancel}
                    onEdit={onOk}
                    onCancelEdit={onCancelEdit}
                    edit={editable}
                />
            }
        >
            <Container>
                <FormContent
                    form={form}
                    type={!visibleInner ? type : "inner"}
                    editState={editable}
                    isCompany={record?.companyID || record?.companyName}
                />
                {!includeItem.includes(type) && (
                    <PersonRelated edit={editable} person={record?.involved} />
                )}
                {!record?.companyID && !record?.companyName && (
                    <VaccineTimeline vaccine={record?.vaccine} onEdit={onEdit} edit={editable} />
                )}
            </Container>
        </Modal>
    );
}

export default ContractInformationModal;
