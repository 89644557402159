import { Card, Col, Row, Statistic, Typography } from "antd";
import styled from "styled-components";
import colors from "../../../assets/colors.json";

import { colorsMap, StatColor } from "../utility/mapping";

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    background-color: ${colors.memberDetailWorkColor.mainDuty};
    width: fit-content;
    border-radius: 50%;
`;

export const AntCard = styled(Card).attrs({
    bordered: false,
})`
    &.ant-card {
        border-radius: 0.5rem;
    }
    & .ant-card-head {
        border-bottom: none;
    }
    & .ant-card-body {
        padding-top: 0;
        margin-top: auto;
    }
    height: 100%;
`;

export const StatisticItem = styled(Statistic).attrs({
    precision: 2,
    suffix: "%",
})`
    & .ant-statistic-content {
        font-size: 0.8rem;
        color: ${(props: { color: StatColor }) => colorsMap[props.color]};
    }
    & .ant-statistic-content-prefix {
        margin-right: 0;
        svg {
            align-self: center;
        }
    }
`;

export const RowTitleCard = styled(Row).attrs({
    justify: "start",
    align: "middle",
})`
    &.ant-row {
        gap: 0.5rem !important;
    }
`;

export const Paragraph = styled(Typography.Paragraph).attrs({
    strong: true,
})`
    font-size: 1rem !important;
    margin-bottom: 0 !important;
`;

export const CardTitle = styled(Typography.Paragraph).attrs({
    strong: true,
})`
    font-size: 0.7rem !important;
    margin-bottom: 0 !important;
    color: ${(props: { color?: "subtitle" }) =>
        props.color ? colorsMap[props.color] : "black"} !important;
    ${(props) => props.color && `cursor: pointer;`}
`;

export const Column = styled(Col)`
    &.ant-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const TypoContainer = styled.div`
    margin-top: 0.3rem;
    & span:first-child {
        font-size: 1.1rem;
        font-weight: bold;
    }
    & span:last-child {
        font-size: 0.8rem;
        color: ${colors.hintText};
    }
`;
