import { Input, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    height: 100%;
    padding-bottom: 0;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 40vh;
    padding-bottom: 1rem;
    overflow-y: auto;
    justify-content: flex-start;
    width: 100%;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 1rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const CardEmpty = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 40vh;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
`;

export const InputSearch = styled(Input.Search)`
    margin: 0.5rem 0;
    width: 50%;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    margin-top: 1rem;
`;

export const TagStatus = styled.div`
    width: 16px;
    height: 16px;
    margin-top: 0.15rem;
    border-radius: 50%;
    background-color: ${(props: { color: string }) => props.color};
`;

export const RowContainer = styled.div`
    display: flex;
    gap: 1rem;
`;
export const Paragraph = styled(Typography.Paragraph)`
    margin: 0;
    font-size: 0.9rem;
`;
