import { PickerProps } from "antd/lib/date-picker/generatePicker";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import dayjs, { Dayjs } from "dayjs";
import { CurrencyCircleDollar } from "phosphor-react";
import React from "react";
import FilterWithDatePicker from "../FilterWithDatePicker";
import { defaultOptions } from "./config";
import { AntCard, LogoContainer, RowTitleCard, Title } from "./index.styles";
import Chart from "react-apexcharts";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import { Spin } from "antd";

type AreaGraphProps = {};
const AreaGraph: React.FC<AreaGraphProps> = () => {
    const optionFilter = ["month", "year"] as PickerProps<Dayjs>["picker"][];
    const [value, setValue] = React.useState<PickerProps<Dayjs>["picker"]>(
        optionFilter[0] as PickerProps<Dayjs>["picker"]
    );
    const [date, setDate] = React.useState<Dayjs | null>(dayjs());
    const { isLoadingComponent, fetchGraphComponent, graphData } = useDashboardProvider();
    React.useEffect(() => {
        fetchGraphComponent(value!, "ieAccount", +date!);
    }, [value, date, fetchGraphComponent]);

    const { xs } = useBreakpoint();

    return (
        <AntCard
            title={
                <RowTitleCard>
                    <LogoContainer>
                        <CurrencyCircleDollar size={26} />
                    </LogoContainer>
                    <Title>รายรับ - ข้อมูลเชิงลึก</Title>
                </RowTitleCard>
            }
            extra={
                <FilterWithDatePicker
                    value={value}
                    date={date}
                    onChange={(v) => setDate(v)}
                    setFilter={(v) => setValue(v)}
                    useOption={optionFilter}
                />
            }
        >
            {!isLoadingComponent && graphData[`ieAccount-${value!}`] ? (
                <Chart
                    options={defaultOptions(value!, date!, xs)}
                    series={graphData[`ieAccount-${value!}`].sort((a: any, b: any) =>
                        a.name > b.name ? -1 : 1
                    )}
                    type="area"
                    height={350}
                />
            ) : (
                <Spin />
            )}
        </AntCard>
    );
};

export default AreaGraph;
