import styled from "styled-components";
import { Button, Input } from "antd";

export const ContainerModal = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 60vh;

    overflow-y: scroll;
`;

export const RowFooter = styled.div`
    display: flex;
    justify-content: center;
`;

export const CancleButton = styled(Button)`
    border-style: hidden;
    background-color: #ececec;
    border-radius: 2px;
`;

export const ConfirmButton = styled(Button)`
    border-style: hidden;
    background-color: #51a728;
    color: #fff;
    border-radius: 2px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #ececec;
`;
export const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: #ececec;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
`;

export const EditContainer = styled.div`
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-style: hidden;
    background-color: #ececec;
`;

export const InputCustom = styled(Input)`
    display: flex;
    columns: 50px 4;
    border-bottom-style: solid;
    border-bottom-width: thin;
`;

export const DropdownButton = styled.div`
    padding-right: 5px;
    border-style: hidden;
    background-color: #ececec;
`;
