import React from "react";
import { AmountContainer, Container, TitleContainer, LogoContainer } from "./index.style";
import DutyIcon from "../DutyIcon";

type CardDutyProps = {
    label?: string;
    content?: React.ReactNode;
    amount?: number;
    duty?: string | "booth" | "veggie" | "lomsak" | "rider" | "labor";
};

const CardDuty: React.FC<CardDutyProps> = (props) => (
    <Container>
        <TitleContainer>
            <LogoContainer>
                <DutyIcon duty={props.duty} />
            </LogoContainer>
            {props.label}
        </TitleContainer>
        <AmountContainer>
            {props.amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-"} <span>บาท</span>
        </AmountContainer>
    </Container>
);

export default CardDuty;
