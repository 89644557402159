import { IBoothMember, IVaccine } from "../../../../interfaces/Contract";
import { IContractResponse } from "../useContractTable";

type IFormValue = { [key: string]: any };
export const filterValues = (values: IFormValue, oldValue: IContractResponse | IBoothMember) => {
    const vaccine: IVaccine[] = [];
    const involved: { [key: string]: any } = {};
    const data: { [key: string]: any } = {};
    const address: { [key: string]: any } = {};

    Object.keys(values).forEach((key) => {
        if (key.includes("dateVaccine")) {
            const index = key.replace("dateVaccine", "");
            const nameVaccine = `nameVaccine${index}`;
            const dateVaccine = `dateVaccine${index}`;

            if (values[dateVaccine] !== undefined || values[nameVaccine] !== undefined) {
                vaccine.push({
                    name: values[nameVaccine] ?? "",
                    date: values[dateVaccine]?.valueOf(),
                });
            };

        }
        if (key.includes("firstNameInvolved")) {
            const firstName = `firstName`;
            const lastName = `lastName`;
            const tel = `tel`;
            involved[firstName] = values[`${firstName}Involved`];
            involved[lastName] = values[`${lastName}Involved`];
            involved[tel] = values[`${tel}Involved`];
        }
        if (key.includes("address") && !key.includes("full")) {
            const innerkey = key.replace("address-", "");
            address[innerkey] = values[key];
        }
        if (
            oldValue &&
            values[key] !== oldValue[key] &&
            !key.includes("Vaccine") &&
            !key.includes("address") &&
            !key.includes("booth") &&
            !key.includes("Involved") &&
            !key.includes("startDate") &&
            !key.includes("endDate") &&
            !key.includes("reminder")
        ) {
            data[key] = values[key];
        }
    });

    data["vaccine"] = vaccine;

    const keyChange: any[] = [];
    const involvedKey: any[] = [];

    Object.keys(involved).forEach((key) => {
        if (oldValue && involved[key] !== oldValue.involved[key]) {
            involvedKey.push(key);
        }
    });

    Object.keys(address).forEach((key) => {
        if (oldValue && address[key] !== oldValue.address[key]) {
            keyChange.push(key);
        }
    });

    involved["change"] = involvedKey;
    address["change"] = keyChange;

    if (keyChange.length > 0) data["address"] = address;
    if (involvedKey.length > 0) data["involved"] = involved;

    return data;
};

export const compareVaccines = (oldVac: IVaccine[], newVac: IVaccine[]) => {
    const compareVac: IVaccine[] = [];
    let arr: IVaccine[], compareTo: IVaccine[], tag: string;
    if (oldVac.length > newVac.length) {
        arr = [...oldVac];
        compareTo = [...newVac];
        tag = "old";
    } else if (oldVac.length < newVac.length) {
        arr = [...newVac];
        compareTo = [...oldVac];
        tag = "new";
    } else {
        arr = [...oldVac];
        compareTo = [...newVac];
        tag = "old";
    }
    arr.forEach((vac: IVaccine, index: number) => {
        if (!compareTo[index]) {
            let state: string;
            if (tag === "old") {
                state = "delete";
            } else {
                state = "add";
            }
            compareVac.push({
                date: vac.date,
                name: vac.name,
                index: index,
                state,
            });
        } else if (vac.date === compareTo[index].date && vac.name === compareTo[index].name) {
            if (tag === "old") {
                compareVac.push({
                    date: compareTo[index].date,
                    name: compareTo[index].name,
                    index: index,
                    state: "no change",
                });
            } else {
                compareVac.push({
                    date: vac.date,
                    name: vac.name,
                    index: index,
                    state: "no change",
                });
            }
        } else if (vac.date !== compareTo[index].date && vac.name === compareTo[index].name) {
            if (tag === "old") {
                compareVac.push({
                    date: compareTo[index].date,
                    name: compareTo[index].name,
                    oldDate: vac.date,
                    oldName: vac.name,
                    index: index,
                    state: "change date",
                });
            } else {
                compareVac.push({
                    date: vac.date,
                    name: vac.name,
                    oldDate: compareTo[index].date,
                    oldName: compareTo[index].name,
                    index: index,
                    state: "change date",
                });
            }
        } else if (vac.date === compareTo[index].date && vac.name !== compareTo[index].name) {
            if (tag === "old") {
                compareVac.push({
                    date: compareTo[index].date,
                    name: compareTo[index].name,
                    oldDate: vac.date,
                    oldName: vac.name,
                    index: index,
                    state: "change name",
                });
            } else {
                compareVac.push({
                    date: vac.date,
                    name: vac.name,
                    oldDate: compareTo[index].date,
                    oldName: compareTo[index].name,
                    index: index,
                    state: "change name",
                });
            }
        } else if (vac.date !== compareTo[index].date && vac.name !== compareTo[index].name) {
            if (tag === "old") {
                compareVac.push({
                    date: compareTo[index].date,
                    name: compareTo[index].name,
                    oldDate: vac.date,
                    oldName: vac.name,
                    index: index,
                    state: "change date&name",
                });
            } else {
                compareVac.push({
                    date: vac.date,
                    name: vac.name,
                    oldDate: compareTo[index].date,
                    oldName: compareTo[index].name,
                    index: index,
                    state: "change date&name",
                });
            }
        } else {
            if (tag === "old") {
                compareVac.push({
                    date: compareTo[index].date,
                    name: compareTo[index].name,
                    oldDate: vac.date,
                    oldName: vac.name,
                    index: index,
                    state: "add",
                });
            } else {
                compareVac.push({
                    date: vac.date,
                    name: vac.name,
                    oldDate: compareTo[index].date,
                    oldName: compareTo[index].name,
                    index: index,
                    state: "add",
                });
            }
        }
    });
    return compareVac;
};
