import { PresetColorType, PresetStatusColorType } from "antd/lib/_util/colors";
import { LiteralUnion } from "react-hook-form";

export const mappingWord: {
    [key: string]: {
        word: string;
        color: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
    };
} = {
    booth: {
        word: "แผงรายเดือน",
        color: "magenta",
    },
    boothWater: {
        word: "ค่านํ้าแผงรายเดือน",
        color: "cyan",
    },
    boothElectricity: {
        word: "ค่าไฟแผงรายเดือน",
        color: "gold",
    },
    boothShirt: {
        word: "ค่าเสื้อแผง",
        color: "magenta",
    },
    boothParking: {
        word: "ที่จอดรถแผงรายเดือน",
        color: "pink",
    },
    boothOther: {
        word: "แผงรายเดือน อื่นๆ",
        color: "lime",
    },
    veggie: {
        word: "แผงผักบ้าน",
        color: "magenta",
    },
    veggieWater: {
        word: "ค่านํ้าแผงผักบ้าน",
        color: "cyan",
    },
    veggieElectricity: {
        word: "ค่าไฟแผงผักบ้าน",
        color: "gold",
    },
    veggieParking: {
        word: "ที่จอดรถแผงผักบ้าน",
        color: "pink",
    },
    veggieOther: {
        word: "แผงผักบ้าน อื่นๆ",
        color: "lime",
    },
    labor: {
        word: "เสื้อขึ้นของ",
        color: "orange",
    },
    laborOther: {
        word: "เสื้อขึ้นของ อื่นๆ",
        color: "lime",
    },
    rider: {
        word: "เสื้อวิน",
        color: "orange",
    },
    riderOther: {
        word: "เสื้อวิน อื่นๆ",
        color: "lime",
    },
    lomsak: {
        word: "รถหล่ม",
        color: "magenta",
    },
    lomsakOther: {
        word: "รถหล่ม อื่นๆ",
        color: "lime",
    },
    lomsakWater: {
        word: "ค่านํ้ารถหล่ม",
        color: "cyan",
    },
    lomsakElectricity: {
        word: "ค่าไฟรถหล่ม",
        color: "gold",
    },
    other: {
        word: "อื่นๆ",
        color: "lime",
    },
    nonRegisterCar: {
        word: "รถที่ไม่ลงทะเบียน",
        color: "geekblue",
    },
};
