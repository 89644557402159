import { Form, InputNumber, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { postBilling } from "../../../../apis/transaction";
import { RolePagesType } from "../../../../assets/typeEnumTranslation";
import { catchingError } from "../../../../functions/notification";
import { IAddBilling } from "../../../../interfaces/Billing";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { onAddAlert, successAlert } from "../alert";

type AddItemModalProps = {
    visible: boolean;
    boothCode: string;
    onCancel: () => void;
    fetchData: () => void;
    loading: () => void;
    type: RolePagesType;
};
const AddItemModal: React.FC<AddItemModalProps> = (props: AddItemModalProps) => {
    const { visible, onCancel, fetchData, loading, boothCode, type } = props;
    const [form] = Form.useForm();
    const { user } = useAuthContext();

    const onFinish = async (values: any) => {
        const result = await onAddAlert();
        if (result) {
            loading();
            try {
                const body: IAddBilling = {
                    ...values,
                    boothCode,
                    type: type,
                    subType: `${type}Other`,
                    date: +dayjs(),
                    billerName: user!.name,
                    billerId: user!.uid,
                };
                await postBilling(body);
                onCancel();
                form.resetFields();
                await successAlert("สำเร็จ", "บันทึกรายการเรียบร้อย");
                await fetchData();
            } catch (error) {
                catchingError(error);
            } finally {
                loading();
            }
        }
    };

    return (
        <Modal
            centered
            visible={visible}
            onOk={() => form.submit()}
            onCancel={onCancel}
            title="เพิ่มรายการอื่นๆ"
        >
            <Form form={form} onFinish={onFinish}>
                <FormItem
                    label="ชื่อรายการ"
                    name="description"
                    rules={[{ required: true, message: "กรุณากรอกชื่อรายการ" }]}
                >
                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={75} />
                </FormItem>
                <FormItem
                    label="จำนวน"
                    name="totalDebt"
                    rules={[
                        {
                            required: true,
                            type: "number",
                            min: 0,
                            message: `กรุณากรอกจำนวนเงินที่เป็นตัวเลข`,
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        addonAfter="บาท"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                    />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default AddItemModal;

export const FormItem = styled(Form.Item)`
    display: flex;
    flex-direction: column;
    & .ant-form-item-label {
        width: fit-content;
        > label {
            &::after {
                content: "";
            }
        }
    }
    & .ant-form-item-required {
        &::before {
            content: "" !important;
        }
        &::after {
            content: "*" !important;
            color: red;
            margin-left: 0.25rem;
        }
    }
    & .ant-input {
        border-radius: 4px;
        overflow: hidden;
    }
`;
