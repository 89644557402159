import React from "react";
import { Modal, Form, Row, Col, Input, Button, Spin, InputNumber, Divider } from "antd";
import { getMeterByBooth, getDetailByBooth, postMeter } from "../../../apis/meter";
import { ButtonHeader, HeaderMeter, DetailBox, DetailText, LabelDetail } from "./index.styles";
import { RowGroupInput } from "../../../components/common/CustomForm";
import { IBooth } from "../../../interfaces/Contract";
import { IPostUtilityRequest } from "../../../interfaces/Meter";
import * as swlConfig from "../../../assets/sweetalert.config";
import { useAuthContext } from "../../../providers/AuthProvider";
import HistoryTable from "./HistoryTable";
import Swal from "sweetalert2";
import ResetModalMeter from "./ResetModal";

import { AxiosError } from "axios";
import { catchingError } from "../../../functions/notification";

export type MeterModalProps = {
    visible: boolean;
    handleCancel: () => void;
    title: string;
    booth: { boothCode: string; boothName: string };
    headMeter: string;
    typeMeter: string;
    typeBooth: string;
};

export const MeterModal: React.FC<MeterModalProps> = (props) => {
    const { visible, handleCancel, booth, title, headMeter, typeMeter, typeBooth } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [lastMeter, setLastMeter] = React.useState<number>(0);
    const [resultMeter, setResultMeter] = React.useState<number>(0);
    const [newMeter, setNewMeter] = React.useState<number>();
    const [userBooth, setUserBooth] = React.useState<IBooth>();
    const [isVisibleHistoryTable, setIsVisibleHistoryTable] = React.useState(false);
    const [isVisibleResetModal, setIsVisibleResetModal] = React.useState(false);
    const [updateMeter, setUpdateMeter] = React.useState<boolean>(false);
    const [disableButton, setDisableButton] = React.useState<boolean>(false);
    const { user } = useAuthContext();
    const getBoothDetail = React.useCallback(async () => {
        setIsLoading(true);
        setUpdateMeter(false);

        try {
            const meterBooth = await getMeterByBooth(booth.boothCode, typeMeter);
            form.setFieldsValue({ lastMeter: meterBooth.meter });
            form.setFieldsValue({ newMeter: meterBooth.meter });
            setLastMeter(meterBooth.meter);
            setNewMeter(meterBooth.meter);
        } catch (error) {
            form.setFieldsValue({ lastMeter: 0 });
            form.setFieldsValue({ newMeter: 0 });
            setNewMeter(0);
            setLastMeter(0);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booth.boothCode, typeMeter]);

    const fetchUserData = React.useCallback(async () => {
        setIsVisibleHistoryTable(false);
        setIsLoading(true);
        try {
            const userData = await getDetailByBooth(booth.boothCode, typeBooth);
            setUserBooth(userData);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [booth.boothCode, typeBooth]);
    React.useEffect(() => {
        const fetchBoothDetail = async () => {
            await getBoothDetail();
            await fetchUserData();
        };

        fetchBoothDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBoothDetail]);

    const onOpenReset = async () => {
        setIsVisibleResetModal(true);
    };

    const onResetNewContract = async () => {
        await form.validateFields(["newMeter"]);
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "คุณต้องการรีเซ็ตหรือไม่ ?",
                text: `กรุณายืนยันการรีเซ็ตมิเตอร์ของแผงนี้เป็น ${newMeter} หน่วย`,
            })
        );
        if (isConfirmed) {
            setIsLoading(true);
            onFinish(true);
        }
    };

    const onResetClearMeter = async () => {
        await form.validateFields(["newMeter"]);
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "คุณต้องการรีเซ็ตหรือไม่ ?",
                text: `กรุณายืนยันการรีเซ็ตมิเตอร์ของแผงนี้เป็น 0 หน่วย`,
            })
        );
        if (isConfirmed) {
            setIsLoading(true);
            onFinish(true, true);
        }
    };
    const onSubmit = async () => {
        await form.validateFields(["newMeter"]);
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "คุณต้องการบันทึกหรือไม่ ?",
                text: `กรุณายืนยันการจดบันทึกมิเตอร์ที่ ${newMeter} หน่วย`,
            })
        );
        if (isConfirmed) {
            setIsLoading(true);
            onFinish(false);
        }
    };

    const onFinish = async (reset: boolean, clearMeter?: boolean) => {
        const datailMeter: IPostUtilityRequest = {
            boothCode: booth.boothCode,
            staff: user?.name ?? "ไม่พบข้อมูล",
            newMeter: reset,
        };

        clearMeter
            ? typeMeter === "water"
                ? (datailMeter.water = 0)
                : (datailMeter.electricity = 0)
            : typeMeter === "water"
            ? (datailMeter.water = newMeter)
            : (datailMeter.electricity = newMeter);

        try {
            await postMeter(datailMeter);
            await Swal.fire(swlConfig.successConfirmation({}));
        } catch (error: any) {
            const err = error as AxiosError<{ error: string }>;
            const msgErr = err.response?.data.error;
            switch (msgErr) {
                case "meter is less than previous meter":
                    await Swal.fire(
                        swlConfig.warningConfirmation({
                            title: "มิเตอร์ไม่ควรได้ค่าน้อยกว่าครั้งก่อน",
                            showConfirmButton: true,
                            timer: 5000,
                        })
                    );
                    break;

                default:
                    await Swal.fire(swlConfig.warningConfirmation({ title: "ทำรายการไม่สำเร็จ" }));
            }

            throw new Error("กรุณาลองใหม่อีกครั้ง");
        } finally {
            await getBoothDetail();
            setUpdateMeter(true);
            setIsLoading(false);
            setIsVisibleResetModal(false);
        }
    };

    React.useEffect(() => {
        setResultMeter(newMeter! - lastMeter! || 0);
        if (newMeter !== undefined && lastMeter <= newMeter) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [lastMeter, newMeter, resultMeter]);

    const convertToName = (booth?: IBooth) => {
        if (!booth) return <p>ไม่พบชื่อผู้ใช้งาน</p>;
        if (booth.isLegalEntity) return booth.companyName;
        if (booth.firstName || booth.lastName)
            return `${booth?.firstName || ""} ${booth?.lastName || ""}`;
        return <p>ไม่พบชื่อผู้ใช้งาน</p>;
    };

    return (
        <Modal title={title} footer={null} visible={visible} onCancel={handleCancel} width={600}>
            <Spin spinning={isLoading}>
                <Form form={form}>
                    <DetailBox>
                        <Row justify="center">
                            <Col span={14}>
                                <Row>
                                    <Col>
                                        <LabelDetail>ชื่อผู้เช่า</LabelDetail>
                                    </Col>
                                    <Col span={14}>
                                        <DetailText>{convertToName(userBooth)}</DetailText>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={10}>
                                <Row justify="end">
                                    <ButtonHeader
                                        type="primary"
                                        onClick={() =>
                                            setIsVisibleHistoryTable(!isVisibleHistoryTable)
                                        }
                                    >
                                        ประวัติย้อนหลัง
                                    </ButtonHeader>
                                </Row>
                            </Col>
                        </Row>
                    </DetailBox>

                    <DetailBox>
                        <Row>
                            <Col span={14}>
                                <Row>
                                    <LabelDetail>เบอร์โทรศัพท์</LabelDetail>
                                    <DetailText>
                                        {userBooth ? userBooth.tel : <p>ไม่พบข้อมูล</p>}
                                    </DetailText>
                                </Row>
                            </Col>
                            <Col span={10} offset={0}>
                                <Row justify="end">
                                    <ButtonHeader onClick={onOpenReset} type="default">
                                        รีเซ็ตมิเตอร์
                                    </ButtonHeader>
                                </Row>
                            </Col>
                        </Row>
                    </DetailBox>
                    <ResetModalMeter
                        isVisible={isVisibleResetModal}
                        handleCancel={() => setIsVisibleResetModal(false)}
                        onResetNewContract={onResetNewContract}
                        onClearMerter={onResetClearMeter}
                    />
                    <Row>
                        <Col span={24}>
                            <DetailBox>
                                <HistoryTable
                                    boothCode={booth.boothCode}
                                    isVisible={isVisibleHistoryTable}
                                    type={typeMeter}
                                    updateMeter={updateMeter}
                                    getBoothDetail={getBoothDetail}
                                />
                            </DetailBox>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24}>
                            <Divider plain>
                                <HeaderMeter>{headMeter}</HeaderMeter>
                            </Divider>
                        </Col>
                    </Row>
                    <RowGroupInput
                        inputs={[
                            {
                                name: "lastMeter",
                                label: "ครั้งก่อน",
                                customInput: (
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        min={lastMeter ? lastMeter : 0}
                                        disabled={true}
                                        onChange={(value) => {
                                            value && setLastMeter(value);
                                        }}
                                    />
                                ),
                            },
                            {
                                name: "newMeter",
                                label: "ครั้งนี้",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกเลขมิเตอร์ที่ต้องการบันทึก",
                                    },
                                ],

                                customInput: (
                                    <InputNumber
                                        type="number"
                                        min={lastMeter ? lastMeter : 0}
                                        style={{ width: "100%" }}
                                        onChange={(value) => {
                                            setNewMeter(value === null ? undefined : value);
                                        }}
                                    />
                                ),
                            },
                        ]}
                    />
                    <Row justify="center">
                        <Col>
                            <Input value={resultMeter} addonBefore={<div>จำนวนหน่วย(Units)</div>} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <DetailBox>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size={"large"}
                                onClick={onSubmit}
                                disabled={disableButton}
                            >
                                บันทึก
                            </Button>
                        </DetailBox>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

export default MeterModal;
