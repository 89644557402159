import ShirtManagement from "./ShirtManagement";
import MeterManagement from "./MeterManagement";
import meterIcon from "../../assets/icons/meter.png";
import shopIcon from "../../assets/icons/shop.png";
import shirtIcon from "../../assets/icons/shirt.png";
import lomsakIcon from "../../assets/icons/lomsak.png";
import otherIcon from "../../assets/icons/other.png";
import buildingIcon from "../../assets/icons/buildings.png";
import veggieBoothIcon from "../../assets/icons/veggieBooth.png";
import { SubRouteProps } from "../../interfaces/PriceManagement";
import BoothManagement from "./BoothManagement";
import OtherManagement from "./OtherManagement";
import BuildingManagement from "./BuildingManagement";
import LomsakManagement from "./LomsakManagement";
import VeggieManagement from "./VeggieManagement";

export const PriceManagementSubRoute: SubRouteProps[] = [
    {
        title: "จัดการอาคาร",
        icon: buildingIcon,
        value: "buildingManagement",
        component: <BuildingManagement />,
    },
    {
        title: "จัดการแผงรายเดือน",
        icon: shopIcon,
        value: "monthlyBoothManagement",
        component: <BoothManagement />,
    },
    {
        title: "จัดการแผงผักบ้าน",
        icon: veggieBoothIcon,
        value: "veggieBoothManagement",
        component: <VeggieManagement />,
    },
    {
        title: "จัดการเสื้อ",
        icon: shirtIcon,
        value: "shirtManagement",
        component: <ShirtManagement />,
    },
    {
        title: "จัดการรถหล่ม",
        icon: lomsakIcon,
        value: "lomsakManagement",
        component: <LomsakManagement />,
    },
    {
        title: "จัดการหน่วยมิเตอร์",
        icon: meterIcon,
        value: "meterManagement",
        component: <MeterManagement />,
    },
    {
        title: "จัดการอื่นๆ",
        icon: otherIcon,
        value: "otherManagement",
        component: <OtherManagement />,
    },
];
