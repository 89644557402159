import { Col, Form, Radio, Row, Spin, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { exportTransaction } from "../../../apis/transaction";
import { RolePagesType, typeList } from "../../../assets/typeEnumTranslation";
import { exportExcel } from "../../../functions/exportExcel";
import { catchingError } from "../../../functions/notification";
import { IExportTx } from "../../../interfaces/Transaction";
import { FormItem } from "../../common/CustomForm";
import DatePicker from "../../common/DatePicker";
import { exportAlert, exportError, exportSuccess } from "../alert";
import { typeRadioRender } from "./index.memo";
import { CancelButton, ConfirmButton, Container, Modal, RowFooter } from "./index.styles";

type ModalExportWithTypeProps = {
    visible: boolean;
    onClose: () => void;
    type: RolePagesType;
};

const ModalExportWithType: React.FC<ModalExportWithTypeProps> = (props) => {
    const { visible, onClose, type } = props;
    const [form] = Form.useForm();
    const [value, setValue] = React.useState<string>(type);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const radio = React.useMemo(() => typeRadioRender(type), [type]);

    const onFinish = async (values: any) => {
        if (Object.keys(values).some((key) => key === undefined))
            return exportError("กรุณาเลือกข้อมูลให้ครบถ้วน");
        else if (+values["startDate"] > +values["endDate"])
            return exportError("วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด");
        const result = await exportAlert();
        if (result) {
            const payload: IExportTx = {
                startDate: +values["startDate"],
                endDate: +values["endDate"],
                subType: values["subType"],
            };
            setIsLoading(true);
            try {
                const res = await exportTransaction(payload);
                await exportExcel(payload, res);
                await exportSuccess();
                onClose();
            } catch (error) {
                await exportError("ไม่สามารถนำออกเอกสารได้ กรุณาลองใหม่อีกครั้ง");
                catchingError(error);
                console.log({ error });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onRadioChange = (e: any) => {
        setValue(e.target.value);
    };

    return (
        <Form form={form} onFinish={onFinish}>
            <Modal
                centered
                visible={visible}
                onCancel={onClose}
                title={`นำออกเอกสาร${typeList[type]}`}
                footer={
                    !isLoading && (
                        <RowFooter>
                            <CancelButton onClick={onClose}>
                                <span> ยกเลิก</span>
                            </CancelButton>
                            <ConfirmButton onClick={() => form.submit()}>ตกลง</ConfirmButton>
                        </RowFooter>
                    )
                }
            >
                {!isLoading ? (
                    <Container>
                        <Typography.Title level={5}>วันที่นำออกเอกสาร</Typography.Title>
                        <Row>
                            <Col xs={9} offset={1}>
                                <FormItem
                                    name="startDate"
                                    label="วันที่เริ่มต้น"
                                    initialValue={dayjs().subtract(1, "month")}
                                >
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        inputReadOnly
                                        allowClear={false}
                                        disabledDate={(current: any) =>
                                            (current && current > dayjs()) ||
                                            current < dayjs("2022-01-01")
                                        }
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={9} offset={2}>
                                <FormItem
                                    name="endDate"
                                    label="วันที่สิ้นสุด"
                                    initialValue={dayjs()}
                                >
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        inputReadOnly
                                        allowClear={false}
                                        disabledDate={(current: any) =>
                                            (current && current > dayjs()) ||
                                            current < dayjs("2022-01-01")
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Typography.Title level={5}>ประเภทการชำระเงิน</Typography.Title>
                        <Col offset={1}>
                            <Form.Item name="subType" initialValue={value}>
                                <Radio.Group onChange={onRadioChange}>{radio}</Radio.Group>
                            </Form.Item>
                        </Col>
                    </Container>
                ) : (
                    <Spin />
                )}
            </Modal>
        </Form>
    );
};

export default ModalExportWithType;
