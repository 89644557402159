import React from "react";
import { Select, Row, Col, Input, Table, Button } from "antd";
import { getAllMeterRecord, revertMeterLastRecord } from "../../../../apis/meter";
import dayjs from "dayjs";
import { MeterTable } from "../../../../interfaces/Meter";
import { TableBox } from "./index.styles";
import { ColumnsType } from "antd/lib/table";
import * as swlConfig from "../../../../assets/sweetalert.config";
import Swal from "sweetalert2";

const { Option } = Select;

type HistoryTableMeterProps = {
    boothCode: string;
    isVisible: boolean;
    type: string;
    updateMeter: boolean;
    getBoothDetail: ()=>void
};
const years: { year: number; unix: number }[] = Array.from({ length: 5 }).map((_, index) => ({
    year: parseInt(dayjs().add(-index, "year").format("YYYY")) + 543,
    unix: +dayjs().add(-index, "year"),
}));

const HistoryTable: React.FC<HistoryTableMeterProps> = (props) => {
    const { boothCode, isVisible, type, updateMeter,getBoothDetail } = props;
    const [recordMeter, setRecordMeter] = React.useState([]);
    const [recordYear, setRecordYear] = React.useState<number | string | Date>(+dayjs());
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<MeterTable[]>([]);
    const [dataStorage, setDataStorage] = React.useState<MeterTable[]>([]);
    const [searchStaff, setSearchStaff] = React.useState<string>("");

    const fecthAllRecordMeter = React.useCallback(async () => {
        setTableLoading(true);
        try {
            const data = await getAllMeterRecord(boothCode, type, recordYear);

            setRecordMeter(data);
        } catch (error) {
        } finally {
            setTableLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothCode, recordYear]);
    const meterDataToTable = React.useCallback(() => {
        const data = recordMeter?.map((item: any) => ({
            date: dayjs(item.date).format("DD/MM/BBBB"),
            lastMeter: item.previousMeter,
            newMeter: item.meter,
            resultMeter: item.increment,
            meterer: item.staff,
            id: item.meterId,
        }));

        return data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordMeter]);

    const onRevertMeter = async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.negativeConfirmation({
                title: "คุณต้องการยกเลิกรายการมิเตอร์ล่าสุดใช่หรือไม่!",
                text: `กรุณายืนยันการลบรายการมิเตอร์ล่าสุด`,
            })
        );
        if (isConfirmed) {
            try {
                await revertMeterLastRecord(boothCode, type);
                fecthAllRecordMeter();
                getBoothDetail();
            } catch (error) {
                console.log({ error });
            }
        }
    };

    React.useEffect(() => {
        setDataStorage(meterDataToTable());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordMeter]);

    React.useEffect(() => {
        if (isVisible) {
            const fetchMeter = async () => {
                await fecthAllRecordMeter();
            };

            fetchMeter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fecthAllRecordMeter, isVisible, updateMeter]);

    React.useEffect(() => {
        if (!searchStaff) {
            setDataTable(dataStorage);
        } else {
            const nData = dataStorage.filter((item) => {
                return item["meterer"]
                    .toLocaleLowerCase()
                    .includes(searchStaff.toLocaleLowerCase());
            });

            setDataTable(nData);
        }
    }, [searchStaff, dataStorage]);

    const SearchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchStaff(e.target.value);

    const columns: ColumnsType<MeterTable> = [
        {
            title: "วันที่จด",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "หน่วยครั้งก่อน",
            dataIndex: "lastMeter",
            key: "lastMeter",
        },
        {
            title: "หน่วยครั้งนี้",
            dataIndex: "newMeter",
            key: "newMeter",
        },
        {
            title: "จำนวนหน่วย",
            dataIndex: "resultMeter",
            key: "resultMeter",
        },
        {
            title: "ชื่อผู้จด",
            dataIndex: "meterer",
            key: "meterer",
        },
        {
            title: "",
            dataIndex: "deleteMenu",
            key: "deleteMenu",
            width: 100,
            render(_, __, index) {
                if (dataTable.length === 1 || index !== 0) return;
                return (
                    <Button type="primary" onClick={onRevertMeter}>
                        ยกเลิก
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            {isVisible && (
                <>
                    <Row justify="space-between" gutter={16}>
                        <Col xs={10} lg={8} md={8}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={(year: string) => setRecordYear(year)}
                                defaultValue={dayjs(recordYear).format("BBBB")}
                            >
                                {years.map(({ year, unix }) => (
                                    <Option value={unix} key={unix}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Input placeholder="ค้าหาชื่อผู้จดมิเตอร์" onChange={SearchKeys} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TableBox>
                                <Table
                                    loading={tableLoading}
                                    columns={columns}
                                    dataSource={dataTable}
                                    pagination={false}
                                    scroll={{ x: "calc(500px + 50%)", y: "300px" }}
                                    rowKey={(item: MeterTable) => item.id}
                                />
                            </TableBox>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default HistoryTable;
