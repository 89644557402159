import Swal, { SweetAlertOptions } from "sweetalert2";
import { positiveConfirmation } from "../../assets/sweetalert.config";

export const transactionDecision = async (option: SweetAlertOptions<any, any>) => {
    const result = await Swal.fire({
        ...option,
    });
    return result.value;
};

export const exportAlert = async () => {
    const result = await Swal.fire({
        ...positiveConfirmation({
            icon: "warning",
            html: `<h3>คุณต้องการนำออกเอกสารดังกล่าวใช่หรือไม่?</h3>`,
        }),
    });
    return result.value;
};

export const exportSuccess = async () => {
    const result = await Swal.fire({
        title: "นำออกเอกสารสำเร็จ",
        timer: 1500,
        icon: "success",
        showConfirmButton: false,
    });

    return result.value;
};

export const exportError = async (title: string) => {
    const result = await Swal.fire({
        title: title,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
    });

    return result.value;
};
