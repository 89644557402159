import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { getDashboardData, getDashboardGraphWithType } from "../apis/dashboard";
import { renderOther } from "../components/DashboardPage/utility/mapping";
import { catchingError } from "../functions/notification";
import { IDashboardCard } from "../interfaces/Dashboard";
import { useAuthContext } from "./AuthProvider";

const DashboardContext = React.createContext<{
    refreshData: () => void;
    isLoading: boolean;
    fetchDashboardPage: () => void;
    fetchGraphComponent: (filter: string, type: string, date: number) => void;
    summaryCard?: IDashboardCard;
    graphData: any;
    isLoadingComponent: boolean;
    date: Dayjs;
}>({
    refreshData: () => {},
    isLoading: false,
    fetchDashboardPage: () => {},
    fetchGraphComponent: () => {},
    summaryCard: undefined,
    graphData: undefined,
    isLoadingComponent: false,
    date: dayjs(),
});

type DashboardProviderProps = {
    children: React.ReactNode;
};

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
    const [summaryCard, setSummaryCard] = React.useState<IDashboardCard>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [graphData, setGraphData] = React.useState<any>({});
    const [isLoadingComponent, setIsLoadingComponent] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Dayjs>(dayjs());
    const { user } = useAuthContext();

    const fetchDashboardPage = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getDashboardData();
            setSummaryCard(data);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setSummaryCard]);

    const refreshData = React.useCallback(async () => {
        await fetchDashboardPage();
        setGraphData({});
        setDate(dayjs());
    }, [fetchDashboardPage, setGraphData]);

    const fetchGraphComponent = React.useCallback(
        async (filter: string, type: string, date: number) => {
            setIsLoadingComponent(true);
            try {
                const data = await getDashboardGraphWithType(filter, type, date);
                if (type === "ieAccount") {
                    let newData = [] as any;
                    data.forEach((item: any) =>
                        newData.push({
                            name: renderOther[item.name as string].name,
                            data: item.data,
                        })
                    );
                    setGraphData((prev: any) => {
                        return { ...prev, [`${type}-${filter}`]: newData };
                    });
                } else {
                    setGraphData((prev: any) => {
                        return { ...prev, [`${type}-${filter}`]: data };
                    });
                }
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoadingComponent(false);
            }
        },
        [setIsLoadingComponent, setGraphData]
    );

    React.useEffect(() => {
        if (user && user.role === "admin") {
            fetchDashboardPage();
        }
    }, [fetchDashboardPage, user]);

    return (
        <DashboardContext.Provider
            value={{
                refreshData,
                isLoading,
                fetchDashboardPage,
                summaryCard,
                fetchGraphComponent,
                graphData,
                isLoadingComponent,
                date,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;

export const useDashboardProvider = () => React.useContext(DashboardContext);
