import React from "react";
import { Upload } from "antd";
import styled from "styled-components";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const CustomUpload = styled(Upload)`
    width: fit-content !important;
    & .ant-upload.ant-upload-select-picture-card {
        border-radius: 999px !important;
        overflow: hidden !important;
        ${({ error }: { error?: string }) =>
            error && `border-color: red !important; color: red !important;`}
    }
`;

export const UploadButton: React.FC<{ loadingImage?: boolean; imageError?: string }> = ({
    loadingImage,
    imageError,
}) => (
    <div>
        {loadingImage ? (
            <LoadingOutlined />
        ) : (
            <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
        )}
        <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
            {imageError || "เพิ่มรูป"}
        </div>
    </div>
);

export default CustomUpload;
