import { Col, Row, Spin, Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { RolePagesType } from "../../../assets/typeEnumTranslation";
import BillingComponent from "../../../components/common/BillingComponent";
import DetailTenant from "../../../components/common/DetailTenant";
import AddItemModal from "../../../components/common/TableBillingComponent/AddItemModal";
import TransactionCard from "../../../components/common/TransactionCard";
import { Content, TabTitle } from "./index.styles";
import usePaymentBilling from "./usePaymentBilling";

type PaymentBillingProps = {
    type: RolePagesType;
    code: string;
    contractId?: string;
};

const PaymentBilling: React.FC<PaymentBillingProps> = (props: PaymentBillingProps) => {
    const { type, code, contractId } = props;
    const { billing, loadingTrigger, fetchBilling, isLoading, visible, setVisible } =
        usePaymentBilling(type, code, contractId);

    const { lg } = useBreakpoint();
    return (
        <>
            {lg ? (
                <Row justify="space-between">
                    <Col xs={24} sm={24} lg={9}>
                        <DetailTenant type={type} boothCode={code} />
                        <TransactionCard
                            boothCode={code}
                            contractId={contractId ?? ""}
                            billing={billing}
                            type={type}
                        />
                    </Col>
                    <Col xs={24} sm={24} lg={14}>
                        {!isLoading ? (
                            <BillingComponent
                                data={billing}
                                code={code}
                                contractId={contractId}
                                fetchData={fetchBilling}
                                loading={loadingTrigger}
                                onActiveModal={() => setVisible(!false)}
                                type={type}
                            />
                        ) : (
                            <Spin />
                        )}
                    </Col>
                </Row>
            ) : (
                <Content>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={<TabTitle>ใบแจ้งยอด</TabTitle>} key={`1`}>
                            {!isLoading ? (
                                <BillingComponent
                                    data={billing}
                                    code={code}
                                    fetchData={fetchBilling}
                                    loading={loadingTrigger}
                                    onActiveModal={() => setVisible(!false)}
                                    type={type}
                                />
                            ) : (
                                <Spin />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<TabTitle>รายการชำระเงิน</TabTitle>} key={`2`}>
                            <TransactionCard
                                boothCode={code}
                                contractId={contractId ?? ""}
                                billing={billing}
                                type={type}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<TabTitle>ผู้เช่า</TabTitle>} key={`3`}>
                            <DetailTenant type={type} boothCode={code} />
                        </Tabs.TabPane>
                    </Tabs>
                </Content>
            )}
            <AddItemModal
                visible={visible}
                type={type}
                boothCode={code}
                onCancel={() => setVisible(!true)}
                fetchData={fetchBilling}
                loading={loadingTrigger}
            />
        </>
    );
};

export default PaymentBilling;
