import { InputNumber } from "antd";
import styled from "styled-components";
import colors from "../../../../assets/colors.json";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    & h3 {
        border-bottom: 1px solid ${colors.border};
        margin: 1rem 0 0 0;
    }
`;

export const MenuWorkValueContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 0 1rem;
    & > label {
        margin: 0;
        margin-top: 4px;
        & > span {
            color: ${colors.hintText};
        }
    }
`;

export const CustomInputNumber = styled(InputNumber)`
    & input {
        text-align: center;
    }
`;
