import { InputNumber } from "antd";
import React from "react";
import RowInput from "../../RowInput";

type ShirtLabelProps = {
    typelabel: "parking" | "boothShirt";
};

export function FormOneLabel(props: ShirtLabelProps) {
    const { typelabel } = props;

    return (
        <RowInput
            name={typelabel === "parking" ? "parking-parkingPrice" : "boothShirt-yearlyPrice"}
            label={typelabel === "parking" ? "ราคาค่าเช่ารายเดือน" : "ราคาค่าเสื้อรายปี"}
            required={true}
            rules={[
                {
                    required: true,
                    message: `กรุณากรอก${
                        typelabel === "parking" ? "ราคาค่าเช่ารายเดือน" : "ราคาค่าเสื้อรายปี"
                    }`,
                },
            ]}
        >
            <InputNumber
                addonAfter="บาท"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
        </RowInput>
    );
}

export function FormLaborAndRiderShirt(props: { type: string }) {
    const { type } = props;

    const formItem = React.useMemo(() => {
        const items = [
            {
                name: `${type}-monthlyPrice`,
                label: "ราคาค่าเสื้อรายเดือน",
                required: true,
                rules: [{ required: true, message: `กรุณากรอกราคาค่าเสื้อรายเดือน` }],
                children: (
                    <InputNumber
                        addonAfter="บาท"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                    />
                ),
            },
            {
                name: `${type}-yearlyPrice`,
                label: "ราคาค่าเสื้อรายปี",
                required: true,
                rules: [{ required: true, message: `กรุณากรอกราคาค่าเสื้อรายปี` }],
                children: (
                    <InputNumber
                        addonAfter="บาท"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                    />
                ),
            },
            {
                name: `${type}-maintaining`,
                label: "ค่ารักษาสิทธิ์ (ต่อเดือน)",
                required: true,
                rules: [{ required: true, message: `กรุณากรอกค่ารักษาสิทธิ์` }],
                other: true,
                children: (
                    <InputNumber
                        addonAfter="บาท"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                    />
                ),
            },
        ];

        return items.map((item, index) => (
            <RowInput
                key={`${item.name}-${index}`}
                name={item.name}
                label={item.label}
                rules={item.rules}
                other={item.other}
                required={item.required}
            >
                {item.children}
            </RowInput>
        ));
    }, [type]);

    return <>{formItem}</>;
}
