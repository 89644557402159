import { Col, PageHeader, Row } from "antd";
import styled from "styled-components";
import colors from "../../assets/colors.json";

export const PageHeaderItem = styled(PageHeader)`
    & .ant-page-header-heading-left {
        @media screen and (max-width: 540px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    & .ant-page-header-heading-extra {
        margin-left: auto;
        align-self: center;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ExtraContainer = styled.div`
    color: ${colors.primary};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
    }
`;

export const FinalColumn = styled(Col).attrs({
    xs: 24,
    xl: 8,
    span: 8,
})`
    @media screen and (max-width: 1200px) {
        margin-top: 1rem;
    }
`;

export const RowMiddle = styled(Row).attrs({
    gutter: 16,
})`
    margin: 1rem 0;
`;
