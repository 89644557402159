import { Button, Form, Modal, Row, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import BoothCardComponent from "./BoothCardComponent";
import { CancleButton, ConfirmButton, ContainerModal, RowFooter } from "./index.styles";
import { IBoothPricingResponse } from "../../../interfaces/PriceManagement";
import {
    onAddAlert,
    onDeleteAlert,
    onEditAlert,
    onErrorAlert,
    onErrorEditAlert,
    onSuccessAlert,
} from "./alert";
import { editZonePricing, getZonePricing } from "../../../apis/priceManagement";
import { typeList } from "../../../assets/typeEnumTranslation";

type IBoothPrice = {
    boothKey: any;
    data: IBoothPricingResponse;
    visible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    fetchBackground: () => void;
    type: "booth" | "veggie" | "lomsak";
};

const initialValues: IBoothPricingResponse = {
    building: 0,
    isAvaliable: [],
    type: "",
    "z0a0#0": {},
};

function ModalCardManage(props: IBoothPrice) {
    const { visible, handleOk, handleCancel, data, boothKey, fetchBackground, type } = props;
    const [edit, setEdit] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const [booth, setBooth] = React.useState<string[]>([]);
    const [editData, setEditData] = React.useState<boolean[]>([]);
    const [boothData, setBoothData] = React.useState<IBoothPricingResponse>(initialValues);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const onPatchingData = async (newBoothCode: string, newData: IBoothPricingResponse) => {
        setIsLoading(true);
        try {
            await editZonePricing(newBoothCode, newData);
            await fetchBackground();
            await onSuccessAlert();
        } catch (e) {
            await onErrorAlert();
        } finally {
            setIsLoading(false);
        }
    };

    const triggerEdit = (index: number) => {
        if (editData.filter((item) => item === true).length === 1) {
            onErrorEditAlert();
            return;
        }

        form.resetFields();
        if (index <= boothData.isAvaliable.length - 1) {
            Object.keys(boothData[booth[index]]).forEach((key) => {
                form.setFieldsValue({
                    [`${booth[index]}-${key}-${index}`]: boothData[booth[index]][key],
                });
            });
        } else {
            Object.keys(boothData[booth[0]]).forEach((key) => {
                if (key !== "boothName") {
                    form.setFieldsValue({
                        [`${booth[index]}-${key}-${index}`]: boothData[booth[0]][key],
                    });
                } else {
                    if (boothData[booth[0]].boothName !== undefined) {
                        const newName = `${boothData[booth[0]].boothName} ${
                            index !== 0 && `#${index}`
                        }`;
                        form.setFieldsValue({
                            [`${booth[index]}-${key}-${index}`]: newName,
                        });
                    } else {
                        const newName = `${booth[0]} ${index !== 0 && `#${index}`}`;
                        form.setFieldsValue({
                            [`${booth[index]}-${key}-${index}`]: newName,
                        });
                    }
                }
            });
        }
        setEditData((prev: boolean[]) => {
            const newData = [...prev];
            newData[index] = true;
            return newData;
        });
        !edit && setEdit(!edit);
    };

    const fetchData = React.useCallback(async () => {
        const res = await getZonePricing(boothKey.mainBoothCode);
        setBoothData(res);
        setBooth(res.isAvaliable);
    }, [boothKey, setBoothData, setBooth]);

    const onAddSubBooth = async () => {
        const response = await onAddAlert(type);
        if (response) {
            const newBoothCode = `${booth[booth.length - 1].split("#")[0]}#${booth.length + 1}`;
            const newName = `${boothData[booth[0]].boothName} #${booth.length}`;
            const newData = boothData;
            newData.isAvaliable.push(newBoothCode);
            newData[newBoothCode] = {
                ...newData[booth[0]],
                boothName: `${newName}`,
            };
            await onPatchingData(newBoothCode, newData);
        }
    };

    const onDeleteSubBooth = async (index: number) => {
        const response = await onDeleteAlert(type);
        if (response) {
            const newData = boothData;
            delete newData[booth[index]];
            newData.isAvaliable.splice(index, 1);
            await onPatchingData(boothKey.mainBoothCode, newData);
        }
    };

    React.useEffect(() => {
        setBooth(data.isAvaliable);
        setBoothData(data);
    }, [setBooth, setBoothData, data]);

    React.useEffect(() => {
        boothData &&
            boothData.isAvaliable &&
            boothData.isAvaliable.length !== 0 &&
            boothData.isAvaliable.map((_: any, __: number) =>
                setEditData((prev: any) => [...prev, false])
            );
    }, [setEditData, boothData, setBoothData, fetchData]);

    const onCancelEdit = () => {
        const newEditData = [...editData].map((_: any) => false);
        setEditData(newEditData);
        setEdit(!edit);
    };

    const onSubmit = async (values: any) => {
        const response = await onEditAlert(boothData, values);
        if (response) {
            onCancelEdit();
            const newData = boothData;
            Object.keys(values).forEach((key) => {
                const [booth, keyName] = key.split("-");
                newData[booth][keyName] = values[key];
            });
            await onPatchingData(boothKey.mainBoothCode, newData);
        }
    };

    return (
        <>
            <Modal
                title={`ราคา${typeList[type]} อาคาร ${boothData.building} รหัส ${
                    boothKey.mainBoothCode
                } ${
                    boothData &&
                    boothData.isAvaliable &&
                    boothData.isAvaliable.length !== 0 &&
                    `จำนวน ${boothData.isAvaliable.length}`
                } ${type === "lomsak" ? "ที่" : "แผง"}ย่อย`}
                footer={
                    !isLoading && (
                        <>
                            <RowFooter>
                                <CancleButton onClick={edit ? onCancelEdit : handleCancel}>
                                    <span> ยกเลิก</span>
                                </CancleButton>
                                <ConfirmButton onClick={edit ? form.submit : handleOk}>
                                    <span> {!edit ? "ตกลง" : "ยืนยันการแก้ไข"}</span>
                                </ConfirmButton>
                            </RowFooter>
                        </>
                    )
                }
                visible={visible}
                onCancel={() => {
                    edit && onCancelEdit();
                    handleCancel();
                }}
            >
                <Form form={form} onFinish={onSubmit}>
                    {!isLoading && boothData ? (
                        <ContainerModal>
                            <h2 style={{ color: "red", marginTop: -5 }}>
                                {booth?.findIndex((_: any) => _ === boothKey.boothCode) === 0
                                    ? "แผงหลัก"
                                    : `แบ่งมาจากแผง ${boothKey.mainBoothCode}`}
                            </h2>
                            <Row justify="space-between">
                                <h2>รายการค่าเช่า</h2>
                                <Button
                                    type="primary"
                                    onClick={onAddSubBooth}
                                    disabled={boothKey.status === "inactive"}
                                >
                                    <PlusOutlined style={{ fontSize: "16px" }} /> แบ่ง
                                    {type === "lomsak" ? "ที่" : "แผง"}
                                </Button>
                            </Row>
                            {booth &&
                                booth.length !== 0 &&
                                booth.map(
                                    (_: any, index: number) =>
                                        boothKey.boothCode === booth[index] && (
                                            <BoothCardComponent
                                                key={index}
                                                index={index}
                                                boothList={booth}
                                                data={boothData}
                                                onCancelEdit={onCancelEdit}
                                                onEdit={triggerEdit}
                                                onDelete={onDeleteSubBooth}
                                                edit={editData[index]}
                                                type={type}
                                            />
                                        )
                                )}
                        </ContainerModal>
                    ) : (
                        <Spin />
                    )}
                </Form>
            </Modal>
        </>
    );
}

export default ModalCardManage;
