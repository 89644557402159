import { Alert, Badge, Button, Divider, Row, Typography } from "antd";
import React from "react";
import { IBillingResponse } from "../../../interfaces/Billing";
import { PlusOutlined } from "@ant-design/icons";
import Calendar from "../Calendar";
import TableBillingComponent from "../TableBillingComponent";
import {
    BadgeLarge,
    Container,
    EventList,
    Message,
    MessageContainer,
    Paragraph,
} from "./index.styles";
import colors from "../../../assets/colors.json";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault("Asia/Bangkok");

export type BillingComponentProps = {
    data?: IBillingResponse;
    onActiveModal: () => void;
    code: string;
    contractId?: string;
    fetchData: () => void;
    loading: () => void;
    type: "booth" | "veggie" | "lomsak" | "rider" | "labor";
};

const BillingComponent: React.FC<BillingComponentProps> = (props) => {
    const { data, onActiveModal, fetchData, loading, code, type, contractId } = props;
    const Title = Typography.Title;
    const [dateSelect, setDateSelect] = React.useState<Dayjs>(dayjs.tz(new Date()));
    const [unixKey, setUnixKey] = React.useState<number>(+dayjs.tz(new Date()).startOf("D"));
    const [isHaveData, setIsHaveData] = React.useState<boolean>(false);

    const onDateChange = (date: Dayjs) => {
        setDateSelect(date);
        setUnixKey(+dayjs.tz(date).startOf("D"));
    };

    React.useEffect(() => {
        if (!data) return;
        else if (Object.keys(data).length > 0) {
            for (const key in data) {
                if (data[key].every((item) => item.paid === true && item.remainingDebt === 0)) {
                    setIsHaveData(false);
                } else {
                    setIsHaveData(true);
                    break;
                }
            }
        }
        return;
    }, [setIsHaveData, data]);

    const renderBilling = React.useMemo(() => {
        if (!data) return null;
        if (type === "veggie") {
            if (!unixKey) return null;
            return (
                <TableBillingComponent
                    unix={unixKey}
                    fetchData={fetchData}
                    loading={loading}
                    code={code}
                    dataSource={data[unixKey]}
                    type={type}
                    contractId={contractId}
                />
            );
        }
        return Object.keys(data).map((key: string, _: number) => {
            return (
                <TableBillingComponent
                    key={key}
                    unix={+key}
                    fetchData={fetchData}
                    loading={loading}
                    code={code}
                    dataSource={data[+key]}
                    type={type}
                    contractId={contractId}
                />
            );
        });
    }, [data, type, unixKey, fetchData, loading, code, contractId]);

    const dateCellRender = (value: Dayjs) => {
        if (dayjs.tz(new Date()).isBefore(value)) return null;
        const isWarning =
            data &&
            Object.keys(data).some((item) => {
                return (
                    dayjs.tz(+item).format("DD/MM/YYYY") === dayjs.tz(value).format("DD/MM/YYYY") &&
                    data[+item].some((item) => item.paid === false && item.remainingDebt !== 0)
                );
            });

        return (
            <EventList>
                <li>
                    {isWarning ? (
                        <Badge status="warning" text="ค้างชำระ" />
                    ) : (
                        <Badge status="success" text="ครบจำนวน" />
                    )}
                </li>
            </EventList>
        );
    };

    const disabledDate = (current: Dayjs) => {
        return dayjs.tz(new Date()).isBefore(current);
    };

    return (
        <Container>
            <Row justify="space-between" align="middle">
                <Title level={4} style={{ marginBottom: 0 }}>
                    {isHaveData ? "ใบแจ้งยอด" : "ไม่พบข้อมูลใบแจ้งยอด"}
                </Title>
                <Button icon={<PlusOutlined />} onClick={onActiveModal}>
                    เพิ่มรายการ
                </Button>
            </Row>
            <Divider style={{ margin: "1rem 0 1rem 0" }} />
            {type === "veggie" && (
                <>
                    <Alert
                        message={
                            <MessageContainer>
                                <Message>
                                    <BadgeLarge color={colors.primary} />{" "}
                                    <Paragraph>ครบจำนวน</Paragraph>
                                </Message>
                                <Message>
                                    <BadgeLarge color={colors.warning} />{" "}
                                    <Paragraph>ค้างชำระ</Paragraph>
                                </Message>
                            </MessageContainer>
                        }
                    />
                    <Calendar
                        style={{ marginBottom: "1rem" }}
                        validRange={[dayjs.tz("2022-05-01"), dayjs.tz(new Date())]}
                        value={dateSelect}
                        dateCellRender={dateCellRender}
                        onChange={onDateChange}
                        disabledDate={disabledDate}
                    />
                </>
            )}
            {data && <>{renderBilling}</>}
        </Container>
    );
};

export default BillingComponent;
