import { Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import colors from "../../../assets/colors.json";
import { RolePagesType } from "../../../assets/typeEnumTranslation";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
`;

const Header = styled(Typography.Title).attrs({
    level: 5,
})`
    color: ${colors.primary} !important;
    margin-bottom: 0.5rem !important;
`;

const Paragraph = styled(Typography.Paragraph)`
    margin-bottom: 0.25rem !important;
`;

type PetitionComponentProps = {
    data: any;
    type: RolePagesType;
    onClick?: () => void;
};

const PetitionComponent: React.FC<PetitionComponentProps> = (props: PetitionComponentProps) => {
    const { data, type, onClick } = props;
    const isShirt = ["rider", "labor"].includes(type);

    return (
        <Container onClick={onClick}>
            <Header>
                {!isShirt ? `แผง ${data.boothCode}` : `เบอร์ ${data.shirtNumber}`} อาคาร{" "}
                {data.building}
            </Header>
            <Paragraph>{data.collectorName} ได้ทำการขอยกเลิกรายการชำระเงิน</Paragraph>
            <Paragraph>{dayjs(data.date).format("วันที่ DD/MM/BBBB เวลา HH:mm นาฬิกา")}</Paragraph>
        </Container>
    );
};

export default PetitionComponent;
