import { message } from "antd";
import React from "react";
import { getRandomAssignValue, updateValueRandom } from "../../../../apis/member";
import mock from "./mock";

export const workManagementContext = React.createContext(
    {} as ReturnType<typeof useWorkManagement>
);
export const useWorkManagementContext = () => React.useContext(workManagementContext);

function useWorkManagement() {
    const [data, setData] = React.useState(mock);
    const [initVal, setInitVal] = React.useState<{ [key: string]: number }>({});
    const [fetching, setFetching] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const getValue = React.useCallback(async () => {
        setFetching(true);
        const result = await getRandomAssignValue();
        const formInit: { [key: string]: number } = {};
        const resData = result.data;
        Object.keys(resData).forEach((key) => {
            const k = key as keyof typeof resData;
            formInit[k] = resData[k].currValue ?? 0;
        });
        setInitVal(formInit);
        setData(result.temp);
        setFetching(false);
    }, []);

    const fetch = React.useCallback(async () => {
        setIsLoading(true);
        try {
            await getValue();
        } catch (error) {
            message.error("มีบางอย่างผิดพลาด");
        }
        setIsLoading(false);
    }, [getValue]);

    const onUpdateRandomAssignValue = React.useCallback(async (data: { [key: string]: number }) => {
        await updateValueRandom(data);
        await fetch();
    }, [fetch]);

    React.useEffect(() => {
        fetch();
    }, [fetch]);

    return { getValue, fetching, data, isLoading, setIsLoading, onUpdateRandomAssignValue, initVal };
}

export default useWorkManagement;
