import { Modal, Row, Radio, Col } from "antd";
import type { RadioChangeEvent } from "antd";
import { Container, ButtonContainer } from "./index.style";
import React from "react";
import { IncomeExpensesTable } from "../../../interfaces/IncomesAndExpenses";
import { formatNumber } from "../../../functions/formatNumber";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

type ModalExportrProps = {
    isVisible: boolean;
    handleCancel: () => void;
    recordSelected: IncomeExpensesTable[];
};

export const ModalExport: React.FC<ModalExportrProps> = (props) => {
    const { isVisible, handleCancel, recordSelected } = props;
    //  1 means Tax 2 means No tax
    const [isTax, isSetTax] = React.useState(1);

    const headers = [
        { label: "ลำดับที่", key: "order" },
        { label: "วันที่ทำรายการ", key: "date" },
        { label: "รายละเอียด", key: "IEName" },
        { label: "จำนวนเงิน (บาท)", key: "amount" },
    ];

    const sumAmount = recordSelected.reduce(
        (acc, item) =>
            item.IEType === "income" ? acc + Number(item.amount) : acc - Number(item.amount),
        0
    );

    const tax = sumAmount * 0.07;
    const data = recordSelected.map((item, index) => ({
        order: (index + 1).toString(),
        date: dayjs(+item.date).format("DD/MM/YYYY"),
        IEName: item.IEName,
        amount:
            item.IEType === "income"
                ? formatNumber(Number(item.amount)).toString()
                : formatNumber(-Number(item.amount)).toString(),
    }));

    data.push({
        order: isTax !== 1 ? "ยอดเงินสุทธิ" : "รวมเงิน",
        date: "",
        IEName: "",
        amount: formatNumber(sumAmount),
    });

    if (isTax === 1) {
        data.push({
            order: "หักภาษีมูลค่าเพิ่ม 7%",
            date: "",
            IEName: "",
            amount: formatNumber(tax),
        });
        data.push({
            order: "ยอดสุทธิ",
            date: "",
            IEName: "",
            amount: formatNumber(sumAmount - tax),
        });
    }

    const onChange = (e: RadioChangeEvent) => {
        isSetTax(e.target.value);
    };

    return (
        <Modal
            visible={isVisible}
            title="นำออกเอกสารรายรับ-รายจ่าย"
            footer={false}
            onCancel={handleCancel}
        >
            <Row>
                <b>รูปแบบใบเสร็จที่ต้องการ</b>
            </Row>
            <Row justify="center" gutter={24}>
                <Container>
                    <Col>
                        <Radio.Group onChange={onChange} value={isTax}>
                            <Radio value={1}>คิดภาษี 7 %</Radio>
                            <Radio value={2}>ไม่คิดภาษี</Radio>
                        </Radio.Group>
                    </Col>
                </Container>
            </Row>
            <Row justify="center" gutter={24}>
                <Col span={8}>
                    <ButtonContainer type="default" onClick={handleCancel}>
                        ยกเลิก
                    </ButtonContainer>
                </Col>
                <Col span={8}>
                    <ButtonContainer type="primary">
                        <CSVLink
                            data={data}
                            headers={headers}
                            filename={`รายรับรายจ่ายของเดือน ${dayjs().format("MM-BBBB")}`}
                        >
                            ดาวน์โหลด
                        </CSVLink>
                    </ButtonContainer>
                </Col>
            </Row>
        </Modal>
    );
};

export default ModalExport;
