import { Modal } from "antd";
import React from "react";
import PDPA from "../pages/PDPA";

const PDPAContext = React.createContext<{
    showPDPA: () => void;
    isShowPDPA: boolean;
    handleAcceptCookies: () => void;
}>({
    showPDPA: () => {},
    isShowPDPA: false,
    handleAcceptCookies: () => {},
});

export const usePDPA = () => React.useContext(PDPAContext);

const PDPAProviders: React.FC = ({ children }) => {
    const [isShowPDPA, setIsShowPDPA] = React.useState(false);
    const handleAcceptCookies = React.useCallback(() => {
        window.localStorage.setItem("acceptCookies", "true");
        setIsShowPDPA(false);
    }, []);

    return (
        <PDPAContext.Provider
            value={{ isShowPDPA, showPDPA: () => setIsShowPDPA(true), handleAcceptCookies }}
        >
            <Modal
                width={"90vw"}
                visible={isShowPDPA}
                onCancel={() => setIsShowPDPA(false)}
                okText={"ยอมรับ"}
                cancelText={"ยกเลิก"}
                onOk={handleAcceptCookies}
            >
                <PDPA />
            </Modal>
            {children}
        </PDPAContext.Provider>
    );
};

export default PDPAProviders;
