import React from "react";
import { Button, Form, message, Row, Spin } from "antd";
import WorkManagementCard from "../../../../components/MemberManagementPage/Staff/WorkManagementCard";
import { Container } from "./index.style";
import useWorkManagment, { workManagementContext } from "./useWorkManagment";
import Swal from "sweetalert2";
import * as swlConfig from "../../../../assets/sweetalert.config";

function WorkManagement() {
    const workManagment = useWorkManagment();
    const { isLoading, data, onUpdateRandomAssignValue, initVal } = workManagment;

    const onFinish = async (values: { [key: string]: number }) => {
        const { isConfirmed } = await Swal.fire({
            ...swlConfig.positiveConfirmation({
                title: "คุณต้องการบันทึกข้อมูลหรือไม่ ?",
                html: "กรุณากดปุ่มยืนยันเพื่อบันทึกข้อมูล",
            }),
        });
        if (!isConfirmed) return;
        try {
            Object.keys(values).forEach((key) => {
                const k = key as keyof typeof values;
                values[k] = values[k] ?? 0;
            });
            await onUpdateRandomAssignValue(values);
        } catch (error) {
            message.error("มีบางอย่างผิดพลาด");
            console.log({ error });
        }
    };

    return (
        <workManagementContext.Provider value={workManagment}>
            <Spin spinning={isLoading}>
                <Container>
                    {!isLoading && (
                        <Form onFinish={onFinish} initialValues={initVal}>
                            <Row justify="end" style={{ padding: "16px", paddingBottom: "0px" }}>
                                <Button type="primary" htmlType="submit" style={{ width: "120px" }}>
                                    บันทึก
                                </Button>
                            </Row>
                            {data.map((item, key) => (
                                <WorkManagementCard key={`${key}-${item.title}`} {...item} />
                            ))}
                        </Form>
                    )}
                </Container>
            </Spin>
        </workManagementContext.Provider>
    );
}

export default WorkManagement;
