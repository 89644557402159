import { Upload } from "antd";
import styled from "styled-components";

export const CustomUpload = styled(Upload)`
    width: fit-content !important;
    & .ant-upload.ant-upload-select-picture-card {
        border-radius: 999px !important;
        overflow: hidden !important;
        ${({ error }: { error?: string }) =>
            error && `border-color: red !important; color: red !important;`}
    }
`;