import {
    Container,
    FooterTable,
    HeaderContainer,
    Modal,
    Paragraph,
    Title,
    TitleModalContainer,
} from "./index.styles";
import { columns as columnsMemo } from "./index.memo";
import React from "react";
import { Table, Tag } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import dayjs from "dayjs";
import { FormItem } from "../../common/CustomForm";
import TextArea from "antd/lib/input/TextArea";
import FooterModal from "../FooterModal";
import { transactionDecision } from "../alert";
import { negativeConfirmation, positiveConfirmation } from "../../../assets/sweetalert.config";
import { convertObjectToArray } from "../../../functions/convertObjectToArray";
import { formatNumber } from "../../../functions/formatNumber";
import { typeList } from "../../../assets/typeEnumTranslation";

const colorMapping: { [key: string]: any } = {
    approved: "green",
    denied: "red",
    pending: "yellow",
};

const wording: { [key: string]: any } = {
    approved: "อนุมัติ",
    denied: "ไม่อนุมัติ",
    pending: "รอการตอบรับ",
};

type ModalDetailPetitionProps = {
    visible: boolean;
    onOk?: () => void;
    onDenied?: () => void;
    onClose: () => void;
    editable: boolean;
    data: any;
};

const ModalDetailPetition: React.FC<ModalDetailPetitionProps> = (
    props: ModalDetailPetitionProps
) => {
    const { visible, onOk, onDenied, data, editable, onClose } = props;

    const shirt = ["rider", "labor"];
    const columns = React.useMemo(() => columnsMemo, []);
    const { xs } = useBreakpoint();

    const cancelModal = async () => {
        const result = await transactionDecision(
            negativeConfirmation({
                title: `คุณต้องการยกเลิกคำร้องนี้ใช่หรือไม่`,
                text: `กดตกลงเพื่อยืนยันการยกเลิกคำร้อง`,
            })
        );
        if (result) {
            onClose();
            onDenied!();
        }
    };

    const handleOk = async () => {
        const result = await transactionDecision(
            positiveConfirmation({
                title: `คุณต้องการอนุมัติคำร้องนี้ใช่หรือไม่`,
                text: `กดตกลงเพื่อยืนยันการอนุมัติคำร้อง`,
            })
        );
        if (result) {
            onClose();
            onOk!();
        }
    };

    return data ? (
        <Modal
            centered
            visible={visible}
            onCancel={onClose}
            title={
                <TitleModalContainer>
                    <Paragraph>
                        รายการยกเลิกของ{data.type && typeList[data.type]}{" "}
                        {shirt.includes(data.type)
                            ? `เลขเสื้อ ${data.shirtNumber}`
                            : `แผง ${data.boothCode}`}{" "}
                        อาคาร {data.building}
                    </Paragraph>
                    {!editable && (
                        <Tag color={colorMapping[data.status]}>{wording[data.status]}</Tag>
                    )}
                </TitleModalContainer>
            }
            footer={editable ? <FooterModal onOk={handleOk} onCancel={cancelModal} /> : null}
        >
            <Container>
                <HeaderContainer>
                    <Paragraph>ประจำวันที่ {dayjs(data.date).format("DD/MM/BBBB")}</Paragraph>
                    <Paragraph>ผู้จด {data.collectorName}</Paragraph>
                </HeaderContainer>
                <Table
                    rowKey={(record) => record["billingId"]}
                    columns={columns}
                    dataSource={convertObjectToArray(data.billingInvoice, "billingId")}
                    scroll={{ x: xs ? 800 : undefined }}
                    pagination={false}
                    summary={() => (
                        <Table.Summary.Row style={{ backgroundColor: "rgba(81, 167, 40, 0.15)" }}>
                            <Table.Summary.Cell index={0} align="center">
                                <Title>รวม</Title>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align="center">
                                <Title>{formatNumber(isNaN(data.amount) ? 0 : data.amount)}</Title>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
                />
                <FooterTable>
                    <Paragraph>
                        <u>ยื่นคำร้อง{!editable && "โดย"}</u> {!editable && data.collectorName}{" "}
                        วันที่ {dayjs(data.date).format("DD/MM/BBBB")}
                    </Paragraph>
                </FooterTable>
                <FormItem label="สาเหตุที่ต้องการยกเลิก">
                    <TextArea
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        showCount
                        maxLength={75}
                        value={data.description ? data.description : "-"}
                        readOnly
                    />
                </FormItem>
            </Container>
        </Modal>
    ) : (
        <></>
    );
};

export default ModalDetailPetition;
