import Payment from "./PaymentTemplate";
import usePayment from "./PaymentTemplate/usePayment";

const BoothPayment: React.FC = () => <Payment type="booth" usePayment={usePayment} />;
const VeggiePayment: React.FC = () => <Payment type="veggie" usePayment={usePayment} />;
const RiderPayment: React.FC = () => <Payment type="rider" usePayment={usePayment} />;
const LomsakPayment: React.FC = () => <Payment type="lomsak" usePayment={usePayment} />;
const LaborPayment: React.FC = () => <Payment type="labor" usePayment={usePayment} />;

export { BoothPayment, VeggiePayment, RiderPayment, LomsakPayment, LaborPayment };
