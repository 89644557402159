import React from "react";
import RegisterMonthlyTenantMemberForm from "../../../components/RegisterPage/Monthly/TenantMemberFrom";
import { Container, ContentBackground, CustomSteps } from "../../../components/common/CustomForm";
import useRegister, { RegisterContext } from "../useRegister";
import SignContract from "../../../components/RegisterPage/common/SignContract";
import RegisterMonthlyBoothForm from "../../../components/RegisterPage/Monthly/BoothForm";
import Success from "../../../components/RegisterPage/common/Success";
import { Spin } from "antd";

const { Step } = CustomSteps;
function RegisterMonthly() {
    const formType = "booth";
    const registerMonthly = useRegister();
    const { index, setIndex, getBuildingStorage, setTypeForm, isLoading, resultId, tenantForm } =
        registerMonthly;

    React.useEffect(() => {
        setTypeForm(formType);
        const fetch = async () => await getBuildingStorage(formType);
        fetch();
    }, [getBuildingStorage, setTypeForm]);

    const steps: {
        title: string;
        content: React.ReactNode;
    }[] = React.useMemo(
        () => [
            {
                title: "ข้อมูลผู้เช่าและสมาชิก",
                content: <RegisterMonthlyTenantMemberForm {...registerMonthly} />,
            },
            {
                title: "ข้อมูลแผงรายเดือน",
                content: <RegisterMonthlyBoothForm {...registerMonthly} />,
            },
            {
                title: "ลงลายมือชื่อ",
                content: <SignContract {...registerMonthly} />,
            },
            {
                title: "เสร็จสิ้น",
                content: (
                    <Success
                        contractCode={resultId}
                        firstName={tenantForm?.firstName0 || ""}
                        lastName={tenantForm?.lastName0 || ""}
                    />
                ),
            },
        ],
        [registerMonthly, resultId, tenantForm]
    );

    return (
        <RegisterContext.Provider value={registerMonthly}>
            <Spin spinning={isLoading}>
                <Container>
                    <ContentBackground>
                        <h1>การลงทะเบียนแผงรายเดือน</h1>
                        <CustomSteps current={index}>
                            {steps.map((item, key) => (
                                <Step
                                    key={key}
                                    title={item.title}
                                    onClick={() => key < index && index !== 3 && setIndex(key)}
                                    style={{ cursor: key < index ? "pointer" : undefined }}
                                />
                            ))}
                        </CustomSteps>
                        {steps[index].content}
                    </ContentBackground>
                </Container>
            </Spin>
        </RegisterContext.Provider>
    );
}

export default RegisterMonthly;
