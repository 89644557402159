import LayoutContract from "../../../components/common/LayoutContract";
import { Row, Col, Spin } from "antd";
import ModalCardManage from "../../../components/PriceManagementPage/ModalCardManage";
import { CardBuildingSelectorArray } from "../../../components/common/CardBuildingSelector";
import useLomsakManagement from "./useLomsakManagement";

function LomsakManagement() {
    const {
        data,
        isLoading,
        firstKey,
        visible,
        modalData,
        triggerModal,
        triggerCloseModal,
        setBoothKey,
        boothKey,
        fetchData,
        onSelectBuilding,
        zone,
    } = useLomsakManagement();

    const handleValue = () => {
        triggerModal();
    };

    return (
        <Spin spinning={isLoading}>
            <Row justify="space-between" style={{ marginTop: "1rem" }}>
                <Col xs={24} sm={24} lg={6} flex={1}>
                    <CardBuildingSelectorArray
                        type="booth"
                        data={data}
                        onSelect={onSelectBuilding}
                        buildingSelect={firstKey}
                        pageType={"lomsak"}
                    />
                </Col>
                <Col xs={24} sm={24} lg={17} flex={4}>
                    <LayoutContract
                        data={zone}
                        keyName="boothName"
                        altKeyName="boothCode"
                        pageType="lomsak"
                        trigger={handleValue}
                        getData={setBoothKey}
                        disableStatus={true}
                        canAccessInactive={true}
                    />
                </Col>
                <ModalCardManage
                    data={modalData}
                    visible={visible}
                    boothKey={boothKey}
                    handleOk={triggerModal}
                    handleCancel={triggerCloseModal}
                    fetchBackground={fetchData}
                    type="lomsak"
                />
            </Row>
        </Spin>
    );
}

export default LomsakManagement;
