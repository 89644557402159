import styled from "styled-components";
import { ZoomInOutlined as ZoomIn } from "@ant-design/icons";

export const ButtonContainer = styled.div`
    display: flex;
    column-gap: 2rem;
    justify-content: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ZoomInOutlined = styled(ZoomIn)`
    font-size: 1rem;
`;
