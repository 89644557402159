import styled from "styled-components";
import colors from "../../../assets/colors.json";
import { Typography } from "antd";

export const Container = styled.div`
    display: flex;
    background-color: rgba(242, 242, 242, 0.3);
    width: calc(100% - 4rem);
    justify-content: space-around;
    align-items: center;
    border: 1px solid ${colors.background};
    border-radius: 1rem;
    padding: 0.75rem 2rem;
    margin-top: 2.5rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    @media screen and (max-width: 768px) {
        width: calc(100% - 2rem);
        margin-top: 1.5rem;
    }
    @media screen and (orientation: portrait) and (max-width: 280px) {
        padding: 0.75rem 1rem;
    }
`;

export const Title = styled(Typography.Title)`
    margin: 0 !important;
    font-weight: 500 !important;
    @media screen and (orientation: portrait) and (max-width: 280px) {
        font-size: 0.9rem !important;
    }
`;
