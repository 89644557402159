import { Card, Typography } from "antd";
import styled from "styled-components";

export const AntCard = styled(Card).attrs({
    bordered: false,
})`
    &.ant-card {
        border-radius: 0.5rem;
    }
    & .ant-card-head {
        border-bottom: none;
    }
    & .ant-card-body {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: auto;
    }
    height: 100%;
`;

export const Title = styled(Typography.Title).attrs({
    level: 4,
})`
    margin-bottom: 0 !important;
`;
