import styled from "styled-components";
import breakpoint from "../../../../assets/breakpoint.json";

export const HeaderContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    @media (max-width: ${breakpoint.mobile}) {
        flex-direction: column;
    }
`;

export const DetailLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 360px;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    flex-wrap: wrap;
    @media (max-width: ${breakpoint.mobile}) {
        margin-bottom: 8px;
        flex-direction: column;
        width: 100%;
    }
`;

export const DetailLabel = styled.div`
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
    &::before {
        content: "color";
        color: transparent;
        height: 1rem;
        border-radius: 4px;
        background-color: ${(props) => props.color};
        margin-right: 0.5rem;
        font-size: 8px;
        padding: 8px 0;
    }
`;

export const CardMemberContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    min-height: 100px;
`;

export const ContainerDetailAndButton = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
`