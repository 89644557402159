import HeadColumn from "../../../components/ContractMemberManagementPage/HeadColumn";

export type ColumnsProps = {
    title: React.ReactNode;
    dataIndex: string | string[];
    render?: (text: string, record: any, index: number) => React.ReactNode;
    renderType?: string[];
};

export const columns: ColumnsProps[] = [
    {
        title: HeadColumn("เลขเสื้อ"),
        dataIndex: "shirtNumber",
        renderType: ["rider", "labor"],
    },
    {
        title: HeadColumn("อาคาร"),
        dataIndex: "building",
        renderType: ["booth", "veggie", "lomsak", "rider", "labor"],
    },

    {
        title: HeadColumn("สังกัดแผง"),
        dataIndex: "boothName",
        renderType: ["booth", "veggie", "lomsak"],
        render: (_: string, record: any, __: number) => {
            const index = record?.booth?.findIndex(
                (item: string) => item === record?.transactionCode
            );
            return record?.boothName[index] || "-";
        },
    },
    {
        title: HeadColumn("ชื่อผู้เช่า"),
        dataIndex: ["prefix", "firstName", "lastName", "companyName"],
        render: (_: string, record: any, __: number) =>
            record.firstName && record.lastName
                ? `${record.prefix !== undefined ? record.prefix + " " : ""} ${record.firstName} ${
                      record.lastName
                  }`
                : record.companyName,
        renderType: ["booth", "veggie", "lomsak", "rider", "labor"],
    },
    {
        title: HeadColumn("เบอร์โทรศัพท์"),
        dataIndex: "tel",
        renderType: ["booth", "veggie", "lomsak", "rider", "labor"],
    },
];
