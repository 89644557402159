import Swal from "sweetalert2";
import { positiveConfirmation } from "../../assets/sweetalert.config";
import { IPricing } from "../../interfaces/PriceManagement";

const convertKeyToWord = (key: string) => {
    const item: { [key: string]: string } = {
        "boothShirt-yearlyPrice": "ราคาเสื้อแผงรายปี",
        "laborShirt-monthlyPrice": "ราคาเสื้อขึ้นของรายเดือน",
        "laborShirt-yearlyPrice": "ราคาเสื้อขึ้นของรายปี",
        "laborShirt-maintaining": "ค่ารักษาสิทธิ์เสื้อขึ้นของ",
        "riderShirt-monthlyPrice": "ราคาเสื้อวินรายเดือน",
        "riderShirt-yearlyPrice": "ราคาเสื้อวินรายปี",
        "riderShirt-maintaining": "ค่ารักษาสิทธิ์เสื้อวิน",
        "lomsakCar-_4w": "ค่าเช่าประเภท 4 ล้อ (รถหล่ม)",
        "lomsakCar-_6w": "ค่าเช่าประเภท 6 ล้อ (รถหล่ม)",
        "lomsakCar-_10w": "ค่าเช่าประเภท 10 ล้อ (รถหล่ม)",
        "parking-parkingPrice": "ค่าที่จอดรถรายเดือน",
        "electricity-pricePerUnit": "ค่ามิเตอร์ไฟต่อหน่วย",
        "electricity-fifteenAmps": "ค่ามิเตอร์ไฟฟ้า 15 แอมป์ต่อหน่วย",
        "electricity-threePhase": "ค่ามิเตอร์ไฟฟ้า 3 เฟสต่อหน่วย",
        "water-pricePerUnit": "ค่ามิเตอร์นํ้าต่อหน่วย",
        accountId: "เลขที่บัญชี",
        accountName: "ชื่อบัญชี",
        bankName: "ชื่อธนาคาร",
    };
    return item[key];
};

export const editAlert = async (data: IPricing) => {
    let text = "";
    Object.keys(data).forEach((key) => {
        text += `${convertKeyToWord(key)} เป็น ${data[key]} บาท <br />`;
        if (key === "qrCode") text += `เปลี่ยนรูป QR Code <br />`;
    });

    const result = await Swal.fire({
        ...positiveConfirmation({
            icon: "warning",
            html: `<h3>คุณต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
            showCancelButton: true,
        }),
    });
    return result.value;
};

export const editOtherAlert = async (data: IPricing) => {
    let text = "";
    Object.keys(data).forEach((key) => {
        if (key === "qrCode") text += `เปลี่ยนรูป QR Code <br />`;
        else text += `${convertKeyToWord(key)} เป็น ${data[key]} <br />`;
    });

    const result = await Swal.fire({
        ...positiveConfirmation({
            icon: "warning",
            html: `<h3>คุณต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
        }),
    });
    return result.value;
};

export const errorAlert = async () => {
    const result = await Swal.fire({
        icon: "error",
        title: `ไม่มีข้อมูลที่ถูกแก้ไข`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};

export const successAlert = async (messages: string) => {
    const result = await Swal.fire({
        icon: "success",
        title: `การ${messages}สำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};

export const editBuildingAlert = async () => {
    const result = await Swal.fire({
        ...positiveConfirmation({
            icon: "warning",
            html: `<h3>คุณต้องการเพิ่มข้อมูลดังกล่าวใช่หรือไม่?</h3>`,
        }),
    });
    return result.value;
};
