import React from "react";
import { CustomModal, SubmitButton } from "./index.style";
import { useMemberDetailContext } from "../../../../pages/MemberManagement/Staff/MemberDetail/useMemberDetail";
import { Avatar, Form, message, notification, Row, Select, Spin } from "antd";
import { CustomSelect, MaxSelectorHint } from "../ModalUpdateWorkList/index.style";
import { rolesEnToTh, rolesIgnore } from "../../../../assets/roles";
import { RowGroupInput } from "../../../common/CustomForm";
import { antdFileToBase64, base64ToBlob } from "../../../../functions/files";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import CustomUpload, { UploadButton } from "../../../common/CustomUpload";
import * as swlConfig from "../../../../assets/sweetalert.config";
import validator from "../../../../assets/formValidator";
import Swal from "sweetalert2";
import { uploadImageReturnUrl } from "../../../../functions/storage";
import { createUser } from "../../../../apis/user";
import { AxiosError } from "axios";

const { Option } = Select;
const rolesList: string[] = Object.keys(rolesEnToTh).filter((item) => !rolesIgnore.includes(item));

type MemberFormProps = {
    name: string;
    email: string;
    mainDuty: string;
    ssn: string;
    tel: string;
    dutyPool: string[];
};

function ModalCreateMember() {
    const { openModalCreateMember, setOpenModalCreateMember, fetchDataPage } =
        useMemberDetailContext();
    const [dutyPool, setDutyPool] = React.useState<string[]>([]);
    const [mainDuty, setMainDuty] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [imageBase64, setImageBase64] = React.useState<string>();
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();

    const [form] = Form.useForm<MemberFormProps>();

    React.useEffect(() => {
        form.resetFields();
        setDutyPool([]);
        setMainDuty("");
        setImageBase64("");
    }, [openModalCreateMember, form]);

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadImage = React.useCallback(async () => {
        if (!imageBase64) return;
        const blob = await base64ToBlob(imageBase64);
        const urls = await uploadImageReturnUrl("images/users", [blob]);
        return urls[0];
    }, [imageBase64]);

    const onFinish = React.useCallback(
        async (data: MemberFormProps) => {
            setIsLoading(true);
            const photoURL = await uploadImage();
            try {
                await createUser({
                    ...data,
                    photoURL,
                    duty: { mainDuty: data.mainDuty, dutyPool },
                });
                setOpenModalCreateMember(false);
                await fetchDataPage();
            } catch (error) {
                const err = error as AxiosError<
                    {
                        code: string;
                    }[]
                >;
                const errs = err.response?.data || [];
                const code = errs.length >= 1 && errs[0].code;
                message.error(
                    code === "auth/email-already-exists"
                        ? "อีเมลนี้มีผู้ใช้แล้ว"
                        : "เกิดข้อผิดพลาดบางอย่าง"
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dutyPool, uploadImage, setOpenModalCreateMember, fetchDataPage]
    );

    const onCancel = React.useCallback(async () => {
        const { isConfirmed } = await Swal.fire(
            swlConfig.positiveConfirmation({
                title: "ยกเลิกการเพิ่มพนักงาน!",
                text: `กรุณายืนยันเพื่อยกเลิกการเพิ่มพนักงาน`,
            })
        );
        if (isConfirmed) return setOpenModalCreateMember(false);
    }, [setOpenModalCreateMember]);

    return (
        <CustomModal
            visible={openModalCreateMember}
            destroyOnClose
            onCancel={onCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <Form form={form} onFinish={onFinish}>
                    <Row justify="center">
                        <CustomUpload
                            error={imageError}
                            name="รูปพนักงาน"
                            listType="picture-card"
                            showUploadList={false}
                            onChange={onChangeImage}
                            customRequest={({ onSuccess }) => onSuccess && onSuccess("ok")}
                        >
                            {imageBase64 ? (
                                <Avatar
                                    src={imageBase64}
                                    alt="avatar"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            ) : (
                                <UploadButton loadingImage={loadingImage} imageError={imageError} />
                            )}
                        </CustomUpload>
                    </Row>
                    <RowGroupInput
                        inputs={[
                            {
                                name: "name",
                                label: "ชื่อ - สกุล",
                                placeholder: "กรุณากรอกชื่อ - สกุล",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกชื่อ - สกุล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "email",
                                label: "อีเมล",
                                placeholder: "กรุณากรอกอีเมล",
                                rules: [
                                    {
                                        required: true,
                                        type: "email",
                                        message: "กรุณากรอกอีเมล",
                                    },
                                ],
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: `tel`,
                                label: "เบอร์โทรศัพท์",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกเบอร์โทรศัพท์",
                                    },
                                    ...validator.phoneNumber,
                                ],
                                placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                                marginBottom: 0,
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "mainDuty",
                                label: "หน้าที่หลัก",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกหน้าที่หลัก",
                                    },
                                ],
                                marginBottom: 0,
                                customInput: (
                                    <CustomSelect
                                        placeholder="กรุณาเลือกหน้าที่หลัก"
                                        value={mainDuty}
                                        onChange={(value) => setMainDuty(value as string)}
                                    >
                                        {rolesList
                                            .filter((item) => !dutyPool?.includes(item))
                                            .map((item) => (
                                                <Option key={item} value={item}>
                                                    {rolesEnToTh[item]}
                                                </Option>
                                            ))}
                                    </CustomSelect>
                                ),
                            },
                        ]}
                    />
                    <RowGroupInput
                        inputs={[
                            {
                                name: "dutyPool",
                                label: "หน้าที่สามารถทำได้",
                                marginBottom: 0,
                                customInput: (
                                    <>
                                        <CustomSelect
                                            mode="multiple"
                                            value={dutyPool}
                                            placeholder="กรุณาเลือกหน้าที่สามารถทำได้"
                                            maxTagCount={5}
                                            onChange={(value) => {
                                                const val = value as string[];
                                                return setDutyPool((old) => {
                                                    if (val.length > 5) {
                                                        form.setFieldsValue({ dutyPool: old });
                                                        return old;
                                                    }
                                                    return val;
                                                });
                                            }}
                                        >
                                            {rolesList
                                                .filter((item) => item !== mainDuty)
                                                .map((item) => (
                                                    <Option key={item} value={item}>
                                                        {rolesEnToTh[item]}
                                                    </Option>
                                                ))}
                                        </CustomSelect>
                                        <MaxSelectorHint>
                                            จำนวนหน้าที่ทั้งหมด{" "}
                                            <span>{dutyPool?.length || 0} / 5</span>
                                        </MaxSelectorHint>
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Row justify="center">
                        <SubmitButton htmlType="submit">บันทึก</SubmitButton>
                    </Row>
                </Form>
            </Spin>
        </CustomModal>
    );
}

export default ModalCreateMember;
