import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import styled from "styled-components";

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
const { RangePicker } = DatePicker;

export default styled(RangePicker)`
    & .ant-picker-panels {
        flex-direction: column !important;
        background-color: #00f !important;
    }
`;
