import React from "react";
import { AntCard, LogoContainer, RowTitleCard, Title } from "./index.styles";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";
import Chart from "react-apexcharts";
import { defaultOptions } from "./config";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import FilterWithDatePicker from "../FilterWithDatePicker";
import { renderBoothCardOption } from "../utility/mapping";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import { Spin } from "antd";

type BoothAreaGraphProps = {
    type: "booth" | "veggie";
    filter?: PickerProps<Dayjs>["picker"][];
};
const BoothAreaGraph: React.FC<BoothAreaGraphProps> = (props: BoothAreaGraphProps) => {
    const { type, filter } = props;
    const [date, setDate] = React.useState<Dayjs | null>(dayjs());
    const { isLoadingComponent, fetchGraphComponent, graphData } = useDashboardProvider();

    const optionFilter = React.useMemo(() => {
        if (!filter) return ["date", "week", "month", "year"] as PickerProps<Dayjs>["picker"][];
        else return filter as PickerProps<Dayjs>["picker"][];
    }, [filter]);

    const [value, setValue] = React.useState<PickerProps<Dayjs>["picker"]>(
        optionFilter[0] as PickerProps<Dayjs>["picker"]
    );

    React.useEffect(() => {
        fetchGraphComponent(value!, type, +date!);
    }, [value, date, type, fetchGraphComponent]);

    const { xs } = useBreakpoint();
    return (
        <AntCard
            title={
                <RowTitleCard>
                    <LogoContainer>{renderBoothCardOption[type].logo}</LogoContainer>
                    <Title>{renderBoothCardOption[type].name} - ข้อมูลเชิงลึก</Title>
                </RowTitleCard>
            }
            extra={
                <FilterWithDatePicker
                    value={value}
                    date={date}
                    onChange={(v) => setDate(v)}
                    setFilter={(v) => setValue(v)}
                    useOption={optionFilter}
                />
            }
        >
            {!isLoadingComponent && graphData[`${type}-${value!}`] ? (
                <Chart
                    options={defaultOptions(
                        value!,
                        date!,
                        [`ยอดขาย ${renderBoothCardOption[type].name}`],
                        xs
                    )}
                    series={graphData[`${type}-${value!}`]}
                    type="area"
                    height={350}
                />
            ) : (
                <Spin />
            )}
        </AntCard>
    );
};

export default BoothAreaGraph;
