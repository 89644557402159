import { Select, Spin } from "antd";
import { AntCard, Title } from "./index.styles";
import Chart from "react-apexcharts";
import { options } from "./config";
import React from "react";
import { useDashboardProvider } from "../../../providers/DashboardProvider";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Option } = Select;

const titleMapping: { [key: string]: any } = {
    รายรับ: "รายละเอียดรวมรายรับทั้งหมด",
    รายจ่าย: "รายละเอียดรวมยอดชำระแล้วทั้งหมด",
};

type IncomeColumnGraphProps = {};

const IncomeColumnGraph: React.FC<IncomeColumnGraphProps> = () => {
    const [optionValue, setOptionValue] = React.useState<string>("รายรับ");
    const { isLoading, summaryCard } = useDashboardProvider();
    const { xs } = useBreakpoint();

    return (
        <AntCard
            title={<Title>{titleMapping[optionValue]}</Title>}
            extra={
                !xs && (
                    <Select
                        defaultValue="รายรับ"
                        style={{ width: 120 }}
                        onChange={(v) => setOptionValue(v)}
                    >
                        <Option value="รายรับ">รายรับ</Option>
                        <Option value="รายจ่าย">รายจ่าย</Option>
                    </Select>
                )
            }
        >
            {!isLoading && summaryCard && summaryCard["รายรับ"] && summaryCard["รายจ่าย"] ? (
                <>
                    {xs && (
                        <Select
                            defaultValue="รายรับ"
                            style={{ width: 150 }}
                            onChange={(v) => setOptionValue(v)}
                        >
                            <Option value="รายรับ">รายรับ</Option>
                            <Option value="รายจ่าย">ยอดชำระแล้ว</Option>
                        </Select>
                    )}
                    <Chart
                        options={options(optionValue, xs)}
                        series={[summaryCard[optionValue]]}
                        type="bar"
                        height={200}
                    />
                </>
            ) : (
                <Spin />
            )}
        </AntCard>
    );
};

export default IncomeColumnGraph;
