import { Badge } from "antd";
import styled from "styled-components";
import { PrinterOutlined as Export, FileAddOutlined as AddFile } from "@ant-design/icons";
import SearchInput from "../../components/common/SearchInput";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const PrinterOutlined = styled(Export)`
    font-size: 1.25rem;
`;

export const FileAddOutlined = styled(AddFile)`
    font-size: 1.25rem;
`;

export const BadgeIcon = styled(Badge).attrs({
    size: "small",
    overflowCount: 999,
    offset: [-12, -5],
})``;

export const Content = styled.div`
    padding: 16px;
    margin: 24px;
    background-color: #ffffff;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 1rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    & h2 {
        text-align: left;
    }
`;

export const CustomSearchInput = styled(SearchInput)`
    width: 100%;
    max-width: 240px;
    margin-right: 16px;
    @media (max-width: 425px) {
        max-width: 100%;
        margin-right: 0;
    }
`;
