import { PageHeader, Row, Col, Button, Table, Tag, Typography, Image } from "antd";
import React from "react";
import {
    Container,
    HeaderContainer,
    FileAddOutlined,
    PrinterOutlined,
    Content,
    CustomSearchInput,
} from "./index.style";
import ModalExport from "./ModalExport";
import type { ColumnsType } from "antd/lib/table";
import { IncomeExpensesTable } from "../../interfaces/IncomesAndExpenses";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/th_TH";

import { formatNumber } from "../../functions/formatNumber";
import { typeIncomeExpenses } from "../../assets/transaction";
import useIncomesAndExpenses, { MemberDetailContext } from "./useIncomesAndExpenses";
import ModalCreateTransactionIE from "../../components/IncomesAndExpensesPage/ModalCreateTransactionIE";
import RangePicker from "../../components/common/RangePicker";

const { Text } = Typography;

const columns: ColumnsType<IncomeExpensesTable> = [
    {
        title: "วันที่ลงรายการ",
        dataIndex: "date",
        render: (date: number) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
        title: "ประเภท",
        dataIndex: "billingType",
        filters: Object.keys(typeIncomeExpenses).map((key) => ({
            text: typeIncomeExpenses[key as keyof typeof typeIncomeExpenses],
            value: key,
        })),
        onFilter: (value, record) =>
            record.billingType ? record.billingType?.toString().includes(value.toString()) : false,
        render: (type, record) => (
            <Tag color={record.IEType === "income" ? "green" : "red"}>
                {typeIncomeExpenses[type as keyof typeof typeIncomeExpenses] || type}
            </Tag>
        ),
    },
    {
        title: "รายการ",
        dataIndex: "IEName",
    },
    {
        title: "หมายเหตุ",
        dataIndex: "description",
    },
    {
        title: "สลิปโอนเงิน",
        dataIndex: "slipUrl",
        render: (slipUrl) => (
            <>{slipUrl ? <Image width={40} height={60} src={slipUrl} /> : "จ่ายเงินสด"}</>
        ),
    },
    {
        title: "จำนวนเงิน",
        dataIndex: "amount",
        filters: [
            {
                text: "รายรับ",
                value: "income",
            },
            {
                text: "รายจ่าย",
                value: "expenses",
            },
        ],
        onFilter: (value, record) => record.IEType === value,
        render: (amount, record) =>
            record.IEType === "income" ? (
                <Text type="success">{formatNumber(+amount)}</Text>
            ) : (
                <Text type="danger">{formatNumber(-+amount)}</Text>
            ),
        align: "right",
    },
    {
        title: "ผู้ลงรายการ",
        dataIndex: "IEWriter",
    },
];

export const IncomesAndExpenses: React.FC = () => {
    const incomesAndExpenses = useIncomesAndExpenses();
    const [isExportModalVisible, setIsExportModalVisible] = React.useState(false);
    const {
        data,
        setMonthYear,
        isLoading,
        handleSearch,
        handleChangeSearch,
        rowSelection,
        handleOpenModal,
        recordSelected,
    } = incomesAndExpenses;

    return (
        <MemberDetailContext.Provider value={incomesAndExpenses}>
            <Container>
                <ModalCreateTransactionIE />
                <HeaderContainer>
                    <PageHeader title="จัดการรายรับ-รายจ่าย" />
                    <Row style={{ padding: "0 24px" }} gutter={[0, 16]}>
                        <Row
                            style={{ flex: 1 }}
                            children={
                                <CustomSearchInput
                                    onChange={handleChangeSearch}
                                    placeholder="ค้นหารายการรายรับ-รายจ่าย"
                                />
                            }
                        />
                        <Row justify="end" gutter={[12, 12]}>
                            <Col
                                style={{ flex: 1 }}
                                children={
                                    <Button
                                        style={{ width: "100%" }}
                                        icon={<FileAddOutlined />}
                                        onClick={handleOpenModal}
                                    >
                                        เพิ่มรายการ
                                    </Button>
                                }
                            />
                            <ModalExport
                                isVisible={isExportModalVisible}
                                handleCancel={() => setIsExportModalVisible(false)}
                                recordSelected={recordSelected}
                            />
                            <Col
                                style={{ flex: 1 }}
                                children={
                                    <Button
                                        style={{ width: "100%" }}
                                        icon={<PrinterOutlined />}
                                        onClick={() => setIsExportModalVisible(true)}
                                    >
                                        นำออกเอกสาร
                                    </Button>
                                }
                            />
                            <Col
                                style={{ flex: 1.5 }}
                                children={
                                    <RangePicker
                                        style={{ minWidth: "120px", width: "100%" }}
                                        allowClear={false}
                                        format="DD/MM/YYYY"
                                        locale={locale}
                                        defaultValue={[
                                            dayjs().startOf("month"),
                                            dayjs().endOf("month"),
                                        ]}
                                        onChange={(date) =>
                                            setMonthYear([
                                                date && date[0]
                                                    ? +date[0]
                                                    : +dayjs().startOf("month"),
                                                date && date[1]
                                                    ? +date[1]
                                                    : +dayjs().endOf("month"),
                                            ])
                                        }
                                    />
                                }
                            />
                        </Row>
                    </Row>
                    <Content>
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={handleSearch(data)}
                            rowSelection={rowSelection}
                            pagination={{
                                showSizeChanger: true,
                            }}
                            rowKey={(record) => record.id}
                        />
                    </Content>
                </HeaderContainer>
            </Container>
        </MemberDetailContext.Provider>
    );
};

export default IncomesAndExpenses;
