import {
    DesktopOutlined,
    UserOutlined,
    MoneyCollectOutlined,
    DatabaseOutlined,
    TeamOutlined,
    FormOutlined,
    DollarOutlined,
    FileProtectOutlined,
    IdcardOutlined,
    InteractionOutlined,
} from "@ant-design/icons";
import LoginPage from "../pages/Login";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import MemberManagementStaff from "../pages/MemberManagement/Staff";
import PriceManagement from "../pages/PriceManagement";
import RegisterMonthly from "../pages/Register/Monthly";
import RegisterVeggie from "../pages/Register/Veggie";
import { MeterBoothElectricity, MeterBoothWater } from "../pages/Meter/MeterBooth";
import { MeterVeggieElectricity, MeterVeggieWater } from "../pages/Meter/MeterVeggie";
import { MeterLomsakElectricity, MeterLomsakWater } from "../pages/Meter/MeterLomsak";
import ContractMemberManagement from "../pages/ContractMemberManagement";
import RegisterLine from "../pages/RegisterLine";
import RegisterRiderShirt from "../pages/Register/Shirt/RiderShirt";
import RegisterLaborShirtPage from "../pages/Register/Shirt/LaborShirt";
import RegisterLomsakPage from "../pages/Register/Car/Lomsak";
import TransactionReport from "../pages/TransactionReport";
import Transaction from "../pages/Transaction";
import TestQRLine from "../pages/TestQRLine";
import IncomesAndExpenses from "../pages/IncomesAndExpenses";
import { Storefront, Leaf, Truck, Bicycle, TShirt, Coin } from "phosphor-react";
import {
    BoothPayment,
    VeggiePayment,
    RiderPayment,
    LomsakPayment,
    LaborPayment,
} from "../pages/Payment";
import AdminManagement from "../pages/AdminManagement";
import Dashboard from "../pages/Dashboard";
import { roleToPhat } from "../assets/roles";
import PDPA from "../pages/PDPA";
import TestPage from "../pages/TestPage";
import Daily from "../pages/Daily";

export type MenuType = {
    type: "menu" | "listMenu" | "hide";
    path: string;
    icon?: React.ReactNode;
    title: string;
    name: string;
    component?: React.ReactNode;
    isAcceptRoles?: boolean;
    list?: {
        path: string;
        title: string;
        component: React.ReactNode;
        isAcceptRoles?: boolean;
        isHideMenu?: boolean;
    }[];
    isHideLayout?: true;
};

const AuthMiddleware = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const auth = useAuthContext();
    if (auth.isLoading) return <div>loading...</div>;
    return auth.user ? children : <Navigate to="/" state={{ from: location }} />;
};

export const converter = (routesList: MenuType[]) => {
    const routes: {
        path: string;
        component: React.ReactNode;
        isHideLayout?: true;
        isAcceptRoles?: boolean;
    }[] = [];
    routesList.forEach((route: MenuType) => {
        if (route.type === "listMenu") {
            route.list?.forEach((list) => {
                routes.push({
                    path: `${route.path}${list.path}`,
                    component: list.component,
                    isAcceptRoles: list.isAcceptRoles,
                });
            });
        } else {
            routes.push({
                path: route.path,
                component: route.component,
                isHideLayout: route.isHideLayout,
                isAcceptRoles: route.isAcceptRoles,
            });
        }
    });
    return routes;
};

export const ROUTES = () => {
    const auth = useAuthContext();
    const rolesUser = auth.user?.roles;
    const acceptRoles = (roles?: string[]) => {
        if (!roles) return true;
        const accept = roles.filter((role) => rolesUser?.includes(role));
        if (accept.length === 0) return false;
        else return true;
    };
    const routes: MenuType[] = [
        {
            path: "/dashboard",
            icon: <DesktopOutlined />,
            type: "menu",
            title: "ภาพรวม",
            name: "dashboard",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <Dashboard />
                </AuthMiddleware>
            ),
        },
        {
            path: "/daily",
            icon: <Coin size={18} />,
            type: "menu",
            title: "สรุปยอดวันนี้",
            name: "daily",
            isAcceptRoles: !acceptRoles(["adminContract"]),
            component: (
                <AuthMiddleware>
                    <Daily />
                </AuthMiddleware>
            ),
        },
        {
            type: "menu",
            icon: <MoneyCollectOutlined />,
            title: "รายการชำระเงิน",
            name: "transaction",
            path: "/transaction",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <Transaction />
                </AuthMiddleware>
            ),
        },
        {
            path: "/incomeAndExpense",
            type: "menu",
            icon: <InteractionOutlined rotate={90} />,
            title: "จัดการรายรับ-รายจ่าย",
            name: "incomesAndExpenses",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <IncomesAndExpenses />
                </AuthMiddleware>
            ),
        },
        {
            type: "listMenu",
            title: "ลงทะเบียน",
            name: "register",
            icon: <UserOutlined />,
            path: "/register",
            isAcceptRoles: acceptRoles(["admin", "adminContract", "staff"]),
            list: [
                {
                    path: "/monthly",
                    title: "แผงรายเดือน",
                    component: (
                        <AuthMiddleware>
                            <RegisterMonthly />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin", "adminContract"]),
                },
                {
                    path: "/veggie",
                    title: "แผงผักบ้าน",
                    component: (
                        <AuthMiddleware>
                            <RegisterVeggie />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin", "adminContract"]),
                },
                {
                    path: "/riderShirt",
                    title: "เสื้อวิน",
                    component: (
                        <AuthMiddleware>
                            <RegisterRiderShirt />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin", "adminContract"]),
                },
                {
                    path: "/laborShirt",
                    title: "เสื้อขึ้นของ",
                    component: (
                        <AuthMiddleware>
                            <RegisterLaborShirtPage />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin", "adminContract"]),
                },
                {
                    path: "/LomsakCar",
                    title: "รถหล่ม",
                    component: (
                        <AuthMiddleware>
                            <RegisterLomsakPage />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin", "adminContract"]),
                },
            ],
        },
        {
            type: "hide",
            path: "/transaction/booth/:id",
            title: "แผงรายเดือน",
            name: "transactionBooth",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <TransactionReport type="booth" />
                </AuthMiddleware>
            ),
        },
        {
            type: "hide",
            path: "/transaction/veggie/:id",
            title: "แผงผักบ้าน",
            name: "transactionVeggie",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <TransactionReport type="veggie" />
                </AuthMiddleware>
            ),
        },
        {
            type: "hide",
            path: "/transaction/rider/:id",
            title: "เสื้อวิน",
            name: "transactionRider",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <TransactionReport type="rider" />
                </AuthMiddleware>
            ),
        },
        {
            type: "hide",
            path: "/transaction/labor/:id",
            title: "เสื้อขึ้นของ",
            name: "transactionLabor",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <TransactionReport type="labor" />
                </AuthMiddleware>
            ),
        },
        {
            type: "hide",
            path: "/transaction/lomsak/:id",
            title: "รถหล่ม",
            name: "transactionLomsak",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <TransactionReport type="lomsak" />
                </AuthMiddleware>
            ),
        },
        {
            path: "/database",
            //TODO change to menu
            type: "hide",
            icon: <DatabaseOutlined />,
            title: "ฐานข้อมูล",
            name: "database",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <div>ฐานข้อมูล</div>
                </AuthMiddleware>
            ),
        },
        {
            path: "/memberManagement",
            type: "menu",
            icon: <TeamOutlined />,
            title: "จัดการพนักงาน",
            name: "memberManagement",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <MemberManagementStaff />
                </AuthMiddleware>
            ),
        },
        {
            path: "/adminManagement",
            type: "menu",
            title: "จัดการผู้ดูแลระบบ",
            name: "adminManagement",
            icon: <IdcardOutlined />,
            component: (
                <AuthMiddleware>
                    <AdminManagement />
                </AuthMiddleware>
            ),
            isAcceptRoles: acceptRoles(["admin"]),
        },
        {
            path: "/contractManagement",
            type: "menu",
            title: "จัดการสัญญา",
            name: "contractManagement",
            icon: <FileProtectOutlined />,
            component: (
                <AuthMiddleware>
                    <ContractMemberManagement />
                </AuthMiddleware>
            ),
            isAcceptRoles: acceptRoles(["admin", "adminContract"]),
        },

        {
            path: "/meterLomsak",
            type: "listMenu",
            icon: <FormOutlined />,
            title: "มิเตอร์รถหล่ม",
            name: "meterLomsak",
            isAcceptRoles: acceptRoles(["meterLomsak"]),
            list: [
                {
                    path: "/electricity",
                    title: "จดมิเตอร์ไฟฟ้า",
                    component: (
                        <AuthMiddleware>
                            <MeterLomsakElectricity />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterLomsak", "admin"]),
                },
                {
                    path: "/water",
                    title: "จดมิเตอร์น้ำ",
                    component: (
                        <AuthMiddleware>
                            <MeterLomsakWater />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterLomsak", "admin"]),
                },
            ],
        },
        {
            path: "/priceManagement",
            type: "menu",
            icon: <DollarOutlined />,
            title: "จัดการราคา",
            name: "priceManagement",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <PriceManagement />
                </AuthMiddleware>
            ),
        },
        {
            path: "/meterBooth",
            type: "listMenu",
            icon: <FormOutlined />,
            title: "มิเตอร์แผงรายเดือน",
            name: "meterBooth",
            isAcceptRoles: acceptRoles(["meterBooth", "admin"]),
            list: [
                {
                    path: "/electricity",
                    title: "จดมิเตอร์ไฟฟ้า",
                    component: (
                        <AuthMiddleware>
                            <MeterBoothElectricity />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterBooth", "admin"]),
                },
                {
                    path: "/water",
                    title: "จดมิเตอร์น้ำ",
                    component: (
                        <AuthMiddleware>
                            <MeterBoothWater />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterBooth", "admin"]),
                },
            ],
        },
        {
            path: "/meterVeggie",
            type: "listMenu",
            icon: <FormOutlined />,
            title: "มิเตอร์แผงผักบ้าน",
            name: "meterVeggie",
            isAcceptRoles: acceptRoles(["meterVeggie", "admin"]),
            list: [
                {
                    path: "/electricity",
                    title: "จดมิเตอร์ไฟฟ้า",
                    component: (
                        <AuthMiddleware>
                            <MeterVeggieElectricity />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterVeggie", "admin"]),
                },
                {
                    path: "/water",
                    title: "จดมิเตอร์น้ำ",
                    component: (
                        <AuthMiddleware>
                            <MeterVeggieWater />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["meterVeggie", "admin"]),
                },
            ],
        },
        {
            path: "/boothPayment",
            type: "menu",
            icon: <Storefront size={18} />,
            title: "แผงรายเดือน",
            name: "boothPayment",
            isAcceptRoles: acceptRoles(["booth", "admin"]),
            component: (
                <AuthMiddleware>
                    <BoothPayment />
                </AuthMiddleware>
            ),
        },
        {
            path: "/veggiePayment",
            type: "menu",
            icon: <Leaf size={18} />,
            title: "แผงผักบ้าน",
            name: "veggiePayment",
            isAcceptRoles: acceptRoles(["veggie", "admin"]),
            component: (
                <AuthMiddleware>
                    <VeggiePayment />
                </AuthMiddleware>
            ),
        },
        {
            path: "/lomsakPayment",
            type: "menu",
            icon: <Truck size={18} />,
            title: "รถหล่ม",
            name: "lomsakPayment",
            isAcceptRoles: acceptRoles(["lomsak", "admin"]),
            component: (
                <AuthMiddleware>
                    <LomsakPayment />
                </AuthMiddleware>
            ),
        },
        {
            path: "/riderPayment",
            type: "menu",
            icon: <Bicycle size={18} />,
            title: "เสื้อวิน",
            name: "riderPayment",
            isAcceptRoles: acceptRoles(["rider", "admin"]),
            component: (
                <AuthMiddleware>
                    <RiderPayment />
                </AuthMiddleware>
            ),
        },
        {
            path: "/laborPayment",
            type: "menu",
            icon: <TShirt size={18} />,
            title: "เสื้อขึ้นของ",
            name: "laborPayment",
            isAcceptRoles: acceptRoles(["labor", "admin"]),
            component: (
                <AuthMiddleware>
                    <LaborPayment />
                </AuthMiddleware>
            ),
        },

        {
            path: "/registerLine",
            type: "hide",
            title: "RegisterLine",
            name: "RegisterLine",
            component: auth.isLoading ? null : <RegisterLine />,
        },
        {
            path: "/testQRLine",
            type: "hide",
            title: "testQRLine",
            name: "testQRLine",
            component: <TestQRLine />,
        },
        {
            path: "/hide",
            type: "hide",
            title: "hide",
            name: "hide",
            component: <TestPage />,
        },
        {
            path: "/PDPA",
            type: "hide",
            title: "PDPA",
            name: "PDPA",
            component: <PDPA />,
        },
        {
            path: "/",
            type: "hide",
            title: "login",
            name: "login",
            component: auth.user ? (
                <Navigate to={roleToPhat[auth.user.mainDuty] || "/daily"} />
            ) : (
                <LoginPage />
            ),
            isHideLayout: true,
        },
    ];
    return routes;
};
