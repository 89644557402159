import { Form } from "antd";
import React from "react";
import { FormInstanceType } from "../ModalComponents/FormContent";
import dayjs from "dayjs";
import {
    IBooth,
    IVeggie,
    IRider,
    ILabor,
    ILomsak,
    IBoothMember,
} from "../../../interfaces/Contract";
import {
    deleteContract,
    deleteMemberContract,
    getActiveContract,
    getAllContractWithType,
    patchContract,
    patchContractMember,
} from "../../../apis/contract";
import { deleteAlert, editAlert, exitOnEditAlert, timerAlert } from "../alert";
import { catchingError, showNotification } from "../../../functions/notification";
import { getIncludes } from "./utility/includes";
import { filterValues } from "./utility/filterValues";
import { initValuesForm } from "./utility/initValuesForm";
import { downloadContractPromise } from "../../../apis/register";

export type IContractResponse = (IBooth | IVeggie | IRider | ILabor | ILomsak) & {
    [key: string]: any;
};

function useContractTable(type: FormInstanceType) {
    const [dataStorage, setDataStorage] = React.useState<IContractResponse[]>([]);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [recordRow, setRecordRow] = React.useState<IBoothMember | IContractResponse>();
    const [visible, setVisible] = React.useState<boolean>(false);
    const [visibleInner, setVisibleInner] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const deleteTyped = [""];
            let data = deleteTyped.includes(type)
                ? await getActiveContract(type)
                : await getAllContractWithType(type);
            setDataStorage(data as IContractResponse[]);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [type, setIsLoading]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = React.useCallback(
        (search: string, list: IContractResponse[]) =>
            list.filter((item) => {
                const date = dayjs(item.startDate).format("DD/MM/YYYY");
                return getIncludes(search, item, date);
            }),
        []
    );

    const openModal = () => setVisible(!visible);

    const closeModal = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            setEdit(false);
            setVisible(false);
        }
        setEdit(false);
    };

    const triggerLoading = () => setIsLoading(!isLoading);

    const searchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value);

    const openInnerModal = () => setVisibleInner(!visibleInner);

    const closeInnerModal = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            setEdit(false);
            setVisibleInner(false);
        }
        setEdit(false);
    };

    const triggerEdit = () => {
        !edit && setEdit(!edit);
        edit && form.submit();
    };

    const onCancelEdit = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            showFormValue(recordRow as IContractResponse | IBoothMember);
            setEdit(!edit);
        }
    };

    const showFormValue = (record: IContractResponse | IBoothMember) => {
        form.resetFields();
        initValuesForm(form, record);
    };

    const onDeleteRow = async (record: any) => {
        const result = await deleteAlert(record);
        if (result) {
            try {
                setIsLoading(true);
                if (record.memberCode) {
                    await deleteMemberContract(record.contractCode, record.memberCode);
                    await fetchData();
                } else {
                    await deleteContract(record.contractCode);
                    await fetchData();
                }
                await timerAlert("success", "ดำเนินการเสร็จสิ้น");
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onFinish = async (values: { [key: string]: any }) => {
        const data = filterValues(values, recordRow as IContractResponse | IBoothMember);
        const result = await editAlert(data, recordRow);
        if (result) {
            setIsLoading(true);
            setEdit(false);
            setVisibleInner(false);
            setVisible(false);
            try {
                setIsLoading(true);
                const contractCode = recordRow?.contractCode as string;
                if (visibleInner) {
                    await patchContractMember(contractCode, recordRow?.memberCode, data);
                } else {
                    await patchContract(contractCode, data);
                }
                await fetchData();
                setIsLoading(false);
                await timerAlert("success", "ดำเนินการเสร็จสิ้น");
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onPrintData = async () => {
        await timerAlert("success", "กำลังดำเนินการเตรียมไฟล์");
        setEdit(false);
        setVisible(false);
        setVisibleInner(false);
        try {
            await downloadContractPromise(recordRow?.contractCode as string);
            showNotification("นำออกเอกสารสำเร็จ", "success");
        } catch (error) {
            showNotification("นำออกเอกสารล้มเหลว", "error");
        }
    };

    return {
        dataStorage,
        handleSearch,
        rowState: {
            recordRow,
            setRecordRow,
            onDeleteRow,
        },
        formState: {
            form,
            onFinish,
            isLoading,
            triggerLoading,
            showFormValue,
        },
        search: {
            searchKey,
            searchKeys,
        },
        innerModal: {
            visibleInner,
            openInnerModal,
            closeInnerModal,
        },
        mainModal: {
            visible,
            openModal,
            closeModal,
            onCancelEdit,
            triggerEdit,
            onPrintData,
            edit,
        },
    };
}
export default useContractTable;
