import { Button, Card, Typography, Row } from "antd";
import React from "react";
import { usePDPA } from "../../providers/PDPAProviders";
import { CardContainer, Container } from "./index.style";

const { Title, Paragraph } = Typography;

const PDPAComponent = () => {
    const pdpa = usePDPA();
    const acceptCookies = window.localStorage.getItem("acceptCookies");
    if (acceptCookies) return null;

    return (
        <Container>
            <CardContainer>
                <Card>
                    <Title level={3}>เว็บไซต์มีการเก็บข้อมูลส่วนบุคคล</Title>
                    <Paragraph>
                        เรามีการเก็บข้อมูลส่วนบุคคลเพื่อเพิ่มประสิทธิภาพ
                        และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
                        คุณสามารถตรวจสอบการเก็บข้อมูลส่วนบุคคลของเว็บไซต์โดยคลิกที่
                        <a href="#PDPA" onClick={pdpa.showPDPA}>
                            นโยบายความเป็นส่วนตัว
                        </a>
                    </Paragraph>
                    <Row justify={"end"} style={{ gap: 12 }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                pdpa.handleAcceptCookies();
                                window.location.reload();
                            }}
                        >
                            ยอมรับ
                        </Button>
                    </Row>
                </Card>
            </CardContainer>
        </Container>
    );
};

export default PDPAComponent;
