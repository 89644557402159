import React from "react";
import { getBilling } from "../../../apis/transaction";
import { RolePagesType } from "../../../assets/typeEnumTranslation";
import { convertHashTag } from "../../../functions/convertHashTag";
import { IBillingResponse } from "../../../interfaces/Billing";

function usePaymentBilling(type: RolePagesType, boothCode: string, contractId?: string) {
    const [billing, setBilling] = React.useState<IBillingResponse>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);

    const fetchBilling = React.useCallback(async () => {
        const shirtNumber = ["rider", "labor"];
        const bc = shirtNumber.includes(type) ? boothCode : convertHashTag(boothCode);
        const res = await getBilling(bc, contractId ?? "");
        setBilling(res);
    }, [type, boothCode, contractId]);

    const loadingTrigger = () => setIsLoading((prev) => !prev);

    React.useEffect(() => {
        fetchBilling();
    }, [fetchBilling]);

    return {
        billing,
        boothCode,
        loadingTrigger,
        isLoading,
        fetchBilling,
        visible,
        setVisible,
    };
}

export default usePaymentBilling;
