import { Card, Typography } from "antd";
import styled from "styled-components";

export const Container = styled(Card).attrs({
    bordered: false,
})`
    max-height: 80vh;
    overflow-y: auto;
`;

export const EventList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    & .ant-badge-status {
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const MessageContainer = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export const BadgeLarge = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${(props: { color: string }) => props.color};
`;

export const Message = styled.div`
    display: flex;
    align-items: center;
`;

export const Paragraph = styled(Typography.Paragraph).attrs({
    strong: true,
})`
    margin: 0 !important;
    margin-left: 0.5rem !important;
`;
