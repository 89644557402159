import React from "react";
import { RowGroupInput } from "../../../common/CustomForm";
import validator from "../../../../assets/formValidator";
import { AutoComplete } from "antd";
import { FormInstance } from "antd/lib/form";
import thaiJson from "../../../../assets/thaiAddress";

type AddressFromProps = {
    addressKey?: string;
    provinceKey?: string;
    districtKey?: string;
    subDistrictKey?: string;
    zipCodeKey?: string;
    isNotRequired?: boolean;
    form: FormInstance<any>;
};

const AddressFrom: React.FC<AddressFromProps> = (props) => {
    const [ops, setOps] = React.useState<{ value: string }[]>([]);
    const options: {
        [key: string]: {
            province: string;
            amphure: string;
            tambon: string;
            zipCode: number;
        };
    } = {};
    const titleOptions: { value: string }[] = [];
    thaiJson.forEach((province) =>
        province.amphure.forEach((amphure) =>
            amphure.tambon.forEach((tambon) => {
                const key = `${tambon.name_th} ${amphure.name_th} ${province.name_th} ${tambon.zip_code}`;
                titleOptions.push({ value: key });
                options[key] = {
                    province: province.name_th,
                    amphure: amphure.name_th,
                    tambon: tambon.name_th,
                    zipCode: tambon.zip_code,
                };
            })
        )
    );

    const onSearch = (searchText: string) =>
        setOps(
            searchText ? titleOptions.filter((option) => option.value.includes(searchText)) : []
        );

    const onSelect = (data: string) => {
        props.form.setFieldsValue({
            [props.provinceKey || "province"]: options[data].province,
            [props.districtKey || "district"]: options[data].amphure,
            [props.subDistrictKey || "subDistrict"]: options[data].tambon,
            [props.zipCodeKey || "zipCode"]: options[data].zipCode,
        });
    };

    return (
        <>
            <RowGroupInput
                inputs={[
                    {
                        name: props.addressKey || "address",
                        label: "ที่อยู่",
                        rules: [{ required: !props.isNotRequired, message: "กรุณากรอกที่อยู่" }],
                        placeholder: "กรุณากรอกที่อยู่",
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: props.provinceKey || "province",
                        label: "จังหวัด",
                        rules: [{ required: !props.isNotRequired, message: "กรุณากรอกจังหวัด" }],
                        placeholder: "กรุณากรอกจังหวัด",
                        customInput: (
                            <AutoComplete
                                options={ops}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="กรุณากรอกจังหวัด"
                            />
                        ),
                    },
                    {
                        name: props.districtKey || "district",
                        label: "อำเภอ",
                        rules: [{ required: !props.isNotRequired, message: "กรุณากรอกอำเภอ" }],
                        placeholder: "กรุณากรอกอำเภอ",
                        customInput: (
                            <AutoComplete
                                options={ops}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="กรุณากรอกอำเภอ"
                            />
                        ),
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: props.subDistrictKey || "subDistrict",
                        label: "ตำบล",
                        rules: [{ required: !props.isNotRequired, message: "กรุณากรอกตำบล" }],
                        placeholder: "กรุณากรอกตำบล",
                        customInput: (
                            <AutoComplete
                                options={ops}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="กรุณากรอกตำบล"
                            />
                        ),
                    },
                    {
                        name: props.zipCodeKey || "zipCode",
                        label: "รหัสไปรษณีย์",
                        rules: [
                            { required: !props.isNotRequired, message: "กรุณากรอกรหัสไปรษณีย์" },
                            ...validator.zipCode,
                        ],
                        placeholder: "กรุณากรอกรหัสไปรษณีย์",
                        customInput: (
                            <AutoComplete
                                options={ops}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="กรุณากรอกรหัสไปรษณีย์"
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};

export default AddressFrom;
