import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
    margin: 1rem;
`;

export const ButtonContainer = styled(Button)`
    width: 100%;
`;
