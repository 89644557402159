import React from "react";
import { getDailyReport } from "../../apis/user";
import { typeDictionary } from "../../components/TransactionPage/ModalExportWithType/index.memo";
type DailyReport = {
    label: string;
    amount: number;
    duty: string;
};
function useDailyReport() {
    const [dailyReport, setDailyReport] = React.useState<DailyReport[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const fetchDaily = React.useCallback(async () => {
        try {
            setIsLoading(true);
            const result = await getDailyReport();
            const dailyReport: DailyReport[] = Object.keys(result).map((key) => ({
                duty: key,
                label: typeDictionary[key] || key,
                amount: result[key],
            }));
            setDailyReport(dailyReport);
        } catch (error) {
            console.log({ error });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const loadingTrigger = () => setIsLoading((prev) => !prev);

    React.useEffect(() => {
        fetchDaily();
    }, [fetchDaily]);

    return {
        loadingTrigger,
        isLoading,
        fetchDaily,
        dailyReport,
    };
}

export default useDailyReport;
