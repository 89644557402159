import { Button, Modal as M } from "antd";
import styled from "styled-components";
export const Modal = styled(M)`
    &.ant-modal-content {
        display: flex;
        flex-direction: column;
    }
    & .ant-modal-body {
        padding-bottom: 0.5rem !important;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 80vh;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const RowFooter = styled.div`
    display: flex;
    justify-content: center;
`;

export const CancelButton = styled(Button)`
    border-style: hidden;
    background-color: #ececec;
    border-radius: 2px;
`;

export const ConfirmButton = styled(Button)`
    border-style: hidden;
    background-color: #51a728;
    color: #fff;
    border-radius: 2px;
`;
