import { formatNumber } from "../../../functions/formatNumber";
import { IBilling } from "../../../interfaces/Billing";
import { Title } from "./index.styles";
import StatusTypeComponents from "./StatusTypeComponents";
import Table, { ColumnsType } from "antd/lib/table";
import PrintBillContent from "../../PrintBillContent";
import dayjs from "dayjs";

export const columnsMemo: ColumnsType<IBilling> = [
    {
        title: <Title>รายการ</Title>,
        dataIndex: "name",
        key: "name",
        render: (_: string, record: IBilling, __: number) => (
            <StatusTypeComponents record={record} showSubType />
        ),
    },
    {
        title: <Title>จำนวนเงิน</Title>,
        dataIndex: ["totalDebt", "remainingDebt"],
        key: "totalDebt",
        width: "20%",
        align: "center",
        render: (_: string, record: IBilling, __: number) => formatNumber(record.totalDebt),
    },
    {
        title: <Title>ยอดที่ชำระแล้ว</Title>,
        dataIndex: "remainingDebt",
        width: "20%",
        key: "remainingDebt",
        align: "center",
        render: (_: string, record: IBilling, __: number) =>
            formatNumber(record.totalDebt - record.remainingDebt),
    },
    {
        title: <Title></Title>,
        dataIndex: "",
        width: "5%",
        key: "",
        align: "center",
        render: (_: string, record: IBilling, __: number) => (
            <PrintBillContent
                type={record.type}
                dataSource={[record]}
                remainingDebt={record.remainingDebt}
                boothCode={record.boothCode}
                date={
                    record.type === "veggie"
                        ? dayjs(record.date).format("วันที่ DD เดือนMMMM BBBB")
                        : dayjs(record.date).format("เดือนMMMM BBBB")
                }
                icon
            />
        ),
    },
];

export const summaryMemo = (summary: { [key: string]: number }) => {
    return (
        <Table.Summary.Row style={{ backgroundColor: "rgba(81, 167, 40, 0.15)" }}>
            <Table.Summary.Cell index={0} align="center">
                <Title>รวม</Title>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
                <Title>{formatNumber(summary.totalDebt)}</Title>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center">
                <Title>{formatNumber(summary.balanceDebt)}</Title>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
        </Table.Summary.Row>
    );
};
