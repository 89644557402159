import React from "react";
import ApplicationThemeProvider from "./ApplicationThemeProvider";
import AuthProvider from "./AuthProvider";
import DashboardProvider from "./DashboardProvider";
import LayoutProvide from "./LayoutProvider";
import NotificationContext from "./NotificationProvider";
import PDPAProviders from "./PDPAProviders";

function Provider(props: { children: React.ReactNode }) {
    return (
        <PDPAProviders>
            <AuthProvider>
                <NotificationContext>
                    <ApplicationThemeProvider>
                        <DashboardProvider>
                            <LayoutProvide>{props.children}</LayoutProvide>
                        </DashboardProvider>
                    </ApplicationThemeProvider>
                </NotificationContext>
            </AuthProvider>
        </PDPAProviders>
    );
}

export default Provider;
